import { useUnit } from 'effector-react';
import type {
  ComponentPropsWithoutRef,
  PropsWithChildren,
  ReactNode,
} from 'react';

import type { ButtonProps } from '../button';
import { Button } from '../button';
import { Modal } from './modal';
import type { createPrompt } from './model';
import { MODAL_ID_KEY, useModalId } from './model';
import styles from './prompt.module.scss';

type PromptProps = PropsWithChildren<{
  title: ReactNode;
  description: ReactNode;
  confirmText: ReactNode;
  cancelText?: ReactNode;
  content?: ReactNode;

  classNames?: {
    content?: string;
  };

  model: Pick<
    ReturnType<typeof createPrompt>,
    'confirmed' | 'canceled' | '$$modal'
  >;

  prepend?: ReactNode;

  withClose?: boolean;

  confirmProps?: Partial<Omit<ButtonProps, 'onClick' | 'children'>>;
  cancelProps?: Partial<Omit<ButtonProps, 'onClick' | 'children'>>;

  props?: {
    content?: Partial<ComponentPropsWithoutRef<typeof Modal.Content>>;
  };

  id?: string;
}>;

const Prompt = ({
  model,
  id: externalId,
  title,
  classNames,
  prepend,
  description,
  confirmText,
  confirmProps = {},
  cancelText,
  cancelProps = {},
  children,
  withClose,
  props,
  content,
}: PromptProps) => {
  const modalId = useModalId(externalId);

  const units = useUnit({
    confirmed: model.confirmed,
    canceled: model.canceled,
  });

  const onCancelClick = units.canceled;
  const onConfirmClick = () => units.confirmed({ [MODAL_ID_KEY]: modalId });

  return (
    <Modal
      model={model.$$modal}
      id={modalId}
      content={
        <Modal.Content
          className={classNames?.content}
          {...(props?.content ?? {})}
        >
          {prepend}

          <Modal.Title className={styles.title} variant='subtitle2'>
            {title}
          </Modal.Title>

          <Modal.Description
            className={styles.description}
            variant='numberAdmin1'
          >
            {description}
          </Modal.Description>

          {content}

          <div className={styles.actions}>
            <Button
              variant='contained'
              color='primary'
              size='lg'
              onClick={onConfirmClick}
              {...confirmProps}
            >
              {confirmText}
            </Button>

            <Button
              variant='text'
              color='primary'
              size='lg'
              onClick={onCancelClick}
              {...cancelProps}
            >
              {cancelText ?? <>Cancel</>}
            </Button>
          </div>

          {withClose && <Modal.Close />}
        </Modal.Content>
      }
    >
      {children}
    </Modal>
  );
};

export { Prompt };
export type { PromptProps };
