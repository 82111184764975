import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { MouseEvent } from 'react';
import { forwardRef } from 'react';

import { CopyIcon } from '../../../icons';
import { IconButton } from '../base-button';
import { $$copy } from './copy-button.model';
import type { CopyButtonProps } from './copy-button.props';
import styles from './copy-button.module.scss';
import containerStyles from './copy-button-container.module.scss';

const CopyButton = forwardRef<HTMLButtonElement, CopyButtonProps>(
  (
    {
      className,
      value,
      size = 'md',
      customMessage,
      children,
      classes,
      onClick,
      ...props
    },
    ref
  ) => {
    const onCopy = useUnit($$copy.copyClicked);

    return (
      <div
        className={clsx(
          containerStyles.container,
          containerStyles[size],
          classes?.root
        )}
      >
        {children}

        <IconButton
          ref={ref}
          className={clsx(
            styles.button,
            styles[size],
            className,
            classes?.button
          )}
          title={customMessage ? customMessage : `Copy '${value}'`}
          onClick={(event) => {
            onCopy(customMessage ? { value, message: customMessage } : value);

            onClick?.(event as MouseEvent<HTMLButtonElement>);
          }}
          {...props}
        >
          <CopyIcon className={classes?.icon} />
        </IconButton>
      </div>
    );
  }
);

export { CopyButton };
export type { CopyButtonProps };
