import type { i18n } from 'i18next';
import { string } from 'yup';

import {
  isIncludeLowercase,
  isIncludeSpecSymbols,
  isIncludeUppercase,
} from '@kuna-pay/utils/validation';

/**
 * Until there is no way to pass global i18n instance
 * users of this class should subscribe to local i18n,
 * so then language changes validators will update their messages
 */
class SessionValidationRules {
  public static email = (i18next: i18n) => {
    const t = i18next.getFixedT(
      null,
      'core',
      'entities.session.model.validation.email'
    );

    return string().required(t('required')!).email(t('email')!);
  };

  public static password = {
    default: (i18next: i18n) => {
      const t = i18next.getFixedT(
        null,
        'core',
        'entities.session.model.validation.password'
      );

      return string().required(t('required')!);
    },

    withRequirements: (i18next: i18n) => {
      const t = i18next.getFixedT(
        null,
        'core',
        'entities.session.model.validation.password-with-requirements'
      );

      return string()
        .required(t('required')!)
        .test('include-lowercase', t('include-lowercase')!, isIncludeLowercase)
        .test('include-uppercase', t('include-uppercase')!, isIncludeUppercase)
        .test('include-spec', t('include-spec')!, isIncludeSpecSymbols)
        .max(40)
        .min(8, t('min')!);
    },
  };
}

export { SessionValidationRules };
