import { useUnit } from 'effector-react';
import type { PropsWithChildren, ReactNode } from 'react';
import { Fragment, useLayoutEffect } from 'react';

import { Navigate } from '@kuna-pay/ui/router';
import { ErrorMatcher } from '@kuna-pay/core/shared/api';

import { routes } from '@kuna-pay/merchant/shared/router';

import { $$session } from '../../model';

type CompanyAuthorizedGuardProps = PropsWithChildren<{}> & {
  fallback?: ReactNode;
};

/**
 * We assume that NonExpiredTokensGuard is used before this guard.
 */
const CompanyAuthorizedGuard = ({
  children,
  fallback,
}: CompanyAuthorizedGuardProps) => {
  const [isLoading, data, error, status, load] = useUnit([
    $$session.$$merchantQuery.$pending,
    $$session.$$merchantQuery.$data,
    $$session.$$merchantQuery.$error,
    $$session.$$merchantQuery.$status,
    $$session.$$merchantQuery.start,
  ]);

  /**
   * We assume that query.$pending is false by default.
   * So on first render we have to consider that query is not started yet(will be started in useEffect).
   *
   * https://kunatech.atlassian.net/browse/KUPAY-1830
   */
  const isInitialLoad =
    !isLoading && ((!data && !error) || (!!error && status === 'fail'));

  useLayoutEffect(() => {
    load();
  }, []);

  if (isLoading || isInitialLoad) {
    return <>{fallback}</>;
  }

  if (!!error || !data) {
    if (error && isForbiddenError(error)) {
      return <Navigate to={routes.merchant.list} />;
    }

    return <Navigate to={routes.auth.signIn} />;
  }

  return (
    <Fragment
      /**
       * This is workaround for reopening(remounting) children pages
       * when we switch company in header
       *
       * This is work because Gate is build on top of useEffect,
       * so changing key will cause Gate.close
       * and Gate.open on next render
       */
      key={data.id}
    >
      {children}
    </Fragment>
  );
};

function isForbiddenError(error: unknown) {
  return ErrorMatcher.createErrorMatcher('FORBIDDEN')(error);
}

export { CompanyAuthorizedGuard };
