import { z } from 'zod';

import { AssetType } from '@kuna-pay/core/generated/public/graphql';

const AssetSchema = z.object({
  type: z.nativeEnum(AssetType),
  payload: z.any(),
  code: z.string(),
  name: z.string(),
  precision: z.number(),
});

export { AssetSchema };
