import { Suspense } from 'react';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';

import { AlreadyVerifiedPageLoading } from './page.loading';

const Page = lazyWithRetryAndRefresh(
  'AlreadyVerifiedPage',
  () => import('./page.component')
);

const AlreadyVerifiedPage = {
  Component: () => (
    <Suspense fallback={<AlreadyVerifiedPageLoading />}>
      <Page />
    </Suspense>
  ),
};

export { AlreadyVerifiedPage };
