import { Suspense } from 'react';
import type { RouteObject } from 'react-router-dom';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';

import {
  MainLayout,
  MainLayoutLoading,
} from '@kuna-pay/merchant/widgets/layout';

const Page = lazyWithRetryAndRefresh(
  'NotificationsPage',
  () => import('./page.component')
);

const NotificationsPages: RouteObject[] = [
  {
    path: '/notifications',
    Component: () => (
      <Suspense
        fallback={
          <MainLayout.Merchant.Content>
            <MainLayoutLoading />
          </MainLayout.Merchant.Content>
        }
      >
        <Page />
      </Suspense>
    ),
  },
];

export { NotificationsPages };
