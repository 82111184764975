import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { modelView } from '@kuna-pay/utils/effector';
import { useMediaQuery } from '@kuna-pay/utils/react/use-media-query';
import { Modal } from '@kuna-pay/ui/ui/modal';
import { State } from '@kuna-pay/core/shared/lib/state';
import { useTypedGate } from '@kuna-pay/core/shared/router';

import { ThanksForYourFeedback } from '../../ui/thanks-for-your-feedback';
import { CollectFeedbackForm } from './collect-feedback-form.component';
import { RateForm } from './rate-form.component';
import { RateYourExperienceModel } from './rate-your-experience.model';
import { RateYourExperienceSteps } from './rate-your-experience.types';
import styles from './rate-your-experience.module.scss';

type RateYourExperienceProps = {
  i18n: {
    rate: {
      title: string;
    };
  };
};

const RateYourExperience = modelView(
  RateYourExperienceModel,
  ({ i18n }: RateYourExperienceProps) => {
    const $$model = RateYourExperienceModel.useModel();

    const isMobileOrLess = useMediaQuery('(max-width: 580px)');

    useTypedGate({
      Gate: $$model.Gate,
      props: { i18n: useTranslation('core') },
    });

    return (
      <Modal
        id={$$model.ID}
        model={$$model.$$modal}
        modal={isMobileOrLess}
        content={
          <Modal.Content
            className={clsx({ [styles.container]: !isMobileOrLess })}
            size='sm'
          >
            <Modal.Close
              className={clsx({ [styles.close]: !isMobileOrLess })}
            />

            <State.Switch $$model={$$model.$$state}>
              <State.Case
                value={RateYourExperienceSteps.Rate}
                view={<RateForm i18n={i18n.rate} />}
              />

              <State.Case
                value={RateYourExperienceSteps.Feedback}
                view={<CollectFeedbackForm />}
              />

              <State.Case
                value={RateYourExperienceSteps.Success}
                view={<ThanksForYourFeedback containerType='popup' />}
              />
            </State.Switch>
          </Modal.Content>
        }
      >
        <div
          style={{
            // Modal need element to mount to,
            // but we don't want this element to influence layout (flex gap)
            position: 'fixed',
          }}
        />
      </Modal>
    );
  }
);

export { RateYourExperience };

export type { RateYourExperienceProps };
