import type { Feature } from '@kuna-pay/core/generated/public/graphql';
import { createEQuery } from '@kuna-pay/core/shared/lib/effector-query';

import { staticFeatures } from '../api';

const $$staticFeatures = createEQuery({
  initialData: [] as Feature[],

  query: async (_: void) => {
    const features = await staticFeatures({ name: true, value: true })();

    return features;
  },
});

export { $$staticFeatures };
