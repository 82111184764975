import { Suspense } from 'react';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';
import { AdaptivePaper } from '@kuna-pay/core/shared/ui/adaptive-paper';

const Page = lazyWithRetryAndRefresh(
  'OutdatedLinkChangePasswordPage',
  () => import('./page.component')
);

const OutdatedLinkChangePasswordPage = {
  Component: () => (
    <Suspense fallback={<AdaptivePaper.Loading height={486} />}>
      <Page />
    </Suspense>
  ),
};

export { OutdatedLinkChangePasswordPage };
