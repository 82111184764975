import { Suspense } from 'react';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';

import { ConfirmEmailPageLoading } from './page.loading';

const Page = lazyWithRetryAndRefresh(
  'ConfirmEmailPage',
  () => import('./page.component')
);

const ConfirmEmailPage = {
  Component: () => (
    <Suspense fallback={<ConfirmEmailPageLoading />}>
      <Page />
    </Suspense>
  ),
};

export { ConfirmEmailPage };
