import { memo } from 'react';

import {
  type PasswordRequirementItem,
  PasswordRequirements,
} from './password-requirements';
import styles from './password-requirements.mobile.module.scss';

type MobilePasswordRequirementsProps = {
  requirements: PasswordRequirementItem[];
};

const MobilePasswordRequirements = memo(
  ({ requirements }: MobilePasswordRequirementsProps) => (
    <PasswordRequirements.Provider requirements={requirements}>
      <PasswordRequirements.Root className={styles.root}>
        <PasswordRequirements.Title variant='numbers1' />

        <PasswordRequirements.StrengthIndicator
          className={styles.strengthIndicator}
        />

        <PasswordRequirements.Description>
          {requirements.map((requirement) => (
            <PasswordRequirements.Requirement.Provider
              key={requirement.text}
              value={requirement}
            >
              <PasswordRequirements.Requirement.Root
                className={styles.requirement}
              >
                <PasswordRequirements.Requirement.Icon width={16} height={16} />

                <PasswordRequirements.Requirement.Text
                  className={styles.requirementText}
                  variant='small2'
                >
                  {requirement.text}
                </PasswordRequirements.Requirement.Text>
              </PasswordRequirements.Requirement.Root>
            </PasswordRequirements.Requirement.Provider>
          ))}

          <PasswordRequirements.Divider className={styles.divider} />

          <PasswordRequirements.Hint.Root>
            <PasswordRequirements.Hint.Icon width={16} height={16} />

            <PasswordRequirements.Hint.Text variant='small2' />
          </PasswordRequirements.Hint.Root>
        </PasswordRequirements.Description>
      </PasswordRequirements.Root>
    </PasswordRequirements.Provider>
  )
);

export { MobilePasswordRequirements };
