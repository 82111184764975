import {
  SuccessOutput,
  QueryMarkManyNotificationAsReadArgs,
} from '@kuna-pay/merchant/generated/graphql';
import { subscription, query } from '@kuna-pay/merchant/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const markManyNotificationAsReadRequest = (
  select: Select<SuccessOutput> | string
) => `query markManyNotificationAsRead($ids: [String!]!) {
  data: markManyNotificationAsRead(ids: $ids) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'SuccessOutput')}`;
export const markManyNotificationAsRead =
  (select: Select<SuccessOutput> | string) =>
  (args: QueryMarkManyNotificationAsReadArgs) =>
    query<SuccessOutput>(markManyNotificationAsReadRequest(select), args);
