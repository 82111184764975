import clsx from 'clsx';
import { memo, useState, type VideoHTMLAttributes } from 'react';

import autoConvertGuideVideoUrl from '../../../../public/guide-v1-auto-conv.mp4?url';
import autoConvertGuideVideoPreviewUrl from '../../../../public/guide-v1-auto-conv-preview.png?url';
import invoiceGuideVideoUrl from '../../../../public/guide-v1-invoice-1.mp4?url';
import invoiceGuideVideoPreviewUrl from '../../../../public/guide-v1-invoice-1-preview.png?url';
import membersGuideVideoUrl from '../../../../public/guide-v1-members.mp4?url';
import membersGuideVideoPreviewUrl from '../../../../public/guide-v1-members-preview.png?url';
import sepaGuideVideoUrl from '../../../../public/guide-v1-sepa.mp4?url';
import sepaGuideVideoPreviewUrl from '../../../../public/guide-v1-sepa-preview.png?url';

type TutorialVideoProps = VideoHTMLAttributes<HTMLVideoElement>;

const TutorialVideo = memo(({ className, ...props }: TutorialVideoProps) => {
  const [hovering, setHover] = useState(false);
  const [focused, setFocused] = useState(false);

  return (
    <video
      className={clsx(className)}
      controls={hovering || focused}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      {...props}
    />
  );
});

const InvoiceTutorialVideo = memo(
  (props: Omit<TutorialVideoProps, 'poster' | 'src'>) => (
    <TutorialVideo
      poster={invoiceGuideVideoPreviewUrl}
      src={invoiceGuideVideoUrl}
      preload='none'
      {...props}
    />
  )
);

const SEPATutorialVideo = memo(
  (props: Omit<TutorialVideoProps, 'poster' | 'src'>) => (
    <TutorialVideo
      poster={sepaGuideVideoPreviewUrl}
      src={sepaGuideVideoUrl}
      preload='none'
      {...props}
    />
  )
);
const MembersTutorialVideo = memo(
  (props: Omit<TutorialVideoProps, 'poster' | 'src'>) => (
    <TutorialVideo
      poster={membersGuideVideoPreviewUrl}
      src={membersGuideVideoUrl}
      preload='none'
      {...props}
    />
  )
);
const AutoConvertTutorialVideo = memo(
  (props: Omit<TutorialVideoProps, 'poster' | 'src'>) => (
    <TutorialVideo
      poster={autoConvertGuideVideoPreviewUrl}
      src={autoConvertGuideVideoUrl}
      preload='none'
      {...props}
    />
  )
);

export type { TutorialVideoProps };
export {
  AutoConvertTutorialVideo,
  InvoiceTutorialVideo,
  MembersTutorialVideo,
  SEPATutorialVideo,
  TutorialVideo,
};
