import clsx from 'clsx';
import type { ComponentPropsWithoutRef } from 'react';
import { forwardRef } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { ButtonProps } from '@kuna-pay/ui/ui/button';
import { Button } from '@kuna-pay/ui/ui/button';

import { DataGridCell } from './data';
import styles from './actions.module.scss';

type TableActionCellProps = ComponentPropsWithoutRef<typeof DataGridCell>;

const DataGridActionCell = ({ className, ...props }: TableActionCellProps) => (
  <DataGridCell
    className={clsx(styles.cell, className)}
    stopPropagation
    {...props}
  />
);

const DataGridActionCellButton = createCompoundComponent(
  () =>
    forwardRef<HTMLButtonElement, ButtonProps>(
      ({ className, ...props }, ref) => (
        <Button
          ref={ref}
          className={clsx(styles.button, className)}
          {...props}
        />
      )
    ),
  {
    Root: forwardRef<
      HTMLButtonElement,
      ComponentPropsWithoutRef<typeof Button.Root>
    >(({ className, ...props }, ref) => (
      <Button.Root
        ref={ref}
        className={clsx(styles.button, className)}
        {...props}
      />
    )),

    Text: Button.Text,

    className: styles.button,
  }
);

export { DataGridActionCell, DataGridActionCellButton };
