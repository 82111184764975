import { objectEntries } from '@kuna-pay/utils/typescript';

import type { SortOrder } from '../types';

function sortMapToOrderByArray<Row extends Record<string, unknown>>(
  sortOrderMap: Partial<Record<keyof Row, SortOrder>>
): { [Key in keyof Row]: SortOrder }[] {
  return objectEntries(sortOrderMap).map(
    ([key, sortOrder]) =>
      ({ [key]: sortOrder } as { [Key in keyof Row]: SortOrder })
  );
}

export { sortMapToOrderByArray };
