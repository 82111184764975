import { useUnit } from 'effector-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '@kuna-pay/utils/react/use-media-query';
import { Button } from '@kuna-pay/ui/ui/button';
import { Modal } from '@kuna-pay/ui/ui/modal';
import { Field, Form } from '@kuna-pay/form';

import { RateYourExperienceModel } from './rate-your-experience.model';
import styles from './collect-feedback-form.module.scss';

const CollectFeedbackForm = memo(() => {
  const $$model = RateYourExperienceModel.useModel();
  const { t } = useTranslation('core');
  const isMobileOrLess = useMediaQuery('(max-width: 580px)');
  const [onBackFromFeedbackClick] = useUnit([$$model.onBackFromFeedbackClick]);

  return (
    <>
      <Modal.Title
        className={styles.title}
        variant='subtitle2'
        center={isMobileOrLess}
      >
        {t('shared.feedback.rate-your-experience.feedback.title')}
      </Modal.Title>

      <Modal.Description
        className={styles.description}
        variant='body3'
        center={isMobileOrLess}
      >
        {t('shared.feedback.rate-your-experience.feedback.description')}
      </Modal.Description>

      <Form
        className={styles.form}
        name='rate-your-experience-feedback-form'
        use={$$model.$$feedbackForm}
      >
        <Field.TextAreaField
          className={styles.feedback}
          field={$$model.$$feedbackForm.fields.feedback!}
          label={t(
            'shared.feedback.rate-your-experience.feedback.fields.feedback.label'
          )}
          placeholder={t(
            'shared.feedback.rate-your-experience.feedback.fields.feedback.placeholder'
          )}
          variant='outlined'
          size='md'
          maxLength={$$model.MAX_DESCRIPTION_LENGTH}
        />

        <div className={styles.actions}>
          <Button
            variant='contained'
            color='secondary'
            size='lg'
            onClick={onBackFromFeedbackClick}
          >
            {t('shared.feedback.rate-your-experience.feedback.back')}
          </Button>

          <Form.Submit
            className={styles.submit}
            variant='contained'
            color='primary'
            size='lg'
          >
            {t('shared.feedback.rate-your-experience.feedback.fields.submit')}
          </Form.Submit>
        </div>
      </Form>
    </>
  );
});

export { CollectFeedbackForm };
