import type * as RadixRadioGroup from '@radix-ui/react-radio-group';
import type { LabelHTMLAttributes, ReactNode } from 'react';
import { Fragment } from 'react';

import { Radio } from '@kuna-pay/ui/ui/radio';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';

import type { FieldModel } from '../lib';
import { useField } from '../lib';

type RadioListOption = { label: ReactNode; value: string };

type RadioListProps<T = string | null> = {
  field: FieldModel<T>;
  options: RadioListOption[];

  renderOption?: (option: RadioListOption) => ReactNode;

  renderLabel?: (option: RadioListOption) => ReactNode;

  hideControl?: boolean;

  className?: string;
  props?: {
    root?: Partial<RadixRadioGroup.RadioGroupProps>;
    row?: Partial<LabelHTMLAttributes<HTMLLabelElement>>;
    itemText?: Partial<TypographyProps>;
    control?: Partial<
      RadixRadioGroup.RadioGroupItemProps & {
        indicatorProps?: RadixRadioGroup.RadioGroupIndicatorProps;
      }
    >;
  };
};

const RadioListField = <T extends string | null = string | null>({
  options,
  field,
  renderOption,
  renderLabel,
  props,
  className,
  hideControl,
}: RadioListProps<T>) => {
  const { onBlur, onChange, onFocus, value, disabled } = useField(field);

  return (
    <Radio.Root
      className={className}
      value={value ?? 'initial'}
      onValueChange={(value) => onChange(value as T)}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
      {...(props?.root ?? {})}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        ...(props?.root?.style ?? {}),
      }}
    >
      {options.map((option) =>
        renderOption ? (
          <Fragment key={option.value}>{renderOption(option)}</Fragment>
        ) : (
          <Radio.Row key={option.value} {...(props?.row ?? {})}>
            {renderLabel ? (
              renderLabel(option)
            ) : (
              <Radio.ItemText {...(props?.itemText ?? {})}>
                {option.label}
              </Radio.ItemText>
            )}

            {!hideControl && (
              <Radio.Control {...(props?.control ?? {})} value={option.value} />
            )}
          </Radio.Row>
        )
      )}
    </Radio.Root>
  );
};

export { RadioListField };
export type { RadioListProps };
