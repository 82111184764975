import type { SlotProps } from '@radix-ui/react-slot';
import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';
import type { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';
import { memo } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { IconProps } from '@kuna-pay/ui/icons';
import type { IAtomicRRRoute } from '@kuna-pay/ui/router';
import { NavLink } from '@kuna-pay/ui/router';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import { ExceedLimitAlert } from '../../exceed-limit-alert';
import styles from './nav.module.scss';

const Nav = createCompoundComponent(() => () => null, {
  DesktopRoot: ({ children }: PropsWithChildren) => {
    const isAlertShown = ExceedLimitAlert.useIsVisible();

    return (
      <div className={clsx(styles.desktopRoot)}>
        <div
          className={clsx(styles.desktopNavRoot, {
            [styles.isAlertShown]: isAlertShown,
          })}
        >
          {children}
        </div>
      </div>
    );
  },

  Item: createCompoundComponent(() => () => null, {
    Root: memo(
      ({ asChild, to, children, className, ...props }: NavItemRootProps) => {
        if (asChild) {
          return (
            <Slot className={clsx(styles.itemRoot, className)} {...props}>
              {children}
            </Slot>
          );
        }

        return (
          <NavLink to={to} onClick={(e) => e.currentTarget.blur()}>
            {({ isActive }) => (
              <NavItemContext.Provider value={isActive}>
                <div
                  className={clsx(styles.itemRoot, className, {
                    [styles.active]: isActive,
                  })}
                  {...props}
                >
                  {children}
                </div>
              </NavItemContext.Provider>
            )}
          </NavLink>
        );
      }
    ),

    Text: memo((props: Partial<TypographyProps>) => {
      const isActive = useContext(NavItemContext);

      return (
        <Typography
          as='p'
          className={styles.itemRootText}
          variant={isActive ? 'subtitle4' : 'subtitle5'}
          nowrap
          {...props}
        />
      );
    }),

    Icon: memo((props: SlotProps) => {
      const Component: FC<Pick<IconProps, 'width' | 'height'> & SlotProps> =
        Slot;

      return <Component width={24} height={24} {...props} />;
    }),
  }),
});

const NavItemContext = createContext(false);

type NavItemRootProps = HTMLAttributes<HTMLDivElement> &
  (
    | {
        to: IAtomicRRRoute<any>;
        asChild?: false;
      }
    | {
        to?: never;
        asChild: true;
      }
  );

export { Nav };
