import type { RouteObject } from 'react-router-dom';

import InvoicesRootPage from '@kuna-pay/merchant/pages/invoices/page.component';

import { CreateInvoicePage } from './pages/create';
import { InvoiceDetailsPage } from './pages/details';
import { InvoicesListPage } from './pages/list';

const InvoicePages: RouteObject[] = [
  {
    path: '/invoices/*',
    Component: InvoicesRootPage,
    children: [
      {
        index: true,
        Component: InvoicesListPage.Component,
      },
      {
        path: 'create',
        Component: CreateInvoicePage.Component,
      },
      {
        path: ':id',
        Component: InvoiceDetailsPage.Component,
      },
    ],
  },
];

export { InvoicePages };
