import * as RadixModal from '@radix-ui/react-dialog';
import clsx from 'clsx';
import { useStoreMap, useUnit } from 'effector-react';
import type {
  ElementRef,
  HTMLAttributes,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from 'react';
import { memo } from 'react';
import { useLayoutEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { createContext, forwardRef, useContext, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { createCompoundComponent } from '@kuna-pay/utils/ui';

import { ArrowLeftIcon, Cross24Icon } from '../../icons';
import type { IconButtonProps } from '../button';
import { IconButton } from '../button';
import type { PaperProps } from '../paper';
import { Paper } from '../paper';
import type { TypographyProps } from '../typography';
import { Typography } from '../typography';
import type { ModalModel } from './model';
import { MODAL_ID_KEY, useModalId } from './model';
import styles from './modal.module.scss';

type PropsWithClickableChildren = {
  children: ReactElement<{ onClick: () => any; disabled?: boolean }>;
};

type ModalContentSize = 'sm' | 'md' | 'xxl';

type ModalProps = {
  content: ReactNode;

  model: Pick<ModalModel, 'close' | 'open' | 'backClicked' | '$isOpen'>;

  id?: string;

  classes?: {
    overlay?: string;
  };

  modal?: boolean;
};

const ModalSizeContext = createContext<ModalContentSize>('md');
const ModalContentRefContext = createContext<
  MutableRefObject<HTMLDivElement | null>
>({
  current: null,
});

const ModalContext = createContext<{
  isWithinModal: boolean;
  onClose: () => void;
  onBackClick: () => void;
}>({
  isWithinModal: false,
  onClose: () => {},
  onBackClick: () => {},
});

const ReactRoot =
  document.querySelector<HTMLDivElement>('#root') ?? document.body;

const Modal = createCompoundComponent(
  () =>
    ({
      id: externalId,
      model,
      content,
      children,
      classes,
      modal,
    }: PropsWithChildren<ModalProps>) => {
      const id = useModalId(externalId);
      const ref = useRef<HTMLDivElement>(null);

      const { openModal, onClose, onBackClick } = useUnit({
        onClose: model.close,
        openModal: model.open,
        onBackClick: model.backClicked,
      });

      const contextValue = useMemo(
        () => ({
          isWithinModal: true,
          onClose,
          onBackClick,
        }),
        [onClose, onBackClick]
      );

      const isOpen = useStoreMap({
        store: model.$isOpen,
        keys: [id],
        fn: (kv, [id]) => !!kv[id],
      });

      const onTriggerClick = () => openModal({ [MODAL_ID_KEY]: id });

      return (
        <ModalContext.Provider value={contextValue}>
          <RadixModal.Root open={isOpen} modal={modal}>
            <RadixModal.Trigger onClick={onTriggerClick} asChild>
              {children}
            </RadixModal.Trigger>

            <RadixModal.Portal
              // Need mount to #root bcs NotificationContainer exists only inside react mounted node
              // Notifications should be on top of modals
              container={ReactRoot}
            >
              <RadixModal.Overlay
                className={clsx(styles.overlay, classes?.overlay)}
                onClick={onClose}
              />

              <ModalContentRefContext.Provider value={ref}>
                <RadixModal.Content ref={ref} onEscapeKeyDown={onClose} asChild>
                  {content}
                </RadixModal.Content>
              </ModalContentRefContext.Provider>
            </RadixModal.Portal>
          </RadixModal.Root>
        </ModalContext.Provider>
      );
    },
  {
    Title: forwardRef<HTMLDivElement, TypographyProps>(
      ({ as = 'h6', children, ...props }, ref) => (
        <RadixModal.Title ref={ref} asChild>
          <Typography as={as} center {...props}>
            {children}
          </Typography>
        </RadixModal.Title>
      )
    ),

    Description: forwardRef<HTMLDivElement, TypographyProps>(
      ({ as = 'h6', children, className, ...props }, ref) => (
        <RadixModal.Description ref={ref} asChild>
          <Typography
            className={clsx(styles.description, className)}
            as={as}
            center
            {...props}
          >
            {children}
          </Typography>
        </RadixModal.Description>
      )
    ),

    Close: ({ className, ...props }: Omit<IconButtonProps, 'onClick'>) => {
      const { onClose } = useContext(ModalContext);
      const size = useContext(ModalSizeContext);

      return (
        <RadixModal.Close asChild>
          <IconButton
            className={clsx(
              styles.closeButton,
              {
                [styles.sm]: size === 'sm',
                [styles.md]: size === 'md',
                [styles.xxl]: size === 'xxl',
              },
              className
            )}
            onClick={onClose}
            {...props}
          >
            <Cross24Icon />
          </IconButton>
        </RadixModal.Close>
      );
    },

    ActionClose: forwardRef<HTMLButtonElement, RadixModal.DialogCloseProps>(
      ({ onClick, ...props }, ref) => {
        const { onClose } = useContext(ModalContext);

        return (
          <RadixModal.Close
            ref={ref}
            onClick={(event) => {
              onClick?.(event);
              onClose();
            }}
            {...props}
          />
        );
      }
    ),

    Back: forwardRef<HTMLButtonElement, IconButtonProps>(
      ({ className, ...props }, ref) => {
        const { onBackClick } = useContext(ModalContext);
        const size = useContext(ModalSizeContext);

        return (
          <IconButton
            ref={ref}
            className={clsx(
              styles.backButton,
              {
                [styles.sm]: size === 'sm',
                [styles.md]: size === 'md',
                [styles.xxl]: size === 'xxl',
              },
              className
            )}
            onClick={onBackClick}
            {...props}
          >
            <ArrowLeftIcon />
          </IconButton>
        );
      }
    ),

    Content: forwardRef<
      HTMLDivElement,
      PaperProps & { size?: ModalContentSize }
    >(({ className, size = 'sm', ...props }, ref) => (
      <ModalSizeContext.Provider value={size}>
        <Paper
          ref={ref}
          className={clsx(
            styles.content,
            {
              [styles.sm]: size === 'sm',
              [styles.contentSm]: size === 'sm',
              [styles.md]: size === 'md',
              [styles.contentMd]: size === 'md',
              [styles.xxl]: size === 'xxl',
              [styles.contentXxl]: size === 'xxl',
            },
            className
          )}
          {...props}
        />
      </ModalSizeContext.Provider>
    )),

    //TODO(tech-debt): rework
    Aside: memo(
      ({
        className,
        side,
        ...props
      }: HTMLAttributes<HTMLDivElement> & {
        side: 'right';
      }) => {
        const ref = useRef<ElementRef<'div'>>(null);
        const contentRef = useContext(ModalContentRefContext)!;

        const current = contentRef.current;
        const parent = current?.parentElement;

        const [style] = useState(() => {
          if (!current) return { top: 0, left: 0 };

          const rect = current.getBoundingClientRect();

          return {
            top: rect.top,
            left: rect.left + rect.width + 12,
          };
        });

        useLayoutEffect(() => {
          if (!parent || !current || !ref.current) return;

          const $html = document.querySelector('html')!;

          function handleResize() {
            if (window.matchMedia('(max-width: 1200px)').matches) {
              return;
            }

            if (ref.current && current) {
              const rect = current.getBoundingClientRect();

              const top = rect.top + $html.scrollTop;
              const left = rect.left + rect.width + 12;
              ref.current.style.top = `${top}px`;
              ref.current.style.left = `${left}px`;
            }
          }

          const observer = new ResizeObserver((entries) => {
            entries.forEach((_entry) => {
              handleResize();
            });
          });

          window.addEventListener('resize', handleResize);
          observer.observe(parent);

          return () => {
            observer.disconnect();
            window.removeEventListener('resize', handleResize);
          };
        }, [current]);

        if (!current || !parent) {
          return null;
        }

        return createPortal(
          <div
            ref={ref}
            className={clsx(styles.aside, className, {
              [styles.right]: side === 'right',
            })}
            style={style}
            {...props}
          />,
          parent
        );
      }
    ),

    Context: ModalContext,
  }
);

export { Modal };
export type { ModalProps, PropsWithClickableChildren };
