import clsx from 'clsx';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { modelView } from '@kuna-pay/utils/effector';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import { TimeoutedResendModel } from './timeouted-resend.model';
import styles from './timeouted-resend.module.scss';

type TimeoutedResendButtonProps = Partial<
  Omit<TypographyProps, 'children' | 'onClick'>
> & {
  i18n?: {
    action: (params: { time: number }) => string;
    'action-loading': (params: { time: number }) => string;
  };
};

const TimeoutedResendButton = modelView(
  TimeoutedResendModel,
  ({ className, i18n, ...props }: TimeoutedResendButtonProps) => {
    const $$model = TimeoutedResendModel.useModel();

    const { t } = useTranslation('core');

    const { loading, time, onResendClick } = useUnit({
      loading: $$model.$pending,
      time: $$model.$time,
      onResendClick: $$model.resendClicked,
    });

    i18n ??= {
      action: ({ time: _ }) =>
        t('features.auth.abstract.timeouted-resend.ui.action', {
          ns: 'core',
        }),

      'action-loading': ({ time }) =>
        t('features.auth.abstract.timeouted-resend.ui.action-loading', {
          replace: { time },
          ns: 'core',
        }),
    };

    return (
      //FIXME: Replace <Text/> with <Button/>
      <Typography
        className={clsx(styles.text, className, { [styles.loading]: loading })}
        variant='subtitle3'
        onClick={() => onResendClick()}
        {...props}
      >
        {!loading ? i18n.action({ time }) : i18n['action-loading']({ time })}
      </Typography>
    );
  }
);

TimeoutedResendButton.displayName = 'ResendButton';

export { TimeoutedResendButton };
export type { TimeoutedResendButtonProps };
