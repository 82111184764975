import { createContext, useContext } from 'react';

type UserMenuDropdownContextValue = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const HeaderMenuDropdownContext = createContext<UserMenuDropdownContextValue>({
  isOpen: false,
  setIsOpen: () => {},
});

const useHeaderMenuDropdownContext = () => {
  const context = useContext(HeaderMenuDropdownContext);

  if (!context) {
    throw new Error(
      'useUserMenuDropdownContext must be used within UserMenuDropdownContextProvider'
    );
  }

  return context;
};

const HeaderMenuDropdownContextProvider = HeaderMenuDropdownContext.Provider;

export { HeaderMenuDropdownContextProvider, useHeaderMenuDropdownContext };
