import clsx from 'clsx';
import type { HTMLAttributes, PropsWithChildren } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { IconProps } from '@kuna-pay/ui/icons';
import { CheckIcon, InfoAlertIcon } from '@kuna-pay/ui/icons';
import type { PaperProps } from '@kuna-pay/ui/ui/paper';
import { Paper } from '@kuna-pay/ui/ui/paper';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import {
  PasswordRequirementsContextProvider,
  RequirementItemContextProvider,
  usePasswordRequirementItemContext,
  usePasswordRequirements,
  usePasswordRequirementsContext,
} from './password-requirements.model';
import type { PasswordRequirementItem } from './password-requirements.types';
import styles from './password-requirements.module.scss';

const PasswordRequirements = {
  Provider: ({
    requirements,
    children,
  }: PropsWithChildren & { requirements: PasswordRequirementItem[] }) => {
    const payload = usePasswordRequirements(requirements);

    return (
      <PasswordRequirementsContextProvider value={payload}>
        {children}
      </PasswordRequirementsContextProvider>
    );
  },

  Root: memo(({ className, ...props }: PaperProps) => (
    <Paper className={clsx(styles.root, className)} {...props} />
  )),

  Title: memo(({ className, ...props }: TypographyProps) => {
    const { title, mode } = usePasswordRequirementsContext();

    return (
      <Typography
        className={clsx(styles.title, className)}
        data-mode={mode}
        {...props}
      >
        {title}
      </Typography>
    );
  }),

  StrengthIndicator: memo(
    ({
      className,
      ...props
    }: Omit<HTMLAttributes<HTMLDivElement>, 'style'>) => {
      const { mode, requirements, totalPassed } =
        usePasswordRequirementsContext();

      return (
        <div
          className={clsx(styles.strengthIndicatorContainer, className)}
          style={{
            gridTemplateColumns: `repeat(${requirements.length}, 1fr)`,
          }}
          {...props}
        >
          {requirements.map((_, index) => {
            const isFirstIndexAndTotalPassZero =
              index === 0 && totalPassed === 0;

            const isPassed = isFirstIndexAndTotalPassZero
              ? true
              : totalPassed >= index + 1;

            return (
              <div
                key={index}
                className={styles.strengthIndicatorItem}
                data-passed={isPassed}
                data-mode={mode}
              />
            );
          })}
        </div>
      );
    }
  ),

  Description: memo(
    ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.description, className)} {...props} />
    )
  ),

  DescriptionTitle: memo(
    ({ className, ...props }: Omit<TypographyProps, 'children'>) => {
      const { mode } = usePasswordRequirementsContext();
      const { t } = useTranslation('core', {
        keyPrefix: 'shared.ui.password-requirements',
      });

      return (
        <Typography
          className={clsx(styles.descriptionTitle, className)}
          data-mode={mode}
          {...props}
        >
          {t('restriction')}
        </Typography>
      );
    }
  ),

  Requirement: createCompoundComponent(
    (C) =>
      memo(({ requirement }: { requirement: PasswordRequirementItem }) => (
        <RequirementItemContextProvider value={requirement}>
          <C.Root>
            <C.Icon />

            <C.Text>{requirement.text}</C.Text>
          </C.Root>
        </RequirementItemContextProvider>
      )),
    {
      Provider: RequirementItemContextProvider,

      Root: memo(({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
        const requirement = usePasswordRequirementItemContext();

        return (
          <div
            className={clsx(styles.requirementContainer, className)}
            data-passed={requirement.passed}
            {...props}
          />
        );
      }),

      Icon: memo(({ className, ...props }: IconProps) => (
        <CheckIcon
          className={clsx(styles.requirementIcon, className)}
          width={24}
          height={24}
          {...props}
        />
      )),

      Text: memo(({ className, ...props }: TypographyProps) => (
        <Typography
          className={clsx(styles.requirementText, className)}
          {...props}
        />
      )),
    }
  ),

  Divider: memo(({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div className={clsx(styles.divider, className)} {...props} />
  )),

  Hint: createCompoundComponent(() => () => null, {
    Root: memo(({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.hintContainer, className)} {...props} />
    )),

    Icon: memo(({ className, ...props }: IconProps) => (
      <InfoAlertIcon
        className={clsx(styles.hintIcon, className)}
        width={16}
        height={24}
        {...props}
      />
    )),

    Text: memo(({ className, ...props }: Omit<TypographyProps, 'children'>) => {
      const { t } = useTranslation('core', {
        keyPrefix: 'shared.ui.password-requirements',
      });

      return (
        <Typography className={clsx(styles.hintText, className)} {...props}>
          {t('hint')}
        </Typography>
      );
    }),
  }),
};

export { PasswordRequirements };
