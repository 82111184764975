import type { Event, GetShapeValue, Store } from 'effector';
import { attach, createEffect, createStore, launch, sample } from 'effector';

type ListenConfig<TClock, TSource> = {
  /**
   * Name of the handler
   */
  name?: string;

  /**
   * When this event is triggered, the effect will be run
   */
  clock: Event<TClock>;

  /**
   * Custom data for the effect
   */
  source?: TSource;

  /**
   * Effect to run
   *
   * @note Name `handler` is used to avoid name collision with `effect` because effector-babel-plugin goes nuts
   */
  handler: (
    clock: TClock,
    data: ListenConfigEffectData<TSource>
  ) => void | Promise<void>;

  debug?: boolean;
};

type ListenConfigEffectData<TSource> = TSource extends Record<
  string,
  Store<any>
>
  ? GetShapeValue<TSource>
  : TSource extends Store<any>
  ? GetShapeValue<TSource>
  : void;

/**
 * Operator for listening events to run imperative effects
 *
 * @see https://redux-toolkit.js.org/api/createListenerMiddleware#startlistening
 */
function listen<TClock, TSource>(config: ListenConfig<TClock, TSource>) {
  const $source =
    config.source ??
    createStore(null, {
      name: `${config.name}.$source`,
      serialize: 'ignore',
    });

  sample({
    clock: config.clock,
    target: attach({
      name: config.name,
      source: $source,
      effect: async (source, clock: TClock) => {
        if (config.debug) {
          console.log(`[listen](start) ${config.name}`, { clock, source });
        }

        const result = config.handler(
          clock,
          source as ListenConfigEffectData<TSource>
        );

        if (config.debug) {
          if (result instanceof Promise) {
            result
              .then((value) =>
                console.log(`[listen](done) ${config.name}`, {
                  clock,
                  source,
                  result: value,
                })
              )
              .catch((error) =>
                console.log(`[listen](error) ${config.name}`, {
                  clock,
                  source,
                  error,
                })
              );
          } else {
            console.log(`[listen](done) ${config.name}`, {
              clock,
              source,
              result,
            });
          }
        }
      },
    }),
  });
}

/** ======================================================================== */
/**
 * Utility for calling imperative effects
 * without need of creating one
 *
 * Awaiting promise instead of effect would lose scope safety
 * @see https://effector.dev/docs/api/effector/scope/#imperative-effects-calls-with-scope
 */
const callFx = createEffect({
  name: 'callFx',
  handler: async <T extends (arg: any) => any>({
    fn,
    params,
  }: {
    fn: T;
    params: Parameters<T>[0];
  }): Promise<ReturnType<T>> => fn(params),
});

/**
 * Calls provided function with provided params inside effect
 *
 * @param fn
 * @param params
 */
const call = <T extends (...args: any[]) => any>(
  fn: T,
  params: Parameters<T>[0] extends void ? undefined : Parameters<T>[0]
): ReturnType<T> => {
  const result = callFx({ fn, params });

  return result as ReturnType<T>;
};

const applyFx = createEffect({
  name: 'applyFx',
  handler: <T extends (...args: any[]) => any>({
    fn,
    params,
  }: {
    fn: T;
    params: Parameters<T>;
  }) => fn(...params),
});

/**
 * Calls provided function with provided params inside effect
 *
 * @param fn
 * @param params
 */
const apply = <T extends (...args: any[]) => any>(
  fn: T,
  params: Parameters<T>
) => applyFx({ fn, params });
/** ======================================================================== */

/** ======================================================================== */
/**
 * Handy utility for imperative setting store state
 */
const setState = <TValue>(store: Store<TValue>, value: TValue) => {
  launch({ target: store, params: value });
};

const stateGetterFx = createEffect((store: Store<unknown>) => store.getState());
const getState = <TValue>(store: Store<TValue>): Promise<TValue> =>
  stateGetterFx(store as Store<unknown>) as Promise<TValue>;
/** ======================================================================== */

const delayFx = createEffect({
  name: 'delayFx',
  handler: async (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms)),
});

export { apply, applyFx, call, callFx, delayFx, getState, listen, setState };
export type { ListenConfig };
