import { createState } from './state.model';
import { StateSwitch } from './state.ui';

const State = Object.assign(StateSwitch, {
  Switch: StateSwitch,

  factory: {
    createModel: createState,
  },
});

export { State };
