import {
  MFAInterceptor as MFAInterceptorView,
  MFAInterceptorCustomViewPlacementGate,
} from './2fa-interceptor.component';
import { MFAInterceptorModelFactory } from './2fa-interceptor.model';

const MFAInterceptor = Object.assign(MFAInterceptorView, {
  factory: MFAInterceptorModelFactory,
  Gate: MFAInterceptorCustomViewPlacementGate,
});

export { MFAInterceptor };
