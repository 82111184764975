class GTMAnalytics {
  public static gtag<Command extends keyof Gtag.GtagCommands>(
    command: Command,
    ...args: Gtag.GtagCommands[Command]
  ): void {
    /**
     * Because in local development we don't load GTM script
     */
    if (typeof window.gtag === 'function') {
      window.gtag(command, ...args);
    }
  }

  public static pushToDataLayer(data: any): void {
    /**
     * Because in local development we don't load GTM script
     */
    if (Array.isArray(window.dataLayer)) {
      window.dataLayer.push(data);
    }
  }
}

export { GTMAnalytics };
