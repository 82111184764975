import { Suspense } from 'react';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';

import { VerificationPageLoading } from './page.loading';

const Page = lazyWithRetryAndRefresh(
  'VerificationDonePage',
  () => import('./page.component')
);

const VerificationDonePage = {
  Component: () => (
    <Suspense fallback={<VerificationPageLoading />}>
      <Page />
    </Suspense>
  ),
};

export { VerificationDonePage };
