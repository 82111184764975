import { SetupMFAModel } from './setup-mfa.model';
import { SetupMFAForm } from './ui';

const SetupMFA = Object.assign(SetupMFAForm, {
  factory: SetupMFAModel,
  Form: SetupMFAForm,
});

export { SetupMFA };
export type { SetupMFAFormProps } from './ui';
