import clsx from 'clsx';
import { memo } from 'react';

import styles from './unread-icon.module.scss';

type UnreadIconProps = {
  className?: string;
  isRead?: boolean;
};

const UnreadIcon = memo(({ className, isRead }: UnreadIconProps) => {
  if (isRead) {
    return null;
  }

  return <div className={clsx(styles.unread, className)} />;
});

export { UnreadIcon };
