import { useUnit } from 'effector-react';
import { useEffect } from 'react';

import type { ViewModelProps } from '@kuna-pay/utils/effector/factorio';

import type { RateYourExperienceProps } from './rate-your-experience.component';
import { RateYourExperience } from './rate-your-experience.component';
import type { RateYourExperienceModel } from './rate-your-experience.model';
import type { RateYourExperienceManagerModel } from './rate-your-experience-manager.model';

type RateYourExperienceStackProps<T extends string> = {
  $$model: ReturnType<typeof RateYourExperienceManagerModel.createModel<T>>;

  i18n: (key: T) => RateYourExperienceProps['i18n'];
};

const RateYourExperienceStack = <T extends string>({
  $$model,
  i18n,
}: RateYourExperienceStackProps<T>) => {
  const item = useUnit($$model.$$ui.$stack);

  if (!item) {
    return null;
  }

  return <RateYourExperienceItem key={item.key} item={item} i18n={i18n} />;
};

type RateYourExperienceItemProps<T extends string> = {
  item: ViewModelProps<typeof RateYourExperienceModel> & {
    key: T;
  };

  i18n: (key: T) => RateYourExperienceProps['i18n'];
};

const RateYourExperienceItem = <T extends string>({
  item,
  i18n,
}: RateYourExperienceItemProps<T>) => {
  const [onOpen] = useUnit([item.$$model.$$modal.open]);

  useEffect(() => {
    onOpen();
  }, []);

  return <RateYourExperience i18n={i18n(item.key)} $$model={item.$$model} />;
};

export { RateYourExperienceStack };
