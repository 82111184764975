import type { FC, PropsWithChildren } from 'react';

import { NotificationProvider } from './notification';
import { TooltipProvider } from './ui/tooltip';

const UIProvider: FC<PropsWithChildren> = ({ children }) => (
  <TooltipProvider delayDuration={250}>
    <NotificationProvider>{children}</NotificationProvider>
  </TooltipProvider>
);

export { UIProvider };
