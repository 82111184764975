import { addMonths, isAfter } from 'date-fns';
import { combine, createEvent, createStore } from 'effector';
import { z } from 'zod';

import { listen } from '@kuna-pay/utils/effector';
import { atom } from '@kuna-pay/utils/misc';
import { PersistStorage } from '@kuna-pay/core/shared/lib/persist-storage';

import { RateYourExperienceModel } from './rate-your-experience.model';
import type { RateFormOutValues } from './rate-your-experience.types';
import type { ShareFeedbackFormOutValues } from './rate-your-experience.types';

type RateYourExperienceManagerModelConfig<T extends string> = {
  keys: T[];

  sendFeedbackFx: (
    key: T,
    values: ShareFeedbackFormOutValues & RateFormOutValues
  ) => void | Promise<void>;

  PERSIST_KEY: string;
};

const RateYourExperienceManagerModel = {
  createModel: <T extends string>(
    config: RateYourExperienceManagerModelConfig<T>
  ) => {
    const $$persist = new RateYourExperienceFeedbackPersist(config.PERSIST_KEY);

    const rate = createEvent<T>();

    const $$stack = atom(() => {
      const push = createEvent<T>();
      const remove = createEvent<T>();
      const $stack = createStore<T[]>([])
        .on(push, (state, key) => [...new Set(state).add(key)])
        .on(remove, (state, key) => state.filter((item) => item !== key));

      return {
        push,
        remove,
        $stack,
      };
    });

    const $$models = config.keys.reduce((acc, key) => {
      acc[key] = RateYourExperienceModel.createModel({
        sendFeedbackFx: async (values) => {
          $$persist.addOrUpdate(key, { createdAt: new Date().toISOString() });
          config.sendFeedbackFx(key, values);
        },

        onClose: () => {
          $$stack.remove(key);
        },
      });

      return acc;
    }, {} as Record<T, ReturnType<typeof RateYourExperienceModel.createModel>>);

    listen({
      clock: rate,
      handler: (key) => {
        const record = $$persist.get(key);

        if (!record) {
          $$stack.push(key);

          return;
        }

        const createdAtAsDate = new Date(record.createdAt);
        const expireAt = addMonths(createdAtAsDate, 3);
        const now = new Date();

        if (isAfter(now, expireAt)) {
          $$persist.remove(key);
          $$stack.push(key);
        }
      },
    });

    return {
      rate,

      $$ui: {
        $stack: combine($$stack.$stack, (stack) => {
          const keysForWhichModelDefined = stack.filter((key) => $$models[key]);

          const firstKey = keysForWhichModelDefined[0];

          if (!firstKey) {
            return null;
          }

          return { key: firstKey, $$model: $$models[firstKey] };
        }),

        $$models,
      },
    };
  },
};

class RateYourExperienceFeedbackPersist {
  public constructor(private readonly KEY: string) {}

  private readonly storage = PersistStorage.create();

  private load() {
    try {
      const jsonAsString = this.storage.getItem(this.KEY);

      if (!jsonAsString) {
        return {};
      }

      const kv = JSON.parse(jsonAsString);

      return this.DBSchema.parse(kv);
    } catch (error) {
      return {};
    }
  }

  public get(key: string): z.input<typeof this.FeedbackSchema> | null {
    try {
      const kv = this.load();

      const item = kv[key];

      if (!item) {
        return null;
      }

      return this.FeedbackSchema.parse(item);
    } catch (error) {
      return null;
    }
  }

  public addOrUpdate(key: string, item: z.input<typeof this.FeedbackSchema>) {
    try {
      const kv = this.load();

      kv[key] = this.FeedbackSchema.parse(item);

      this.storage.setItem(this.KEY, JSON.stringify(kv));
    } catch (error) {
      console.error('Failed to save feedback. ', error);
    }
  }

  public remove(key: string) {
    try {
      const kv = this.load();

      if (kv[key]) {
        delete kv[key];
      }

      this.storage.setItem(this.KEY, JSON.stringify(kv));
    } catch (error) {
      console.error('Failed to save feedback. ', error);
    }
  }

  private readonly FeedbackSchema = z
    .object({
      createdAt: z.string(),
    })
    .passthrough();

  private readonly DBSchema = z.record(this.FeedbackSchema);
}

export { RateYourExperienceManagerModel };
