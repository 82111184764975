import clsx from 'clsx';
import type { FC } from 'react';
import type { SkeletonProps as ReactSkeletonProps } from 'react-loading-skeleton';
import ReactSkeleton from 'react-loading-skeleton';

import styles from './skeleton.module.scss';

type SkeletonProps = ReactSkeletonProps & {
  fullWidth?: boolean;
  flex?: boolean;
};

const Skeleton: FC<SkeletonProps> = ({
  className,
  flex,
  fullWidth,
  containerClassName,
  ...props
}) => (
  <ReactSkeleton
    className={clsx(styles.skeleton, className)}
    containerClassName={clsx(styles.container, containerClassName, {
      [styles.flex]: !!flex,
      [styles.fullWidth]: !!fullWidth,
    })}
    {...props}
  />
);

export { Skeleton };
export type { SkeletonProps };
