import type { Numeric } from 'decimal.js-light';
import { Decimal } from 'decimal.js-light';

function formatPercent(
  rawPercent: Numeric,
  config = { showPlusSign: false, showDecimals: true }
) {
  const decimalPercent = new Decimal(rawPercent);

  const isZeroInteger =
    decimalPercent.greaterThan(-1) && decimalPercent.lessThan(1);

  const percent = isZeroInteger
    ? decimalPercent.toString()
    : decimalPercent.toFixed(config.showDecimals ? 2 : 0).toString();

  const [integer = '0', decimals = ''] = percent
    .replace('-', '')
    .replace('+', '')
    .split('.');

  const formattedPercent = joinIntegersAndDecimals(
    integer,

    trimTrailingZerosFromDecimals(
      integer === '0'
        ? decimals
        : new Decimal(joinIntegersAndDecimals(integer, decimals))
            .toFixed(2)
            .split('.')[1]
    )
  );

  return `${parseSign(percent, config)}${formattedPercent}%`;
}

function trimTrailingZerosFromDecimals(decimals: string) {
  return decimals.split('').reduceRight((acc, digit) => {
    if (acc.length !== 0 || digit !== '0') {
      return digit + acc;
    }

    return acc;
  }, '');
}

function joinIntegersAndDecimals(integers: string, decimals: string) {
  if (decimals.length === 0) {
    return integers;
  }

  return `${integers}.${decimals}`;
}

function parseSign(
  percent: string,
  { showPlusSign } = { showPlusSign: false }
) {
  return percent.startsWith('-') ? '-' : showPlusSign ? '+' : '';
}

export { formatPercent };
