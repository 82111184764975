import type { Gate } from 'effector-react';
import { useGate } from 'effector-react';

function useTypedGate<Props>(
  config: {} extends Props
    ? { Gate: Gate<Props> }
    : unknown extends Props
    ? { Gate: Gate<Props> }
    : { Gate: Gate<Props>; props: Props }
): void {
  if ('props' in config) {
    useGate(config.Gate, config.props);
  } else {
    useGate(config.Gate);
  }
}

export { useTypedGate };
