import { useTranslation } from 'react-i18next';

import type {
  InvoiceCalculationDetailsOutput,
  Maybe,
} from '@kuna-pay/core/generated/public/graphql';

import type { FormatAmount, FormatAsset, FormatAssetConfig } from './format';
import { AssetFormat } from './format';

type LocalizedFormatter = {
  decimalSeparator: () => string;
  thousandsSeparator: () => string;

  formatAmount: (
    input: FormatAmount,
    asset?: Maybe<FormatAsset>,
    config?: Omit<FormatAssetConfig, 'locale'>
  ) => string;

  formatBalance: (
    input: FormatAmount,
    asset: Maybe<FormatAsset>,
    config?: Omit<FormatAssetConfig, 'locale'>
  ) => string;

  formatExchangeRate: (
    from: FormatAsset,
    rate: FormatAmount,
    to?: FormatAsset,
    config?: {
      from?: Omit<FormatAssetConfig, 'locale'>;
      to?: Omit<FormatAssetConfig, 'locale'>;
    }
  ) => string;

  formatExchangeRateWithAppliedCoercionCoefficient: (
    from: FormatAsset,
    rate: FormatAmount,
    to: FormatAsset,
    CalculationDetails: InvoiceCalculationDetailsOutput,
    config?: {
      from?: Omit<FormatAssetConfig, 'locale'>;
      to?: Omit<FormatAssetConfig, 'locale'>;
    }
  ) => string;
};

function useAssetFormat(): LocalizedFormatter {
  const { i18n } = useTranslation();

  return {
    decimalSeparator: () => AssetFormat.decimalSeparator(i18n.language),

    thousandsSeparator: () => AssetFormat.thousandsSeparator(i18n.language),

    formatAmount: (
      input: FormatAmount,
      asset?: Maybe<FormatAsset>,
      config: FormatAssetConfig = {}
    ) =>
      AssetFormat.formatAmount(input, asset, {
        locale: i18n.language,
        ...config,
      }),

    formatBalance: (
      input: FormatAmount,
      asset: Maybe<FormatAsset>,
      config: FormatAssetConfig = {}
    ) =>
      AssetFormat.formatBalance(input, asset, {
        locale: i18n.language,
        ...config,
      }),

    formatExchangeRate: (
      from: FormatAsset,
      rate: FormatAmount,
      to?: FormatAsset,
      config?: {
        from?: FormatAssetConfig;
        to?: FormatAssetConfig;
      }
    ) =>
      AssetFormat.formatExchangeRate(from, rate, to, {
        from: {
          locale: i18n.language,
          ...config?.from,
        },
        to: {
          locale: i18n.language,
          ...config?.to,
        },
      }),

    formatExchangeRateWithAppliedCoercionCoefficient: (
      from: FormatAsset,
      rate: FormatAmount,
      to: FormatAsset,
      CalculationDetails: InvoiceCalculationDetailsOutput,
      config?: {
        from?: FormatAssetConfig;
        to?: FormatAssetConfig;
      }
    ) =>
      AssetFormat.formatExchangeRateWithAppliedCoercionCoefficient(
        from,
        rate,
        to,
        CalculationDetails,
        {
          from: {
            locale: i18n.language,
            ...config?.from,
          },
          to: {
            locale: i18n.language,
            ...config?.to,
          },
        }
      ),
  };
}

export { useAssetFormat };
