import { useUnit } from 'effector-react';
import type { PropsWithChildren, ReactNode } from 'react';
import { memo, useLayoutEffect } from 'react';

import { Navigate } from '@kuna-pay/ui/router';

import { routes } from '@kuna-pay/merchant/shared/router';

import { $$session } from '../../model';

type SystemAuthorizedGuardProps = PropsWithChildren<{}> & {
  fallback?: ReactNode;
};

/**
 * We assume that NonExpiredTokensGuard is used before this guard.
 */
const SystemAuthorizedGuard = memo(
  ({ children, fallback }: SystemAuthorizedGuardProps) => {
    const [isLoading, data, error, status, load] = useUnit([
      $$session.$$meQuery.$pending,
      $$session.$$meQuery.$data,
      $$session.$$meQuery.$error,
      $$session.$$meQuery.$status,
      $$session.$$meQuery.start,
    ]);

    /**
     * We assume that query.$pending is false by default.
     * So on first render we have to consider that query is not started yet(will be started in useEffect).
     *
     * https://kunatech.atlassian.net/browse/KUPAY-1830
     */
    const isInitialLoad =
      !isLoading && ((!data && !error) || (error && status === 'fail'));

    useLayoutEffect(() => {
      load();
    }, []);

    if (isLoading || isInitialLoad) {
      return <>{fallback}</>;
    }

    if (!!error || !data) {
      return <Navigate to={routes.auth.signIn} />;
    }

    return <>{children}</>;
  }
);

export { SystemAuthorizedGuard };
