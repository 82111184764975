import { useUnit } from 'effector-react';
import type { PropsWithChildren } from 'react';
import { memo } from 'react';

import { Navigate } from '@kuna-pay/ui/router';
import { JWTService } from '@kuna-pay/core/entities/session';

import { $$api } from '@kuna-pay/merchant/shared/api';
import { routes } from '@kuna-pay/merchant/shared/router';

type NonExpiredTokensGuardProps = PropsWithChildren<{}> & {};

const NonExpiredTokensGuard = memo(
  ({ children }: NonExpiredTokensGuardProps) => {
    const { accessToken, refreshToken } = useUnit($$api.tokenService.$tokens);

    if (!accessToken || !refreshToken) {
      return <Navigate to={routes.auth.signIn} />;
    }

    if (!JWTService.canDecodeJWT(accessToken)) {
      return <Navigate to={routes.auth.signIn} />;
    }

    const isAccessTokenExpired = JWTService.isTokenExpired(accessToken);

    if (isAccessTokenExpired) {
      if (!JWTService.canDecodeJWT(refreshToken)) {
        return <Navigate to={routes.auth.signIn} />;
      }

      const isRefreshTokenExpired = JWTService.isTokenExpired(refreshToken);

      if (isRefreshTokenExpired) {
        return <Navigate to={routes.auth.signIn} />;
      }
    }

    return <>{children}</>;
  }
);

export { NonExpiredTokensGuard };
