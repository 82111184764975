import clsx from 'clsx';

import { Typography } from '@kuna-pay/ui/ui/typography';

import { DataGridConfig } from '../../config';
import { useI18n } from '../../i18n';
import { isActionColumn } from '../../lib';
import type { GridColumn } from '../../types';
import { TableHeadColumn } from './head-column.compoent';
import styles from './head.module.scss';

type TableHeadProps<T extends Record<string, unknown>> = {
  className?: string;
  columns: GridColumn<T>[];
  gridTemplateColumns: string;
};

type TableHeadActionColumnProps<T extends Record<string, unknown>> = {
  column: GridColumn<T>;
};

const TableHead = <T extends Record<string, unknown>>({
  columns,
  gridTemplateColumns,
  className,
}: TableHeadProps<T>) => (
  <div
    className={clsx(styles.root, className)}
    style={{
      gridTemplateColumns,
      paddingLeft: DataGridConfig.ROW_PADDING,
      paddingRight: DataGridConfig.ROW_PADDING,
    }}
  >
    {columns.map((column) => {
      if (column.renderHead) {
        const Component = column.renderHead;

        return (
          <Component key={`${column.field}:${column.label}`} column={column} />
        );
      }

      if (isActionColumn(column)) {
        return (
          <TableHeadActionColumn
            key={`${column.field}:${column.label}`}
            column={column}
          />
        );
      }

      return (
        <TableHeadColumn
          key={`${column.field}:${column.label}`}
          column={column}
        />
      );
    })}
  </div>
);

const TableHeadActionColumn = <T extends Record<string, unknown>>({
  column,
}: TableHeadActionColumnProps<T>) => {
  const t = useI18n();

  return (
    <div
      key={`${column.field}:${column.label}`}
      className={clsx(styles.cell, styles.action)}
    >
      <Typography
        className={clsx(styles.text, styles.action)}
        variant='numbers2'
      >
        {column.label ? t(column.label) : ''}
      </Typography>
    </div>
  );
};
export { TableHead, TableHeadActionColumn };
