import {
  SuccessOutput,
  MutationPublicCollectGenericFeedbackArgs,
} from '@kuna-pay/merchant/generated/graphql';
import { subscription, query } from '@kuna-pay/merchant/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const publicCollectGenericFeedbackRequest = (
  select: Select<SuccessOutput> | string
) => `mutation publicCollectGenericFeedback($feedback: String!, $attachments: [String!], $meta: FeedbackMeta) {
  data: publicCollectGenericFeedback(
    feedback: $feedback
    attachments: $attachments
    meta: $meta
  ) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'SuccessOutput')}`;
export const publicCollectGenericFeedback =
  (select: Select<SuccessOutput> | string) =>
  (args: MutationPublicCollectGenericFeedbackArgs) =>
    query<SuccessOutput>(publicCollectGenericFeedbackRequest(select), args);
