import clsx from 'clsx';
import type { ReactNode } from 'react';
import { memo } from 'react';

import { Tooltip } from '@kuna-pay/ui/ui/tooltip';

import styles from './requirements-tooltip.module.scss';

type PasswordInputTooltipProps = {
  content: ReactNode;
  children: ReactNode;
  open: boolean;
  inputHasError?: boolean;
};

const PasswordInputTooltip = memo(
  ({ content, children, open, inputHasError }: PasswordInputTooltipProps) => (
    <div className={styles.root}>
      {children}

      <Tooltip content={content} open={open} side='right' rawContent>
        <button
          className={clsx(styles.hiddenButton, {
            [styles.inputHasError]: inputHasError,
          })}
          type='button'
        />
      </Tooltip>
    </div>
  )
);

export { PasswordInputTooltip };
