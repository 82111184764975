import type { MouseEvent } from 'react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  shouldOpenInNewTabOnClick,
  shouldOpenInNewTabOnMouseUp,
} from '@kuna-pay/utils/router';

import { RedirectModel } from './redirect.model';

const useRedirectOnRowClick = <Row extends Record<string, unknown>>(
  row: Row
) => {
  const $$model = RedirectModel.useModel();
  const navigate = useNavigate();

  const redirect = useMemo((): { can: false } | { can: true; url: string } => {
    try {
      if (!$$model.can) return { can: false };

      const url = $$model.urlCreator(row);

      if (!url) return { can: false };

      return { can: true, url: url.toNavigate() };
    } catch (e) {
      console.error(e);

      return { can: false };
    }
  }, []);

  const navigateInNewTab = (url: string) => {
    window.open(url, '_blank');
  };

  return {
    can: !!redirect.can,

    onClick: (event: MouseEvent) => {
      if (!redirect.can) return;

      if (shouldOpenInNewTabOnClick(event)) {
        navigateInNewTab(redirect.url);
      } else {
        navigate(redirect.url);
      }
    },
    onMouseUp: (event: MouseEvent) => {
      if (!redirect.can) return;

      if (shouldOpenInNewTabOnMouseUp(event)) {
        navigateInNewTab(redirect.url);
      }
    },
  };
};

export { useRedirectOnRowClick };
