import type { FC } from 'react';

const createCompoundComponent = <
  ComponentMap extends Record<string, any>,
  Props extends object
>(
  factory: (components: ComponentMap) => FC<Props>,
  components: ComponentMap
) => Object.assign(factory(components), components);

export { createCompoundComponent };
