import { useUnit } from 'effector-react/effector-react.mjs';
import type { PropsWithChildren } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { VerificationGuard } from '@kuna-pay/merchant/entities/session';
import { AutoConvertTutorialVideo } from '@kuna-pay/merchant/entities/tutorial/ui';
import { $$tutorialsFeatureFlag } from '@kuna-pay/merchant/shared/feature-flags/features/dynamic';
import { MainLayout } from '@kuna-pay/merchant/widgets/layout';

import restrictedImg from '../../assets/restricred.asset.png';
import styles from './layout.module.scss';

const SettingsLayout = memo(({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const tutorialsFeatureFlag = useUnit($$tutorialsFeatureFlag);

  return (
    <MainLayout.Merchant.Content className={styles.root}>
      <VerificationGuard
        from='Settings'
        skipVerifyButton={tutorialsFeatureFlag.enabled}
        pending={
          tutorialsFeatureFlag.enabled && (
            <VerificationGuard.PendingContainer>
              <AutoConvertTutorialVideo
                className={VerificationGuard.videoClassName}
              />

              <VerificationGuard.PendingTitle variant='h9' />

              <VerificationGuard.Description>
                {t(
                  'pages.settings.host.unverified.auto-convert.description-pending'
                )}
              </VerificationGuard.Description>
            </VerificationGuard.PendingContainer>
          )
        }
        unverified={
          tutorialsFeatureFlag.enabled ? (
            <>
              <AutoConvertTutorialVideo
                className={VerificationGuard.videoClassName}
              />

              <VerificationGuard.VideoTitle>
                {t('pages.settings.host.unverified.auto-convert.title')}
              </VerificationGuard.VideoTitle>

              <VerificationGuard.Description>
                {t('pages.settings.host.unverified.auto-convert.description')}
              </VerificationGuard.Description>

              <VerificationGuard.VerifyButton from='Settings' />
            </>
          ) : (
            <>
              <VerificationGuard.Img
                src={restrictedImg}
                width={190}
                height={190}
                alt=''
              />

              <VerificationGuard.Title>
                {t('pages.settings.host.unverified.title')}
              </VerificationGuard.Title>

              <VerificationGuard.Description>
                {t('pages.settings.host.unverified.description')}
              </VerificationGuard.Description>
            </>
          )
        }
      >
        {children}
      </VerificationGuard>
    </MainLayout.Merchant.Content>
  );
});

export { SettingsLayout };
