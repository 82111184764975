import { Suspense } from 'react';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';

import { ReportingPageLoading } from './page.loading';

const Page = lazyWithRetryAndRefresh(
  'StatementReportPage',
  () => import('./page.component')
);

const StatementReportPage = {
  Component: () => (
    <Suspense fallback={<ReportingPageLoading />}>
      <Page />
    </Suspense>
  ),
};

export { StatementReportPage };
