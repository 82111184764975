import clsx from 'clsx';
import { forwardRef } from 'react';

import { GoogleLogoIcon } from '../../../icons';
import { Typography } from '../../typography';
import { UnstyledButton } from '../base-button';
import type { GoogleButtonProps } from './google-button.props';
import styles from './google-button.module.scss';

//TODO: Move to ui-kit
const GoogleButton = forwardRef<HTMLButtonElement, GoogleButtonProps>(
  ({ children = 'Google', className, classes, ...props }, ref) => (
    <UnstyledButton
      ref={ref}
      className={clsx(styles.button, className, classes?.root)}
      {...props}
    >
      <GoogleLogoIcon className={clsx(styles.icon, classes?.icon)} />

      <Typography
        className={clsx(styles.text, classes?.text)}
        variant='numberAdmin2'
      >
        {children}
      </Typography>
    </UnstyledButton>
  )
);

export { GoogleButton };
