import * as RadixSwitch from '@radix-ui/react-switch';
import clsx from 'clsx';
import { forwardRef } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';

import { UnstyledButton } from '../button';
import styles from './switch.module.scss';

type SwitchSize = 'sm' | 'lg';

type SwitchProps = RadixSwitch.SwitchProps & { size: SwitchSize };

const Switch = createCompoundComponent(
  ({ Root, Thumb }) =>
    forwardRef<HTMLButtonElement, SwitchProps>(
      ({ children, size = 'sm', ...props }, ref) => (
        <Root ref={ref} size={size} {...props}>
          <Thumb size={size}>{children}</Thumb>
        </Root>
      )
    ),
  {
    Root: forwardRef<HTMLButtonElement, SwitchProps>(
      ({ children, className, size, ...props }, ref) => (
        <RadixSwitch.Root
          ref={ref}
          className={clsx(styles.root, className)}
          {...props}
          data-size={size}
          asChild
        >
          <UnstyledButton>{children}</UnstyledButton>
        </RadixSwitch.Root>
      )
    ),
    Thumb: forwardRef<
      HTMLSpanElement,
      RadixSwitch.SwitchThumbProps & { size: SwitchSize }
    >(({ className, size, ...props }, ref) => (
      <RadixSwitch.Thumb
        ref={ref}
        className={clsx(styles.thumb, className)}
        {...props}
        data-size={size}
      />
    )),
  }
);

export { Switch };
export type { SwitchProps };
