import { useUnit } from 'effector-react';
import { memo } from 'react';

import type { ModelOf } from '@kuna-pay/utils/effector';

import type { VerifyOTPModel } from './verify-2fa.model';
import { VerifyAuthenticator2FA } from './verify-authenticator-2fa.component';
import { VerifyEmail2FA } from './verify-email-2fa.component';
import styles from './verify-sign-in-2fa.module.scss';

const VerifySignIn2FA = memo(
  ({ $$model }: { $$model: ModelOf<typeof VerifyOTPModel> }) => {
    const [type] = useUnit([$$model.$type]);

    if (type === 'email') {
      return (
        <VerifyEmail2FA.PaperRoot className={styles.root}>
          <VerifyEmail2FA.Img />

          <VerifyEmail2FA.Title variant='h7' />

          <VerifyEmail2FA.Subtitle $$model={$$model} variant='body3' />

          <VerifyEmail2FA.Form $$model={$$model} />

          <VerifyEmail2FA.Resend variant='body2' $$model={$$model} />
        </VerifyEmail2FA.PaperRoot>
      );
    }

    return (
      <VerifyAuthenticator2FA.PaperRoot className={styles.root}>
        <VerifyAuthenticator2FA.Title variant='h5' />

        <VerifyAuthenticator2FA.Subtitle variant='body3' />

        <VerifyAuthenticator2FA.Form $$model={$$model}>
          <VerifyAuthenticator2FA.IsTrustedDevice $$model={$$model} />
        </VerifyAuthenticator2FA.Form>

        <VerifyAuthenticator2FA.Hint variant='body2' />
      </VerifyAuthenticator2FA.PaperRoot>
    );
  }
);

export { VerifySignIn2FA };
