import {
  NotificationOutput,
  QueryFindManyNotificationsArgs,
} from '@kuna-pay/merchant/generated/graphql';
import { subscription, query } from '@kuna-pay/merchant/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const findManyNotificationsRequest = (
  select: Select<NotificationOutput> | string
) => `query findManyNotifications($take: Int, $skip: Int, $where: FindNotificationsWhereInput) {
  data: findManyNotifications(take: $take, skip: $skip, where: $where) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'NotificationOutput')}`;
export const findManyNotifications =
  (select: Select<NotificationOutput> | string) =>
  (args: QueryFindManyNotificationsArgs) =>
    query<NotificationOutput[]>(findManyNotificationsRequest(select), args);
