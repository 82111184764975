import type { Effect, Store } from 'effector';
import {
  attach,
  combine,
  createEvent,
  createStore,
  sample,
  split,
} from 'effector';
import { createGate } from 'effector-react';
import i18next from 'i18next';
import { or } from 'patronum';
import type { useTranslation } from 'react-i18next';
import type { SchemaOf } from 'yup';
import { object, string } from 'yup';

import { modelFactory } from '@kuna-pay/utils/effector';
import { bridge } from '@kuna-pay/utils/misc';
import { objectEntries } from '@kuna-pay/utils/typescript';
import { createForm } from '@kuna-pay/form';

import type { VerifyOtpPayload } from './verify-one-time-code.types';

const VerifyOneTimeCodeModel = modelFactory(
  (config: {
    verifyFx: Effect<VerifyOtpPayload, void>;

    $disabled?: Store<boolean>;

    errors: Record<
      string,
      {
        match: (payload: { params: VerifyOtpPayload; error: Error }) => boolean;
        $message: Store<string>;
      }
    >;
  }) => {
    const Gate = createGate<{ i18n: ReturnType<typeof useTranslation> }>();

    config.$disabled ??= createStore(false);

    const verifyFx = attach({ effect: config.verifyFx });

    const done = createEvent<VerifyOtpPayload>();

    const $$form = createForm<VerifyOtpPayload>({
      $disabled: or(verifyFx.pending, config.$disabled),

      initialValues: { otp: '' },

      schema: combine(
        Gate.state,
        (): SchemaOf<VerifyOtpPayload> =>
          object({
            otp: string()
              .required()
              .length(6)
              .label(
                i18next.t(
                  'features.auth.abstract.verify-one-time-code.model.form.otp.errors.label',
                  { ns: 'core' }
                )
              ),
          })
      ),
    });

    bridge(() => {
      //Auto-submit
      sample({
        source: { otp: $$form.fields.otp.$value, isValid: $$form.$valid },
        filter: ({ otp, isValid }) => isValid && otp.length === 6,
        target: $$form.submit,
      });
    });

    bridge(() => {
      sample({
        clock: $$form.submitted,
        target: verifyFx,
      });

      bridge(() => {
        const errorMatchersMap = Object.fromEntries(
          objectEntries(config.errors).map(([key, { match }]) => [key, match])
        );

        const failed = split(verifyFx.fail, errorMatchersMap);

        objectEntries(config.errors).forEach(([key, { $message }]) => {
          sample({
            clock: failed[key],
            source: $message,
            target: $$form.setFormError,
          });
        });

        sample({
          clock: failed.__,
          fn: () =>
            i18next.t(
              'features.auth.abstract.verify-one-time-code.model.verify.failed.unknown',
              { ns: 'core' }
            ),
          target: $$form.setFormError,
        });
      });

      bridge(() => {
        sample({
          clock: verifyFx.done,
          fn: ({ params }) => params,
          target: done,
        });
      });
    });

    return {
      done,
      reset: $$form.reinit,

      setFormError: $$form.setFormError,

      $$ui: { Gate, $$form },

      __: {
        verifyFx,
      },
    };
  }
);

export { VerifyOneTimeCodeModel };
