const variants = {
  h1: createTypographyVariant('bold', 48, 56),
  h2: createTypographyVariant('bold', 40, 44),
  h3: createTypographyVariant('bold', 36, 40),
  h4: createTypographyVariant('bold', 32, 36),
  h5: createTypographyVariant('bold', 28, 32),
  h6: createTypographyVariant('bold', 24, 28),
  h7: createTypographyVariant('bold', 20, 24),
  h8: createTypographyVariant('medium', 20, 24),
  h9: createTypographyVariant('bold', 18, 20),
  subtitle1: createTypographyVariant('medium', 18, 24, 0.2),
  subtitle2: createTypographyVariant('bold', 16, 20),
  subtitle3: createTypographyVariant('medium', 16, 24, 0.1),
  subtitle4: createTypographyVariant('bold', 14, 20, 0.1),
  subtitle5: createTypographyVariant('medium', 14, 20, 0.1),
  subtitle6: createTypographyVariant('bold', 12, 16),
  body1: createTypographyVariant('regular', 18, 24),
  body2: createTypographyVariant('regular', 16, 24),
  body3: createTypographyVariant('regular', 14, 20),
  numberAdmin1: createTypographyVariant('regular', 13, 18),
  numberAdmin2: createTypographyVariant('medium', 13, 18),
  numbers1: createTypographyVariant('medium', 12, 16),
  numbers2: createTypographyVariant('regular', 12, 16),
  small1: createTypographyVariant('bold', 11, 14),
  small2: createTypographyVariant('medium', 11, 14),
  small3: createTypographyVariant('regular', 11, 14),
};

function createTypographyVariant(
  fontWeight: 'bold' | 'medium' | 'regular',
  fontSize: number,
  lineHeight: number,
  letterSpacing = 0
) {
  const fw = { bold: 700, medium: 500, regular: 400 } as const;

  return {
    fontWeight: fw[fontWeight],
    fontSize: `${fontSize}px`,
    lineHeight: `${lineHeight}px`,
    letterSpacing: `${letterSpacing}px`,
  };
}

export { variants };
