import { Suspense } from 'react';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';

import { ApiKeySettingsPageLoading } from './page.loading';

const Page = lazyWithRetryAndRefresh(
  'ApiKeySettingsPage',
  () => import('./page.component')
);

const ApiKeySettingsPage = {
  Component: () => (
    <Suspense fallback={<ApiKeySettingsPageLoading />}>
      <Page />
    </Suspense>
  ),
};

export { ApiKeySettingsPage };
