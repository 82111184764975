import { VerifyOneTimeCodeForm } from './verify-one-time-code.component';
import { VerifyOneTimeCodeModel } from './verify-one-time-code.model';

const VerifyOneTimeCode = Object.assign(VerifyOneTimeCodeForm, {
  factory: VerifyOneTimeCodeModel,
  Form: VerifyOneTimeCodeForm,
});

export { VerifyOneTimeCode };
export type { VerifyOTPFormProps } from './verify-one-time-code.component';
