/* eslint-disable jsx-a11y/aria-role */
import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type {
  FC,
  ImgHTMLAttributes,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ArrowRightIcon } from '@kuna-pay/ui/icons';
import picture from '@kuna-pay/ui/images/verification-pending.png';
import { Link } from '@kuna-pay/ui/router';
import type { ButtonProps } from '@kuna-pay/ui/ui/button';
import { Button } from '@kuna-pay/ui/ui/button';
import type { PaperProps } from '@kuna-pay/ui/ui/paper';
import { Paper } from '@kuna-pay/ui/ui/paper';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { JWTCompanyRole } from '@kuna-pay/core/entities/session';

import type { TutorialVideoProps } from '@kuna-pay/merchant/entities/tutorial';
import { TutorialVideo } from '@kuna-pay/merchant/entities/tutorial';
import type { AnalyticsProperties } from '@kuna-pay/merchant/shared/analytics';
import { routes } from '@kuna-pay/merchant/shared/router';
import { MainLayout } from '@kuna-pay/merchant/widgets/layout';

import { $$session } from '../../model';
import { CanAccess } from '../has-access';
import styles from './verification-guard.module.scss';

type VerificationGuardProps = PropsWithChildren<{
  from: AnalyticsProperties.VerificationPageViewedEventFrom;

  pending?: ReactNode;

  unverified: ReactNode;
  skipVerifyButton?: boolean;

  Layout?: FC<PropsWithChildren>;
}>;

const VerificationGuard = ({
  from,
  pending,
  children,
  unverified,
  skipVerifyButton,
  Layout = Fragment,
}: VerificationGuardProps) => {
  const { t } = useTranslation();

  const [isVerified, isPending] = useUnit([
    $$session.$$jwt.$$company.$isVerified,
    $$session.$$jwt.$$company.$isPendingVerification,
  ]);

  if (isVerified) {
    return <>{children}</>;
  }

  if (isPending) {
    return pending ? (
      <Layout>{pending}</Layout>
    ) : (
      <Layout>
        <Paper className={styles.pending}>
          <img
            className={styles.pendingPicture}
            src={picture}
            height={180}
            alt=''
          />

          <VerificationGuard.PendingTitle variant='h7' />

          <Typography className={styles.pendingSubtitle} variant='subtitle5'>
            {t('entities.session.ui.verification-guard.pending.subtitle')}
          </Typography>
        </Paper>
      </Layout>
    );
  }

  return (
    <Layout>
      <MainLayout.MFANotEnabledAlert />

      <Paper className={styles.root}>
        {unverified}

        {!skipVerifyButton && <VerificationGuard.VerifyButton from={from} />}
      </Paper>
    </Layout>
  );
};

VerificationGuard.VerifyButton = ({
  from,
  ...props
}: Pick<VerificationGuardProps, 'from'> & Partial<ButtonProps>) => {
  const { t } = useTranslation();

  return (
    <CanAccess.Company.Role role={[JWTCompanyRole.Owner, JWTCompanyRole.Admin]}>
      <Link to={routes.auth.verification.start.withQuery({ from })}>
        <Button
          variant='contained'
          color='primary'
          size='lg'
          end={<ArrowRightIcon />}
          {...props}
        >
          {props.children ??
            t([
              'entities.session.ui.verification-guard.unverified.action-new',
              'entities.session.ui.verification-guard.unverified.action',
            ])}
        </Button>
      </Link>
    </CanAccess.Company.Role>
  );
};

VerificationGuard.Title = ({
  className,
  ...props
}: Partial<TypographyProps>) => (
  <Typography
    className={clsx(styles.title, className)}
    variant='h7'
    center
    {...props}
  />
);

VerificationGuard.PendingTitle = ({ variant, ...props }: TypographyProps) => {
  const { t } = useTranslation();

  return (
    <VerificationGuard.Title variant={variant} {...props}>
      <Trans
        t={t}
        i18nKey={[
          'entities.session.ui.verification-guard.pending.title-new',
          'entities.session.ui.verification-guard.pending.title',
        ]}
        components={{
          yellow: <Typography className={styles.yellow} variant={variant} />,
        }}
      />
    </VerificationGuard.Title>
  );
};

VerificationGuard.Img = ({
  className,
  ...props
}: ImgHTMLAttributes<HTMLImageElement>) => (
  <img className={clsx(styles.img, className)} alt='' {...props} />
);

VerificationGuard.Video = ({ className, ...props }: TutorialVideoProps) => (
  <TutorialVideo className={clsx(styles.video, className)} {...props} />
);

VerificationGuard.videoClassName = styles.video;

VerificationGuard.VideoTitle = ({
  className,
  ...props
}: Partial<TypographyProps>) => (
  <Typography
    className={clsx(styles.title, className)}
    variant='h9'
    center
    {...props}
  />
);

VerificationGuard.Description = ({
  className,
  ...props
}: Partial<TypographyProps>) => (
  <Typography
    className={clsx(styles.description, className)}
    variant='body3'
    center
    {...props}
  />
);

VerificationGuard.PendingContainer = (props: PaperProps) => (
  <Paper className={styles.pending} {...props} />
);

export { VerificationGuard };
