import type { RouteObject } from 'react-router-dom';

import { CreatePayoutPage } from './pages/create';
import { PayoutDetailsPage } from './pages/details';
import { LaunchPayoutPage } from './pages/launch';
import { PayoutsListPage } from './pages/list';
import { PayoutLayout } from './ui';

const PayoutPages: RouteObject[] = [
  {
    path: '/payouts',
    Component: PayoutLayout,
    children: [
      {
        index: true,
        Component: PayoutsListPage.Component,
      },
      {
        path: 'create',
        Component: CreatePayoutPage.Component,
      },
      {
        path: 'launch/:id',
        Component: LaunchPayoutPage.Component,
      },
      {
        path: ':id',
        Component: PayoutDetailsPage.Component,
      },
    ],
  },
];

export { PayoutPages };
