import { listen } from '@kuna-pay/utils/effector';
import { atom } from '@kuna-pay/utils/misc';
import { AppStage } from '@kuna-pay/core/shared/config';
import { CookieConsent } from '@kuna-pay/core/shared/cookie-consent';

import {
  $$analytics,
  $$googleAnalytics,
} from '@kuna-pay/merchant/shared/analytics';
import { environment } from '@kuna-pay/merchant/shared/config';

const $$cookieConsent = atom(() => {
  const $$consent = CookieConsent.factory.createModel({
    name: '$$cookie-consent',

    persist: 'cookie-consent',
  });

  listen({
    name: '$$cookie-consent.onSave',
    clock: $$consent.done,
    handler: (preferences) => {
      // Because we only have PROD stage for clarity
      if (environment.stage === AppStage.PROD) {
        import('react-microsoft-clarity').then(({ clarity }) => {
          if (!clarity.hasStarted()) {
            clarity.init(environment.clarity.id);
          }

          // Microsoft Clarity still can work if consent is not provided
          if (preferences.analytics) {
            clarity.consent();
          }
        });
      }

      // Exclude analytics from local development cycle
      // to not exceed the limit of events
      if (!import.meta.env.DEV) {
        if (preferences.analytics) {
          void $$analytics.initFx({});
        }

        void $$googleAnalytics.consentFx(preferences);
      }
    },
  });

  return {
    init: $$consent.init,
    $$ui: {
      $$consent,
    },
  };
});

export { $$cookieConsent };
