import { Suspense } from 'react';
import type { RouteObject } from 'react-router-dom';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';

import {
  MainLayout,
  MainLayoutLoading,
} from '@kuna-pay/merchant/widgets/layout';

import { SettlementReportPage } from './pages/settlement';
import { StatementReportPage } from './pages/statement';

const ReportingPage = lazyWithRetryAndRefresh(
  'ReportingPage',
  () => import('./page.component')
);

const ReportingPages: RouteObject[] = [
  {
    path: '/reporting',
    Component: () => (
      <Suspense
        fallback={
          <MainLayout.Merchant.Content>
            <MainLayoutLoading />
          </MainLayout.Merchant.Content>
        }
      >
        <ReportingPage />
      </Suspense>
    ),
    children: [
      {
        path: 'settlement',
        Component: SettlementReportPage.Component,
      },
      {
        path: 'statement',
        Component: StatementReportPage.Component,
      },
    ],
  },
];

export { ReportingPages };
