import { useUnit } from 'effector-react';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getState } from '@kuna-pay/utils/effector';
import { invariant } from '@kuna-pay/utils/invariant';
import { ArrowRightIcon } from '@kuna-pay/ui/icons';
import { Button, IconButton } from '@kuna-pay/ui/ui/button';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { PersistStorage } from '@kuna-pay/core/shared/lib/persist-storage';

import { $$session } from '@kuna-pay/merchant/entities/session';
import { $$analytics } from '@kuna-pay/merchant/shared/analytics';
import { LocalStoragePersistKeys } from '@kuna-pay/merchant/shared/persist';

import { ReactComponent as CloseIcon } from '../../assets/cross.svg';
import { ReactComponent as WalletIcon } from '../../assets/wallet.svg';
import { PaymentPreferencesModal } from './payment-preferences-modal.component';
import styles from './payment-settings.module.scss';

const DiscoveryNotificationPaymentSettingsBanner = memo(() => {
  const { t } = useTranslation();

  const { isViewed, setViewed } = usePersistDiscoveryPaymentSettingsBanner();

  if (isViewed) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.header}>
          <WalletIcon className={styles.walletIcon} />

          <IconButton
            className={styles.close}
            onClick={async () => {
              setViewed();

              const companyId = (await getState(
                $$session.$$jwt.$$company.$id
              ))!;
              const userId = (await getState($$session.$$jwt.$$user.$id))!;

              void $$analytics.logEvent({
                event: 'Payment Settings One Time Notification Closed',
                properties: {
                  companyId,
                  userId,
                },
              });
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div className={styles.content}>
          <Typography className={styles.title} variant='subtitle4'>
            {t('pages.invoices.payment-settings-popup.title')}
          </Typography>

          <Typography className={styles.description} variant='numbers2'>
            {t('pages.invoices.payment-settings-popup.description')}
          </Typography>
        </div>

        <div className={styles.footer}>
          <PaymentPreferencesModal onClose={setViewed}>
            <Button
              variant='text'
              color='primary'
              size='lg'
              end={<ArrowRightIcon />}
            >
              {t('pages.invoices.payment-settings-popup.action')}
            </Button>
          </PaymentPreferencesModal>
        </div>
      </div>
    </div>
  );
});

const usePersistDiscoveryPaymentSettingsBanner = () => {
  const [_, rerender] = useState(0);

  const [companyId] = useUnit([$$session.$$jwt.$$company.$id]);

  const [storage] = useState(() => PersistStorage.create());

  const storageKey = useMemo(() => {
    invariant.log(companyId, 'Company ID should be defined');

    return `${LocalStoragePersistKeys.PaymentPreferencesDiscoveryPopup}:${companyId}`;
  }, [companyId]);

  const setViewed = useCallback(() => {
    storage.setItem(storageKey, JSON.stringify(true));

    rerender((prev) => prev + 1);
  }, [storageKey, storage]);

  return {
    isViewed: !!storage.getItem(storageKey),
    setViewed,
  };
};

export { DiscoveryNotificationPaymentSettingsBanner };
