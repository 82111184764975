import { AmplitudeAnalyticsFactory } from '@kuna-pay/core/shared/analytics';
import { GoogleTagManagerAnalyticsModel } from '@kuna-pay/core/shared/analytics/google-tag-manager';

import { environment } from '@kuna-pay/merchant/shared/config';

import type { MerchantEvents } from './analytics.types';

const $$analytics = AmplitudeAnalyticsFactory.createModel<MerchantEvents>({
  API_KEY: environment.amplitude.apiKey,

  initOptions: {
    /**
     * @see https://help.amplitude.com/hc/en-us/articles/360017800371-Releases-See-how-users-respond-to-changes-in-your-product
     */
    appVersion: environment.amplitude.release ?? 'Development',

    defaultTracking: {
      pageViews: false,
    },

    trackingOptions: {
      language: false,
    },
  },
});

const $$googleAnalytics = GoogleTagManagerAnalyticsModel.createModel();

export { $$analytics, $$googleAnalytics };
