import clsx from 'clsx';
import { forwardRef } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { styled } from '@kuna-pay/ui/theme';

import type { TypographyProps } from '../typography';
import { Typography } from '../typography';
import { BaseButton } from './base-button';
import type { ButtonSize } from './button.props';
import type { ButtonProps } from './button.props';
import styles from './button.module.scss';

const Button = createCompoundComponent(
  (Components) =>
    forwardRef<HTMLButtonElement, ButtonProps>(
      (
        {
          className,
          size,
          start,
          end,
          classes,
          children,
          typographyVariant,

          ...props
        },
        ref
      ) => (
        <Components.Root
          ref={ref}
          className={clsx(className, classes?.root)}
          size={size}
          hasStart={!!start}
          hasEnd={!!end}
          {...props}
        >
          {start}

          <Components.Text
            className={clsx(classes?.text)}
            size={size}
            hasStart={!!start}
            hasEnd={!!end}
            variant={typographyVariant}
          >
            {children}
          </Components.Text>

          {end}
        </Components.Root>
      )
    ),
  {
    Root: forwardRef<
      HTMLButtonElement,
      Omit<ButtonProps, 'classes' | 'start' | 'end'> & {
        hasStart?: boolean;
        hasEnd?: boolean;
      }
    >(
      (
        {
          className,
          color,
          variant,
          size,
          loading,
          disabled,
          children,
          disablePaddingLeft,
          disablePaddingTop,
          disablePaddingBottom,
          disablePaddingRight,
          hasStart,
          hasEnd,

          ...props
        },
        ref
      ) => (
        <BaseButton
          ref={ref}
          className={clsx(styles.root, className, {
            [styles.disablePaddingLeft]: disablePaddingLeft,
            [styles.disablePaddingTop]: disablePaddingTop,
            [styles.disablePaddingRight]: disablePaddingRight,
            [styles.disablePaddingBottom]: disablePaddingBottom,
          })}
          data-size={size}
          data-variant={variant}
          data-color={color}
          data-has-start={!!hasStart}
          data-has-end={!!hasEnd}
          disabled={!!loading || !!disabled}
          {...props}
        >
          {children}
        </BaseButton>
      )
    ),
    Text: ({
      className,
      size,
      hasEnd,
      hasStart,
      variant,

      ...props
    }: Omit<
      Partial<TypographyProps> &
        Pick<ButtonProps, 'size'> & { hasStart?: boolean; hasEnd?: boolean },
      'color'
    >) => (
      <Typography
        className={clsx(styles.text, className)}
        variant={variant ?? sizeTypographyVariant[size]}
        data-has-start={!!hasStart}
        data-has-end={!!hasEnd}
        {...props}
      />
    ),
  }
);

const ButtonGroup = styled('div', styles.group);

const sizeTypographyVariant: Record<ButtonSize, TypographyProps['variant']> = {
  sm: 'small2',
  md: 'numbers1',
  lg: 'subtitle5',
  xl: 'subtitle3',
};

export { Button, ButtonGroup };
