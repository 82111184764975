import { useUnit } from 'effector-react';

import type { ModelOf } from '@kuna-pay/utils/effector';
import { createCompoundComponent } from '@kuna-pay/utils/ui';

import type { CookieConsentModelFactory } from '../model/cookie-consent.factory';
import { CookieConsentBanner } from './cookie-consent-banner.component';
import { ManageCookieSettings } from './manage-cookie-settings.component';

type CookieConsentProps = {
  $$model: ModelOf<typeof CookieConsentModelFactory>;
};

const CookieConsent = createCompoundComponent(
  (C) =>
    ({ $$model }: CookieConsentProps) => {
      const [visible, onAcceptAll, onManageOrReject] = useUnit([
        $$model.$$ui.$$banner.$visible,
        $$model.$$ui.$$banner.allAcceptClicked,
        $$model.$$ui.$$banner.manageOrRejectClicked,
      ]);

      return (
        <>
          <C.Banner
            show={visible}
            onAcceptAll={onAcceptAll}
            onManage={onManageOrReject}
          />

          <ManageCookieSettings $$model={$$model.$$ui.$$cookiePreferences} />
        </>
      );
    },
  {
    Banner: CookieConsentBanner,
    Settings: ManageCookieSettings,
  }
);

export { CookieConsent };
