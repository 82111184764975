enum Verify2FAErrors {
  // Errors for logic
  OTP_REQUIRED = 'OTP_REQUIRED',

  // Errors with message
  INCORRECT_OTP = 'INCORRECT_OTP',
  OTP_ALREADY_USED = 'OTP_ALREADY_USED',
}

type VerifyOtpPayload = {
  otp: string;
};

export type { VerifyOtpPayload };
export { Verify2FAErrors };
