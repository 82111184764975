import { useUnit } from 'effector-react';
import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { $$optional2FAFeatureFlag } from '@kuna-pay/merchant/shared/feature-flags/features/static';

import { MFAActivatedPage } from './activated';
import { AskToSetupAuthenticator2FA } from './ask';
import { TurnOn2FAPage } from './turn-on';

const MFAPages: RouteObject[] = [
  {
    path: '/2fa/*',
    children: [
      {
        path: 'turn-on',
        Component: TurnOn2FAPage.Component,
      },
      {
        path: 'activated',
        Component: MFAActivatedPage.Component,
      },
      {
        path: 'ask',
        Component: () => {
          const optional2FAFeatureFlag = useUnit($$optional2FAFeatureFlag);

          if (optional2FAFeatureFlag.disabled) {
            return <Navigate to='turn-on' replace />;
          }

          return <AskToSetupAuthenticator2FA.Component />;
        },
      },
      {
        path: '*',
        Component: () => {
          const optional2FAFeatureFlag = useUnit($$optional2FAFeatureFlag);

          return (
            <Navigate
              to={optional2FAFeatureFlag.enabled ? 'ask' : 'turn-on'}
              replace
            />
          );
        },
      },
    ],
  },
];

export { MFAPages };
