import { createEffect } from 'effector';
import { toast } from 'react-toastify';

import { listen } from '@kuna-pay/utils/effector';

import { $$copy } from '../ui/button';
import type { INotify, Notification } from './notification.types';
import { Toast } from './ui';

const AUTO_CLOSE_TIMEOUT_MS = 5_000;

const displayNotificationFx = createEffect(
  ({ message: msgs, mode, options = {} }: Notification) => {
    const messages = Array.isArray(msgs) ? msgs : [msgs];
    messages
      /**
       * Filter out empty messages
       * if somehow we get empty message
       *
       * @example missing translation key
       */
      .filter(Boolean)

      .forEach((message) => {
        const id = toast(<Toast type={mode}>{message}</Toast>, {
          autoClose: AUTO_CLOSE_TIMEOUT_MS,
          ...options,
          type: mode,
        });

        setTimeout(() => toast.dismiss(id), AUTO_CLOSE_TIMEOUT_MS);
      });
  }
);

const notify: INotify = {
  success: (message, options) => {
    displayNotificationFx({ message, options, mode: 'success' });
  },
  error: (message, options) => {
    displayNotificationFx({ message, options, mode: 'error' });
  },
  warning: (message, options) => {
    displayNotificationFx({ message, options, mode: 'warning' });
  },
};

listen({
  clock: $$copy.copyToClipboardFx.done,
  handler: ({ params }) => {
    notify.success(params.message ?? `'${params.value}' copied`);
  },
});

export { notify };
