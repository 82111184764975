import type { FC } from 'react';

import type { CompanyType } from '@kuna-pay/merchant/generated/graphql';

import { $$companyTypeGuard } from '../../model';
import type { BaseCanAccessProps } from './base.can-access.component';
import { BaseCanAccess } from './base.can-access.component';

type CompanyTypeHasAccessProps = BaseCanAccessProps & {
  type: CompanyType | CompanyType[];
};

const CompanyTypeHasAccess: FC<CompanyTypeHasAccessProps> = ({
  type,
  ...props
}) => {
  const canAccess = $$companyTypeGuard.hooks.useCanAccess(type);

  return <BaseCanAccess can={canAccess} {...props} />;
};

export { CompanyTypeHasAccess };
