import { useUnit } from 'effector-react';

import { useIntersectionObserver } from '@kuna-pay/utils/hooks/browser';

import { DataGridModel } from './model';

const useInfiniteLoading = <T extends Record<string, unknown>>() => {
  const $$model = DataGridModel.useModel<T>();

  const { rowsCount, onEndReached } = useUnit({
    rowsCount: $$model.$rowsCount,
    onEndReached: $$model.$$infiniteScroll.endReached,
  });

  const { ref } = useIntersectionObserver(
    (entry) => {
      if (!entry.isIntersecting) return;

      onEndReached();
    },
    // if rowsCount changes, we need to re-observe the element to trigger the callback if it's already in the viewport
    [rowsCount]
  );

  return { ref };
};

export { useInfiniteLoading };
