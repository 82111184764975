import { useUnit } from 'effector-react';
import type { PropsWithChildren } from 'react';
import { memo } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { $$redirectOnSignIn } from '../../model';

const RedirectOnSignInCatchall = memo(({ children }: PropsWithChildren) => {
  const location = useLocation();
  const [savePath] = useUnit([$$redirectOnSignIn.savePath]);

  useEffect(() => {
    savePath(location.pathname);
  }, [location.pathname]);

  return <>{children}</>;
});

export { RedirectOnSignInCatchall };
