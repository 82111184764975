import { createFeatureFlagFactory } from '@kuna-pay/core/shared/feature-flags';

import type { AvailableFeatureFlags } from '../feature-flags.types';
import { $$featureFlags } from './dynamic.model';

const createDynamicFlag = createFeatureFlagFactory<AvailableFeatureFlags>(
  $$featureFlags.$features
);

type DynamicFeatureFlag = ReturnType<typeof createDynamicFlag>;

export { createDynamicFlag };
export type { DynamicFeatureFlag };
