//try to fix error TS2354: This syntax requires an imported helper but module 'tslib' cannot be found.
import type {} from 'tslib';

import { createLazyWithRetryAndRefresh } from '../react';

/**
 *  @see https://kunatech.atlassian.net/browse/KUPAY-1166
 */
const lazyWithRetryAndRefresh = createLazyWithRetryAndRefresh({
  persistName: 'has-been-force-refreshed',
  storage: window.localStorage,
  retryTimes: 2,
});

export { lazyWithRetryAndRefresh };
