import type { RouteObject } from 'react-router-dom';

import { TransactionProcessingPage } from '@kuna-pay/merchant/pages/transactions/processing';

import { TransactionDetailsPage } from './details';
import { TransactionListPage } from './list';

const TransactionPages: RouteObject[] = [
  {
    path: '/transactions/*',
    children: [
      {
        index: true,
        Component: TransactionListPage.Component,
      },
      {
        path: ':id',
        Component: TransactionDetailsPage.Component,
      },
    ],
  },
];

const TransactionNavbarlessPages: RouteObject[] = [
  {
    path: '/transactions/processing/:id',
    Component: TransactionProcessingPage.Component,
  },
];

export { TransactionNavbarlessPages, TransactionPages };
