import type { SlotProps } from '@radix-ui/react-slot';
import { Slot } from '@radix-ui/react-slot';
import { useUnit } from 'effector-react';
import { type ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { modelView } from '@kuna-pay/utils/effector';
import type { ViewModelProps } from '@kuna-pay/utils/effector/factorio';
import { Button } from '@kuna-pay/ui/ui/button';
import { Modal } from '@kuna-pay/ui/ui/modal';
import { State } from '@kuna-pay/core/shared/lib/state';
import { useTypedGate } from '@kuna-pay/core/shared/router';

import { ThanksForYourFeedback } from '../../ui/thanks-for-your-feedback';
import { CollectFeedbackForm } from './collect-feedback-form.component';
import { GenericFeedbackModel } from './generic-feedback.model';
import { GenericFeedbackSteps } from './generic-feedback.types';
import styles from './generic-feedback.module.scss';

type GenericFeedbackTriggerProps = SlotProps &
  ViewModelProps<typeof GenericFeedbackModel>;

const GenericFeedback = {
  Modal: modelView(GenericFeedbackModel, () => {
    const $$model = GenericFeedbackModel.useModel();

    const i18n = useTranslation('core');

    useTypedGate({ Gate: $$model.Gate, props: { i18n } });

    return (
      <Modal
        id={$$model.ID}
        model={$$model.$$modal}
        content={
          <Modal.Content className={styles.container} size='md'>
            <Modal.Close />

            <State.Switch $$model={$$model.$$state}>
              <State.Case
                value={GenericFeedbackSteps.Feedback}
                view={<CollectFeedbackForm />}
              />

              <State.Case
                value={GenericFeedbackSteps.Success}
                view={
                  <ThanksForYourFeedback
                    containerType='modal'
                    append={
                      <Modal.ActionClose asChild>
                        <Button
                          variant='contained'
                          color='primary'
                          size='lg'
                          fullWidth
                        >
                          {i18n.t(
                            'shared.feedback.thanks-for-your-feedback.close'
                          )}
                        </Button>
                      </Modal.ActionClose>
                    }
                  />
                }
              />
            </State.Switch>
          </Modal.Content>
        }
      >
        <div
          style={{
            // Modal need element to mount to,
            // but we don't want this element to influence layout (flex gap)
            position: 'fixed',
          }}
        />
      </Modal>
    );
  }),

  Trigger: forwardRef(
    (
      { $$model, children, onClick, ...props }: GenericFeedbackTriggerProps,
      ref: ForwardedRef<HTMLElement>
    ) => {
      const onModalOpen = useUnit($$model.$$modal.open);

      return (
        <Slot
          ref={ref}
          onClick={(e) => {
            onModalOpen();
            onClick?.(e);
          }}
          {...props}
        >
          {children}
        </Slot>
      );
    }
  ),
};

export { GenericFeedback };
