import type { FC } from 'react';

import {
  MainLayout,
  MainLayoutLoading,
} from '@kuna-pay/merchant/widgets/layout';

import styles from './page.module.scss';

const AlreadyVerifiedPageLoading: FC = () => (
  <MainLayout.Merchant.Content className={styles.nav}>
    <MainLayoutLoading width={445} height={638} />
  </MainLayout.Merchant.Content>
);

export { AlreadyVerifiedPageLoading };
