import {
  SuccessOutput,
  MutationPublicRateUxArgs,
} from '@kuna-pay/merchant/generated/graphql';
import { subscription, query } from '@kuna-pay/merchant/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const publicRateUXRequest = (
  select: Select<SuccessOutput> | string
) => `mutation publicRateUX($feature: String!, $rating: String!, $feedback: String, $meta: FeedbackMeta) {
  data: publicRateUX(
    feature: $feature
    rating: $rating
    feedback: $feedback
    meta: $meta
  ) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'SuccessOutput')}`;
export const publicRateUX =
  (select: Select<SuccessOutput> | string) =>
  (args: MutationPublicRateUxArgs) =>
    query<SuccessOutput>(publicRateUXRequest(select), args);
