import clsx from 'clsx';
import { useUnit } from 'effector-react';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Cross24Icon } from '@kuna-pay/ui/icons';
import { NavLink } from '@kuna-pay/ui/router';
import { Button, IconButton } from '@kuna-pay/ui/ui/button';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { PersistStorage } from '@kuna-pay/core/shared/lib/persist-storage';

import { $$session } from '@kuna-pay/merchant/entities/session';
import { LocalStoragePersistKeys } from '@kuna-pay/merchant/shared/persist';
import { routes } from '@kuna-pay/merchant/shared/router';

import { ReactComponent as OtpNotEnabledAlertIcon } from '../../assets/otp-not-enabled-alert.svg';
import styles from './mfa-not-enabled-alert.module.scss';

type MFANotEnabledAlertProps = {
  disableMarginBottom?: boolean;

  encourage2FAText?: boolean;
};

const useIsMFANotEnabledAlertVisible = () => {
  const [_, rerender] = useState(0);
  const isOtpEnabled = useUnit($$session.$$jwt.$$user.$isOtpEnabled);

  const [storage] = useState(() => PersistStorage.create());

  const setVisible = useCallback(() => {
    storage.setItem(
      LocalStoragePersistKeys.MfaNotEnabledAlert,
      JSON.stringify(true)
    );

    rerender((prev) => prev + 1);
  }, [storage]);

  return {
    isVisible:
      !isOtpEnabled &&
      !storage.getItem(LocalStoragePersistKeys.MfaNotEnabledAlert),

    setVisible,
  };
};

const MFANotEnabledAlert = Object.assign(
  memo(({ disableMarginBottom, encourage2FAText }: MFANotEnabledAlertProps) => {
    const { t } = useTranslation();

    const { isVisible, setVisible } = useIsMFANotEnabledAlertVisible();

    if (!isVisible) {
      return null;
    }

    return (
      <div
        className={clsx(styles.root, {
          [styles.disableMarginBottom]: disableMarginBottom,
        })}
      >
        <div className={styles.content}>
          <div className={styles.aside}>
            <OtpNotEnabledAlertIcon />
          </div>

          {encourage2FAText ? (
            <Typography className={clsx(styles.text)} variant='subtitle5'>
              {t('widgets.layout.mfa-not-enabled-alert.title-encourage')}
            </Typography>
          ) : (
            <Typography className={clsx(styles.text)} variant='subtitle5'>
              {t('widgets.layout.mfa-not-enabled-alert.title')}{' '}
              <Button.Root
                variant='text'
                color='primary'
                size='lg'
                disablePaddingLeft
                disablePaddingTop
                disablePaddingBottom
                disablePaddingRight
              >
                <NavLink to={routes.profile.security}>
                  <Button.Text size='lg'>
                    {t('widgets.layout.mfa-not-enabled-alert.action')}
                  </Button.Text>
                </NavLink>
              </Button.Root>
            </Typography>
          )}
        </div>

        <IconButton className={styles.close} onClick={setVisible}>
          <Cross24Icon />
        </IconButton>
      </div>
    );
  }),
  {
    useIsVisible: useIsMFANotEnabledAlertVisible,
  }
);

export { MFANotEnabledAlert };
