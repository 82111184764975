import { ConfirmEmailForm } from './confirm-email.component';
import { ConfirmEmailModel } from './confirm-email.model';

const ConfirmEmail = Object.assign(ConfirmEmailForm, {
  factory: ConfirmEmailModel,
  Form: ConfirmEmailForm,
});

export { ConfirmEmail };
export type { ConfirmEmailFormProps } from './confirm-email.component';
