import clsx from 'clsx';
import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import styles from './paper.module.scss';

type PaperProps = HTMLAttributes<HTMLDivElement> & {
  flatBottomBorders?: boolean;
  shadow?: boolean;
  rounded?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

const Paper = forwardRef<HTMLDivElement, PaperProps>(
  ({ className, flatBottomBorders, shadow, rounded = 'xl', ...props }, ref) => (
    <div
      ref={ref}
      className={clsx(styles.paper, className, {
        [styles.roundedXs]: rounded === 'xs',
        [styles.roundedSm]: rounded === 'sm',
        [styles.roundedMd]: rounded === 'md',
        [styles.roundedLg]: rounded === 'lg',
        [styles.roundedXl]: rounded === 'xl',
      })}
      data-flat-bottom-borders={!!flatBottomBorders}
      data-shadow={!!shadow}
      {...props}
    />
  )
);

export { Paper };
export type { PaperProps };
