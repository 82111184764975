import clsx from 'clsx';
import { forwardRef } from 'react';

import { KunaLogoIcon } from '../../../icons';
import { Typography } from '../../typography';
import { UnstyledButton } from '../base-button';
import type { KunaButtonProps } from './kuna-button.props';
import styles from './kuna-button.module.scss';

//TODO: Move to ui-kit
const KunaButton = forwardRef<HTMLButtonElement, KunaButtonProps>(
  ({ className, classes, ...props }, ref) => (
    <UnstyledButton
      ref={ref}
      className={clsx(styles.button, className, classes?.root)}
      {...props}
    >
      <KunaLogoIcon className={clsx(styles.icon, classes?.icon)} />

      <Typography
        className={clsx(styles.text, classes?.text)}
        variant='subtitle5'
      >
        KUNA <span className={styles.separator} /> Exchange
      </Typography>
    </UnstyledButton>
  )
);

export { KunaButton };
