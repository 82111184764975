import type { Store } from 'effector';
import { combine } from 'effector';
import { attach, createStore } from 'effector';

import { modelFactory, setState } from '@kuna-pay/utils/effector';

import { ReCaptcha } from './recaptcha.lib';

type RecaptchaModelConfig = {
  $isEnabled: Store<boolean>;

  SITE_KEY: string;
};

const RecaptchaModel = modelFactory((config: RecaptchaModelConfig) => {
  const $token = createStore<string | null>(null);
  const $reCaptcha = createStore<ReCaptcha | null>(null);

  const $isEnabled = combine($reCaptcha, (instance) => !!instance);

  const initFx = attach({
    source: config.$isEnabled,
    effect: async (isRecaptchaEnabled) => {
      if (!isRecaptchaEnabled) {
        return;
      }

      const reCaptcha = new ReCaptcha(config.SITE_KEY);

      setState($reCaptcha, reCaptcha);
    },
  });

  const ensureRecaptchaFx = attach({
    source: $reCaptcha,
    effect: async (reCaptcha) => {
      if (!reCaptcha) {
        return;
      }

      const token = await reCaptcha.getToken();

      setState($token, token ?? null);
    },
  });

  const showBadgeFx = attach({
    source: $reCaptcha,
    effect: async (reCaptcha) => {
      if (!reCaptcha) {
        return;
      }

      reCaptcha.showReCaptchaBadge();
    },
  });

  const hideBadgeFx = attach({
    source: $reCaptcha,
    effect: async (reCaptcha) => {
      if (!reCaptcha) {
        return;
      }

      reCaptcha.hideReCaptchaBadge();
    },
  });

  return {
    $isEnabled,
    $token,
    initFx,
    ensureRecaptchaFx,
    $$ui: { showBadgeFx, hideBadgeFx },
  };
});

export { RecaptchaModel };
