import type { SlotProps } from '@radix-ui/react-slot';
import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type {
  ForwardedRef,
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from 'react';
import { memo, useEffect } from 'react';
import { forwardRef } from 'react';
import { cloneElement, isValidElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import type { ModelOf } from '@kuna-pay/utils/effector';
import { modelView } from '@kuna-pay/utils/effector';
import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { TelegramIcon, WhatsAppIcon } from '@kuna-pay/ui/icons';
import type { ButtonProps } from '@kuna-pay/ui/ui/button';
import { Button } from '@kuna-pay/ui/ui/button';
import { Modal } from '@kuna-pay/ui/ui/modal';
import type { ModalProps } from '@kuna-pay/ui/ui/modal/modal';
import { urls } from '@kuna-pay/core/shared/config';

import { useAnalytics } from '@kuna-pay/merchant/shared/analytics';

import { ContactSupportModalViewedEvent } from './analytics';
import { ReactComponent as LiveChatLogo } from './assets/live-chat-logo.svg';
import supportImg from './assets/support.illustration.png';
import { ContactSupportModel } from './contact-support.model';
import { $$liveChat } from './live-chat';
import styles from './contact-support.module.scss';

type ContactSupportProps = PropsWithChildren &
  Omit<ModalProps, 'content' | 'model'> & {
    title?: ReactNode;
    description?: ReactNode;
  };

const ContactSupport = createCompoundComponent(
  (Channels) =>
    modelView(
      ContactSupportModel,
      ({ children, id, title, description }: ContactSupportProps) => {
        const $$model = ContactSupportModel.useModel();
        const { t } = useTranslation();

        return (
          <Modal
            id={id ?? $$model.$$modal.ID}
            content={
              <Modal.Content className={styles.root} size='sm' rounded='lg'>
                <ContactSupportLogEventOnMount />

                {/** Close button here to prevent autofocus on actions */}
                <Modal.Close />

                <div className={styles.imgContainer}>
                  <img src={supportImg} width={92} height={92} alt='' />
                </div>

                <Modal.Title className={styles.title} variant='h9'>
                  {title ?? t('features.contact-support.title')}
                </Modal.Title>

                <Modal.Description
                  className={styles.description}
                  variant='body3'
                >
                  {description ?? t('features.contact-support.description')}
                </Modal.Description>

                <div className={styles.actions}>
                  <Channels.Telegram source='modal' />

                  <Channels.WhatsApp source='modal' />
                </div>
              </Modal.Content>
            }
            model={$$model.$$modal.$$modal}
          >
            {children}
          </Modal>
        );
      }
    ),
  {
    Trigger: forwardRef(
      (
        {
          $$model,
          children,
          onClick,
          ...props
        }: SlotProps & {
          $$model: ModelOf<typeof ContactSupportModel>;
        },
        ref: ForwardedRef<HTMLElement>
      ) => {
        const onModalOpen = useUnit($$model.$$modal.open);

        return (
          <Slot
            ref={ref}
            onClick={(e) => {
              onModalOpen();
              onClick?.(e);
            }}
            {...props}
          >
            {children}
          </Slot>
        );
      }
    ),

    Telegram: ({
      icon,
      children,
      source,

      ...props
    }: Partial<Omit<ButtonProps, 'start'>> & {
      icon?: ReactElement<{ className?: string }>;

      source: string;
    }) => {
      const { t } = useTranslation();
      const analytics = useAnalytics();

      const onClick = () => {
        analytics.logEvent({
          event: 'ContactSupport Telegram Clicked',
          properties: { source },
        });
      };

      return (
        <a
          href={urls.support.telegram}
          target='_blank'
          rel='noreferrer'
          onClick={onClick}
        >
          <Button
            className={styles.action}
            variant='contained'
            color='secondary'
            size='lg'
            start={
              isValidElement(icon) ? (
                cloneElement(icon, { className: styles.actionIcon })
              ) : (
                <TelegramIcon className={styles.actionIcon} />
              )
            }
            fullWidth
            tabIndex={0}
            {...props}
          >
            {children ?? t('features.contact-support.channels.tg')}
          </Button>
        </a>
      );
    },
    WhatsApp: ({
      icon,
      children,
      source,

      ...props
    }: Partial<Omit<ButtonProps, 'start'>> & {
      icon?: ReactElement<{ className?: string }>;

      source: string;
    }) => {
      const { t } = useTranslation();
      const analytics = useAnalytics();

      const onClick = () => {
        analytics.logEvent({
          event: 'ContactSupport WhatsApp Clicked',
          properties: { source },
        });
      };

      return (
        <a
          href={urls.support.whatsapp}
          target='_blank'
          rel='noreferrer'
          onClick={onClick}
        >
          <Button
            className={styles.action}
            variant='contained'
            color='secondary'
            size='lg'
            start={
              isValidElement(icon) ? (
                cloneElement(icon, { className: styles.actionIcon })
              ) : (
                <WhatsAppIcon className={styles.actionIcon} />
              )
            }
            fullWidth
            tabIndex={0}
            {...props}
          >
            {children ?? t('features.contact-support.channels.wa')}
          </Button>
        </a>
      );
    },

    LiveChat: ({
      children,
      source,
      onClick,
      ...props
    }: Partial<Omit<ButtonProps, 'start'>> & {
      source: string;
    }) => {
      const { t } = useTranslation();

      const liveChat = $$liveChat.useVisibility();
      const analytics = useAnalytics();

      const onButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
          onClick(event);
        }

        if (!liveChat.isVisible) {
          liveChat.show();
        }

        analytics.logEvent({
          event: 'ContactSupport LiveChat Clicked',
          properties: { source },
        });
      };

      return (
        <Button
          className={styles.action}
          variant='contained'
          color='secondary'
          size='lg'
          onClick={onButtonClick}
          disabled={liveChat.isVisible}
          title={
            liveChat.isVisible
              ? t(
                  'features.contact-support.channels.livechat.hints.is-visible'
                )!
              : undefined
          }
          start={
            <LiveChatLogo
              className={clsx(styles.actionIcon, styles.lvChatIcon)}
            />
          }
          fullWidth
          tabIndex={0}
          {...props}
        >
          {children ?? t('features.contact-support.channels.livechat.title')}
        </Button>
      );
    },
  }
);

const ContactSupportLogEventOnMount = memo(() => {
  const location = useLocation();

  useEffect(() => {
    const event = new ContactSupportModalViewedEvent();

    void event.logEvent(location);
  }, []);

  return null;
});

export { ContactSupport };
