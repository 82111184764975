import type { WidgetState } from '@livechat/widget-react';
import { combine, createApi, createEvent, createStore } from 'effector';
import { useStoreMap, useUnit } from 'effector-react';

import { UNIT_SHAPE } from '@kuna-pay/utils/effector';
import { atom, bridge } from '@kuna-pay/utils/misc';

import { $$session } from '@kuna-pay/merchant/entities/session';

const $$liveChat = atom(() => {
  const visibilityChanged = createEvent<Pick<WidgetState, 'visibility'>>();

  const $customerEmail = $$session.$$jwt.$$user.$email;
  const $visibility = createStore<WidgetState['visibility']>('hidden');

  const $$changeVisibility = createApi($visibility, {
    show: () => 'maximized',
    hide: () => 'hidden',
  });

  bridge(() => {
    $visibility.on(visibilityChanged, (_, { visibility }) => visibility);
  });

  const $sessionVariables = combine(
    {
      jwt_user_id: $$session.$$jwt.$$user.$id,
      jwt_email: $$session.$$jwt.$$user.$email,
      company: $$session.$$merchantQuery.$data,
    },
    ({ jwt_user_id, jwt_email, company }): Record<string, string> => {
      /**
       * @note This vars are shown in Support Chats
       */
      const result: Record<string, string> = {};

      result.is_logged_into_system = `${!!jwt_user_id}`;
      result.is_logged_into_company = `${!!company}`;

      if (jwt_user_id) {
        result.last_known_jwt_user_id = jwt_user_id;
      }

      if (jwt_email) {
        result.last_known_jwt_user_email = jwt_email;
      }

      if (company) {
        result.last_logged_company_id = company.id;
        result.last_logged_company_title = company.title;
      }

      return result;
    }
  );

  return {
    [UNIT_SHAPE]: () => ({
      customerEmail: $customerEmail,
      sessionVariables: $sessionVariables,
      visibility: $visibility,

      onVisibilityChange: visibilityChanged,
    }),

    useVisibility: () => {
      const { show, hide } = useUnit($$changeVisibility);

      const isVisible = useStoreMap($visibility, (v) => v !== 'hidden');

      return {
        isVisible,
        show,
        hide,
      };
    },
  };
});

export { $$liveChat };
