import { createEffect } from 'effector';

import { Cookie } from '../cookie';

const setCookieFx = createEffect(Cookie.setCookie);

const getCookieFx = createEffect(Cookie.getCookie);

const eraseCookieFx = createEffect(Cookie.eraseCookie);

export { eraseCookieFx, getCookieFx, setCookieFx };
