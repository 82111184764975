import clsx from 'clsx';
import type { ReactNode } from 'react';
import { useContext, useEffect } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@kuna-pay/ui/ui/modal';
import { Typography } from '@kuna-pay/ui/ui/typography';

import thanksForYourFeedbackImg from '../../assets/thanks-for-your-feedback.png';
import styles from './thanks-for-your-feedback.module.scss';

type ThanksForYourFeedbackProps = {
  containerType: 'modal' | 'popup';

  append?: ReactNode;
};

const ThanksForYourFeedback = memo(
  ({ containerType, append }: ThanksForYourFeedbackProps) => {
    const { t } = useTranslation('core');
    const { onClose } = useContext(Modal.Context);

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        onClose();
      }, 3000);

      return () => {
        clearTimeout(timeoutId);
      };
    }, []);

    return (
      <>
        <div
          className={clsx(styles.imgContainer, {
            [styles.modalImgContainer]: containerType === 'modal',
            [styles.popupImgContainer]: containerType === 'popup',
          })}
        >
          <img
            className={clsx(styles.img, {
              [styles.modalImg]: containerType === 'modal',
              [styles.popupImg]: containerType === 'popup',
            })}
            src={thanksForYourFeedbackImg}
            alt=''
          />
        </div>

        <Typography
          className={clsx(styles.title, {
            [styles.modalTitle]: containerType === 'modal',
            [styles.popupTitle]: containerType === 'popup',
          })}
          variant={containerType === 'popup' ? 'subtitle2' : 'h9'}
        >
          {t('shared.feedback.thanks-for-your-feedback.title')}
        </Typography>

        <Typography
          className={clsx(styles.description, {
            [styles.modalDescription]: containerType === 'modal',
            [styles.popupDescription]: containerType === 'popup',
          })}
          variant='body3'
        >
          {t('shared.feedback.thanks-for-your-feedback.description')}
        </Typography>

        {append}
      </>
    );
  }
);

export { ThanksForYourFeedback };
