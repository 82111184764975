import { useStoreMap } from 'effector-react';
import { lazy, memo, Suspense } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { Skeleton } from '@kuna-pay/ui/ui/skeleton';

import { $$session } from '@kuna-pay/merchant/entities/session';

const LazyDropdown = lazy(() => import('./company-dropdown.component'));

const CompanyDropdown = createCompoundComponent(
  (C) =>
    memo(() => {
      const isCompanyAuthorized = C.useIsCompanyAuthorized();

      if (!isCompanyAuthorized) {
        return null;
      }

      return (
        <Suspense fallback={<Skeleton width={150} height={35} />}>
          <LazyDropdown />
        </Suspense>
      );
    }),
  {
    useIsCompanyAuthorized: () =>
      useStoreMap({
        store: $$session.$$merchantQuery.$data,
        fn: (data) => !!data,
        keys: [],
      }),
  }
);

export { CompanyDropdown };
