import type { Store } from 'effector';
import { createEffect } from 'effector';

import { getState, modelFactory, setState } from '@kuna-pay/utils/effector';

import type { NotificationOutput } from '@kuna-pay/merchant/generated/graphql';
import { markAllNotificationAsRead } from '@kuna-pay/merchant/shared/api/generated/PushNotifications/request/markAllNotificationAsRead';
import { markManyNotificationAsRead } from '@kuna-pay/merchant/shared/api/generated/PushNotifications/request/markManyNotificationAsRead';

type OptimisticReadPushNotificationModelConfig = {
  $$notifications: {
    $data: Store<NotificationOutput[]>;
  };
  $$counter: {
    $data: Store<{ count: number } | null>;
    refreshFx: () => Promise<any>;
  };
};

const OptimisticReadPushNotificationModel = modelFactory(
  (config: OptimisticReadPushNotificationModelConfig) => {
    const readNotificationFx = createEffect(
      async (notification: NotificationOutput) => {
        if (notification.isRead) {
          return;
        }

        try {
          const notifications = await getState(config.$$notifications.$data);
          setState(
            config.$$notifications.$data,
            notifications.map((n) => {
              if (n.id === notification.id) {
                return { ...notification, isRead: true };
              }

              return n;
            })
          );

          await markManyNotificationAsRead({ success: true })({
            ids: [notification.id],
          });

          void config.$$counter.refreshFx();
        } catch (error) {
          const notifications = await getState(config.$$notifications.$data);
          setState(
            config.$$notifications.$data,
            notifications.map((n) => {
              if (n.id === notification.id) {
                return { ...n, isRead: false };
              }

              return n;
            })
          );
        }
      }
    );

    const readAllNotificationFx = createEffect(async () => {
      const data = await getState(config.$$notifications.$data);

      try {
        setState(
          config.$$notifications.$data,
          data.map((notification) => ({
            ...notification,
            isRead: true,
          }))
        );
        setState(config.$$counter.$data, { count: 0 });

        await markAllNotificationAsRead({ success: true })();
      } catch (error) {
        const currentNotifications = await getState(
          config.$$notifications.$data
        );
        const oldNotificationsAsKV = data.reduce((acc, notification) => {
          acc[notification.id] = notification;

          return acc;
        }, {} as Record<string, NotificationOutput>);

        setState(
          config.$$notifications.$data,
          currentNotifications.map((notification) => ({
            ...notification,
            isRead:
              oldNotificationsAsKV[notification.id]?.isRead ??
              notification.isRead,
          }))
        );
      } finally {
        void config.$$counter.refreshFx();
      }
    });

    return {
      readNotificationFx,
      readAllNotificationFx,
    };
  }
);

export { OptimisticReadPushNotificationModel };
