import { combine } from 'effector';

import { listen } from '@kuna-pay/utils/effector';
import { atom } from '@kuna-pay/utils/misc';
import type { Feature } from '@kuna-pay/core/generated/public/graphql';
import { createEQuery } from '@kuna-pay/core/shared/lib/effector-query';

import { $$api } from '@kuna-pay/merchant/shared/api';
import { publicFeatures } from '@kuna-pay/merchant/shared/api/generated/Feature/request/publicFeatures';

import { listToKV } from '../lib';
import { $$staticFeatures } from './static.model';

const $$featureFlags = atom(() => {
  const $$dynamicFeatures = createEQuery({
    initialData: [] as Feature[],

    query: async (_: void) => {
      const features = await publicFeatures({ name: true, value: true })();

      return features;
    },
  });

  listen({
    clock: $$api.tokenService.onTokenUpdated,
    handler: () => {
      void $$dynamicFeatures.refreshFx();
    },
  });

  listen({
    clock: $$api.tokenService.onTokenCleared,
    handler: () => {
      $$dynamicFeatures.reset();
    },
  });

  return {
    loadStaticFeaturesFx: $$staticFeatures.startFx,
    loadDynamicFeaturesFx: $$dynamicFeatures.startFx,

    $features: combine(
      $$staticFeatures.$data,
      $$dynamicFeatures.$data,
      (nonAuth, auth) => ({ ...listToKV(nonAuth), ...listToKV(auth) })
    ),
    $dynamicFeatures: $$dynamicFeatures.$data,
    $staticFeatures: $$staticFeatures.$data,

    $$staticFeatures,
    $$dynamicFeatures,
  };
});

export { $$featureFlags };
