const isIncludeLowercase = (str = '') => /^(?=.*[a-z]).+$/.test(str);

const isIncludeUppercase = (str = '') => /^(?=.*[A-Z]).+$/.test(str);

const isIncludeSpecSymbols = (str = '') =>
  /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).+$/.test(str);

const isLongerThan =
  (num: number, config: { includes: boolean } = { includes: false }) =>
  (str = '') =>
    config.includes ? str.length >= num : str.length > num;

const isSmallerThan =
  (num: number, config: { includes: boolean } = { includes: false }) =>
  (str = '') =>
    config.includes ? str.length <= num : str.length < num;

export {
  isIncludeLowercase,
  isIncludeSpecSymbols,
  isIncludeUppercase,
  isLongerThan,
  isSmallerThan,
};
