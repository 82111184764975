import { combine, createEvent, createStore, sample } from 'effector';
import { delay } from 'patronum';
import { useMemo } from 'react';
import { v4 } from 'uuid';

import { bridge } from '@kuna-pay/utils/misc';
import { trimPayload } from '@kuna-pay/utils/transform';

import { ModalConfig } from './modal.config';

const MODAL_SINGLETON_KEY = 'SINGLETON';
const MODAL_ID_KEY = '__key';

type ModalPayload = { [MODAL_ID_KEY]: string } | void;

const createModal = (
  defaultValue: Record<string, boolean> = {},
  config?: { name?: string }
) => {
  const name = config?.name ?? 'unnamed.$$modal';

  const open = createEvent<ModalPayload>(`${name}.open`);
  const close = createEvent(`${name}.close`);
  const reset = createEvent(`${name}.reset`);
  const backClicked = createEvent(`${name}.backClicked`);

  const getKey = (param: ModalPayload) =>
    !!param ? param[MODAL_ID_KEY] : MODAL_SINGLETON_KEY;

  const $isOpen = createStore<Record<string, boolean>>(defaultValue, {
    name: `${name}.$isOpen`,
  })
    .on(open, (kv, config) => ({ ...kv, [getKey(config)]: true }))
    .on([close, reset], () => ({}));

  const closed = delay({
    source: close,
    timeout: ModalConfig.ANIMATION_DURATION_MS,
  });

  return {
    $isOpen,

    open,
    opened: open,
    close,
    closed: closed,

    backClicked: backClicked,

    reset,

    MODAL_ID_KEY,
  } as const;
};

const createSingleModal = (defaultOpen = false, config?: { name?: string }) => {
  const id = v4();

  const $$modal = createModal({ [id]: defaultOpen }, config);

  return {
    open: $$modal.open.prepend(() => ({ [$$modal.MODAL_ID_KEY]: id })),
    beforeOpen: $$modal.open.map(trimPayload),
    opened: $$modal.opened.map(trimPayload),
    $isOpen: combine($$modal.$isOpen, (kv) => kv[id] ?? false),
    close: $$modal.close,
    closed: $$modal.closed,
    backClicked: $$modal.backClicked,

    $$modal,
    ID: id,
  };
};

const createPrompt = () => {
  const confirmed = createEvent<ModalPayload>();
  const canceled = createEvent();

  const $$modal = createModal();

  bridge(() => {
    sample({
      clock: canceled,
      target: $$modal.close,
    });
  });

  return {
    $isOpen: $$modal.$isOpen,

    open: $$modal.open,
    close: $$modal.close,
    closed: $$modal.closed,
    backClicked: $$modal.backClicked,

    canceled,
    confirmed,

    reset: $$modal.reset,

    $$modal,
  };
};

type ModalModel = ReturnType<typeof createModal>;

const useModalId = (externalId?: string) =>
  useMemo(() => externalId ?? v4(), [externalId]);

export {
  createModal,
  createPrompt,
  createSingleModal,
  MODAL_ID_KEY,
  MODAL_SINGLETON_KEY,
  useModalId,
};
export type { ModalModel };
