import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '@kuna-pay/utils/react/use-media-query';
import type { PasswordTextFieldProps } from '@kuna-pay/ui/ui/text-field';
import { PasswordTextField } from '@kuna-pay/ui/ui/text-field';
import type { FieldModel } from '@kuna-pay/form';
import { useField } from '@kuna-pay/form';

import type { PasswordRequirementItem } from './password-requirements';
import { PasswordRequirementsConfig } from './password-requirements.config';
import { DesktopPasswordRequirements } from './password-requirements.desktop.component';
import { MobilePasswordRequirements } from './password-requirements.mobile.component';
import { PasswordInputTooltip } from './requirements-tooltip.component';

type FormPasswordInputProps = Omit<
  PasswordTextFieldProps,
  'onChange' | 'onFocus' | 'onBlur' | 'error' | 'value' | 'helperText'
> & {
  field: FieldModel<string>;

  hideValidation?: boolean;
};

const SessionPasswordField = memo(
  ({ hideValidation, field: $$field, ...props }: FormPasswordInputProps) => {
    /**
     * On 1000px and below, tooltip is overflowing the screen,
     * so we need to hide it and show requirements directly
     */
    const isShowMobileRequirements = useMediaQuery('(max-width: 1000px)');
    const { t } = useTranslation();

    const [isFocused, setFocused] = useState(false);
    const field = useField($$field);

    const shouldShowError = !hideValidation && field.shouldShowValidation;
    const error = shouldShowError ? field.error : undefined;

    const requirements = useMemo(
      () =>
        PasswordRequirementsConfig.requirements.map(
          (requirement): PasswordRequirementItem => ({
            text: t(requirement.text),
            passed: requirement.test.every((fn) => fn(field.value)),
          })
        ),
      [field.value, t]
    );

    return (
      <>
        <PasswordInputTooltip
          open={isShowMobileRequirements ? false : isFocused}
          inputHasError={field.hasError}
          content={<DesktopPasswordRequirements requirements={requirements} />}
        >
          <PasswordTextField
            name={field.path}
            onChange={(e) => field.onChange(e.target.value)}
            isError={!!error}
            helperText={error}
            onFocus={() => {
              field.onFocus();
              setFocused(true);
            }}
            onBlur={() => {
              field.onBlur();
              setFocused(false);
            }}
            hideHelperText={isShowMobileRequirements}
            {...props}
          />
        </PasswordInputTooltip>

        {isShowMobileRequirements && (
          <>
            {!!(field.touched || isFocused) && (
              <MobilePasswordRequirements requirements={requirements} />
            )}
          </>
        )}
      </>
    );
  }
);

export { SessionPasswordField };
