import { createUIBindingsToAmplitudeAnalytics } from '@kuna-pay/core/shared/analytics';

import { $$analytics } from './analytics.model';

const uiBindings = createUIBindingsToAmplitudeAnalytics($$analytics);

const useAnalytics = uiBindings.useAmplitudeAnalytics;
const LogEventOnMount = uiBindings.LogAmplitudeEventOnMount;

export { LogEventOnMount, useAnalytics };
