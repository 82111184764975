import { Suspense } from 'react';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';

import { MerchantListPageLoading } from './page.loading';

const Page = lazyWithRetryAndRefresh(
  'MerchantListPage',
  () => import('./page.component')
);

const MerchantListPage = {
  Component: () => (
    <Suspense fallback={<MerchantListPageLoading />}>
      <Page />
    </Suspense>
  ),
};

export { MerchantListPage };
