import { useUnit } from 'effector-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAssetFormat } from '@kuna-pay/core/entities/asset';

import { $$pushNotifications } from '@kuna-pay/merchant/entities/push-notification';
import type { NotificationOutput } from '@kuna-pay/merchant/generated/graphql';

import { PushNotificationTemplate } from '../../types';

type NotificationDescriptionProps = {
  notification: NotificationOutput;
};

const NotificationDescription = memo(
  ({ notification }: NotificationDescriptionProps) => {
    const { t } = useTranslation();
    const AssetFormat = useAssetFormat();
    const template = notification.template as PushNotificationTemplate;
    const [assets] = useUnit([$$pushNotifications.$$assets.$data]);

    switch (template) {
      case PushNotificationTemplate.SuccessfulLogin: {
        return t('entities.push-notification.description.login-successful', {
          replace: {
            ip: notification.payload.ip,
            browser: notification.payload.browser,
            location: notification.payload.location,
          },
        });
      }
      //

      case PushNotificationTemplate.IdentificationNeedHelp: {
        return t(
          'entities.push-notification.description.identification-need-help'
        );
      }

      case PushNotificationTemplate.IdentificationDetailsAboutProduct: {
        return t(
          'entities.push-notification.description.identification-details-about-product'
        );
      }

      case PushNotificationTemplate.IdentificationNeedToComplete: {
        return t(
          'entities.push-notification.description.identification-need-to-complete'
        );
      }

      case PushNotificationTemplate.IdentificationRejected: {
        return t(
          'entities.push-notification.description.identification-rejected'
        );
      }

      case PushNotificationTemplate.IdentificationAccepted: {
        return t(
          'entities.push-notification.description.identification-success'
        );
      }

      //
      case PushNotificationTemplate.InvoiceReminderWithHelp: {
        return t(
          'entities.push-notification.description.invoice-reminder-with-help'
        );
      }

      case PushNotificationTemplate.InvoiceDepositProcessed: {
        return t(
          'entities.push-notification.description.invoice-deposit-processed',
          {
            replace: {
              amount: AssetFormat.formatAmount(
                notification.payload.amount,
                assets[notification.payload.currency],
                { includeCode: false }
              ),
              currency: notification.payload.currency,
            },
          }
        );
      }

      case PushNotificationTemplate.InvoiceUnPaid: {
        return t('entities.push-notification.description.invoice-unpaid');
      }

      case PushNotificationTemplate.InvoiceDepositSuspended: {
        return t(
          'entities.push-notification.description.invoice-deposit-suspended'
        );
      }

      case PushNotificationTemplate.InvoiceDepositCheckSuccessful: {
        return t(
          'entities.push-notification.description.invoice-deposit-check-successful'
        );
      }

      case PushNotificationTemplate.InvoiceDepositArrested: {
        return t(
          'entities.push-notification.description.invoice-deposit-arrested'
        );
      }

      //
      case PushNotificationTemplate.DepositProcessed: {
        return t('entities.push-notification.description.deposit-processed', {
          replace: {
            amountWithCurrency: AssetFormat.formatAmount(
              notification.payload.amount,
              assets[notification.payload.currency]
            ),
          },
        });
      }

      case PushNotificationTemplate.CompanyExceededDepositLimits: {
        return t(
          'entities.push-notification.description.deposit-company-exceeded-limits'
        );
      }

      //
      case PushNotificationTemplate.AutoConvertProcessed: {
        return t(
          'entities.push-notification.description.auto-convert-processed',
          {
            replace: {
              amountWithCurrency: AssetFormat.formatAmount(
                notification.payload.amount,
                assets[notification.payload.currency]
              ),
            },
          }
        );
      }

      //
      case PushNotificationTemplate.ReferralRewardProcessed: {
        return t(
          'entities.push-notification.description.referral-reward-processed',
          {
            replace: {
              amountWithCurrency: AssetFormat.formatAmount(
                notification.payload.amount,
                assets[notification.payload.currency]
              ),
            },
          }
        );
      }

      case PushNotificationTemplate.ReferralReminderEndDiscount: {
        return t(
          'entities.push-notification.description.referral-reminder-end-discount',
          {
            replace: {
              //TODO
              date: notification.payload.date,
            },
          }
        );
      }

      //
      case PushNotificationTemplate.WithdrawalProcessed: {
        return t(
          'entities.push-notification.description.withdrawal-processed',
          {
            replace: {
              amountWithCurrency: AssetFormat.formatAmount(
                notification.payload.amount,
                assets[notification.payload.currency]
              ),
            },
          }
        );
      }

      //
      case PushNotificationTemplate.PayoutProcessed: {
        return t('entities.push-notification.description.payout-processed');
      }

      case PushNotificationTemplate.PayoutFailed: {
        return t('entities.push-notification.description.payout-failed');
      }

      default: {
        const _: never = template;

        return template;
      }
    }
  }
);

export type { NotificationDescriptionProps };
export { NotificationDescription };
