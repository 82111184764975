import { Suspense } from 'react';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';
import { Skeleton } from '@kuna-pay/ui/ui/skeleton';

const Page = lazyWithRetryAndRefresh(
  'WithdrawalCompanyFeesPage',
  () => import('./page.component')
);

const WithdrawalCompanyFeesPage = {
  Component: () => (
    <Suspense fallback={<Skeleton flex fullWidth />}>
      <Page />
    </Suspense>
  ),
};

export { WithdrawalCompanyFeesPage };
