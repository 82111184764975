import type { DetectorOptions } from 'i18next-browser-languagedetector';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

import { JwtDetector } from './jwt.detector';

class LanguageDetector {
  public static options: DetectorOptions = {
    order: [
      'jwt-detector',
      'localStorage',
      'navigator',
      'htmlTag',
      'querystring',
      'cookie',
      'sessionStorage',
    ],
  };

  public static create = () => {
    const detector = new I18nextBrowserLanguageDetector();

    detector.addDetector(new JwtDetector());

    return detector;
  };
}

export { LanguageDetector };
