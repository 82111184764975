import { createEvent, createStore } from 'effector';

import { listen, modelFactory, setState } from '@kuna-pay/utils/effector';
import { atom } from '@kuna-pay/utils/misc';
import { createSingleModal } from '@kuna-pay/ui/ui/modal';

import type { CookiePreference } from '../cookie-consent.types';
import { CookieConsentPersist } from './cookie-consent.persist';

type CookieConsentConfig = {
  name?: string;

  persist: string;
};

const CookieConsentModelFactory = modelFactory(
  (config: CookieConsentConfig) => {
    const name = config.name ?? '$$cookie-consent';

    const init = createEvent(`${name}.init`);
    const saved = createEvent<Record<CookiePreference, boolean>>(
      `${name}.accepted`
    );

    const $$banner = atom(() => {
      const hide = createEvent(`${name}.$$banner.hide`);

      const $visible = createStore(false, {
        name: `${name}.$$banner.$visible`,
      });

      const allAcceptClicked = createEvent(
        `${name}.$$banner.all-accept-clicked`
      );
      const manageOrRejectClicked = createEvent(
        `${name}.$$banner.manage-or-reject-clicked`
      );

      listen({
        name: `${name}.$$banner.onHide`,
        clock: hide,
        handler: () => {
          setState($visible, false);
        },
      });

      return { allAcceptClicked, manageOrRejectClicked, hide, $visible };
    });

    const $$cookiePreferences = atom(() => {
      const preferenceChecked = createEvent<CookiePreference>(
        `${name}.$$cookie-preferences.preference-checked`
      );

      const saveCookiePreferencesClicked = createEvent(
        `${name}.$$cookie-preferences.save-cookie-preferences-clicked`
      );

      const rejectAllClicked = createEvent(
        `${name}.$$cookie-preferences.reject-all-clicked`
      );

      const $$modal = createSingleModal(false, {
        name: `${name}.$$cookie-preferences.$$modal`,
      });

      const $preferences = createStore<Record<CookiePreference, boolean>>(
        // All true by default
        // so then user opens modal and click save, out of frustration,
        // is would be same as accepting all
        {
          functional: true,
          analytics: true,
          marketing: true,
        },
        {
          name: `${name}.$$cookie-preferences.$preferences`,
        }
      ).on(preferenceChecked, (state, preference) => ({
        ...state,
        [preference]: !state[preference],
      }));

      return {
        preferenceChecked,
        saveCookiePreferencesClicked,
        rejectAllClicked,
        $preferences,
        $$modal,
      };
    });

    listen({
      name: `${name}.onInit`,
      clock: init,
      handler: () => {
        const storage = new CookieConsentPersist(config.persist);

        const payload = storage.load();

        // Failed to load or empty
        if (payload === null) {
          setState($$banner.$visible, true);

          return;
        }

        saved(payload.preferences);
      },
    });

    listen({
      name: `${name}.onAllAcceptClicked`,
      clock: $$banner.allAcceptClicked,
      handler: () => {
        const state: Record<CookiePreference, boolean> = {
          analytics: true,
          functional: true,
          marketing: true,
        };

        setState($$cookiePreferences.$preferences, state);
        saved(state);
        new CookieConsentPersist(config.persist).save(state);

        $$cookiePreferences.$$modal.close();
        $$banner.hide();
      },
    });

    listen({
      name: `${name}.onManageOrRejectClicked`,
      clock: $$banner.manageOrRejectClicked,
      handler: () => {
        $$cookiePreferences.$$modal.open();
      },
    });

    listen({
      name: `${name}.onSaveCookiePreferencesClicked`,
      clock: $$cookiePreferences.saveCookiePreferencesClicked,
      source: $$cookiePreferences.$preferences,
      handler: (_, preferences) => {
        saved(preferences);
        new CookieConsentPersist(config.persist).save(preferences);

        $$cookiePreferences.$$modal.close();
        $$banner.hide();
      },
    });

    listen({
      name: `${name}.onRejectAllClicked`,
      clock: $$cookiePreferences.rejectAllClicked,
      handler: () => {
        const state: Record<CookiePreference, boolean> = {
          analytics: false,
          functional: false,
          marketing: false,
        };

        setState($$cookiePreferences.$preferences, state);
        saved(state);
        new CookieConsentPersist(config.persist).save(state);

        $$cookiePreferences.$$modal.close();
        $$banner.hide();
      },
    });

    return {
      init,
      done: saved,

      $$ui: {
        $$banner,
        $$cookiePreferences,
      },
    };
  }
);

export { CookieConsentModelFactory };
