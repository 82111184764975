import { combine } from 'effector';

import { createFeatureFlagFactory } from '@kuna-pay/core/shared/feature-flags';

import type { AvailableFeatureFlags } from '../feature-flags.types';
import { listToKV } from '../lib';
import { $$staticFeatures } from './static.model';

const createStaticFeatureFlag = createFeatureFlagFactory<AvailableFeatureFlags>(
  combine($$staticFeatures.$data, (features) => listToKV(features))
);

type StaticFeatureFlag = ReturnType<typeof createStaticFeatureFlag>;

export { createStaticFeatureFlag };
export type { StaticFeatureFlag };
