import { combine, type Store } from 'effector';

import { modelFactory } from '@kuna-pay/utils/effector';
import type { IAtomicRRRoute } from '@kuna-pay/ui/router';

const MFAActivatedRRPageModel = modelFactory(
  (config: {
    $isConfirmedStatus: Store<boolean>;

    $scope: Store<string | null>;

    routes: {
      home: IAtomicRRRoute;

      migration?: IAtomicRRRoute;

      email: {
        confirm: IAtomicRRRoute;
      };
    };
  }) => {
    const $scope = combine(config.$scope, (scope) => scope ?? 'default');

    const $continueRoute = combine(
      $scope,
      config.$isConfirmedStatus,
      (scope, isConfirmedStatus): IAtomicRRRoute => {
        if (scope === 'migration' && config.routes.migration) {
          return config.routes.migration;
        }

        //TODO(Router): Check if this still needed
        if (isConfirmedStatus) {
          return config.routes.home;
        }

        return config.routes.email.confirm;
      }
    );

    return {
      $$ui: {
        $isConfirmedStatus: config.$isConfirmedStatus,

        $continueRoute,

        routes: config.routes,
      },
    };
  }
);

export { MFAActivatedRRPageModel };
