import type { FC, PropsWithChildren } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';
import { useTranslation } from 'react-i18next';

const I18nContext = createContext('');

const I18nProvider: FC<PropsWithChildren & { i18nPrefix: string }> = ({
  i18nPrefix,
  children,
}) => (
  <I18nContext.Provider value={i18nPrefix}>{children}</I18nContext.Provider>
);

const useI18nPrefix = () => useContext(I18nContext);

const useI18n = () => {
  const i18nPrefix = useI18nPrefix();
  const { t } = useTranslation();

  return (key: string) => t(i18nPrefix ? `${i18nPrefix}.${key}` : key);
};

export { I18nProvider, useI18n, useI18nPrefix };
