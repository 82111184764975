import type { WidgetConfig } from '@livechat/widget-react';
import { LiveChatWidget } from '@livechat/widget-react';
import { useUnit } from 'effector-react';
import type { FC, PropsWithChildren } from 'react';

import { environment } from '@kuna-pay/merchant/shared/config';

import { $$liveChat } from './live-chat.model';

const LiveChatProvider: FC<PropsWithChildren> = ({ children }) => {
  const { onVisibilityChange, ...props } = useUnit($$liveChat);

  return (
    <>
      {children}

      <LiveChatWidget
        {...(props as WidgetConfig)}
        onVisibilityChanged={onVisibilityChange}
        license={environment.liveChat.licence}
      />
    </>
  );
};

export { LiveChatProvider };
