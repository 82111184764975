import type { Store } from 'effector';
import { combine } from 'effector';

import type { Feature } from '@kuna-pay/core/generated/public/graphql';

function listToKV(list: Feature[]) {
  return Object.fromEntries(list.map(({ name, value }) => [name, value]));
}

function mapListToKV($list: Store<Feature[]>) {
  return combine($list, listToKV);
}

export { listToKV, mapListToKV };
