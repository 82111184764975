import type { Effect } from 'effector';
import { attach, createEvent, sample } from 'effector';
import { not } from 'patronum';

import { createTimer, modelFactory } from '@kuna-pay/utils/effector';

type TimeoutedResendConfig = {
  resendFx: Effect<any, any>;
  timeoutInSeconds: number;
  persist?: { name: string };
};

const TimeoutedResendModel = modelFactory((config: TimeoutedResendConfig) => {
  const resendFx = attach({ effect: config.resendFx });

  const resendClicked = createEvent();
  const $$timer = createTimer(config.timeoutInSeconds, config);

  sample({
    clock: resendClicked,
    filter: not($$timer.$pending),
    target: [resendFx, $$timer.start],
  });

  return {
    startTimer: $$timer.start,

    $$ui: {
      $time: $$timer.$count,
      $pending: $$timer.$pending,

      resendClicked,
    },
    __: {
      resendFx,
    },
  };
});

export { TimeoutedResendModel };
