import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import clsx from 'clsx';
import type { LabelHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import type { PartialField } from '@kuna-pay/utils/typescript';
import { createCompoundComponent } from '@kuna-pay/utils/ui';

import { UnstyledButton } from '../button';
import type { TypographyProps } from '../typography';
import { Typography } from '../typography';
import styles from './radio.module.scss';

const Radio = createCompoundComponent(({ Root }) => Root, {
  Root: forwardRef<HTMLDivElement, RadixRadioGroup.RadioGroupProps>(
    ({ className, ...props }, ref) => (
      <RadixRadioGroup.Root
        ref={ref}
        className={clsx(styles.root, className)}
        {...props}
      />
    )
  ),

  Control: forwardRef<
    HTMLButtonElement,
    Omit<RadixRadioGroup.RadioGroupItemProps, 'children'> & {
      indicatorProps?: RadixRadioGroup.RadioGroupIndicatorProps;
    }
  >(({ className, indicatorProps, ...props }, ref) => (
    <RadixRadioGroup.Item
      ref={ref}
      className={clsx(styles.item, className)}
      {...props}
      asChild
    >
      <UnstyledButton>
        <RadixRadioGroup.Indicator
          {...(indicatorProps ?? {})}
          className={clsx(styles.indicator, indicatorProps?.className)}
        />
      </UnstyledButton>
    </RadixRadioGroup.Item>
  )),

  Indicator: forwardRef<
    HTMLDivElement,
    RadixRadioGroup.RadioGroupIndicatorProps
  >(({ className, ...props }, ref) => (
    <RadixRadioGroup.Indicator
      ref={ref}
      className={clsx(styles.indicator, className)}
      {...props}
    />
  )),

  Row: forwardRef<HTMLLabelElement, LabelHTMLAttributes<HTMLLabelElement>>(
    ({ className, ...props }, ref) => (
      <label
        ref={ref}
        className={clsx(styles.listItem, className)}
        {...props}
      />
    )
  ),

  ItemText: forwardRef<
    HTMLSpanElement,
    PartialField<TypographyProps, 'variant'>
  >((props, ref) => <Typography ref={ref} variant='numberAdmin2' {...props} />),

  lineHeight: parseInt(Typography.variants.numberAdmin2.lineHeight),
});

export { Radio };
