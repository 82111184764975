import type { ApiErrorParseResultCustomException } from './api.types';

class InternalServerError extends Error {
  public static match = ({ code }: ApiErrorParseResultCustomException) =>
    code.includes('INTERNAL_SERVER_ERROR');

  public name = 'InternalServerError';
}
class RBACError extends Error {
  public static match = ({ message }: ApiErrorParseResultCustomException) =>
    message.includes('Protected by rule');

  public name = 'RBACError';
}

class UnknownApiError extends Error {
  public name = 'UnknownApiError';
}
class UnparsedApiError extends Error {
  public name = 'UnparsedApiError';
}

/**
 * We need to have separate errors for each type of error
 * that we want to track.
 *
 * If we don't do this, Sentry will merge(by error name) both errors into one big error:
 * captureError(new Error('INTERNAL_SERVER_ERROR: Something went wrong')) -> Error: INTERNAL_SERVER_ERROR: Something went wrong
 * captureError(new Error('Protected by rule')) -> Error: Protected by rule
 *
 * If we do this, Sentry will have two separate errors:
 * captureError(new InternalServerError('Something went wrong')) -> InternalServerError: Something went wrong
 * captureError(new RBACError('Protected by rule')) -> RBACError: Protected by rule
 * captureError(new CustomError('FooError','Something went wrong')) -> FooError: Something went wrong
 */
class CustomError extends Error {
  public constructor(name: string, message: string) {
    super(message);
    this.name = name;
  }
}

export {
  CustomError,
  InternalServerError,
  RBACError,
  UnknownApiError,
  UnparsedApiError,
};
