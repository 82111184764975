import { useStoreMap } from 'effector-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ExchangeCourse24Icon } from '@kuna-pay/ui/icons';
import { Link } from '@kuna-pay/ui/router';
import { Tabs } from '@kuna-pay/ui/ui/tabs';
import { JWTCompanyRole } from '@kuna-pay/core/entities/session';

import { $$session, CanAccess } from '@kuna-pay/merchant/entities/session';
import { routes } from '@kuna-pay/merchant/shared/router';
import { NavButton } from '@kuna-pay/merchant/shared/ui/nav-button';

import { SettingsLayoutTabs } from '../page.config';

const ExchangeCourseSettingsNavItem = {
  useNavLink: () => {
    // const isActive = useMatch(routes.settings.exchangeRate);

    const to = routes.settings.exchangeRate.clone();

    // if (!isActive) {
    //   to.searchParams.set('from', 'Settings');
    // }

    return to;
  },

  useCanAccess: () => {
    const isRBACAllowed = CanAccess.Company.Role.useCanAccess([
      JWTCompanyRole.Owner,
      JWTCompanyRole.Admin,
    ]);

    const isVolatilityCoverageDisabled = useStoreMap(
      $$session.$$merchantQuery.$data,
      (data) => !data?.isVolatilityCoverageEnabled
    );

    return isVolatilityCoverageDisabled && isRBACAllowed;
  },

  Tab: memo(() => {
    const { t } = useTranslation();
    const to = ExchangeCourseSettingsNavItem.useNavLink();

    const isAllowedToView = ExchangeCourseSettingsNavItem.useCanAccess();

    if (!isAllowedToView) {
      return null;
    }

    return (
      <Tabs.Trigger value={SettingsLayoutTabs.ExchangeRate} asChild>
        <Link.ForwardRef to={to} variant='text'>
          {t('pages.settings.host.tabs.exchange-rate')}
        </Link.ForwardRef>
      </Tabs.Trigger>
    );
  }),
  NavButton: memo(() => {
    const { t } = useTranslation();

    const to = ExchangeCourseSettingsNavItem.useNavLink();

    const isAllowedToView = ExchangeCourseSettingsNavItem.useCanAccess();

    if (!isAllowedToView) {
      return null;
    }

    return (
      <NavButton to={to} icon={<ExchangeCourse24Icon />}>
        {t('pages.settings.host.tabs.exchange-rate')}
      </NavButton>
    );
  }),
};

export { ExchangeCourseSettingsNavItem };
