import { memo } from 'react';

import type { JWTCompanyRole } from '@kuna-pay/core/entities/session';

import { $$roleGuard } from '../../model';
import type { BaseCanAccessProps } from './base.can-access.component';
import { BaseCanAccess } from './base.can-access.component';

type CompanyRoleHasAccessProps = BaseCanAccessProps & {
  role: JWTCompanyRole | JWTCompanyRole[];
};

const CompanyRoleHasAccess = Object.assign(
  memo(({ role, ...props }: CompanyRoleHasAccessProps) => {
    const canAccess = $$roleGuard.hooks.useCanAccess(role);

    return <BaseCanAccess can={canAccess} {...props} />;
  }),
  {
    useCanAccess: $$roleGuard.hooks.useCanAccess,
  }
);

export { CompanyRoleHasAccess };
