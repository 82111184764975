import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import SettingsRootPage from './page.component';
import { AccountSettingsPage } from './pages/account';
import { ApiKeySettingsPage } from './pages/api-key';
import { ExchangeCourseSettingsPage } from './pages/exchange-course';
import { PaymentCustomizationSettingsPage } from './pages/payment-customization';

const SettingsPages: RouteObject[] = [
  {
    path: '/settings/*',
    Component: SettingsRootPage,
    children: [
      {
        path: 'account',
        Component: AccountSettingsPage.Component,
      },
      {
        path: 'api-key',
        Component: ApiKeySettingsPage.Component,
      },
      {
        // legacy path
        path: 'auto-conversion',
        Component: () => (
          <Navigate to='/settings/payments-customization' replace />
        ),
      },
      {
        path: 'payments-customization',
        Component: PaymentCustomizationSettingsPage.Component,
      },
      {
        path: 'exchange-rate',
        Component: ExchangeCourseSettingsPage.Component,
      },
      {
        path: '*',
        element: <Navigate to='account' replace />,
      },
    ],
  },
];

export { SettingsPages };
