import {
  isIncludeLowercase,
  isIncludeSpecSymbols,
  isIncludeUppercase,
  isLongerThan,
} from '@kuna-pay/utils/validation';

class PasswordRequirementsConfig {
  public static requirements = [
    {
      text: 'entities.session.model.password-requirements.casing',
      test: [isIncludeUppercase, isIncludeLowercase],
    },
    {
      text: 'entities.session.model.password-requirements.spec-symbols',
      test: [isIncludeSpecSymbols],
    },
    {
      text: 'entities.session.model.password-requirements.length',
      test: [isLongerThan(8, { includes: true })],
    },
  ];
}

export { PasswordRequirementsConfig };
