import {
  AuthTokensOutput,
  QueryRefreshTokenArgs,
} from '@kuna-pay/merchant/generated/graphql';
import { subscription, query } from '@kuna-pay/merchant/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const refreshTokenRequest = (
  select: Select<AuthTokensOutput> | string
) => `query refreshToken($refreshToken: String!) {
  data: refreshToken(refreshToken: $refreshToken) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'AuthTokensOutput')}`;
export const refreshToken =
  (select: Select<AuthTokensOutput> | string) =>
  (args: QueryRefreshTokenArgs) =>
    query<AuthTokensOutput>(refreshTokenRequest(select), args);
