import { attach } from 'effector';
import { createGate } from 'effector-react';
import type { NavigateFunction, NavigateOptions } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { invariant } from '@kuna-pay/utils/invariant';
import { atom } from '@kuna-pay/utils/misc';
import { AtomicRRRoute } from '@kuna-pay/ui/router';
import { useTypedGate } from '@kuna-pay/core/shared/router';

const $$router = atom(() => {
  const name = '$$router';

  const Gate = createGate<{ navigate: NavigateFunction }>(`${name}.AppGate`);

  const redirectFx = attach({
    source: Gate.state,
    effect: (
      { navigate },
      {
        to,
        options,
      }: { to: string | URL | AtomicRRRoute; options?: NavigateOptions }
    ) => {
      invariant.log(navigate, 'redirect called before $$router.Gate mounted');

      let path: string;

      if (to instanceof AtomicRRRoute) {
        path = to.toNavigate();
      } else if (typeof to === 'string') {
        path = to;
      } else {
        path = to.pathname + to.search + to.hash;
      }

      navigate(path, options);
    },
  });

  return {
    redirect: (to: string | URL | AtomicRRRoute, options?: NavigateOptions) => {
      redirectFx({ to, options });
    },

    useSetup: () => {
      useTypedGate({ Gate, props: { navigate: useNavigate() } });
    },
  };
});

export { $$router };
