import { captureException, withScope } from '@sentry/react';

import type { APIException } from '../lib';
import { ErrorMatcher } from '../lib';

class NoActiveShardsError extends Error {
  public static code = 'NO_ACTIVE_SHARDS' as const;

  public static match(
    error: unknown
  ): error is NoActiveShardsAPICompatibleException {
    return ErrorMatcher.createErrorMatcher(NoActiveShardsError.code)(error);
  }

  public static from(error: NoActiveShardsAPICompatibleException) {
    const message = ErrorMatcher.getApiErrorMessage(error);

    const [_prefix, service] = message
      .split('No active shards for routing:')
      .map((str) => str.trim());

    return new NoActiveShardsError(error, message, service);
  }

  private constructor(
    public cause: NoActiveShardsAPICompatibleException,
    public message: string,
    public service: string
  ) {
    super(message);
  }

  public logToSentry() {
    withScope((scope) => {
      scope.setExtra('service', this.service);
      captureException(this);
    });
  }

  public get messageForNotification() {
    return `It seems like the service ${this.service} service is currently unavailable. Please try again later. If the issue persists, please contact DevOps team for further assistance.`;
  }
}

type NoActiveShardsAPICompatibleException = APIException<
  typeof NoActiveShardsError.code
>;

export { NoActiveShardsError };
