import { useUnit } from 'effector-react/effector-react.mjs';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { JWTCompanyRole } from '@kuna-pay/core/entities/session';

import {
  CanAccess,
  VerificationGuard,
} from '@kuna-pay/merchant/entities/session';
import { InvoiceTutorialVideo } from '@kuna-pay/merchant/entities/tutorial/ui';
import { DiscoveryNotificationPaymentSettingsBanner } from '@kuna-pay/merchant/pages/invoices/ui';
import { $$tutorialsFeatureFlag } from '@kuna-pay/merchant/shared/feature-flags/features/dynamic';
import { MerchantLayout } from '@kuna-pay/merchant/widgets/layout/ui';

import restrictedImg from './assets/restricted.png';

const InvoicesRootPage = memo(() => {
  const { t } = useTranslation();
  const tutorialsFeatureFlag = useUnit($$tutorialsFeatureFlag);

  return (
    <VerificationGuard
      from='Invoices'
      Layout={MerchantLayout.Content}
      skipVerifyButton={tutorialsFeatureFlag.enabled}
      pending={
        tutorialsFeatureFlag.enabled && (
          <VerificationGuard.PendingContainer>
            <InvoiceTutorialVideo
              className={VerificationGuard.videoClassName}
            />

            <VerificationGuard.PendingTitle variant='h9' />

            <VerificationGuard.Description>
              {t('pages.invoices.root.unverified.description-2')}
            </VerificationGuard.Description>
          </VerificationGuard.PendingContainer>
        )
      }
      unverified={
        tutorialsFeatureFlag.enabled ? (
          <>
            <InvoiceTutorialVideo
              className={VerificationGuard.videoClassName}
            />

            <VerificationGuard.VideoTitle>
              {t('pages.invoices.root.unverified.title')}
            </VerificationGuard.VideoTitle>

            <VerificationGuard.Description>
              {t('pages.invoices.root.unverified.description')}
            </VerificationGuard.Description>

            <VerificationGuard.VerifyButton from='Invoices' />
          </>
        ) : (
          <>
            <VerificationGuard.Img
              src={restrictedImg}
              width={343}
              height={180}
            />

            <VerificationGuard.Title>
              {t('pages.invoices.list.unverified.title')}
            </VerificationGuard.Title>

            <VerificationGuard.Description>
              {t('pages.invoices.list.unverified.description')}
            </VerificationGuard.Description>
          </>
        )
      }
    >
      <Outlet />

      <CanAccess.Company.Role
        role={[JWTCompanyRole.Admin, JWTCompanyRole.Owner]}
      >
        <DiscoveryNotificationPaymentSettingsBanner />
      </CanAccess.Company.Role>
    </VerificationGuard>
  );
});

export default InvoicesRootPage;
