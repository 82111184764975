import type { Select } from '@kuna/graphql-util/dist/select';

import { TakeFirstUseCase } from '@kuna-pay/core/shared/api/lib';

import type { AuthTokensOutput } from '@kuna-pay/merchant/generated/graphql';
import { refreshToken } from '@kuna-pay/merchant/shared/api/generated/Auth/request/refreshToken';

class RefreshTokensUseCase extends TakeFirstUseCase<string, AuthTokensOutput> {
  private static readonly SELECT: Select<AuthTokensOutput> = {
    accessToken: true,
    refreshToken: true,
  };

  public constructor() {
    super({
      request: (token) =>
        refreshToken(RefreshTokensUseCase.SELECT)({
          refreshToken: token,

          //@ts-expect-error Untyped parameter required for the request to be sent skipping auth
          __noAuth: true,
        }),
    });
  }
}

export { RefreshTokensUseCase };
