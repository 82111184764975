import { SocketсlusterSubscriptionTransport } from '@kuna/graphql-client';
import type AuthEngine from 'socketcluster-client/lib/auth';

class KunaPaySocketSubscriptionTransport extends SocketсlusterSubscriptionTransport {
  private static readonly ACCESS_TOKEN_NAME = 'accessToken';

  private static readonly WSS_PORT = 443;

  private static readonly WS_PORT = 80;

  /**
   * Should be http/https url
   */
  private static isSecureUrl(url: URL): boolean {
    return url.protocol.includes('https');
  }

  /**
   * Returns port for ws connection
   *
   * @note Method exist because SocketсlusterSubscriptionTransport lies about default port
   * and uses, active serving port (eg. 3000 if you run dev server on locahost:3000) (????)
   *
   */
  private static getPort(url: URL): number {
    if (import.meta.env.DEV) {
      if (url.port) {
        return Number(url.port);
      }
    }

    return KunaPaySocketSubscriptionTransport.isSecureUrl(url)
      ? KunaPaySocketSubscriptionTransport.WSS_PORT
      : KunaPaySocketSubscriptionTransport.WS_PORT;
  }

  public constructor(config: {
    /**
     * Should be http/https url
     */
    url: string;

    /**
     * Should implement at least loadToken method
     */
    authEngine?: AuthEngine.AGAuthEngine;

    /**
     *
     */
    persist?: {
      /**
       * String key for localStorage
       */
      accessToken?: string;
    };
  }) {
    const url = new URL(config.url);

    const authTokenName =
      config.persist?.accessToken ??
      KunaPaySocketSubscriptionTransport.ACCESS_TOKEN_NAME;

    const authEngine = config.authEngine ?? new KunaPayWsAuthEngine();

    super({
      hostname: url.hostname,
      secure: KunaPaySocketSubscriptionTransport.isSecureUrl(url),
      port: KunaPaySocketSubscriptionTransport.getPort(url),
      authTokenName: authTokenName,
      authEngine: authEngine,
      disconnectOnUnload: true,
    });
  }
}

class KunaPayWsAuthEngine implements AuthEngine.AGAuthEngine {
  public saveToken(
    _name: string,
    _token: string | any,
    _options?: { [key: string]: any }
  ): Promise<string | any> {
    throw new Error('Method not implemented.');
  }

  public async removeToken(_name: string): Promise<string | any | null> {
    throw new Error('Method not implemented.');
  }

  public async loadToken(name: string): Promise<string | any | null> {
    try {
      //TODO: Add effector bindings

      const storedToken = localStorage.getItem(name);

      if (!storedToken) {
        return null;
      }

      const storedTokenAsString = String(storedToken);

      if (!storedTokenAsString) {
        return null;
      }

      const parsedToken: string | null = JSON.parse(storedTokenAsString);

      if (!parsedToken) {
        return null;
      }

      return parsedToken;
    } catch (e) {
      console.log('Failed to load token for socket connection', e);

      return null;
    }
  }
}

export { KunaPaySocketSubscriptionTransport, KunaPayWsAuthEngine };
