import { memo } from 'react';

import type { PasswordTextFieldProps } from '@kuna-pay/ui/ui/text-field';
import { PasswordTextField } from '@kuna-pay/ui/ui/text-field';

import type { FieldModel } from '../../lib';
import { useField } from '../../lib';

type FormPasswordInputProps = Omit<
  PasswordTextFieldProps,
  'onChange' | 'onBlur' | 'error' | 'value'
> & {
  field: FieldModel<string>;

  hideValidation?: boolean;
};

const FormPasswordInput = memo(
  ({
    hideValidation,

    field,
    disabled,

    helperText,
    ...props
  }: FormPasswordInputProps) => {
    const {
      path,
      disabled: formDisabled,
      shouldShowValidation,
      onBlur,
      onChange,
      onFocus,
      value,
      error: fieldError,
    } = useField(field);
    const error =
      !hideValidation && shouldShowValidation ? fieldError : undefined;

    return (
      <PasswordTextField
        name={path}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        isError={!!error}
        /* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */
        helperText={error || helperText}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={formDisabled || disabled}
        {...props}
      />
    );
  }
);

export { FormPasswordInput };
