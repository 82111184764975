import type { Nullable } from 'vitest';

const invariant: {
  /**
   * @see https://github.com/microsoft/TypeScript/issues/34523#issuecomment-700491122
   */
  log: <T>(
    condition: Nullable<T>,
    message: string,
    logger?: typeof console.error
  ) => asserts condition is T;

  error: <T>(
    condition: Nullable<T>,
    message: string,
    Exception?: typeof InvariantError
  ) => asserts condition is T;
} = {
  log: function <T>(
    condition: Nullable<T>,
    message: string,
    logger: typeof console.error = console.error
  ): asserts condition is T {
    if (!condition) {
      logger(message);
    }
  },

  error: function <T>(
    condition: Nullable<T>,
    message: string,
    Exception: typeof InvariantError = InvariantError
  ): asserts condition is T {
    if (!condition) {
      throw new Exception(message);
    }
  },
};

class InvariantError extends Error {}

export { invariant, InvariantError };
