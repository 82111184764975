import type { Store } from 'effector';
import { useUnit } from 'effector-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { IAtomicRRRoute } from '@kuna-pay/ui/router';
import { Link } from '@kuna-pay/ui/router';
import { Button } from '@kuna-pay/ui/ui/button';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { AdaptivePaper } from '@kuna-pay/core/shared/ui/adaptive-paper';

import styles from './page.module.scss';

const EmailConfirmedPage = memo(
  ({ $continue }: { $continue: Store<IAtomicRRRoute> }) => {
    const to = useUnit($continue);
    const { t } = useTranslation();

    return (
      <AdaptivePaper className={styles.root}>
        <div className={styles.imgContainer}>
          <img
            src='/images/confirm-email.png'
            alt=''
            width={244}
            height={244}
          />
        </div>

        <Typography as='h1' className={styles.title} variant='h7' center>
          {t('pages.auth.email.confirmed.ui.title', { ns: 'core' })}
        </Typography>

        <Typography as='h2' className={styles.subtitle} variant='body3' center>
          {t('pages.auth.email.confirmed.ui.description', { ns: 'core' })}
        </Typography>

        <Button.Root
          variant='contained'
          color='primary'
          size='xl'
          fullWidth
          asChild
        >
          <Link to={to}>
            <Button.Text size='xl'>
              {t('pages.auth.email.confirmed.ui.action', { ns: 'core' })}
            </Button.Text>
          </Link>
        </Button.Root>
      </AdaptivePaper>
    );
  }
);

export { EmailConfirmedPage };
