import { useUnit } from 'effector-react';
import { type PropsWithChildren, Suspense } from 'react';

import {
  AppErrorScreen,
  AppLoadingScreen,
} from '@kuna-pay/ui/ui/splash-screen';

import { $$boot } from './boot.model';

const AppBoot = ({ children }: PropsWithChildren) => {
  const [isLoading, isError, onReload] = useUnit([
    $$boot.$$ui.$loading,
    $$boot.$$ui.$error,
    $$boot.$$ui.reloadClicked,
  ]);

  if (isLoading) {
    return <AppLoadingScreen />;
  }

  if (isError) {
    return (
      <AppErrorScreen
        onReload={onReload}
        description='We couldn’t load the page, please try again'
      />
    );
  }

  return (
    <Suspense
      /**
       * This is necessary for first load of translation
       * because it would crash if initial json is not loaded yet
       * due to "Error: A component suspended while responding to synchronous input"
       *
       * Changing language to any other language will NOT show this fallback
       * instead it will wait for translation to load in the background
       * and then update the UI
       */
      fallback={<AppLoadingScreen />}
    >
      {children}
    </Suspense>
  );
};

export { AppBoot };
