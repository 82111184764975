import { useUnit } from 'effector-react';
import type { FC, Key, ReactNode } from 'react';

import { DataGridModel } from '../model';
import type { GridColumn, InsertConfig } from '../types';
import type { TableRowProps } from './data';
import { TableBodyData } from './data';
import { DataGridError } from './error';
import { DataGridBodyLoading } from './loading';
import { DataGridNotFound } from './not-found';

type TableBodyProps<T extends Record<string, unknown>> = {
  columns: GridColumn<T>[];

  insert?: InsertConfig<T>;

  addRowProps?: (row: T) => Partial<TableRowProps>;

  gridTemplateColumns: string;

  getRowId: (row: T) => Key;

  customNotFound?: ReactNode | FC;
  classNames?: {
    row?: string;

    loadingSkeletonContainer?: string;
    loadingSkeleton?: string;
  };
  customError?: ReactNode | FC;
  loading?: ReactNode;
};

const TableBody = <T extends Record<string, unknown>>({
  insert,
  columns,
  loading,
  getRowId,
  classNames,
  addRowProps,
  customError,
  customNotFound,
  gridTemplateColumns,
}: TableBodyProps<T>) => {
  const $$model = DataGridModel.useModel<T>();

  const { isLoading, isError, isNotFound } = useUnit({
    isLoading: $$model.$initialDataLoading,
    isError: $$model.$isError,
    isNotFound: $$model.$isNotFound,
  });

  if (isLoading) {
    return (
      loading ?? (
        <DataGridBodyLoading
          classNames={classNames}
          columns={columns}
          gridTemplateColumns={gridTemplateColumns}
        />
      )
    );
  }

  if (isError) {
    return customError ? (
      renderComponentOrElement(customError)
    ) : (
      <DataGridError />
    );
  }

  if (isNotFound) {
    return customNotFound ? (
      renderComponentOrElement(customNotFound)
    ) : (
      <DataGridNotFound />
    );
  }

  return (
    <TableBodyData
      classNames={classNames}
      columns={columns}
      addRowProps={addRowProps}
      gridTemplateColumns={gridTemplateColumns}
      getRowId={getRowId}
      insert={insert}
    />
  );
};

function renderComponentOrElement(target: ReactNode | FC) {
  if (typeof target === 'function') {
    const Component = target;

    return <Component />;
  }

  return <>{target}</>;
}

export { TableBody };
