import { Suspense } from 'react';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';

import { AccountSettingsPageLoading } from './page.loading';

const Page = lazyWithRetryAndRefresh(
  'AccountSettingsPage',
  () => import('./page.component')
);

const AccountSettingsPage = {
  Component: () => (
    <Suspense fallback={<AccountSettingsPageLoading />}>
      <Page />
    </Suspense>
  ),
};

export { AccountSettingsPage };
