import { AuthHttpTransport } from '@kuna/graphql-client/dist/transport/AuthHttpTransport';

import { notify } from '@kuna-pay/ui/notification';
import { NoActiveShardsError } from '@kuna-pay/core/shared/api/errors';
import { KunaPayGraphqlClient } from '@kuna-pay/core/shared/api/http';
import { KunaPaySocketSubscriptionTransport } from '@kuna-pay/core/shared/api/ws';
import { AppStage } from '@kuna-pay/core/shared/config';

import { environment } from '@kuna-pay/merchant/shared/config';

import { $$api } from '../api.service';
import { listen } from '@kuna-pay/utils/effector';

/**
 * Singleton client for the graphql
 *
 *
 * There is no way to resolve the runtime circular dependency
 * api.service -> generated/Auth/refreshToken -> graphql/codegen.bindings -> graphql/graphql.client -> api.service
 */
class GraphqlClient {
  private static client: KunaPayGraphqlClient | null = null;

  public static get() {
    if (!this.client) {
      const wsTransport = new KunaPaySocketSubscriptionTransport({
        url: environment.backend.ws.url,
      });

      this.client = new KunaPayGraphqlClient({
        transport: new AuthHttpTransport({
          url: environment.backend.gw.url,
          authService: $$api.createAuthServiceForGQLClient(),
        }),

        subscription: wsTransport,
      });

      listen({
        clock: $$api.tokenService.onTokenUpdated,
        handler: (token) => {
          void wsTransport.authenticate(token);
        },
      });

      listen({
        clock: $$api.tokenService.onTokenCleared,
        handler: () => {
          void wsTransport.deauthenticate();
        },
      });

      if (environment.stage === AppStage.DEV) {
        this.client.onError().subscribe((error) => {
          if (NoActiveShardsError.match(error)) {
            const noActiveShardsError = NoActiveShardsError.from(error);

            notify.error(noActiveShardsError.messageForNotification);
          }
        });
      }
    }

    return this.client;
  }
}

export { GraphqlClient };
