import { modelFactory } from '@kuna-pay/utils/effector';
import type { IAtomicRRRoute } from '@kuna-pay/ui/router';

/**
 * Can't be properly typed because of
 * implications of adding generics to all DataGrid parts
 */
type RedirectConfig<Row extends Record<string, unknown>> = {
  (row: Row): IAtomicRRRoute | null;
};

type PublicRedirectConfig<Row extends Record<string, unknown>> = {
  (row: Row): IAtomicRRRoute | null;
};

const RedirectModel = modelFactory(
  <Row extends Record<string, unknown>>(
    userDefinedFn?: RedirectConfig<Row>
  ) => {
    if (!userDefinedFn) {
      return {
        $$ui: {
          can: false,
        },
      } as const;
    }

    return {
      $$ui: {
        can: true,
        urlCreator: userDefinedFn,
      },
    } as const;
  }
);

export { RedirectModel };
export type { PublicRedirectConfig };
