import type { FC, PropsWithChildren } from 'react';
import { toast, ToastContainer } from 'react-toastify';

import { useMediaQuery } from '@kuna-pay/utils/react/use-media-query';

const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const isMobileOrLess = useMediaQuery('(max-width: 580px)');

  return (
    <>
      {children}

      <ToastContainer
        position={
          isMobileOrLess
            ? toast.POSITION.TOP_RIGHT
            : toast.POSITION.BOTTOM_RIGHT
        }
        closeButton={false}
        hideProgressBar
      />
    </>
  );
};

export { NotificationProvider };
