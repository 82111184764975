import { Suspense } from 'react';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';
import { AskToSetupAuthenticator2FAPageLoading } from '@kuna-pay/core/pages/auth/mfa/ask';

const Page = lazyWithRetryAndRefresh(
  'AskToSetupAuthenticator2FA',
  () => import('./page.component')
);

const AskToSetupAuthenticator2FA = {
  Component: () => (
    <Suspense fallback={<AskToSetupAuthenticator2FAPageLoading />}>
      <Page />
    </Suspense>
  ),
};

export { AskToSetupAuthenticator2FA };
