import { memo } from 'react';

import type { TextAreaFieldProps } from '@kuna-pay/ui/ui/text-field';
import { TextAreaField } from '@kuna-pay/ui/ui/text-field';

import type { FieldModel } from '../lib';
import { useField } from '../lib';

type FormTextAreaFieldProps = Omit<
  TextAreaFieldProps,
  'onChange' | 'onBlur' | 'isError' | 'value'
> & {
  field:
    | FieldModel<string>
    | FieldModel<string | undefined>
    | FieldModel<string | undefined | null>;
  hideValidation?: boolean;
};

const FormTextAreaField = memo(
  ({
    hideValidation,

    field,
    disabled,
    onFocus,
    helperText,
    ...props
  }: FormTextAreaFieldProps) => {
    const {
      value,
      onBlur,
      path,
      onFocus: onFieldFocus,
      onChange,
      error: fieldError,
      shouldShowValidation,
      disabled: formDisabled,
    } = useField(field as FieldModel<string>);

    const error =
      !hideValidation && shouldShowValidation ? fieldError : undefined;

    return (
      <TextAreaField
        name={path}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onClear={() => onChange('')}
        onFocus={(event) => {
          onFocus?.(event);
          onFieldFocus?.();
        }}
        onBlur={onBlur}
        isError={!!error}
        /* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */
        helperText={error || helperText}
        disabled={!!disabled || !!formDisabled}
        {...props}
      />
    );
  }
);

export { FormTextAreaField };
