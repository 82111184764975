import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { ConfirmEmailPage } from './confirm';
import { EmailConfirmedPage } from './confirmed/';

const ConfirmEmailPages: RouteObject[] = [
  {
    path: '/confirm-email',
    children: [
      {
        index: true,
        Component: ConfirmEmailPage.Component,
      },
      {
        path: '/confirm-email/confirmed',
        Component: EmailConfirmedPage.Component,
      },
      {
        path: '*',
        element: <Navigate to='/confirm-email' replace />,
      },
    ],
  },
];

export { ConfirmEmailPages };
