import type {
  Batch,
  Batches,
  GraphqlClientOptions,
  IGraphqlClient,
  ISocketClusterTransport,
  ISubscriptionTransport,
  ITransport,
} from '@kuna/graphql-client';
import type { GraphqlResponse } from '@kuna/graphql-client/dist/interface';
import type { GraphqlRequest } from '@kuna/graphql-client/dist/interface';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { v4 } from 'uuid';

type ErrorWithMeta = Error | string | string[] | GQLError[];

type GQLError = {
  code: 'INTERNAL_SERVER_ERROR' | 'ARGUMENT_VALIDATION_ERROR' | string;
  fields?: Record<string, any>;
  message: string;
  path: {
    code: 'ARGUMENT_VALIDATION_ERROR' | string;
    message: string;
    product: string;
    source: string;
    stacktrace: string[];
  }[];
};

type GraphqlClientInterceptorOnResolve = <T>(data: T) => any;
type GraphqlClientInterceptorOnReject = (
  request: GraphqlRequest<any>,
  error: ErrorWithMeta
) => any;

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
interface IGraphqlClientWithMeta extends IGraphqlClient {
  onErrorWithMeta: () => Observable<{
    request: GraphqlRequest<any>;
    error: ErrorWithMeta;
  }>;
}

const defaultOptions: Partial<GraphqlClientOptions> = {
  batchInterval: 10,
  batchMax: 5,
};

/**
 * Custom class needed bcs of inability to extend GraphqlClient to override `batch` method
 * Class should also be compatible with GraphqlClient interface
 *
 * added `onErrorWithMeta` method, and change `batch` method
 *
 * Original: https://g.anuk.tech/kuna/services/servicesteam/big-package/-/blob/master/ui/graphql-client/src/GraphqlClient.ts
 * Original version at the time: 1.0.15
 */
export class KunaPayGraphqlClient implements IGraphqlClientWithMeta {
  public constructor(options: GraphqlClientOptions) {
    this.options = {
      ...defaultOptions,
      ...options,
    } as Required<GraphqlClientOptions>;

    this.transport = this.options.transport;
    this.subscriptionTransport = this.options.subscription;

    this.query = this.query.bind(this);
    this.subscription = this.subscription.bind(this);

    this.error = new Subject();
    this.errorWithMeta = new Subject();
  }

  private readonly options: Required<GraphqlClientOptions>;

  private readonly transport: ITransport;

  private readonly interceptors: {
    id: string;
    onResolve: GraphqlClientInterceptorOnResolve;
    onReject: GraphqlClientInterceptorOnReject;
  }[] = [];

  private readonly batches: Map<number, Batches> = new Map();

  private batchNumber = 0;

  private readonly error: Subject<Error | string[]>;

  private readonly errorWithMeta: Subject<{
    request: GraphqlRequest<any>;
    error: ErrorWithMeta;
  }>;

  public readonly subscriptionTransport?:
    | ISubscriptionTransport
    | ISocketClusterTransport;

  private addBatch<T, D>(batch: Batch<T, D>): Batches {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    const batches = this.batches.get(this.batchNumber) || { items: [] };

    batches.items.push(batch);
    this.batches.set(this.batchNumber, batches);

    return batches;
  }

  public use(
    onResolve: GraphqlClientInterceptorOnResolve,
    onReject: GraphqlClientInterceptorOnReject
  ) {
    const id = v4();

    this.interceptors.push({
      id,
      onResolve,
      onReject,
    });

    return id;
  }

  public removeInterceptor(id: string) {
    const index = this.interceptors.findIndex(
      (interceptor) => interceptor.id !== id
    );

    if (index !== -1) {
      this.interceptors.splice(index, 1);
    }
  }

  public onError() {
    return this.error.asObservable() as /**
     * TS2416: Property onError in type GraphqlClient is not assignable to the same property in base type IGraphqlClient
     * Type '() => import("F:/code/kuna/kuna.pay/services/front/node_modules/rxjs/internal/Observable").Observable<string[] | Error>' is not assignable to type '() => import("F:/code/kuna/kuna.pay/services/front/node_modules/@kuna/graphql-client/node_modules/rxjs/dist/types/internal/Observable").Observable<string[] | Error>'.
     * Call signature return types Observable<string[] | Error> and Observable<string[] | Error> are incompatible.
     * The types of source.operator.call are incompatible between these types.
     * Type '(subscriber: import("F:/code/kuna/kuna.pay/services/front/node_modules/rxjs/internal/Subscriber").Subscriber<any>, source: any) => import("F:/code/kuna/kuna.pay/services/front/node_modules/rxjs/internal/types").TeardownLogic' is not assignable to type '(subscriber: import("F:/code/kuna/kuna.pay/services/front/node_modules/@kuna/graphql-client/node_modules/rxjs/dist/types/internal/Subscriber").Subscriber<any>, source: any) => import("F:/code/kuna/kuna.pay/services/front/node_modules/@kuna/graphql-client/node_modules/rxjs/dist/types/internal/types").TeardownLogic'.
     * Types of parameters subscriber and subscriber are incompatible.
     * Type Subscriber<any> is missing the following properties from type Subscriber<any>:
     * syncErrorValue, syncErrorThrown, syncErrorThrowable, _unsubscribeAndRecycle
     * , and 2 more.
     */ unknown as ReturnType<IGraphqlClient['onError']>;
  }

  public onErrorWithMeta() {
    return this.errorWithMeta.asObservable();
  }

  public async query<T, D = any>(query: string, variables?: D): Promise<T> {
    return this.interceptors.reduce(
      (promise, { onReject, onResolve }) =>
        promise.then(
          (data) => onResolve(data),
          (error) => onReject({ query, variables }, error)
        ),

      new Promise<T>((resolve, reject) => {
        const batches = this.addBatch<T, D>({
          request: {
            query,
            variables,
          },
          resolve,
          reject,
        });

        if (batches.items.length >= this.options.batchMax) {
          void this.batch();
        } else {
          const timeout = window.setTimeout(() => {
            batches.timeout = timeout;
            void this.batch();
          }, this.options.batchInterval);
        }
      })
    );
  }

  public subscription<T = any, D = any>(query: string, variables?: D) {
    if (!this.subscriptionTransport) {
      throw new Error('Subscription Transport not set');
    }

    return this.subscriptionTransport.subscription<T>(query, variables);
  }

  private async batch() {
    if (this.batches.has(this.batchNumber)) {
      const batches = this.batches.get(this.batchNumber) as Batches;
      clearTimeout(batches.timeout);
      this.batches.delete(this.batchNumber);

      this.batchNumber++;

      try {
        const data = batches.items.map((item) => item.request);
        const responses = await this.transport.send<GraphqlResponse<unknown>[]>(
          data
        );

        responses.forEach((response, i) => {
          const { request, resolve, reject } = batches.items[i];

          if (response.errors) {
            reject(response.errors);
            /**
             * @note Cant break interface
             */
            this.error.next(response.errors);
            this.errorWithMeta.next({ request, error: response.errors });
          } else {
            resolve(response.data!.data);
          }
        });
      } catch (error: any) {
        batches.items.forEach((batch) => {
          batch.reject([error.message]);
          /**
           * @note Cant break interface
           */
          this.error.next([error.message]);
          this.errorWithMeta.next({
            request: batch.request,
            error: error.message,
          });
        });
      }
    }
  }
}
export type {
  ErrorWithMeta,
  GQLError,
  GraphqlClientInterceptorOnReject,
  GraphqlClientInterceptorOnResolve,
  GraphqlRequest,
  IGraphqlClientWithMeta,
};
