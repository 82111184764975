import { setExtra } from '@sentry/react';

import { BaseTrackingIntegration } from './core.integration';

class FeatureFlagsMonitoringIntegration extends BaseTrackingIntegration {
  public constructor(private readonly features: Record<string, boolean>) {
    super();
  }

  public override install = () => {
    setExtra('features', this.features);
  };
}

export { FeatureFlagsMonitoringIntegration };
