import { forwardRef, useState } from 'react';

import { HideIcon, ShowIcon } from '../../../icons';
import { IconButton } from '../../button';
import { InputAdornment, TextField } from '../text-field.component';
import type { TextFieldProps } from '../text-field.props';

type PasswordTextFieldProps = Omit<TextFieldProps, 'end'> & {
  defaultOpen?: boolean;
};

const PasswordTextField = forwardRef<HTMLInputElement, PasswordTextFieldProps>(
  ({ type: inputType = 'text', defaultOpen = false, ...props }, ref) => {
    const [isOpen, setOpen] = useState(defaultOpen);

    const Icon = isOpen ? ShowIcon : HideIcon;

    const onIconClick = () => {
      setOpen((open) => !open);
    };

    return (
      <TextField
        ref={ref}
        type={isOpen ? inputType : 'password'}
        end={
          <InputAdornment>
            <IconButton onClick={onIconClick}>
              <Icon />
            </IconButton>
          </InputAdornment>
        }
        {...props}
      />
    );
  }
);

export { PasswordTextField };
export type { PasswordTextFieldProps };
