import * as RadixTabs from '@radix-ui/react-tabs';
import clsx from 'clsx';
import { forwardRef } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';

import { UnstyledButton } from '../button';
import { Typography } from '../typography';
import { TabsVariantProvider, useTabVariant } from './tabs.context';
import type { TabsProps } from './tabs.props';
import styles from './tabs.module.scss';

const Tabs = createCompoundComponent(
  ({ Root }) =>
    forwardRef<HTMLDivElement, TabsProps>(
      ({ activationMode = 'manual', ...props }, ref) => (
        <TabsVariantProvider value={props.variant}>
          <Root ref={ref} activationMode={activationMode} {...props} />
        </TabsVariantProvider>
      )
    ),
  {
    Root: forwardRef<HTMLDivElement, Omit<TabsProps, 'variant'>>(
      ({ className, disableGrow, ...props }, ref) => (
        <RadixTabs.Root
          ref={ref}
          className={clsx(styles.root, className)}
          data-grow={!disableGrow}
          {...props}
        />
      )
    ),
    List: forwardRef<HTMLDivElement, RadixTabs.TabsListProps>(
      ({ className, ...props }, ref) => (
        <RadixTabs.List
          ref={ref}
          className={clsx(styles.list, className)}
          data-variant={useTabVariant()}
          {...props}
        />
      )
    ),
    Content: forwardRef<HTMLDivElement, RadixTabs.TabsContentProps>(
      ({ className, ...props }, ref) => (
        <RadixTabs.Content
          ref={ref}
          className={clsx(styles.content, className)}
          {...props}
        />
      )
    ),
    Trigger: forwardRef<
      HTMLButtonElement,
      RadixTabs.TabsTriggerProps & { asChild?: boolean }
    >(({ className, children, asChild, ...props }, ref) => {
      const variant = useTabVariant();

      return (
        <RadixTabs.Trigger
          ref={ref}
          className={clsx(
            styles.trigger,
            className,
            {
              linear: Typography.classes.subtitle5,
              round: Typography.classes.numbers1,
            }[variant]
          )}
          data-variant={variant}
          {...props}
          asChild
        >
          <UnstyledButton asChild={asChild}>{children}</UnstyledButton>
        </RadixTabs.Trigger>
      );
    }),
  }
);

export { Tabs };
