import clsx from 'clsx';
import { useUnit } from 'effector-react';
import {
  type HTMLAttributes,
  memo,
  type PropsWithChildren,
  useId,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { ModelOf } from '@kuna-pay/utils/effector';
import { Checkbox } from '@kuna-pay/ui/ui/checkbox';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { urls } from '@kuna-pay/core/shared/config';
import type { AdaptivePaperProps } from '@kuna-pay/core/shared/ui/adaptive-paper';
import { AdaptivePaper } from '@kuna-pay/core/shared/ui/adaptive-paper';

import type { VerifyOTPModel } from './verify-2fa.model';
import { Verify2FAForm } from './verify-2fa-form.component';
import styles from './verify-authenticator-2fa.module.scss';

type VerifyAuthenticator2FAProps = {
  $$model: ModelOf<typeof VerifyOTPModel>;
};

type VerifyOTPHintProps = HTMLAttributes<HTMLDivElement> & {
  variant?: TypographyProps['variant'];

  linkVariant?: TypographyProps['variant'];
};

const VerifyAuthenticator2FAView = memo(
  ({ $$model }: VerifyAuthenticator2FAProps) => (
    <C.Root>
      <C.Title variant='h9' />

      <C.Subtitle variant='body3' />

      <C.Form className={styles.form} $$model={$$model} />

      <C.Hint variant='body3' linkVariant='subtitle5' />
    </C.Root>
  )
);

const C = {
  Root: memo(({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div className={clsx(styles.root, className)} {...props} />
  )),

  PaperRoot: memo(({ className, ...props }: AdaptivePaperProps) => (
    <AdaptivePaper
      className={clsx(styles.container, className)}
      scrollIntoView
      {...props}
    />
  )),

  Title: memo(({ className, ...props }: TypographyProps) => {
    const { t } = useTranslation();

    return (
      <Typography
        as='h1'
        className={clsx(styles.title, className)}
        center
        {...props}
      >
        {t('features.auth.mfa.verify.authenticator.title', { ns: 'core' })}
      </Typography>
    );
  }),

  Subtitle: memo(({ className, ...props }: TypographyProps) => {
    const { t } = useTranslation();

    return (
      <Typography
        as='h2'
        className={clsx(styles.subtitle, className)}
        center
        {...props}
      >
        {t('features.auth.mfa.verify.authenticator.subtitle', {
          ns: 'core',
        })}
      </Typography>
    );
  }),

  Form: Verify2FAForm,

  Hint: memo(
    ({
      className,
      variant = 'body2',
      linkVariant = 'subtitle3',
      ...props
    }: VerifyOTPHintProps) => {
      const { t } = useTranslation('core');

      return (
        <div className={clsx(styles.mfaHintContainer, className)} {...props}>
          <Typography variant={variant} center>
            <Trans
              t={t}
              i18nKey='features.auth.mfa.verify.authenticator.hint'
              components={{
                'link-docs-troubleshouting': (
                  <LinkDocsTrobleshooting variant={linkVariant} />
                ),
              }}
            />
          </Typography>
        </div>
      );
    }
  ),

  IsTrustedDevice: memo(
    ({ $$model }: { $$model: ModelOf<typeof VerifyOTPModel> }) => {
      const id = useId();
      const [isTrustedDevice, onTrustedDeviceChange] = useUnit([
        $$model.$$ui.$trustedDevice,
        $$model.$$ui.onTrustedDeviceChange,
      ]);
      const { t } = useTranslation();

      return (
        <div className={styles.trustedDevice}>
          <Checkbox
            size='lg'
            id={id}
            checked={isTrustedDevice}
            onCheckedChange={(checked) => {
              onTrustedDeviceChange(!!checked);
            }}
          />

          <Typography as='label' htmlFor={id} variant='subtitle5'>
            {t('features.auth.mfa.verify.authenticator.trusted-device', {
              ns: 'core',
            })}
          </Typography>
        </div>
      );
    }
  ),
};

const VerifyAuthenticator2FA = Object.assign(VerifyAuthenticator2FAView, C);

const LinkDocsTrobleshooting = memo(
  ({
    variant,
    children,
  }: PropsWithChildren & Pick<TypographyProps, 'variant'>) => {
    const { i18n } = useTranslation('core');

    return (
      <a
        href={urls.docs.troubleShouting.withLocale(i18n.language)}
        target='_blank'
        rel='noreferrer'
      >
        <Typography className={styles.mfaHintLink} variant={variant}>
          {children}
        </Typography>
      </a>
    );
  }
);

export { VerifyAuthenticator2FA };
