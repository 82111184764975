import { useUnit } from 'effector-react';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { unstable_useBlocker } from 'react-router-dom';
import { v4 } from 'uuid';

import { listen } from '@kuna-pay/utils/effector';
import {
  createExternalDepsRef,
  useLocalModel,
  useSyncExternalDeps,
} from '@kuna-pay/utils/react/use-local-model';
import { createPrompt, MODAL_ID_KEY, Prompt } from '@kuna-pay/ui/ui/modal';
import { FormContext } from '@kuna-pay/form/context/context';

import alertPng from './alert.png';
import styles from './prompt-on-leave.module.scss';

const FormPromptOnLeave = memo(() => {
  const $$form = useContext(FormContext);
  const [isDirty, isDisabled] = useUnit([$$form.$dirty, $$form.$disabled]);
  const { t } = useTranslation('core');

  /**
   * @see https://reactrouter.com/en/6.19.0/hooks/use-blocker
   */
  const blocker = unstable_useBlocker(
    /**
     * We should block navigation when form is dirty
     *
     * But not when form is disabled, because it means that form is in the process of submitting or user can't affect values anyway
     */

    isDirty && !isDisabled
  );

  const $$model = useLocalModel(() => {
    const ID = v4();
    const $$ref = createExternalDepsRef({ blocker });
    const $$prompt = createPrompt();

    listen({
      clock: $$ref.$current.updates,
      handler: ({ blocker }) => {
        if (blocker.state === 'blocked') {
          $$prompt.open({ [MODAL_ID_KEY]: ID });
        }
      },
    });

    listen({
      clock: $$prompt.confirmed,
      source: $$ref.$current,
      handler: (_, { blocker }) => {
        blocker.proceed?.();
      },
    });

    listen({
      clock: $$prompt.canceled,
      source: $$ref.$current,
      handler: (_, { blocker }) => {
        blocker.reset?.();
      },
    });

    return { ID, $$prompt, $$ref };
  });

  useSyncExternalDeps($$model.$$ref, { blocker });

  return (
    <Prompt
      id={$$model.ID}
      prepend={
        <div className={styles.imgContainer}>
          <img className={styles.img} src={alertPng} alt='' />
        </div>
      }
      title={t('shared.forms.prompt.title')}
      description={t('shared.forms.prompt.description')}
      confirmText={t('shared.forms.prompt.confirm')}
      cancelText={t('shared.forms.prompt.cancel')}
      model={$$model.$$prompt}
    >
      <div />
    </Prompt>
  );
});

export { FormPromptOnLeave };
