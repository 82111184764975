import {
  SuccessOutput,
  MutationDisableSessionArgs,
} from '@kuna-pay/merchant/generated/graphql';
import { subscription, query } from '@kuna-pay/merchant/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const disableSessionRequest = (
  select: Select<SuccessOutput> | string
) => `mutation disableSession($fingerprint: String!) {
  data: disableSession(fingerprint: $fingerprint) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'SuccessOutput')}`;
export const disableSession =
  (select: Select<SuccessOutput> | string) =>
  (args: MutationDisableSessionArgs) =>
    query<SuccessOutput>(disableSessionRequest(select), args);
