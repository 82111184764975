import { CompanyRoleHasAccess } from './company.role.can-access';
import { CompanyTypeHasAccess } from './company.type.can-access';

const CanAccess = {
  Company: {
    Role: CompanyRoleHasAccess,

    Type: CompanyTypeHasAccess,
  },
};

export { CanAccess };
