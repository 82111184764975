import { Suspense } from 'react';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';

import { MFAActivatedPageLoading } from './page.loading';

const Page = lazyWithRetryAndRefresh(
  'MFAActivatedPage',
  () => import('./page.component')
);

const MFAActivatedPage = {
  Component: () => (
    <Suspense fallback={<MFAActivatedPageLoading />}>
      <Page />
    </Suspense>
  ),
};

export { MFAActivatedPage };
