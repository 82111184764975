import { z } from 'zod';

import {
  CompanyType,
  IdentificationStatus,
  StatusOfCompany,
  StatusOfUser,
} from '@kuna-pay/core/generated/public/graphql';

import { JWTCompanyRole } from './jwt.types';

const UserJWTSchema = z.object({
  id: z.string(),
  email: z.string(),
  language: z.string(),
  username: z.string(),
  fingerprint: z.string(),
  jwtId: z.string(),
  s: z.boolean(),
  o: z.boolean(), // isOtpEnabled
  merchantId: z.string(),
  t: z.string(),
  status: z.nativeEnum(StatusOfUser),
  identStatus: z.string(),
  role: z.string(),
});

const CompanyJWTSchema = z.object({
  id: z.string(),
  role: z.nativeEnum(JWTCompanyRole),
  type: z.nativeEnum(CompanyType),

  groups: z.array(
    z.object({
      id: z.string(),
      type: z.string(),
    })
  ),
  status: z.nativeEnum(StatusOfCompany),
  identStatus: z.nativeEnum(IdentificationStatus),
  isLimitsExceeded: z.coerce.boolean().optional(),
});

const UserWithCompanyJWTSchema = UserJWTSchema.extend({
  company: CompanyJWTSchema.optional(),
});

export { CompanyJWTSchema, UserJWTSchema, UserWithCompanyJWTSchema };
