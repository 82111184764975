import { SuccessOutput } from '@kuna-pay/merchant/generated/graphql';
import { subscription, query } from '@kuna-pay/merchant/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const markAllNotificationAsReadRequest = (
  select: Select<SuccessOutput> | string
) => `query markAllNotificationAsRead {
  data: markAllNotificationAsRead {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'SuccessOutput')}`;
export const markAllNotificationAsRead =
  (select: Select<SuccessOutput> | string) => () =>
    query<SuccessOutput>(markAllNotificationAsReadRequest(select));
