import { useUnit } from 'effector-react';
import { useEffect } from 'react';

import { modelView } from '@kuna-pay/utils/effector';

import { RecaptchaModel } from './recaptcha.model';

const RecaptchaBadgeGate = modelView(RecaptchaModel, () => {
  const $$model = RecaptchaModel.useModel();
  const [show, hide] = useUnit([$$model.showBadgeFx, $$model.hideBadgeFx]);

  useEffect(() => {
    show();

    return () => {
      hide();
    };
  }, []);

  return null;
});

export { RecaptchaBadgeGate };
