import { memo } from 'react';

import { ChangeLanguageView } from './change-language.component';
import styles from './change-language.mobile.module.scss';

const MobileNavigationChangeLanguage = memo(() => (
  <div className={styles.navigationItemLanguageChanger}>
    <ChangeLanguageView align='start' variant='subtitle5' />
  </div>
));

export { MobileNavigationChangeLanguage };
