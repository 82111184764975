import { createStaticFeatureFlag } from '../model/static.factory';

const $$isESLangEnabledFeatureFlag = createStaticFeatureFlag(
  'KUPAY_1936_FE_MERCHANT_ES_LANGUAGE_ENABLED'
);

const $$crowdinInContextFeatureFlag = createStaticFeatureFlag(
  'FE_MERCHANT_CROWDIN_IN_CONTEXT'
);

const $$kunaProAuthFeatureFlag = createStaticFeatureFlag(
  'KUPAY_1479_IS_KUNA_PRO_AUTH_ENABLED'
);

const $$recaptchaFeatureFlag = createStaticFeatureFlag('RECAPTCHA_ENABLED');

const $$optional2FAFeatureFlag = createStaticFeatureFlag(
  'KUPAY_2698_STORY_OPTIONAL_2FA'
);

export {
  $$crowdinInContextFeatureFlag,
  $$isESLangEnabledFeatureFlag,
  $$kunaProAuthFeatureFlag,
  $$optional2FAFeatureFlag,
  $$recaptchaFeatureFlag,
};
