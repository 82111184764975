import type { MouseEvent } from 'react';

/**
 * (!!!) Should use `onMouseUp` event to open link in new tab and not focus on it.
 */
function shouldOpenInNewTabOnMouseUp<T>(event: MouseEvent<T>) {
  // is middle mouse button
  return event.button === 1;
}

function shouldOpenInNewTabOnClick<T>(event: MouseEvent<T>) {
  return event.ctrlKey || event.shiftKey || event.altKey || event.metaKey;
}

export { shouldOpenInNewTabOnClick, shouldOpenInNewTabOnMouseUp };
