import { Suspense } from 'react';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';

import { TurnOn2FAPageLoading } from './page.loading';

const Page = lazyWithRetryAndRefresh(
  'TurnOn2FAPage',
  () => import('./page.component')
);

const TurnOn2FAPage = {
  Component: () => (
    <Suspense fallback={<TurnOn2FAPageLoading />}>
      <Page />
    </Suspense>
  ),
};

export { TurnOn2FAPage };
