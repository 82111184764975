import type { Store } from 'effector';
import { combine } from 'effector';
import { reshape } from 'patronum';

import { IdentificationStatus } from '@kuna-pay/core/generated/public/graphql';

import { UserWithCompanyJWTSchema } from '../jwt.schema';
import { JWTService } from '../jwt.service';
import { UserJWTModelFactory } from './user-jwt.model';

type CompanyJWTModelFactoryConfig = {
  $jwt: Store<string | null>;
};

class CompanyJWTModelFactory {
  public static create(config: CompanyJWTModelFactoryConfig) {
    const $accessTokenJwt = combine(config.$jwt, (token) =>
      new JWTService(UserWithCompanyJWTSchema).parseAndDecodeJWT(token)
    );

    const { $$user } = UserJWTModelFactory.create({ $jwt: config.$jwt });

    return {
      $accessTokenJwt,

      $$user,

      $$company: reshape({
        source: $accessTokenJwt,

        shape: {
          $isCompanyLogged: (state) => !!state?.company,

          $id: (state) => state?.company?.id ?? null,

          $role: (state) => state?.company?.role ?? null,

          $identStatus: (state) => state?.company?.identStatus ?? null,

          $isVerified: (state) =>
            state?.company?.identStatus === IdentificationStatus.Accepted,

          $isVerifiedOrPending: (state) => {
            if (!state?.company) return false;

            return (
              state.company.identStatus === IdentificationStatus.Accepted ||
              state.company.identStatus === IdentificationStatus.Pending
            );
          },

          $isPendingVerification: (state) =>
            state?.company?.identStatus === IdentificationStatus.Pending,

          $type: (state) => state?.company?.type ?? null,

          $isLimitsExceeded: (state) => !!state?.company?.isLimitsExceeded,

          $groups: (state) => state?.company?.groups ?? [],
        },
      }),
    };
  }

  public static Schema = UserWithCompanyJWTSchema;
}

type CompanyJWTModelType = ReturnType<typeof CompanyJWTModelFactory.create>;

export type { CompanyJWTModelType };
export { CompanyJWTModelFactory };
