import { memo } from 'react';

import type { FieldModel } from '@kuna-pay/form';
import { useField } from '@kuna-pay/form';

import type { AmountInputProps } from './amount-input.component';
import { AmountInput } from './amount-input.component';

type AmountInputFormFieldProps = Omit<
  AmountInputProps,
  'onChange' | 'onFocus' | 'onBlur' | 'isError' | 'value' | 'name'
> & {
  field: FieldModel<string>;
  hideValidation?: boolean;
};

const AmountInputFormField = memo(
  ({
    hideValidation,

    field,
    disabled,
    helperText,
    ...props
  }: AmountInputFormFieldProps) => {
    const {
      path,
      value,
      onChange,
      onBlur,
      error: fieldError,
      shouldShowValidation,
      disabled: formDisabled,
      onFocus,
    } = useField(field);

    const error =
      !hideValidation && shouldShowValidation ? fieldError : undefined;

    return (
      <AmountInput
        name={path}
        value={value}
        onValueChange={({ value }) => onChange(value)}
        onFocus={onFocus}
        onBlur={onBlur}
        isError={!!error}
        /* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */
        helperText={error || helperText}
        disabled={!!disabled || !!formDisabled}
        {...props}
      />
    );
  }
);

export { AmountInputFormField };
