try {
  /**
   * @see https://kunatech.atlassian.net/browse/KUPAY-2104
   */
  console.log(
    `%c⚠️ Warning ⚠️%c
  Some of DevTools features, like Device Toolbar, may have impact on application

  Do not use DevTools features while application is open in more than 1 browser-tab

  After you finish, please close DevTools to make sure that everything works correctly
`,

    "color:#ceb73f; background: #ceb73f33; font-size:1.5rem; padding:0.15rem; margin: 1rem auto; font-family: Rockwell, Tahoma, 'Trebuchet MS', Helvetica; border: 2px solid #ceb73f; border-radius: 4px; font-weight: bold; text-shadow: 1px 1px 1px #000000bf;",
    'font-weight: bold; font-size: 1rem;color: #ceb73f;'
  );
} catch (e) {
  // In case devtools doesn't support console styling and throws an error
}
