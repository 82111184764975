import { Navigate, type RouteObject } from 'react-router-dom';

import { ChangePasswordPage } from './change';
import { ChangePasswordFailedPage } from './change-failed';
import { ChangePasswordSucceedPage } from './change-succeed';
import { OutdatedLinkChangePasswordPage } from './outdated';
import { RequestResetPasswordPage } from './request';
import { RequestedPasswordResetPage } from './requested';

const ResetPasswordPages: RouteObject[] = [
  {
    path: '/reset-password',
    children: [
      {
        index: true,
        Component: ChangePasswordPage.Component,
      },
      {
        path: '/reset-password/request',
        Component: RequestResetPasswordPage.Component,
      },
      {
        path: '/reset-password/requested',
        Component: RequestedPasswordResetPage.Component,
      },
      {
        path: '/reset-password/outdated',
        Component: OutdatedLinkChangePasswordPage.Component,
      },
      {
        path: '/reset-password/change/fail',
        Component: ChangePasswordFailedPage.Component,
      },
      {
        path: '/reset-password/change/success',
        Component: ChangePasswordSucceedPage.Component,
      },
      {
        path: '*',
        element: <Navigate to='/reset-password/request' replace />,
      },
    ],
  },
];

export { ResetPasswordPages };
