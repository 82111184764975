import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';
import { forwardRef } from 'react';

import type { BaseButtonProps, UnstyledButtonProps } from './base-button.props';
import styles from './base-button.module.scss';

const UnstyledButton = Object.assign(
  forwardRef<HTMLButtonElement, UnstyledButtonProps>(
    ({ className, type = 'button', fullWidth, asChild, ...props }, ref) => {
      const Component = asChild ? Slot : 'button';

      return (
        <Component
          ref={ref}
          className={clsx(styles.unstyled, className, {
            [styles.fullWidth]: !!fullWidth,
          })}
          type={type}
          {...props}
        />
      );
    }
  ),
  {
    className: (fullWidth = false) =>
      clsx(styles.unstyled, {
        [styles.fullWidth]: fullWidth,
      }),
  }
);

const BaseButton = Object.assign(
  forwardRef<HTMLButtonElement, BaseButtonProps>(
    ({ className, ...props }, ref) => (
      <UnstyledButton
        ref={ref}
        className={clsx(styles.base, className)}
        {...props}
      />
    )
  ),
  {
    className: (fullWidth = false) =>
      clsx(styles.base, UnstyledButton.className(fullWidth)),
  }
);

const IconButton = forwardRef<HTMLButtonElement, BaseButtonProps>(
  ({ className, disableInteractiveStyles, ...props }, ref) => (
    <BaseButton
      ref={ref}
      className={clsx({ [styles.icon]: !disableInteractiveStyles }, className)}
      {...props}
    />
  )
);

export { BaseButton, IconButton, UnstyledButton };
