import type { PropsWithChildren } from 'react';
import { memo } from 'react';

import styles from '././ui.module.scss';

const Header = memo(({ children }: PropsWithChildren) => (
  <div className={styles.root}>
    <div className={styles.container}>{children}</div>
  </div>
));

export { Header };
