import { memo } from 'react';

import type { IconProps } from '@kuna-pay/ui/icons';
import { LogoIcon } from '@kuna-pay/ui/icons';

type KUNALogoProps = IconProps & {
  mainIconProps?: IconProps;
};

const KUNALogo = memo(({ mainIconProps = {}, ...props }: KUNALogoProps) => (
  <LogoIcon {...props} {...mainIconProps} />
));

export { KUNALogo };
