import { ChangeLanguageView } from './change-language.component';
import { MobileNavigationChangeLanguage } from './change-language.mobile.component';
import { $$changeLanguage } from './change-language.model';

const ChangeLanguage = Object.assign(ChangeLanguageView, {
  factory: () => $$changeLanguage,
  $$instance: $$changeLanguage,

  Desktop: ChangeLanguageView,
  MobileNavigation: MobileNavigationChangeLanguage,
});

export { ChangeLanguage };
