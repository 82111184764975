import { useTranslation } from 'react-i18next';

class DateTimeLocaleResolver {
  public static resolve = (language: string) => {
    switch (language) {
      case 'en': {
        return 'en-UK';
      }

      default: {
        return language;
      }
    }
  };
}

class DateUtils {
  public static isDateAfter = (source: Date, target: Date) => source > target;

  public static isAfterNow = (source: Date) =>
    DateUtils.isDateAfter(source, new Date(Date.now()));

  //@see https://stackoverflow.com/a/74529856/17032207
  public static toUTCStringWithTimezoneOffset = (source: Date) => {
    const date = new Date(source);

    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

    return date.toISOString();
  };

  public static attachHoursAndMinutesFromNow = (source: Date) => {
    const date = new Date(source);
    const now = new Date();
    date.setHours(now.getHours());
    date.setMinutes(now.getMinutes());

    return date;
  };
}

class DateFormat {
  public static formatDateTime = (date: Date | string, locale: string) =>
    new Date(date).toLocaleString(DateTimeLocaleResolver.resolve(locale), {
      hour12: false,
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      second: 'numeric',
      minute: 'numeric',
      hour: 'numeric',
    });

  public static formatTime = (date: Date | string, locale: string) =>
    new Date(date).toLocaleTimeString(DateTimeLocaleResolver.resolve(locale), {
      hour12: false,
    });

  public static formatDate = (date: Date | string, locale: string) =>
    new Date(date).toLocaleDateString(DateTimeLocaleResolver.resolve(locale), {
      hour12: false,

      // Because in IT it formats as 1/7/2021 instead of 01/07/2021
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
}

type LocalizedDateFormat = {
  formatDateTime: (date: Date | string) => string;
  formatTime: (date: Date | string) => string;
  formatDate: (date: Date | string) => string;
};

/**
 * Binding with i18n to format date according to the current locale
 */
function useDateFormat(): LocalizedDateFormat {
  const { i18n } = useTranslation();

  return {
    formatDateTime: (date: Date | string) =>
      DateFormat.formatDateTime(date, i18n.language),

    formatTime: (date: Date | string) =>
      DateFormat.formatTime(date, i18n.language),

    formatDate: (date: Date | string) =>
      DateFormat.formatDate(date, i18n.language),
  };
}

export { DateFormat, DateTimeLocaleResolver, DateUtils, useDateFormat };
export type { LocalizedDateFormat };
