import type { EventOptions } from '@amplitude/analytics-types';
import { useUnit } from 'effector-react';
import { useEffect } from 'react';

import type { IAmplitudeAnalyticsModel } from './amplitude.model';

const createUIBindingsToAmplitudeAnalytics = <
  TEvents extends {
    event: string;
    properties?: Record<string, unknown>;
    options?: EventOptions;
  }
>(
  $$analytics: IAmplitudeAnalyticsModel<TEvents>
) => {
  const useAmplitudeAnalytics = () => {
    const logEvent = useUnit($$analytics.logEvent);

    return {
      logEvent: (...params: Parameters<typeof logEvent>) => {
        void logEvent(...params);
      },
    };
  };

  /**
   * (!!!)
   * Doesn't handle case with changing props
   * to not log new event on every render or
   * force users to use memoization for props.
   * Use `key` prop to force remount component and log event again
   */
  const LogAmplitudeEventOnMount = (event: TEvents) => {
    const { logEvent } = useAmplitudeAnalytics();

    useEffect(() => {
      logEvent(event);
    }, []);

    return null;
  };

  return {
    useAmplitudeAnalytics,
    LogAmplitudeEventOnMount,
  };
};

export { createUIBindingsToAmplitudeAnalytics };
