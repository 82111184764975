import { RefObject, useRef } from 'react';

const useExternalRef = <T>(
  externalRef?: RefObject<T> | null,
  defaultValue: T | null = null
) => {
  const innerRef = useRef<T>(defaultValue);

  return externalRef || innerRef;
};

export { useExternalRef };
