import type { InitOptions } from 'i18next';

class CrowdinInContextConfig {
  public static PSEUDO_LANGUAGE = 'zu';

  public static patchI18nextInitConfig(initOptions: InitOptions) {
    if (initOptions.supportedLngs) {
      initOptions.supportedLngs = [
        ...initOptions.supportedLngs,
        CrowdinInContextConfig.PSEUDO_LANGUAGE,
      ];
    }

    initOptions.lng = CrowdinInContextConfig.PSEUDO_LANGUAGE;
  }
}

export { CrowdinInContextConfig };
