import clsx from 'clsx';
import type { FC, HTMLAttributes, ImgHTMLAttributes } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';

import type { TypographyProps } from '../../typography';
import { Typography } from '../../typography';
import emptyBoxImg from '../assets/empty.png';
import styles from './error.module.scss';

/**
 * TODO: Add i18n
 */
const DataGridNotFound = createCompoundComponent(
  ({ Root, Image, Title, Description }): FC =>
    () =>
      (
        <Root>
          <Image />

          <Title>Nothing found</Title>

          <Description>
            Try different keywords or use other categories
          </Description>
        </Root>
      ),

  {
    Root: ({
      className,
      adjustToHiddenHeader,
      ...props
    }: HTMLAttributes<HTMLDivElement> & {
      adjustToHiddenHeader?: boolean;
    }) => (
      <div
        className={clsx(
          styles.root,
          { [styles.adjustToHiddenHeader]: adjustToHiddenHeader },
          className
        )}
        {...props}
      />
    ),

    Image: (props: ImgHTMLAttributes<HTMLImageElement>) => (
      <img src={emptyBoxImg} width={140} height={73} alt='' {...props} />
    ),

    Title: ({ className, ...props }: Partial<TypographyProps>) => (
      <Typography
        as='p'
        className={clsx(styles.title, className)}
        variant='numberAdmin2'
        {...props}
      />
    ),

    Description: ({ className, ...props }: Partial<TypographyProps>) => (
      <Typography
        as='p'
        className={clsx(styles.subtitle, className)}
        variant='numbers2'
        {...props}
      />
    ),
  }
);

export { DataGridNotFound };
