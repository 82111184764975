import { useLayoutEffect, useState } from 'react';

function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState<boolean>(
    () => window.matchMedia(query).matches
  );

  // Handles the change event of the media query.

  useLayoutEffect(() => {
    const matchMedia = window.matchMedia(query);

    function onChange(event: MediaQueryListEvent) {
      setMatches(event.matches);
    }

    // Use deprecated `addListener` and `removeListener` to support Safari < 14 (#135)
    if (matchMedia.addListener) {
      matchMedia.addListener(onChange);

      return () => {
        matchMedia.removeListener(onChange);
      };
    }

    matchMedia.addEventListener('change', onChange);

    return () => {
      matchMedia.removeEventListener('change', onChange);
    };
  }, [query]);

  return matches;
}

export { useMediaQuery };
