import { createQuery } from '@farfetched/core';
import type { Select } from '@kuna/graphql-util/dist/select';
import { createEffect } from 'effector';

import type { UserProfileInformationOutput } from '@kuna-pay/merchant/generated/graphql';
import { me } from '@kuna-pay/merchant/shared/api/generated/Profile/request/me';

import { createQueryWithImperativeCacheAccess } from './lib.query';

const SELECT: Select<UserProfileInformationOutput> = {
  firstName: true,
  lastName: true,
  identificationStatus: true,
  fullName: true,
  role: true,
  email: true,
  status: true,
  language: true,
  username: true,
};

const $$meQuery = Object.assign(
  createQueryWithImperativeCacheAccess(
    createQuery({
      name: 'session/queries/me',
      effect: createEffect(
        async (): Promise<UserProfileInformationOutput> => me(SELECT)()
      ),
    })
  ),
  {
    SELECT,
  }
);

export { $$meQuery };
