import { useUnit } from 'effector-react';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useDateFormat } from '@kuna-pay/utils/date';
import { ArrowRightIcon, Bell24Icon } from '@kuna-pay/ui/icons';
import { NavLink } from '@kuna-pay/ui/router';
import { Button, UnstyledButton } from '@kuna-pay/ui/ui/button';
import { Divider } from '@kuna-pay/ui/ui/divider';
import { Skeleton } from '@kuna-pay/ui/ui/skeleton';
import type { GridColumn } from '@kuna-pay/ui/ui/table';
import { DataGrid } from '@kuna-pay/ui/ui/table';
import { Typography } from '@kuna-pay/ui/ui/typography';

import {
  $$pushNotifications,
  NotificationDescription,
  NotificationTitle,
  PushNotificationGroup24Icon,
  ReadAllPushNotifications,
  UnreadIcon,
  useNotificationAction,
} from '@kuna-pay/merchant/entities/push-notification';
import emptyPng from '@kuna-pay/merchant/entities/push-notification/assets/empty.png';
import type { NotificationOutput } from '@kuna-pay/merchant/generated/graphql';
import { $$router, routes } from '@kuna-pay/merchant/shared/router';
import { useHeaderMenuDropdownContext } from '@kuna-pay/merchant/widgets/layout/ui/header-menu/ui/desktop-menu-dropdown/dropdown.context';

import { $$headerMenu } from '../header-menu.model';
import { HeaderMenuDropdown } from '../ui';
import styles from './notification-menu.module.scss';

const DesktopPushNotificationsMenu = memo(() => {
  const [
    isLoggedIntoCompany,
    isCompanyLoggedPending,
    unreadNotificationsCounter,
    isGateMounted,
  ] = useUnit([
    $$headerMenu.$isCompanyLogged,
    $$headerMenu.$isCompanyLoggedPending,
    $$pushNotifications.$$unreadNotificationCounter.$data,
    $$pushNotifications.Gate.status,
  ]);

  if (!isGateMounted) {
    return null;
  }

  if (!isLoggedIntoCompany) {
    if (isCompanyLoggedPending) {
      return <Skeleton height={24} width={24} />;
    }

    return null;
  }

  return (
    <HeaderMenuDropdown
      className={styles.root}
      content={<PushNotificationsInfo />}
    >
      <UnstyledButton className={styles.button}>
        <Bell24Icon />

        {!!unreadNotificationsCounter?.count && <UnreadIcon />}
      </UnstyledButton>
    </HeaderMenuDropdown>
  );
});

const PushNotificationsInfo = memo(() => {
  const { t } = useTranslation();

  return (
    <>
      <PushNotificationsInfoHeader />

      <DataGrid
        columns={columns}
        $$model={$$pushNotifications.$$notifications}
        loading={
          <div className={styles.loading}>
            <Skeleton height={160} fullWidth />
          </div>
        }
        noHeader
        notFound={
          <div className={styles.empty}>
            <img src={emptyPng} alt='' />

            <Typography variant='body3'>
              {t('widgets.layout.header-menu.notification-menu.empty')}
            </Typography>
          </div>
        }
      />
    </>
  );
});

const columns: GridColumn<NotificationOutput>[] = [
  {
    field: 'id',
    flex: 1,
    minWidth: 100,
    renderCell: memo(({ row }) => <NotificationView notification={row} />),
  },
];

const PushNotificationsInfoHeader = memo(() => {
  const { t } = useTranslation();
  const menu = useHeaderMenuDropdownContext();

  const [isLoading, isError, data, readAllNotifications] = useUnit([
    $$pushNotifications.$$unreadNotificationCounter.$isLoading,
    $$pushNotifications.$$unreadNotificationCounter.$isError,
    $$pushNotifications.$$unreadNotificationCounter.$data,
    $$pushNotifications.readAllNotificationFx,
  ]);

  return (
    <div className={styles.header}>
      <div>
        <div className={styles.unread}>
          <div className={styles.unreadText}>
            {isLoading || isError || !data ? (
              <Trans
                t={t}
                i18nKey='widgets.layout.header-menu.notification-menu.unread-counter-unknown'
                components={{
                  skeleton: (
                    <Skeleton height={24} width={14} borderRadius={4} />
                  ),
                  body3: <Typography variant='body3' />,
                }}
              />
            ) : (
              <Trans
                t={t}
                i18nKey='widgets.layout.header-menu.notification-menu.unread-counter'
                components={{
                  h8: <Typography variant='h8' />,
                  body3: <Typography variant='body3' />,
                }}
                values={{ unreadCount: data.count }}
                count={data.count}
              />
            )}
          </div>

          <ReadAllPushNotifications read={readAllNotifications} />
        </div>

        <Button.Root variant='text' color='primary' size='lg' asChild>
          <NavLink
            to={routes.notifications.root}
            onClick={() => menu.setIsOpen(false)}
          >
            <Button.Text size='lg'>
              {t('widgets.layout.header-menu.notification-menu.all')}
            </Button.Text>

            <ArrowRightIcon />
          </NavLink>
        </Button.Root>
      </div>

      <Divider className={styles.divider} />
    </div>
  );
});

type NotificationProps = {
  notification: NotificationOutput;
};

const isTouchDevice = 'ontouchstart' in window;

const NotificationView = memo(({ notification }: NotificationProps) => {
  const DateFormat = useDateFormat();
  const { setIsOpen } = useHeaderMenuDropdownContext();
  const [onReadNotification] = useUnit([
    $$headerMenu.$$pushNotifications.readNotificationFx,
  ]);
  const action = useNotificationAction(notification);

  return (
    <div
      className={styles.notification}
      onMouseOver={() => {
        void onReadNotification(notification);
      }}
      onClick={() => {
        if (isTouchDevice) {
          void onReadNotification(notification);
        }

        switch (action.type) {
          case 'button': {
            if (action.action === 'contact-support') {
              $$headerMenu.$$contactSupport.$$ui.$$modal.open();
            }

            return;
          }

          case 'link': {
            if (action.isExternal) {
              window.open(action.href, '_blank');

              return;
            }

            setIsOpen(false);
            $$router.redirect(action.href);

            return;
          }
        }
      }}
    >
      <div className={styles.iconContainer}>
        <PushNotificationGroup24Icon group={notification.group} />

        <UnreadIcon
          className={styles.iconContainerUnread}
          isRead={notification.isRead}
        />
      </div>

      <div className={styles.content}>
        <NotificationTitle
          className={styles.title}
          notification={notification}
          variant='subtitle5'
        />

        <div className={styles.text}>
          <Typography className={styles.description} variant='body3'>
            <NotificationDescription notification={notification} />
          </Typography>

          <Typography className={styles.createdAt} variant='numbers2'>
            {DateFormat.formatDateTime(notification.createdAt)}
          </Typography>
        </div>
      </div>
    </div>
  );
});

export { DesktopPushNotificationsMenu, PushNotificationsInfo };
