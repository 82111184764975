import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { RateYourExperienceManager } from '@kuna-pay/core/shared/feedback/features/rate-your-experience';

import { $$rateYourExperienceFeedback } from './rate-your-experience-feedback.model';

const RateYourExperienceStack = memo(() => {
  const { t } = useTranslation();

  return (
    <RateYourExperienceManager
      $$model={$$rateYourExperienceFeedback}
      i18n={(key) => ({
        rate: {
          title: t(`shared.feedback.rate.title.${key}`),
        },
      })}
    />
  );
});

export { RateYourExperienceStack };
