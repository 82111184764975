import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useListFormat = () => {
  const { i18n } = useTranslation();

  const ListFormat = useMemo((): Intl.ListFormat => {
    if (typeof Intl.ListFormat === 'undefined') {
      return {
        format: (items) => items.join(', '),
        formatToParts: () => [],
        supportedLocalesOf: () => [],
      };
    }

    /**
     }
     * en: "and" (e.g. "A, B, and C") -> "Oxford comma"
     * @see https://github.com/tc39/proposal-intl-list-format/issues/31
     */
    return new Intl.ListFormat(i18n.language, {
      style: 'long',
      type: 'conjunction',
    });
  }, [i18n.language]);

  return ListFormat;
};
export { useListFormat };
