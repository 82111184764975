import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { Link } from '@kuna-pay/ui/router';
import { urls } from '@kuna-pay/core/shared/config';
import { KUNALogo } from '@kuna-pay/core/shared/ui/logo';

import { routes } from '@kuna-pay/merchant/shared/router';

const HeaderLogo = memo(() => {
  const { i18n } = useTranslation();
  const isSignInPage = useMatch(routes.auth.signIn.path);
  const isSignUpPage = useMatch(routes.auth.signUp.path);

  const isSignUpOrSignInPage = !!isSignInPage || !!isSignUpPage;

  return (
    <>
      {isSignUpOrSignInPage ? (
        <Link
          to={urls.landings.kunaPay.withLocale(i18n.language)}
          target='_blank'
        >
          <KUNALogo
            mainIconProps={{ className: 'text-black900', width: 105 }}
            height={32}
          />
        </Link>
      ) : (
        <Link to={routes.dashboard}>
          <KUNALogo
            mainIconProps={{ className: 'text-black900', width: 105 }}
            height={32}
          />
        </Link>
      )}
    </>
  );
});

export { HeaderLogo };
