import { createEvent } from 'effector';
import { useLayoutEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { listen } from '@kuna-pay/utils/effector';
import { useLocalModel } from '@kuna-pay/utils/react/use-local-model';

function createSearchParamsCollector(config: {
  storageKey: string;
  searchParamKey: string;
  storage: Storage;
}) {
  return function () {
    const [searchParams, setSearchParams] = useSearchParams();
    const $$model = useLocalModel(() => {
      const collect = createEvent<string | null>();

      listen({
        clock: collect,
        handler: (value) => {
          if (value) {
            config.storage.setItem(config.storageKey, value);
          }
        },
      });

      return { collect };
    });

    /**
     * It's ok to do it in render, 'cause we can miss search param in useEffect/useLayout effect,
     * if there would be `return <Navigate/>` in any child component
     */
    $$model.collect(searchParams.get(config.searchParamKey));

    useLayoutEffect(() => {
      setSearchParams((searchParams) => {
        searchParams.delete(config.searchParamKey);

        return searchParams;
      });
    }, []);

    return null;
  };
}

export { createSearchParamsCollector };
