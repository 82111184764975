import { memo, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { CloseIcon } from '@kuna-pay/ui/icons';
import { notify } from '@kuna-pay/ui/notification';
import { Button, IconButton } from '@kuna-pay/ui/ui/button';
import { Modal } from '@kuna-pay/ui/ui/modal';
import { Typography } from '@kuna-pay/ui/ui/typography';
import type { FieldModel } from '@kuna-pay/form';
import { Field, Form, useField } from '@kuna-pay/form';
import { useListFormat } from '@kuna-pay/core/shared/lib/list-format';

import { GenericFeedbackModel } from './generic-feedback.model';
import styles from './collect-feedback-form.module.scss';

const CollectFeedbackForm = memo(() => {
  const $$model = GenericFeedbackModel.useModel();
  const { t } = useTranslation('core');

  return (
    <>
      <Modal.Title className={styles.title} variant='h9'>
        {t('shared.feedback.generic-feedback.title')}
      </Modal.Title>

      <Modal.Description className={styles.description} variant='body3'>
        {t('shared.feedback.generic-feedback.description')}
      </Modal.Description>

      <Form
        className={styles.form}
        name='generic-feedback-form'
        use={$$model.$$form}
      >
        <Field.TextAreaField
          className={styles.feedback}
          field={$$model.$$form.fields.feedback!}
          label={t('shared.feedback.generic-feedback.fields.feedback.label')}
          placeholder={t(
            'shared.feedback.generic-feedback.fields.feedback.placeholder'
          )}
          variant='outlined'
          size='md'
          maxLength={$$model.MAX_DESCRIPTION_LENGTH}
        />

        <AttachmentsField $$field={$$model.$$form.fields.attachments} />

        <Form.Submit
          className={styles.submit}
          variant='contained'
          color='primary'
          size='lg'
          fullWidth
        >
          {t('shared.feedback.generic-feedback.fields.submit')}
        </Form.Submit>
      </Form>
    </>
  );
});

type AttachmentsFieldProps = {
  $$field: FieldModel<any>;
};

const AttachmentsField = memo(({ $$field }: AttachmentsFieldProps) => {
  const $$model = GenericFeedbackModel.useModel();
  const { t } = useTranslation('core');

  const {
    value: attachments,
    disabled,
    onChange,
  } = useField($$field as FieldModel<File[]>);

  const listFormat = useListFormat();

  const $$dropzone = useDropzone({
    noDrag: true,
    accept: { 'image/*': ['.jpeg', '.jpg', '.png', '.webp', '.svg', '.gif'] },
    maxSize: 5 * 1024 * 1024,
    multiple: true,
    disabled,
    onDrop: (acceptedFiles, fileRejections) => {
      const newAttachments = [...attachments, ...acceptedFiles];

      onChange(newAttachments.slice(0, $$model.MAX_ATTACHMENTS));

      const tooLargeFiles = fileRejections.filter(
        (rejection) => rejection.errors[0].code === 'file-too-large'
      );

      if (tooLargeFiles.length > 0) {
        const names = tooLargeFiles.map(({ file }) => file.name);

        notify.warning(
          t(
            'shared.feedback.generic-feedback.fields.attachments.errors.too-large',
            {
              replace: {
                maxSize: '5MB',
                files: listFormat.format(names),
              },
            }
          )
        );
      }

      if (newAttachments.length > $$model.MAX_ATTACHMENTS) {
        notify.warning(
          t('shared.feedback.generic-feedback.fields.attachments.errors.max', {
            replace: {
              max: $$model.MAX_ATTACHMENTS,
            },
          })
        );
      }
    },
  });

  return (
    <div className={styles.attachments}>
      <div>
        <Button
          {...$$dropzone.getRootProps()}
          variant='contained'
          color='secondary'
          size='md'
        >
          {t('shared.feedback.generic-feedback.fields.attachments.add')}

          <input {...$$dropzone.getInputProps()} />
        </Button>
      </div>

      <Typography className={styles.attachmentsSubtitle} variant='numbers2'>
        {t('shared.feedback.generic-feedback.fields.attachments.help-text', {
          replace: { maxSize: '5MB', maxAttachments: $$model.MAX_ATTACHMENTS },
        })}
      </Typography>

      {attachments.length > 0 && (
        <div className={styles.attachmentsPreviews}>
          {attachments.map((file) => (
            <AttachmentPreview
              key={`${file.name}_${file.size}_${file.type}_${file.lastModified}_${file.webkitRelativePath}`}
              file={file}
              onDelete={() =>
                onChange(
                  attachments.filter(
                    (attachmentFile) => attachmentFile !== file
                  )
                )
              }
            />
          ))}
        </div>
      )}
    </div>
  );
});

type AttachmentPreviewProps = {
  file: File;
  onDelete: () => void;
};

const AttachmentPreview = memo(({ file, onDelete }: AttachmentPreviewProps) => {
  const [src] = useState(() => {
    try {
      return URL.createObjectURL(file);
    } catch {
      console.error('Failed to create object URL for file', file);

      return null;
    }
  });

  useEffect(() => {
    if (!src) return;

    return () => {
      URL.revokeObjectURL(src);
    };
  }, [src]);

  if (!src) {
    return null;
  }

  return (
    <div className={styles.attachmentPreview}>
      <img className={styles.attachmentPreviewImage} src={src} alt='' />

      <IconButton className={styles.attachmentPreviewClose} onClick={onDelete}>
        <CloseIcon />
      </IconButton>
    </div>
  );
});

export { CollectFeedbackForm };
