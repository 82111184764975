import { useUnit } from 'effector-react';
import type { ComponentPropsWithoutRef } from 'react';
import { memo } from 'react';
import { useMemo } from 'react';
import { useContext } from 'react';

import { Button } from '@kuna-pay/ui/ui/button';

import { FormContext } from './context';

type FormButtonProps = Omit<ComponentPropsWithoutRef<typeof Button>, 'type'> & {
  type: 'submit' | 'reset';

  disableIfNotValid?: boolean;
  disableIfNotDirty?: boolean;
  disableIfSubmittedInvalidForm?: boolean;
};

const FormButton = memo(
  ({
    type,

    disableIfNotValid,
    disableIfSubmittedInvalidForm,
    disableIfNotDirty,
    disabled,
    loading,
    ...props
  }: FormButtonProps) => {
    const form = useContext(FormContext);
    const [isLoading, isValid, isSubmitted, isDirty] = useUnit([
      form.$disabled,
      form.$valid,
      form.$submitted,
      form.$dirty,
    ]);

    const isDisabled = useMemo(() => {
      if (disabled) {
        return true;
      }

      if (disableIfSubmittedInvalidForm) {
        if (isSubmitted) {
          return !isValid;
        }
      }

      if (disableIfNotValid) {
        if (disableIfNotDirty) {
          return !isValid || !isDirty;
        }

        return !isValid;
      }

      if (disableIfNotDirty) {
        return !isDirty;
      }

      return false;
    }, [
      disableIfNotValid,
      disableIfSubmittedInvalidForm,
      disabled,
      isValid,
      isLoading,
      isSubmitted,
      isDirty,
    ]);

    return (
      <Button
        type={type}
        {...props}
        loading={isLoading || loading}
        disabled={isDisabled || disabled}
      />
    );
  }
);

type FormSubmitButtonProps = Omit<FormButtonProps, 'type'>;

const FormSubmitButton = memo((props: FormSubmitButtonProps) => (
  <FormButton type='submit' {...props} />
));

type FormResetButtonProps = Omit<FormButtonProps, 'type'>;

const FormResetButton = memo((props: FormResetButtonProps) => (
  <FormButton type='reset' {...props} />
));

export { FormButton, FormResetButton, FormSubmitButton };
