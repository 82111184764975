class CrowdinInContextScript {
  public static inject() {
    const script = document.createElement('script');
    const jiptScript = document.createElement('script');
    jiptScript.src = '//cdn.crowdin.com/jipt/jipt.js';

    script.innerHTML = `
      var _jipt = [];
      _jipt.push(['project', 'kuna-pay']);
    `;

    document.head.appendChild(script);
    document.head.appendChild(jiptScript);
  }
}

export { CrowdinInContextScript };
