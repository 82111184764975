import { UserProfileInformationOutput } from '@kuna-pay/merchant/generated/graphql';
import { subscription, query } from '@kuna-pay/merchant/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const meRequest = (
  select: Select<UserProfileInformationOutput> | string
) => `query me {
  data: me {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'UserProfileInformationOutput')}`;
export const me =
  (select: Select<UserProfileInformationOutput> | string) => () =>
    query<UserProfileInformationOutput>(meRequest(select));
