import clsx from 'clsx';
import { useStoreMap } from 'effector-react';

import { IconButton } from '@kuna-pay/ui/ui/button';

import { ReactComponent as SortIcon } from '../../assets/sort.svg';
import { DataGridModel } from '../../model';
import type { GridValueColumn } from '../../types';
import styles from './head-column-sort.module.scss';

type TableHeadColumnSortProps<T extends Record<string, unknown>> = {
  column: GridValueColumn<T>;

  className?: string;
  classes?: {
    root?: string;
    sortIcon?: string;
  };
};

const TableHeadColumnSort = <T extends Record<string, unknown>>({
  column,
  className,
  classes,
}: TableHeadColumnSortProps<T>) => {
  const $$model = DataGridModel.useModel<T>();

  const sortOrder = useStoreMap({
    store: $$model.$sort,
    keys: [column.field],
    fn: (sort, [field]) => sort[field] ?? null,
  });

  return (
    <IconButton className={clsx(className, classes?.root)}>
      <SortIcon
        className={clsx(styles.sort, classes?.sortIcon)}
        data-sort={sortOrder}
      />
    </IconButton>
  );
};

export { TableHeadColumnSort };
