import { Spinner } from '@kuna-pay/ui/ui/spinner';

import styles from './splash-screen.module.scss';

const AppLoadingScreen = () => (
  <div className={styles.root}>
    <Spinner className={styles.spinner} />
  </div>
);

export { AppLoadingScreen };
