import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { HTMLAttributes } from 'react';

import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import { useI18n } from '../../i18n';
import { DataGridModel } from '../../model';
import type { GridValueColumn } from '../../types';
import { TableHeadColumnSort } from './head-column-sort.component';
import styles from './head.module.scss';

type TableHeadColumnProps<T extends Record<string, unknown>> = {
  column: GridValueColumn<T>;
};

type TableHeadCellProps<T extends Record<string, unknown>> =
  HTMLAttributes<HTMLDivElement> & {
    column: Omit<GridValueColumn<T>, 'renderCell'>;
  };

type TableHeadedCellTextProps = Partial<TypographyProps>;

const TableHeadColumn = <T extends Record<string, unknown>>({
  column,
}: TableHeadColumnProps<T>) => {
  const t = useI18n();

  return (
    <TableHeadCell column={column}>
      <TableHeadCellText>
        {column.label ? t(column.label) : ''}

        {column.sortable && <TableHeadColumnSort column={column} />}
      </TableHeadCellText>
    </TableHeadCell>
  );
};

const TableHeadCell = <T extends Record<string, unknown>>({
  column,
  className,
  onClick,
  ...props
}: TableHeadCellProps<T>) => {
  const $$model = DataGridModel.useModel<T>();

  const { headColumnClicked } = useUnit({
    headColumnClicked: $$model.$$head.clicked,
  });

  return (
    <div
      className={clsx(styles.cell, className, {
        [styles.interactive]: column.sortable,
      })}
      onClick={(event) => {
        onClick?.(event);
        headColumnClicked(column);
      }}
      {...props}
    />
  );
};

const TableHeadCellText = ({
  className,
  ...props
}: TableHeadedCellTextProps) => (
  <Typography
    className={clsx(styles.text, className)}
    variant='numbers2'
    {...props}
  />
);

export { TableHeadCell, TableHeadCellText, TableHeadColumn };
export type {
  TableHeadCellProps,
  TableHeadColumnProps,
  TableHeadedCellTextProps,
};
