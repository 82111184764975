import { Suspense } from 'react';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';

import { MainInfoPageLoading } from './page.loading';

const Page = lazyWithRetryAndRefresh(
  'ProfileSecurityPage',
  () => import('./page.component')
);

const ProfileSecurityPage = {
  path: 'main',
  Component: () => (
    <Suspense fallback={<MainInfoPageLoading />}>
      <Page />
    </Suspense>
  ),
};

export { ProfileSecurityPage };
