import type { ComponentPropsWithoutRef } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '@kuna-pay/ui/router';
import { Button } from '@kuna-pay/ui/ui/button';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { AdaptivePaper } from '@kuna-pay/core/shared/ui/adaptive-paper';

import safePng from './assets/safe.png';
import styles from './page.module.scss';

type AskToSetupAuthenticator2FAProps = {
  setup: ComponentPropsWithoutRef<typeof Link>;
  skip: ComponentPropsWithoutRef<typeof Link>;
};

const AskToSetupAuthenticator2FAPage = memo(
  ({ setup, skip }: AskToSetupAuthenticator2FAProps) => {
    const { t } = useTranslation();

    return (
      <AdaptivePaper className={styles.root}>
        <div className={styles.imgContainer}>
          <img src={safePng} alt='' width={244} height={244} />
        </div>

        <Typography as='h1' className={styles.title} variant='h7' center>
          {t('pages.auth.mfa.ask.title', { ns: 'core' })}
        </Typography>

        <Typography as='h2' className={styles.subtitle} variant='body3' center>
          {t('pages.auth.mfa.ask.description', { ns: 'core' })}
        </Typography>

        <Button.Root
          variant='contained'
          color='primary'
          size='xl'
          fullWidth
          asChild
        >
          <Link {...setup}>
            <Button.Text size='xl'>
              {t('pages.auth.mfa.ask.setup', { ns: 'core' })}
            </Button.Text>
          </Link>
        </Button.Root>

        <Button.Root variant='text' color='primary' size='xl' fullWidth asChild>
          <Link {...skip}>
            <Button.Text size='xl'>
              {t('pages.auth.mfa.ask.skip', { ns: 'core' })}
            </Button.Text>
          </Link>
        </Button.Root>
      </AdaptivePaper>
    );
  }
);

AskToSetupAuthenticator2FAPage.displayName = 'AskToSetupAuthenticator2FAPage';

export { AskToSetupAuthenticator2FAPage };
