import type { Query } from '@farfetched/core';
import type { EventPayload, StoreValue } from 'effector';
import { attach, createEvent } from 'effector';

import { listen } from '@kuna-pay/utils/effector';
import { atom } from '@kuna-pay/utils/misc';

function createQueryWithImperativeCacheAccess<
  TQuery extends Query<any, any, any, any>
>(query: TQuery) {
  const updateCache = atom(() => {
    const update = createEvent<Partial<StoreValue<TQuery['$data']>>>();

    listen({
      name: `${query.__.meta.name}.updateCache`,
      clock: update,
      source: query.$data,
      handler: (updates, current) => {
        // Bcs it can be null if we are not logged in or if there was an error
        if (!current) return;

        query.__.lowLevelAPI.pushData({ ...current, ...updates });
      },
    });

    return update;
  });

  const revalidateInBackgroundFx = attach({
    name: `${query.__.meta.name}.revalidateInBackgroundFx`,
    source: query.$data,
    effect: async (company, params: EventPayload<TQuery['start']>) => {
      if (!company) return;

      const result = await query.__.executeFx(params);

      updateCache(result);

      return result;
    },
  });

  const $$query = Object.assign(query, {
    updateCache: updateCache,

    revalidateInBackgroundFx: revalidateInBackgroundFx,
  });

  return $$query;
}

export { createQueryWithImperativeCacheAccess };
