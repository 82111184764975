import type { Effect, Store } from 'effector';
import { attach, combine } from 'effector';
import { createGate } from 'effector-react';
import i18next from 'i18next';
import type { useTranslation } from 'react-i18next';

import { modelFactory } from '@kuna-pay/utils/effector';

import { ErrorMatcher } from '../../../../shared/api';
import { VerifyOneTimeCode } from '../../abstract/verify-one-time-code';
import { ConfirmEmailErrors } from './confirm-email.types';

const ConfirmEmailModel = modelFactory(
  (config: {
    confirmFx: Effect<{ otp: string }, void>;

    $disabled?: Store<boolean>;
  }) => {
    const Gate = createGate<{ i18n: ReturnType<typeof useTranslation> }>();

    const confirmFx = attach({ effect: config.confirmFx });

    const $$form = VerifyOneTimeCode.factory.createModel({
      verifyFx: confirmFx,

      errors: {
        incorrectOtp: {
          match: ErrorMatcher.fromEffectPayload(ConfirmEmailErrors.InvalidOtp),
          $message: combine(Gate.state, () =>
            i18next.t(
              'features.auth.email.confirm.model.verify.errors.incorrect-otp',
              { ns: 'core' }
            )
          ),
        },
        incorrectOtpOrTokenExpired: {
          match: ErrorMatcher.fromEffectPayload(
            ConfirmEmailErrors.InvalidEmailOtpOrTokenExpired
          ),
          $message: combine(Gate.state, () =>
            i18next.t(
              'features.auth.email.confirm.model.verify.errors.incorrect-otp-or-token-expired',
              {
                ns: 'core',
              }
            )
          ),
        },
      },
    });

    return {
      done: $$form.done,
      reset: $$form.reset,

      $$ui: {
        Gate,
        $$form,
      },

      __: {
        confirmFx,
      },
    };
  }
);

export { ConfirmEmailModel };
