import { useLayoutEffect, useRef, useState } from 'react';

const useSyncTriggerAndContentWidth = (deps: unknown[] = []) => {
  const [width, setWidth] = useState(0);
  const ref = useRef<HTMLButtonElement>(null);

  useLayoutEffect(() => {
    if (!ref.current) return;

    setWidth(ref.current.getBoundingClientRect().width);
  }, deps);

  return { width, triggerRef: ref };
};

export { useSyncTriggerAndContentWidth };
