import type { Store } from 'effector';
import { combine } from 'effector';
import { reshape } from 'patronum';

import { StatusOfUser } from '@kuna-pay/core/generated/public/graphql';

import { UserJWTSchema } from '../jwt.schema';
import { JWTService } from '../jwt.service';

type UserJWTModelFactoryConfig = {
  $jwt: Store<string | null>;
};

class UserJWTModelFactory {
  public static create(config: UserJWTModelFactoryConfig) {
    const $accessTokenJwt = combine(config.$jwt, (token) =>
      new JWTService(UserJWTModelFactory.Schema).parseAndDecodeJWT(token)
    );

    return {
      $accessTokenJwt,

      $$user: reshape({
        source: $accessTokenJwt,

        shape: {
          $id: (state) => state?.id ?? null,

          $email: (state) => state?.email ?? null,

          $language: (state) => state?.language ?? null,

          $isOtpEnabled: (state) => !!state?.o,

          $isConfirmedStatus: (state) =>
            state?.status === StatusOfUser.Confirmed,
        },
      }),
    };
  }

  public static Schema = UserJWTSchema;
}

type UserJWTModelType = ReturnType<typeof UserJWTModelFactory.create>;

export type { UserJWTModelType };
export { UserJWTModelFactory };
