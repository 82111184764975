const theme = {
  mode: 'light',

  colors: {
    white: '#FFFFFF',
    black: '#000000',

    black100: '#F7F8FB',
    black200: '#F1F2F3',
    black300: '#D5D7DD',
    black400: '#A9AEB9',
    black500: '#787F8D',
    black600: '#5D626D',
    black700: '#272931',
    black750: '#1E2129',
    black800: '#1E1F23',
    black850: '#1A1C20',
    black900: '#141618',

    blue: '#B1D1FF',

    deepBlue: '#2E71F0',
    deepBlue100: '#F5F6FF',
    deepBlue200: '#D3D7FA',
    deepBlue300: '#BAC1F7',
    deepBlue400: '#707EF9',
    deepBlue500: '#5766EC',
    deepBlue600: '#4856D8',
    deepBlue700: '#384297',
    deepBlue750: '#1D224E',
    deepBlue800: '#1D224E',
    deepBlue850: '#1B1F31',
    deepBlue900: '#0E1228',

    green: '#C2E2DF',
    green100: '#B2FFE4',
    green200: '#89F7CF',
    green300: '#40E5AA',
    green400: '#22CF91',
    green500: '#0BBA7B',

    orange: '#FFD2A8',
    orange100: '#FFEFDD',
    orange200: '#FFDDB4',
    orange300: '#FBA846',
    orange400: '#F08608',
    orange800: '#2A2116',

    pink: '#FEBCBC',

    red100: '#FFECEB',
    red200: '#FFB9B5',
    red300: '#FF8178',
    red350: '#F26969',
    red400: '#F25656',
    red500: '#E34444',

    yellow: '#FFE797',
    yellow100: '#FFF7E2',
    yellow200: '#FFE49F',
    yellow300: '#FFD159',
    yellow400: '#F6C137',
    yellow600: '#DDA512',
  },

  gradients: {
    greenLinear: 'linear-gradient(180deg, #22CF91 0%, #89F7CF 100%)',
    greenVerticalLinear:
      'linear-gradient(180deg, rgba(64, 229, 170, 0.4) 0%, rgba(64, 229, 170, 0.08) 92.5%)',
    greenHorizontalLeftLinear:
      'linear-gradient(90deg, rgba(64, 229, 170, 0.3) 0%, rgba(64, 229, 170, 0) 100%)',
    redLinear: 'linear-gradient(180deg, #F25656 0%, #FFB9B5 100%)',
    redVerticalLinear:
      'linear-gradient(180deg, rgba(242, 86, 86, 0.4) 0%, rgba(242, 86, 86, 0.08) 100%)',
    redHorizontalLeftLinear:
      'linear-gradient(90deg, rgba(255, 129, 120, 0.3) 0%, rgba(255, 129, 120, 0) 100%)',
    redHorizontalRightLinear:
      'linear-gradient(90deg, rgba(255, 129, 120, 0) 0.07%, rgba(255, 129, 120, 0.3) 100%)',
  },

  shadows: {
    shadow1:
      '0px 4px 12px rgba(87, 102, 236, 0.06), 0px 16px 24px rgba(87, 102, 236, 0.12), 0px 12px 24px rgba(87, 102, 236, 0.06)',
    shadow2: '0px 12px 24px rgba(213, 215, 221, 0.32)',
    shadow3: '0px 20px 40px rgba(0, 10, 62, 0.05)',
    shadow4: '0px 16px 24px rgba(213, 215, 221, 0.08)',
  },

  font: 'TTNorms, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
};

type EnrichedTheme = typeof theme;

const useTheme = () => theme;

export type { EnrichedTheme };
export { theme, useTheme };
