/* eslint-disable @typescript-eslint/method-signature-style,@typescript-eslint/consistent-type-definitions */

/**
 *
 */
interface IMonitoringIntegration {
  install(): void;
  uninstall(): void;
}

abstract class BaseTrackingIntegration implements IMonitoringIntegration {
  protected subscriptions: { unsubscribe: () => void }[] = [];

  public install = () => {};

  public uninstall = () => {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  };

  /**
   * Need this sanitizer otherwise sentry will hide whole text from UI
   *
   * @see https://docs.sentry.io/product/data-management-settings/scrubbing/server-side-scrubbing/
   */
  protected readonly sanitize = (text: string) =>
    text
      .replace(/secret/gi, '******')
      .replace(/apikey/gi, '******')
      .replace(/accesstoken/gi, '***********')
      .replace(/refreshtoken/gi, '************')
      .replace(/token/gi, '*****')
      .replace(/auth/gi, '****')
      .replace(/password/gi, '********')
      .replace(/credentials/gi, '***********')
      .replace(/card/gi, '****');
}

export { BaseTrackingIntegration };
export type { IMonitoringIntegration };
