import { ExternalURL, KUNAPayLandingURL } from './kunapay-landing.config';

const urls = {
  userAgreement: 'https://kuna.io/legal/user-agreement',

  whatIs2FA:
    'https://help.kuna.io/en/articles/3390290-what-is-two-factor-authentication',

  verify2FAInstuctions:
    'https://help.kuna.io/en/articles/3389668-how-to-turn-off-two-factor-authentication',

  authenticatorApp: {
    googlePlay:
      'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US&pli=1',
    appStore: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
  },

  support: {
    telegram: 'https://t.me/kuna_pay',

    email: 'mailto:support@kuna.io',

    whatsapp: 'https://wa.me/+37069523736',
  },

  aml: {
    policy: new KUNAPayLandingURL('/aml-policy'),

    complianceManager: {
      href: 'mailto:compliance@kuna.io',
      email: 'compliance@kuna.io',
    },
  },

  faq: new KUNAPayLandingURL('/faq'),
  termsOfUse: new KUNAPayLandingURL('/terms-of-use'),
  privacyPolicy: new KUNAPayLandingURL('/privacy-policy'),
  cookiePolicy: new KUNAPayLandingURL('/cookie-policy'),

  whatIsKunaId:
    'https://help.kuna.io/en/articles/3389680-withdrawal-with-kuna-code',

  whatIsCompanyId: 'https://docs.kunapay.io/docs/kuna-pay-company-id',

  landings: {
    kunaPay: new KUNAPayLandingURL('/'),
  },

  services: {
    exchange: 'https://kuna.io/',

    chainAnalysis: 'https://chainalysis.com/',
  },

  docs: {
    root: new ExternalURL('https://docs.kunapay.io/docs'),
    autoConversion: new ExternalURL(
      'https://docs.kunapay.io/docs/auto-conversion'
    ),
    authentication: new ExternalURL(
      'https://docs.kunapay.io/reference/authentication'
    ),

    onboarding: new KUNAPayLandingURL('/faq/onboarding'),
    troubleShouting: new KUNAPayLandingURL('/faq/trouble-shooting'),
  },
};

export { urls };
