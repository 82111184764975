import {
  isIOS,
  isIOS13,
  isIPad13,
  isIPhone13,
  isIPod13,
  isMacOs,
  isMobileSafari,
  isSafari,
} from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { modelView } from '@kuna-pay/utils/effector';
import { ExternalLink } from '@kuna-pay/ui/router';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { urls } from '@kuna-pay/core/shared/config';
import { useTypedGate } from '@kuna-pay/core/shared/router';

import { VerifyOneTimeCode } from '../@x';
import { SetupMFAModel } from '../setup-mfa.model';
import type { OTPAuthenticatorAppSecretProps } from './secret';
import { OTPAuthenticatorAppSecret } from './secret';
import styles from './setup-form.module.scss';

type SetupMFAFormProps = {
  props?: {
    title?: Partial<TypographyProps>;
    subtitle?: Partial<TypographyProps>;
    suggestedAuthenticator?: Partial<TypographyProps>;
    formTitle?: Partial<TypographyProps>;
    whatIsMfa?: Partial<TypographyProps>;
    otp?: OTPAuthenticatorAppSecretProps['props'];
  };

  onWhatIsMfaClick?: () => void;
};

const isApple = [
  isIOS,
  isIOS13,
  isMacOs,
  isSafari,
  isMobileSafari,
  isIPod13,
  isIPhone13,
  isIPad13,
].some(Boolean);

const SetupMFAForm = modelView(
  SetupMFAModel,
  ({ onWhatIsMfaClick, props }: SetupMFAFormProps) => {
    const $$model = SetupMFAModel.useModel();
    const i18n = useTranslation('core');

    useTypedGate({ Gate: $$model.Gate, props: { i18n } });

    return (
      <>
        <Typography
          as='h1'
          className={styles.title}
          variant='h5'
          center
          {...(props?.title ?? {})}
        >
          {i18n.t('pages.auth.mfa.turn-on.title')}
        </Typography>

        <Typography
          as='h2'
          className={styles.subtitle}
          variant='subtitle5'
          center
          {...(props?.subtitle ?? {})}
        >
          {i18n.t('pages.auth.mfa.turn-on.description')}
          <br />
          <a
            href={
              isApple
                ? urls.authenticatorApp.appStore
                : urls.authenticatorApp.googlePlay
            }
            target='_blank'
            rel='noreferrer'
          >
            <Typography
              className={styles.subtitleLink}
              variant='subtitle5'
              {...(props?.suggestedAuthenticator ?? {})}
            >
              {i18n.t('pages.auth.mfa.turn-on.suggested-authenticator')}
            </Typography>
          </a>
        </Typography>

        <div className={styles.formContainer}>
          <OTPAuthenticatorAppSecret props={props?.otp} />

          <Typography
            as='h3'
            className={styles.formTitle}
            variant='subtitle5_1'
            center
            {...(props?.formTitle ?? {})}
          >
            {i18n.t('features.auth.mfa.setup.ui.title')}
          </Typography>

          <VerifyOneTimeCode.Form
            name='setup-mfa-form'
            $$model={$$model.$$verifySecret}
          />
        </div>

        <ExternalLink
          className={styles.whatIsMfa}
          href={urls.whatIs2FA}
          target='_blank'
          rel='noreferrer'
          onClick={onWhatIsMfaClick}
        >
          <Typography variant='subtitle5' center {...(props?.whatIsMfa ?? {})}>
            {i18n.t('pages.auth.mfa.turn-on.help')}
          </Typography>
        </ExternalLink>
      </>
    );
  }
);

SetupMFAForm.displayName = 'SetupMFAForm';

export { SetupMFAForm };
export type { SetupMFAFormProps };
