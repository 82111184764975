import { createEvent, createStore } from 'effector';

import { modelFactory } from './factorio';
import { UNIT_SHAPE } from './unit-shape';

const Collapse = modelFactory(() => {
  const opened = createEvent();
  const closed = createEvent();
  const $isOpen = createStore(false)
    .on(opened, () => true)
    .on(closed, () => false);

  return {
    $$ui: {
      $isOpen,
      opened,
      closed,

      [UNIT_SHAPE]: () => ({
        isOpen: $isOpen,
        onOpen: opened,
        onClose: closed,
      }),
    },
  };
});

export { Collapse };
