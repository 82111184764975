import { useMediaQuery } from '@kuna-pay/utils/react/use-media-query';
import { Skeleton } from '@kuna-pay/ui/ui/skeleton';

import { MainLayout } from '@kuna-pay/merchant/widgets/layout';

import styles from './page.module.scss';

const TransactionProcessingPageLoading = () => {
  const isMobileOrLess = useMediaQuery('(max-width: 575px)');

  return (
    <MainLayout.Merchant.Content className={styles.container}>
      <Skeleton width={420} height={isMobileOrLess ? '100%' : 550} />
    </MainLayout.Merchant.Content>
  );
};

export { TransactionProcessingPageLoading };
