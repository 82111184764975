import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { AlreadyVerifiedPage } from './already-verified';
import { VerificationDonePage } from './done';
import { VerificationStartPage } from './start';

const VerificationPages: RouteObject[] = [
  {
    path: '/verification',
    children: [
      {
        index: true,
        Component: VerificationStartPage.Component,
      },
      {
        path: '/verification/done',
        Component: VerificationDonePage.Component,
      },
      {
        path: '/verification/already-verified',
        Component: AlreadyVerifiedPage.Component,
      },
      {
        path: '*',
        element: <Navigate to='/verification' replace />,
      },
    ],
  },
];

export { VerificationPages };
