import { z } from 'zod';

import type { CookiePreference } from '../cookie-consent.types';

const CookiePersistSchema = z.object({
  acceptAll: z.boolean(),
  rejectAll: z.boolean(),
  preferences: z.object({
    functional: z.boolean(),
    analytics: z.boolean(),
    marketing: z.boolean(),
  }),
});

class CookieConsentPersist {
  public constructor(private readonly key: string) {}

  public save(preferences: Record<CookiePreference, boolean>): void {
    try {
      const payload = {
        acceptAll: Object.values(preferences).every(Boolean),
        rejectAll: Object.values(preferences).every((value) => !value),
        preferences,
      };

      localStorage.setItem(this.key, JSON.stringify(payload));
    } catch (e) {
      console.error('Failed to save cookie preferences');
    }
  }

  public load() {
    try {
      const payload = localStorage.getItem(this.key);

      if (payload === null) {
        return null;
      }

      return CookiePersistSchema.parse(JSON.parse(payload));
    } catch (e) {
      console.error('Failed to load cookie preferences');

      return null;
    }
  }
}

export { CookieConsentPersist };
