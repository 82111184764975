import { ErrorMatcher } from '@kuna-pay/core/shared/api';

class RecaptchaError extends Error {
  public constructor(message: string) {
    super(message);
  }
}

class RecaptchaRabacErrorMatcher {
  public static isInvalidRecaptchaError(error: unknown) {
    if (error instanceof RecaptchaError) {
      return true;
    }

    if (ErrorMatcher.createErrorMatcher('INTERNAL_SERVER_ERROR')(error)) {
      const message = ErrorMatcher.getApiErrorMessage(error);

      return message.includes('Incorrect passing guard "ReCaptcha"');
    }

    return false;
  }
}

export { RecaptchaError, RecaptchaRabacErrorMatcher };
