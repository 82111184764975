import type { JwtPayload } from 'jwt-decode';
import jwtDecode from 'jwt-decode';
import type { Schema } from 'zod';

class JWTService<T> {
  public static isTokenExpired(token: string) {
    try {
      const decodedToken: JwtPayload = jwtDecode(token);

      return JWTService.isJWTExpired(decodedToken);
    } catch (e) {
      return true;
    }
  }

  public static isJWTExpired(jwt: JwtPayload) {
    try {
      //Fail fast
      if (!jwt?.exp) return true;

      const jwxExpiredInMS = jwt.exp * 1000;

      return Date.now() > jwxExpiredInMS;
    } catch (e) {
      return true;
    }
  }

  public static canDecodeJWT(token: string) {
    try {
      jwtDecode(token);

      return true;
    } catch (error) {
      return false;
    }
  }

  public constructor(private readonly JWTSchema: Schema<T>) {}

  public parseAndDecodeJWT(token: string | null): T | null {
    try {
      if (!token) return null;

      const decodedToken = jwtDecode(token);

      const result = this.JWTSchema.safeParse(decodedToken);

      return result.success ? result.data : null;
    } catch (e) {
      console.error('JWTService.parseAndDecodeJWT', e);

      return null;
    }
  }
}

export { JWTService };
