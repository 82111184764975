import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';
import type { InputHTMLAttributes, TextareaHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';

import styles from './base-input.module.scss';

type UnstyledTextFieldProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  /**
   *  Types of property 'crossOrigin' are incompatible.
   *  Type 'string | undefined' is not assignable to type '"" | "anonymous" | "use-credentials" | undefined'.
   *  Type 'string' is not assignable to type '"" | "anonymous" | "use-credentials" | undefined'.
   */
  'crossOrigin'
> & {
  asChild?: boolean;

  'data-size'?: string;
  'data-has-start-adornment'?: boolean;
  'data-has-end-adornment'?: boolean;
};

const UnstyledTextField = createCompoundComponent(
  () =>
    forwardRef<HTMLInputElement, UnstyledTextFieldProps>(
      ({ className, asChild, ...props }, ref) => {
        const Component = asChild ? Slot : 'input';

        return (
          <Component
            ref={ref}
            className={clsx(styles.unstyledInput, className)}
            {...props}
          />
        );
      }
    ),
  {
    className: styles.unstyledInput,
  }
);

type BaseInputProps = UnstyledTextFieldProps;
const BaseInput = createCompoundComponent(
  () =>
    forwardRef<HTMLInputElement, BaseInputProps>(
      ({ className, ...props }, ref) => (
        <UnstyledTextField
          ref={ref}
          className={clsx(styles.input, className)}
          {...props}
        />
      )
    ),
  {
    className: clsx(styles.input, UnstyledTextField.className),
  }
);

type UnstyledTextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;
const UnstyledTextArea = createCompoundComponent(
  () =>
    forwardRef<HTMLTextAreaElement, UnstyledTextAreaProps>(
      ({ className, ...props }, ref) => (
        <textarea
          ref={ref}
          className={clsx(styles.unstyledTextarea, className)}
          {...props}
        />
      )
    ),
  {
    className: styles.unstyledTextarea,
  }
);

type BaseTextAreaProps = UnstyledTextAreaProps;
const BaseTextArea = createCompoundComponent(
  () =>
    forwardRef<HTMLTextAreaElement, BaseTextAreaProps>(
      ({ className, ...props }, ref) => (
        <UnstyledTextArea
          ref={ref}
          className={clsx(styles.textarea, className)}
          {...props}
        />
      )
    ),
  {
    className: clsx(styles.textarea, UnstyledTextArea.className),
  }
);

export type {
  BaseInputProps,
  BaseTextAreaProps,
  UnstyledTextAreaProps,
  UnstyledTextFieldProps,
};
export { BaseInput, BaseTextArea, UnstyledTextArea, UnstyledTextField };
