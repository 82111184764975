import type { Effect, Event, Store } from 'effector';
import type { AnySchema, SchemaOf } from 'yup';
import type Lazy from 'yup/lib/Lazy';

import type { MappedErrors } from './yup';

enum ValidateOnEventType {
  Blur = 0,

  Change = 1,

  Focus = 2,
}

enum ValidationVisibilityCondition {
  Submitted = 0,

  Touched = 1,

  Dirty = 2,
}

type DeepSchema<V> = {
  [P in keyof V]?: AnySchema | DeepSchema<V[P]>;
};

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

type CreateFormOptions<V> = {
  name?: string;

  initialValues: Store<V> | V;

  reinitialize?: boolean;

  $disabled?: Store<boolean>;

  $validating?: Store<boolean>;

  schema?: Store<Lazy<SchemaOf<V>>> | Store<SchemaOf<V>> | SchemaOf<V>;

  $errors?: Store<MappedErrors<V>>;

  validateOn?: ValidateOnEventType[];

  showValidationWhen?: ValidationVisibilityCondition[];
};

type FormModel<V> = {
  $dirty: Store<boolean>;

  $dirtyFields: Store<Record<string, boolean>>;

  $valid: Store<boolean>;

  $disabled: Store<boolean>;

  $values: Store<V>;

  $errors: Store<MappedErrors<V>>;

  $touched: Store<boolean>;

  $touchedFields: Store<Record<string, boolean>>;

  $focused: Store<boolean>;

  $submitted: Store<boolean>;

  $submitCount: Store<number>;

  put: Event<V>;

  patch: Event<DeepPartial<V>>;

  /**
   * @deprecated use `reinit` instead
   * @see reinit
   *
   * Bcs `reset` shouldn't have any arguments
   */
  reset: Event<V | void>;

  /**
   * Real reset form
   */
  reinit: Event<void>;

  submit: Event<void>;

  changed: Event<[string, any]>;

  validate: Event<void>;

  blured: Event<[string]>;

  focused: Event<[string]>;

  rejected: Event<{ values: V; errors: MappedErrors<V> }>;

  submitted: Event<V>;

  $initialValues: Store<V>;

  fields: Fields<V>;

  __: {
    lowLevelAPI: {
      $$error: {
        setErrors: Event<MappedErrors<V>>;
      };
      readValuesFx: Effect<void, V>;
    };
  };
};

type FieldModel<V> = {
  $value: Store<V>;
  $focused: Store<boolean>;
  $errors: Store<string[]>;
  $dirty: Store<boolean>;
  $touched: Store<boolean>;

  changed: Event<V>;
  path: string;
  change: Event<V>;
  blured: Event<void>;
  focused: Event<void>;

  // also hidden field __formMeta__
  // also hidden field __meta__
};

type FieldMeta = {
  path: string;
};

type FormMeta = Pick<
  CreateFormOptions<any>,
  'validateOn' | 'showValidationWhen'
> & {
  $submitted: Store<boolean>;

  $values: Store<Record<string, any>>;

  $dirty: Store<Record<string, boolean>>;

  $touched: Store<Record<string, boolean>>;

  $errors: Store<MappedErrors<any>>;

  $disabled: Store<boolean>;

  $focusedField: Store<string>;

  change: Event<[string, any]>;

  focused: Event<[string]>;

  blured: Event<[string]>;
};

type Fields<V> = {
  [P in keyof V]: V[P] extends object
    ? FieldModel<V[P]> & Fields<V[P]>
    : V[P] extends any[]
    ? FieldModel<V[P]> & {
        [x: number]: FieldModel<V[P][number]>;
      }
    : FieldModel<V[P]>;
};

export type {
  CreateFormOptions,
  DeepPartial,
  DeepSchema,
  FieldMeta,
  FieldModel,
  Fields,
  FormMeta,
  FormModel,
};
export { ValidateOnEventType, ValidationVisibilityCondition };
