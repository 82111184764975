import { I18nProvider } from './i18n';
import { DataGridModel } from './model';
import { DataGrid as View } from './ui';
import {
  DataGridActionCell,
  DataGridActionCellButton,
  DataGridBodyLoadingCell,
  DataGridCell,
  DataGridCellText,
  DataGridError,
  DataGridNotFound,
  TableHeadCell,
  TableHeadCellText,
  TableHeadColumnSort,
} from './ui';

const DataGrid = Object.assign(View, {
  factory: DataGridModel,
  Cell: DataGridCell,
  CellText: DataGridCellText,
  ActionCell: DataGridActionCell,
  ActionCellButton: DataGridActionCellButton,
  NotFound: DataGridNotFound,
  Error: DataGridError,
  LoadingCell: DataGridBodyLoadingCell,
  I18nProvider,

  HeadCell: TableHeadCell,
  HeadCellText: TableHeadCellText,
  HeadCellSort: TableHeadColumnSort,
});

export { DataGrid };
export type { GridModelValue } from './lib';
export type {
  AdvancedSearchFilter,
  BasicSearchFilter,
  Filter,
  GetDataGridDataParam,
  SearchFilter,
} from './model';
export { createHasDataFlag, DataGridEditorModel } from './model';
export type {
  GridActionColumn,
  GridColumn,
  GridCustomActionColumnProps,
  GridCustomHeadColumnProps,
  GridCustomValueColumnProps,
  GridValueColumn,
} from './types';
export { SortOrder } from './types';
export type { DataGridProps, TableCellProps } from './ui';
export {
  DataGridActionCell,
  DataGridActionCellButton,
  DataGridCell,
  DataGridCellText,
  EditGridDataControls,
} from './ui';
