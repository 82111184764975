import { FormResetButton, FormSubmitButton } from './button';
import { FormError } from './error';
import { Form as _Form } from './form';
import { FormPromptOnLeave } from './prompt';

const Form = Object.assign(_Form, {
  Submit: FormSubmitButton,
  Reset: FormResetButton,
  Error: FormError,
  PromptOnLeave: FormPromptOnLeave,
});

export { Form };
export type { FormProps } from './form';
