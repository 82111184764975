import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';
import type { HTMLAttributes, MouseEvent, ReactNode } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import { useHeaderMenuDropdownContext } from './dropdown.context';
import styles from './dropdown-item.module.scss';

type HeaderMenuDropdownItemProps = HTMLAttributes<HTMLDivElement> & {
  start?: ReactNode;
  end?: ReactNode;

  iconSize?: '20' | '24';
};

const HeaderMenuDropdownItem = createCompoundComponent(
  (Components) =>
    ({
      start,
      end,
      children,
      iconSize,
      ...props
    }: HeaderMenuDropdownItemProps) =>
      (
        <Components.Root {...props}>
          {start && <Components.Icon size={iconSize}>{start}</Components.Icon>}

          <Components.Text>{children}</Components.Text>

          {end && <Components.Icon size={iconSize}>{end}</Components.Icon>}
        </Components.Root>
      ),
  {
    Root: ({
      className,
      asChild,
      onClick,
      ...props
    }: HTMLAttributes<HTMLDivElement> & { asChild?: boolean }) => {
      const { setIsOpen } = useHeaderMenuDropdownContext();

      const Component = asChild ? Slot : 'div';

      return (
        <Component
          className={clsx(styles.dropdownItem, className)}
          onClick={(event) => {
            onClick?.(event as MouseEvent<HTMLDivElement>);
            setIsOpen(false);
          }}
          {...props}
        />
      );
    },
    Text: (props: Partial<TypographyProps>) => (
      <Typography variant='subtitle5' {...props} />
    ),
    Icon: ({
      className,
      size,
      ...props
    }: HTMLAttributes<HTMLDivElement> & { size?: '20' | '24' }) => (
      <div
        className={clsx(styles.dropdownItemIcon, className, {
          [styles.sm]: size === '20',
        })}
        {...props}
      />
    ),
  }
);

export { HeaderMenuDropdownItem };
