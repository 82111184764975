import { useUnit } from 'effector-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@kuna-pay/ui/ui/button';

import { $$pushNotifications } from '../../model';

type ReadAllPushNotificationsProps = {
  read: () => void;
};

const ReadAllPushNotifications = memo(
  ({ read }: ReadAllPushNotificationsProps) => {
    const { t } = useTranslation();

    const [data] = useUnit([
      $$pushNotifications.$$unreadNotificationCounter.$data,
    ]);

    return (
      <Button
        variant='text'
        color='primary'
        size='lg'
        onClick={read}
        disabled={data ? data.count === 0 : true}
      >
        {t('entities.push-notification.read-all')}
      </Button>
    );
  }
);

export { ReadAllPushNotifications };
