import { Suspense } from 'react';
import type { RouteObject } from 'react-router-dom';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';

import {
  MainLayout,
  MainLayoutLoading,
} from '@kuna-pay/merchant/widgets/layout';

const MembersPage = lazyWithRetryAndRefresh(
  'MembersPage',
  () => import('./page.component')
);

const MembersPages: RouteObject[] = [
  {
    path: '/members',
    children: [
      {
        index: true,
        Component: () => (
          <Suspense
            fallback={
              <MainLayout.Merchant.Content>
                <MainLayoutLoading />
              </MainLayout.Merchant.Content>
            }
          >
            <MembersPage />
          </Suspense>
        ),
      },
    ],
  },
];

export { MembersPages };
