import type { RouteObject } from 'react-router-dom';

import { MerchantListPage } from '@kuna-pay/merchant/pages/merchant/list';

import { CreateMerchantPage } from './create';
import { CreateMerchantSuccessPage } from './created';

const MerchantPages: RouteObject[] = [
  {
    path: '/',
    Component: MerchantListPage.Component,
  },
  {
    path: '/merchant/create',
    Component: CreateMerchantPage.Component,
  },
  {
    path: '/merchant/created',
    Component: CreateMerchantSuccessPage.Component,
  },
];

export { MerchantPages };
