import { Cookie } from '@kuna-pay/utils/cookie';

class GoogleAnalyticsUserIdentity {
  /**
   * Method to sync user id to cookie
   * So that we can add custom logic in GTM/GA depending on the user id
   *
   * @note Should not clear the cookie if user logs out
   */
  public static syncUserIdToCookie(userId: string | null): void {
    if (userId) {
      Cookie.setCookie({
        name: 'user_id',
        value: userId,
        minutes: 60 * 24 * 365, // For a year
      });
    }
  }
}

export { GoogleAnalyticsUserIdentity };
