import { z } from 'zod';

const ASSET_WS_EVENTS = {
  rates: {
    channel: 'rates',

    contract: z.object({
      data: z.array(
        z.object({
          currency: z.string(),
          equivalent: z.array(
            z.object({
              amount: z.string(),
              currency: z.string(),
            })
          ),
        })
      ),
    }),
  },
};

export { ASSET_WS_EVENTS };
