import type { Effect, Store } from 'effector';

import { modelFactory } from '@kuna-pay/utils/effector';
import type { IAtomicRRRoute } from '@kuna-pay/ui/router';

import { TimeoutedResend } from '../../../../features/auth/abstract/timeouted-resend';
import { ConfirmEmail } from '../../../../features/auth/email/confirm';

const ConfirmEmailRRPageModel = modelFactory(
  (config: {
    $isEmailConfirmed: Store<boolean>;
    redirectToOnConfirm: IAtomicRRRoute;

    confirmEmailFx: Effect<{ otp: string }, void>;
    resendFx: Effect<void, void>;

    $email: Store<string | null>;
  }) => {
    const $email = config.$email;

    const $$confirm = ConfirmEmail.factory.createModel({
      confirmFx: config.confirmEmailFx,
    });

    const $$resend = TimeoutedResend.factory.createModel({
      resendFx: config.resendFx,
      timeoutInSeconds: 60,
    });

    return {
      done: $$confirm.done,
      reset: $$confirm.reset,

      $$ui: {
        $email,
        $isEmailConfirmed: config.$isEmailConfirmed,
        redirectToOnConfirm: config.redirectToOnConfirm,
        $$confirm,
        $$resend,
      },

      __: {},
    };
  }
);

export { ConfirmEmailRRPageModel };
