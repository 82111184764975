import { modelFactory } from '@kuna-pay/utils/effector';
import { createSingleModal } from '@kuna-pay/ui/ui/modal';

import { $$liveChat } from './live-chat';

const ContactSupportModel = modelFactory(() => {
  const $$modal = createSingleModal();

  return {
    $$modal,

    $$ui: {
      $$modal,
      $$liveChat,
    },
  };
});

export { ContactSupportModel };
