import clsx from 'clsx';
import type { ComponentProps, HTMLAttributes, PropsWithChildren } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { Skeleton } from '@kuna-pay/ui/ui/skeleton';

import styles from './merchant-layout.module.scss';

const MerchantLayout = createCompoundComponent(
  () =>
    ({ children }: PropsWithChildren) =>
      <div className={styles.container}>{children}</div>,
  {
    Content: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.content, className)} {...props} />
    ),
  }
);

const MainLayoutLoading = (props: ComponentProps<typeof Skeleton>) => (
  <Skeleton flex fullWidth {...props} />
);

export { MainLayoutLoading, MerchantLayout };
