import { useLayoutEffect, useMemo, useRef, useState } from 'react';

import { DataGridConfig } from '../config';
import type { GridColumn } from '../types';

const useColumnWidth = <Row extends Record<string, unknown>>(
  columns: GridColumn<Row>[]
) => {
  const [width, setWidth] = useState(1000);
  const containerRef = useRef<HTMLDivElement>(null);

  const { nonFixedWidth, fractions } = useMemo(
    () =>
      columns.reduce(
        (acc, field) => {
          if ('width' in field) {
            acc.nonFixedWidth += field.width;
          } else {
            acc.fractions += field.flex;
          }

          return acc;
        },
        { nonFixedWidth: 0, fractions: 0 }
      ),
    [columns]
  );

  const gridTemplateColumns = useMemo(() => {
    let responsiveWidth = width - nonFixedWidth;
    let responsiveColumnsCount = fractions;

    return columns
      .map((column) => {
        if ('width' in column) {
          return `${column.width}px`;
        }

        // need to calculate here
        // bcs dynamic width depends on other columns
        const fractionSize = Math.max(
          responsiveWidth / responsiveColumnsCount,
          0
        );

        const minWidth = Math.max(
          column.minWidth ?? DataGridConfig.DEFAULT_COLUMN_MIN_WIDTH,
          DataGridConfig.DEFAULT_COLUMN_MIN_WIDTH,
          fractionSize * column.flex
        );

        const columnWidth = column.maxWidth
          ? Math.min(column.maxWidth, minWidth)
          : minWidth;

        // If column width is equal to max or min width, then we should
        // subtract it from responsive width and columns count, because
        // column became "fixed"

        //FIXME: if last non-fixed column hit maxWidth, width is not spreed between other columns
        if (columnWidth >= column.maxWidth! || columnWidth <= column.minWidth) {
          responsiveWidth -= columnWidth;
          responsiveColumnsCount -= column.flex;
        }

        return `${columnWidth}px`;
      })
      .join(' ');
  }, [columns, nonFixedWidth, fractions, width]);

  useLayoutEffect(() => {
    if (!containerRef.current) return;

    const { width } = containerRef.current.getBoundingClientRect();
    setWidth(width);

    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setWidth(entry.contentRect.width - DataGridConfig.ROW_PADDING * 2);
      });
    });

    observer.observe(containerRef.current);

    return () => {
      if (!containerRef.current) return;

      observer.unobserve(containerRef.current);
    };
  }, []);

  return { containerRef, gridTemplateColumns };
};

export { useColumnWidth };
