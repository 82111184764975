import type { MutableRefObject, Ref, RefCallback } from 'react';
import { useEffect, useMemo, useRef } from 'react';

const useScrollToSelectedElement = <T extends HTMLElement>(
  forwardedRef: Ref<T>,
  condition: (element: T) => boolean = () => true,
  deps: any[] = [],
  params: boolean | ScrollIntoViewOptions = { block: 'center' }
) => {
  const ref = useRef<T>(null);
  const refCallback = useForkRef(ref, forwardedRef);

  useEffect(() => {
    if (!ref.current) return;

    if (condition(ref.current)) {
      ref.current.scrollIntoView(params);
    }
  }, deps);

  return refCallback;
};

const setRef = <T>(
  ref:
    | MutableRefObject<T | null>
    | ((instance: T | null) => void)
    | null
    | undefined,
  value: T | null
) => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
};

const useForkRef = <Instance>(
  ...refs: (Ref<Instance> | undefined)[]
): RefCallback<Instance> | null =>
  /**
   * This will create a new function if the refs passed to this hook change and are all defined.
   * This means react will call the old forkRef with `null` and the new forkRef
   * with the ref. Cleanup naturally emerges from this behavior.
   */
  useMemo(() => {
    if (refs.every((ref) => ref == null)) {
      return null;
    }

    return (instance) => {
      refs.forEach((ref) => {
        setRef(ref, instance);
      });
    };
  }, refs);

export { useScrollToSelectedElement };
