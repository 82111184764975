import type { Store } from 'effector';
import { createStore } from 'effector';

/**
 * Unwrapping box(store) to access the value
 *
 */
class StoreProperty<T> {
  public static create<T>(initialValue: T) {
    return new StoreProperty(createStore(initialValue), initialValue);
  }

  public static from<T>(store: Store<T>) {
    return new StoreProperty(store, store.getState());
  }

  private constructor(
    public $store: Store<T>,
    public value: T = $store.defaultState
  ) {
    this.$store.watch((value) => {
      this.value = value;
    });
  }
}

export { StoreProperty };
