/**
 * Need to do this bcs it doesn't properly work with nullish values out of the box
 */
class LocalStorageSerializer {
  public static serialize(data: any): any {
    try {
      if (!data) return '';

      return JSON.stringify(data);
    } catch (e) {
      return '';
    }
  }

  public static deserialize(data: string | null): any {
    try {
      if (!data) return null;

      const jsValue = JSON.parse(data);

      if (!jsValue) return null;

      return JSON.parse(data);
    } catch (e) {
      return null;
    }
  }
}

export { LocalStorageSerializer };
