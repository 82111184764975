import type { TFunction } from 'i18next';
import { setLocale } from 'yup';
import type { Message } from 'yup/lib/types';

const initFormsTranslations = (t: TFunction, lng?: string | undefined) => {
  function localeT(path: string): Message {
    return function (params) {
      return t(`shared.forms.${path}`, { ns: 'core', replace: params, lng })!;
    };
  }

  setLocale({
    object: {
      noUnknown: localeT('object.no-unknown'),
    },
    string: {
      email: localeT('string.email'),
      max: localeT('string.max'),
      matches: localeT('string.matches'),
      url: localeT('string.url'),
      length: localeT('string.length'),
      lowercase: localeT('string.lowercase'),
      uppercase: localeT('string.uppercase'),
      min: localeT('string.min'),
      uuid: localeT('string.uuid'),
      trim: localeT('string.trim'),
    },
    boolean: {
      isValue: localeT('boolean.is-value'),
    },
    date: {
      min: localeT('date.min'),
      max: localeT('date.max'),
    },
    number: {
      min: localeT('number.min'),
      max: localeT('number.max'),
      integer: localeT('number.integer'),
      lessThan: localeT('number.less-than'),
      moreThan: localeT('number.more-than'),
      negative: localeT('number.negative'),
      positive: localeT('number.positive'),
    },
    mixed: {
      defined: localeT('mixed.defined'),
      default: localeT('mixed.default'),
      notType: localeT('mixed.not-type'),
      oneOf: localeT('mixed.one-of'),
      required: localeT('mixed.required'),
      notOneOf: localeT('mixed.not-one-of'),
    },
    array: {
      min: localeT('array.min'),
      max: localeT('array.max'),
      length: localeT('array.length'),
    },
  });
};

export { initFormsTranslations };
