import clsx from 'clsx';
import type { HTMLAttributes } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { ExternalLink } from '@kuna-pay/ui/router';
import type { ButtonProps } from '@kuna-pay/ui/ui/button';
import { Button } from '@kuna-pay/ui/ui/button';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { urls } from '@kuna-pay/core/shared/config';

import styles from './cookie-consent-banner.module.scss';

type CookieConsentBannerProps = {
  show?: boolean;
  onAcceptAll?: () => void;
  onManage?: () => void;
};

const CookieConsentBanner = createCompoundComponent(
  (C) =>
    ({ show, onAcceptAll, onManage }: CookieConsentBannerProps) => {
      const { t, i18n } = useTranslation('core', {
        keyPrefix: 'shared.cookie-consent.ui',
      });

      if (!show) return null;

      return (
        <C.Root>
          <C.Content>
            <C.Text>
              <Trans
                t={t}
                i18nKey='banner.description'
                components={{
                  'cookie-policy': (
                    <ExternalLink
                      className={Typography.classes.subtitle5}
                      href={urls.privacyPolicy.withLocale(i18n.language)}
                      primary
                    />
                  ),
                }}
              />
            </C.Text>

            <C.Actions>
              <C.Manage onClick={onManage}>{t('banner.manage')}</C.Manage>

              <C.AcceptAll onClick={onAcceptAll}>
                {t('banner.accept')}
              </C.AcceptAll>
            </C.Actions>
          </C.Content>
        </C.Root>
      );
    },
  {
    Root: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.root, className)} {...props} />
    ),

    Content: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.content, className)} {...props} />
    ),

    Text: ({ className, ...props }: Partial<TypographyProps>) => (
      <Typography
        className={clsx(styles.text, className)}
        variant='body3'
        {...props}
      />
    ),

    Actions: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.actions, className)} {...props} />
    ),

    AcceptAll: (props: Partial<ButtonProps>) => (
      <Button color='primary' size='lg' variant='contained' {...props} />
    ),

    Manage: (props: Partial<ButtonProps>) => (
      <Button color='secondary' size='lg' variant='contained' {...props} />
    ),

    Save: (props: Partial<ButtonProps>) => (
      <Button color='secondary' size='lg' variant='contained' {...props} />
    ),
  }
);

export { CookieConsentBanner };
export type { CookieConsentBannerProps };
