/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import * as RadixTooltip from '@radix-ui/react-tooltip';
import clsx from 'clsx';
import type { ReactElement } from 'react';
import { useMemo } from 'react';
import { cloneElement, useState } from 'react';

import { useMediaQuery } from '@kuna-pay/utils/react/use-media-query';
import { createCompoundComponent } from '@kuna-pay/utils/ui';

import type { PaperProps } from '../paper';
import { Paper } from '../paper';
import type { TooltipProps } from './tooltip.props';
import styles from '././tooltip.module.scss';

const Tooltip = createCompoundComponent(
  (Components) =>
    ({
      classes,
      className,
      side = 'bottom',
      sideOffset = 16,
      rawContent,
      children,
      content,

      open,
      onOpenChange,

      ...props
    }: Omit<TooltipProps, 'children'> & {
      children: ReactElement<{ onClick: () => void }>;
    }) => {
      const [isOpen, setOpen] = useState(false);
      const isMobileOrLess = useMediaQuery('(max-width: 580px)');

      const tooltipContentSide = useMemo(() => {
        if (!isMobileOrLess) {
          return side;
        }

        const isVerticalSide = side === 'top' || side === 'bottom';

        if (isVerticalSide) {
          return side;
        }

        return 'bottom';
      }, [side, isMobileOrLess]);

      return (
        <RadixTooltip.Root
          // Use the provided open state if it's defined
          open={typeof open === 'boolean' ? open : isOpen}
          onOpenChange={(isOpen) => {
            if (typeof open === 'boolean') {
              onOpenChange?.(isOpen);
            } else {
              setOpen(isOpen);
            }
          }}
          {...props}
        >
          <RadixTooltip.Trigger asChild>
            {cloneElement(children, {
              onClick: () => {
                /**
                 * @see KUPAY-2247
                 */
                setOpen(true);
              },
            })}
          </RadixTooltip.Trigger>

          <RadixTooltip.Portal>
            <RadixTooltip.Content
              className={clsx(className, styles.content, classes?.content)}
              side={tooltipContentSide}
              sideOffset={sideOffset}
              collisionPadding={24}
            >
              <RadixTooltip.Arrow
                className={clsx(styles.arrow, classes?.arrow)}
              />

              {!rawContent ? (
                <Components.Content className={classes?.contentWrapper}>
                  {content}
                </Components.Content>
              ) : (
                content
              )}
            </RadixTooltip.Content>
          </RadixTooltip.Portal>
        </RadixTooltip.Root>
      );
    },
  {
    Provider: RadixTooltip.Provider,

    Content: ({ className, ...props }: PaperProps) => (
      <Paper
        className={clsx(styles.contentWrapper, className)}
        shadow={false}
        rounded='lg'
        {...props}
      />
    ),
  }
);

const TooltipProvider = Tooltip.Provider;

export { Tooltip, TooltipProvider };
