type FeedbackFormInValues = {
  feedback?: string;

  attachments: unknown[];
};

type FeedbackFormOutValues = {
  feedback?: string;

  attachments: File[];
};

enum GenericFeedbackSteps {
  Feedback = 'feedback',
  Success = 'success',
}

type GenericFeedbackModelProps = {
  sendFeedbackFx: (values: FeedbackFormOutValues) => Promise<void>;

  MAX_ATTACHMENTS?: number;
  MAX_DESCRIPTION_LENGTH?: number;
};

export type {
  FeedbackFormInValues,
  FeedbackFormOutValues,
  GenericFeedbackModelProps,
};
export { GenericFeedbackSteps };
