import { getState } from '@kuna-pay/utils/effector';
import { objectValues } from '@kuna-pay/utils/typescript';
import { RateYourExperienceManager } from '@kuna-pay/core/shared/feedback/features/rate-your-experience';

import { $$session } from '@kuna-pay/merchant/entities/session';
import { publicRateUX } from '@kuna-pay/merchant/shared/api/generated/Feedback/request/publicRateUX';
import { LocalStoragePersistKeys } from '@kuna-pay/merchant/shared/persist';

enum RateYourExperienceFeedbackKey {
  InvoiceCreation = 'invoice-creation',
  ReportDownload = 'report-download',
  Withdrawal = 'withdrawal',
  MassPayoutCreation = 'mass-payout-creation',
}

const $$rateYourExperienceFeedback =
  RateYourExperienceManager.factory.createModel<RateYourExperienceFeedbackKey>({
    keys: objectValues(RateYourExperienceFeedbackKey),

    sendFeedbackFx: async (feature, values) => {
      const userEmail = await getState($$session.$$jwt.$$user.$email);
      const companyId = await getState($$session.$$jwt.$$company.$id);

      await publicRateUX({ success: true })({
        feature: feature,
        rating: values.rate,
        feedback: values.feedback,

        meta: { companyId, userEmail },
      });
    },

    PERSIST_KEY: LocalStoragePersistKeys.RateYourExperienceFeedback,
  });

export { $$rateYourExperienceFeedback, RateYourExperienceFeedbackKey };
