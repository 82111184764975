import clsx from 'clsx';
import type { FC, HTMLAttributes } from 'react';

import styles from './divider.module.scss';

type DividerProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * @note For vertical orientation, you need to set the height explicitly
   */
  orientation?: 'horizontal' | 'vertical';
};

const Divider: FC<DividerProps> = ({
  className,
  orientation = 'horizontal',
  ...props
}) => (
  <div
    className={clsx(styles.divider, className, {
      [styles.horizontal]: orientation === 'horizontal',
      [styles.vertical]: orientation === 'vertical',
    })}
    {...props}
  />
);

export { Divider };
