import { useTranslation } from 'react-i18next';

import { modelView } from '@kuna-pay/utils/effector';
import type { VerifyOTPFormProps } from '@kuna-pay/core/features/auth/abstract/verify-one-time-code';
import { VerifyOneTimeCode } from '@kuna-pay/core/features/auth/abstract/verify-one-time-code';
import { useTypedGate } from '@kuna-pay/core/shared/router';

import { VerifyOTPModel } from './verify-2fa.model';

type Verify2FAFormProps = Omit<VerifyOTPFormProps, 'name'>;

const Verify2FAForm = modelView(VerifyOTPModel, (props: Verify2FAFormProps) => {
  const $$model = VerifyOTPModel.useModel();
  useTypedGate({
    Gate: $$model.Gate,
    props: { i18n: useTranslation('core') },
  });

  return (
    <VerifyOneTimeCode.Form
      name='verify-otp-form'
      $$model={$$model.$$form}
      {...props}
    />
  );
});

export { Verify2FAForm };
