import clsx from 'clsx';
import type { Key } from 'react';
import type { FC, ReactNode } from 'react';

import { DataGridEditorModel, RedirectModel } from '../model';
import { DataGridModel, useColumnWidth } from '../model';
import type { GridColumn, InsertConfig } from '../types';
import { TableBody } from './body';
import type { TableRowProps } from './data';
import { TableHead } from './head';
import styles from './table.module.scss';

type DataGridProps<T extends Record<string, unknown>> = WithCustomRowId<T> & {
  columns: GridColumn<T>[];

  $$model: ReturnType<typeof DataGridModel.createModel<T>>;

  $$editor?: ReturnType<typeof DataGridEditorModel.createModel>;

  className?: string;

  noHeader?: boolean;

  insert?: InsertConfig<T>;

  // TODO: replace with custom render later;
  addRowProps?: (row: T) => Partial<TableRowProps>;

  /**
   * Custom component to render when no data is found
   */
  notFound?: ReactNode | FC;

  /**
   * Custom component to render when getDataFx fails
   */
  error?: ReactNode | FC;

  classNames?: {
    head?: string;
    row?: string;

    loadingSkeletonContainer?: string;
    loadingSkeleton?: string;
  };

  loading?: ReactNode;
};

/**
 * User should specify getRowId if `entity` has no `id` field
 */
type WithCustomRowId<T extends Record<string, unknown>> = T extends {
  id: string;
}
  ? { getRowId?: (row: T) => Key }
  : { getRowId: (row: T) => Key };

const DataGrid = <T extends Record<string, unknown>>({
  columns,
  getRowId = defaultGetRowId,
  $$model,
  $$editor,
  className,
  addRowProps,
  notFound,
  noHeader,
  insert,
  classNames,
  error,
  loading,
}: DataGridProps<T>) => {
  const { gridTemplateColumns, containerRef } = useColumnWidth(columns);

  return (
    <DataGridModel.Provider<T> value={$$model.$$ui}>
      <DataGridEditorModel.Provider
        // Optional editor context. Gonna drop error if editable columns used without editor
        value={$$editor?.$$ui ?? null!}
      >
        <RedirectModel.Provider
          value={$$model.$$ui.$$row.$$redirect.$$ui as never}
        >
          <div className={clsx(styles.wrapper, className)}>
            <div className={clsx(styles.root)} ref={containerRef}>
              <div className={styles.table} role='table'>
                {!noHeader && (
                  <TableHead
                    className={classNames?.head}
                    columns={columns}
                    gridTemplateColumns={gridTemplateColumns}
                  />
                )}

                <TableBody
                  columns={columns}
                  insert={insert}
                  addRowProps={addRowProps}
                  classNames={classNames}
                  gridTemplateColumns={gridTemplateColumns}
                  getRowId={getRowId}
                  customNotFound={notFound}
                  customError={error}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </RedirectModel.Provider>
      </DataGridEditorModel.Provider>
    </DataGridModel.Provider>
  );
};

function defaultGetRowId<T extends Record<string, unknown>>(row: T) {
  return `${row.id}`;
}

export { DataGrid };
export type { DataGridProps };
