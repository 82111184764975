import { CompanyDetailsOutput } from '@kuna-pay/merchant/generated/graphql';
import { subscription, query } from '@kuna-pay/merchant/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const findCompanyDetailsRequest = (
  select: Select<CompanyDetailsOutput> | string
) => `query findCompanyDetails {
  data: findCompanyDetails {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'CompanyDetailsOutput')}`;
export const findCompanyDetails =
  (select: Select<CompanyDetailsOutput> | string) => () =>
    query<CompanyDetailsOutput>(findCompanyDetailsRequest(select));
