import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import CompanyFeesRootPage from './page.component';
import { CompanyFeesLayoutTabs } from './page.config';
import { ConvertCompanyFeesPage } from './pages/convert';
import { DepositCompanyFeesPage } from './pages/deposit';
import { InvoiceDepositCompanyFeesPage } from './pages/invoice-deposit';
import { PayoutCompanyFeesPage } from './pages/payout';
import { WithdrawalCompanyFeesPage } from './pages/withdrawal';

const CompanyFeesPages: RouteObject[] = [
  {
    path: '/company-fees/*',
    Component: CompanyFeesRootPage,
    children: [
      {
        path: CompanyFeesLayoutTabs.InvoiceDeposit,
        Component: InvoiceDepositCompanyFeesPage.Component,
      },
      {
        path: CompanyFeesLayoutTabs.Withdrawal,
        Component: WithdrawalCompanyFeesPage.Component,
      },
      {
        path: CompanyFeesLayoutTabs.Payout,
        Component: PayoutCompanyFeesPage.Component,
      },
      {
        path: CompanyFeesLayoutTabs.Deposit,
        Component: DepositCompanyFeesPage.Component,
      },
      {
        path: CompanyFeesLayoutTabs.Convert,
        Component: ConvertCompanyFeesPage.Component,
      },
      {
        path: '*',
        element: <Navigate to={CompanyFeesLayoutTabs.InvoiceDeposit} replace />,
      },
    ],
  },
];

export { CompanyFeesPages };
