import type { IAuthService } from '@kuna/graphql-client/dist/transport/AuthHttpTransport';

import { APIResponseCode, APIResponseStatusCode } from '../http.config';

type IKunaPayAuthService = Pick<
  IAuthService,
  'refresh' | 'logout' | 'addHeaders'
>;

class NoOpKunaPayAuthService implements IKunaPayAuthService {
  public addHeaders(_headers: Record<string, unknown>) {}

  public refresh() {
    return Promise.resolve();
  }

  public logout() {
    return Promise.resolve();
  }
}

type AuthServiceFactoryConfig = {
  middlewares: IKunaPayAuthService[];
};

class KunaPayAuthServiceFactory {
  public static createAuthService(
    config: AuthServiceFactoryConfig
  ): IAuthService {
    return {
      refresh: async () => {
        await Promise.all(
          config.middlewares.map((middleware) => middleware.refresh())
        );
      },
      logout: async () => {
        await Promise.all(
          config.middlewares.map((middleware) => middleware.logout())
        );
      },
      addHeaders: (headers) => {
        config.middlewares.forEach((middleware) => {
          middleware.addHeaders(headers);
        });
      },

      isUnauthorized: (request: unknown) => {
        if (!Array.isArray(request)) return false;

        return request.some((item) => {
          const statusCode =
            item?.errors?.[0]?.extensions?.exception?.response?.statusCode;

          if (statusCode === APIResponseStatusCode.Unauthorized) {
            return true;
          }

          if (!item.errors) {
            return false;
          }

          return item.errors[0]?.code === APIResponseCode.Unauthorized;
        });
      },
      isAuthRequest: (request) => {
        if (!Array.isArray(request)) return false;

        if (request.length === 0) return false;

        return !request[0].variables?.__noAuth;
      },
    };
  }
}

export { KunaPayAuthServiceFactory, NoOpKunaPayAuthService };
export type { IKunaPayAuthService };
