type IUseCase<TArgs, TData> = {
  execute: (args: TArgs) => Promise<TData>;
};

type TakeFirstUseCaseConfig<TArgs, TData> = {
  request: (args: TArgs) => Promise<TData>;
};

class TakeFirstUseCase<TArgs, TData> implements IUseCase<TArgs, TData> {
  public constructor(
    private readonly config: TakeFirstUseCaseConfig<TArgs, TData>
  ) {}

  private runningPromise: Promise<TData> | null = null;

  public async execute(args: TArgs): Promise<TData> {
    return this.takeFirst(() => this.config.request(args));
  }

  private async takeFirst(query: () => Promise<TData>) {
    if (this.runningPromise) {
      return this.runningPromise;
    }

    this.runningPromise = query().then(
      (result) => {
        this.runningPromise = null;

        return result;
      },
      (error) => {
        this.runningPromise = null;

        throw error;
      }
    );

    return this.runningPromise;
  }
}

export { TakeFirstUseCase };
export type { IUseCase, TakeFirstUseCaseConfig };
