import type { ComponentType, LazyExoticComponent } from 'react';
import { lazy } from 'react';
import { z } from 'zod';

import { createDynamicImportWithRetry } from './retry-dynamic-import';

/**
 *  @see https://kunatech.atlassian.net/browse/KUPAY-1166
 */
const createLazyWithRetryAndRefresh = (options: {
  persistName: string;
  storage: Storage;
  retryTimes?: number;
  logger?: (...args: any[]) => void;
}) => {
  const { retryTimes = 5, ...ops } = options;

  const dynamicImportWithRetry = createDynamicImportWithRetry(retryTimes, ops);

  const storage = new LazyWithRetryStorage(
    options.persistName,
    options.storage
  );

  const nameValidator = new LazyWithRetryUniqueNameValidator();

  const fn = <T extends ComponentType<any>>(
    name: string,
    factory: () => Promise<{ default: T }>
  ): LazyExoticComponent<T> => {
    if (import.meta.env.DEV) {
      nameValidator.validate(name);
    }

    return lazy(async () => {
      const hasAlreadyBeenForceRefreshedForComponent =
        storage.getValueFor(name);

      try {
        /**
         * Should retry to import component
         * with cache bust
         * if there is an error
         * bcs it could mean that browser cached broken chunk
         * (bcs it's expected to cache errors by spec)
         *
         * @see https://stackoverflow.com/a/76200536
         */
        const component = await dynamicImportWithRetry(factory);

        storage.setValue(name, false);

        return component;
      } catch (error) {
        if (hasAlreadyBeenForceRefreshedForComponent) {
          console.error(`Component load failed. Component: ${name}`);

          throw error;
        }

        console.error(
          `Component load failed, forcing page reload`,
          name,
          error
        );

        storage.setValue(name, true);
        window.location.reload();

        return Promise.resolve({ default: (() => null) as unknown as T }); // to satisfy the type checker
      }
    });
  };

  return fn;
};

class LazyWithRetryUniqueNameValidator {
  private readonly registeredNames: Record<string, unknown> = {};

  public validate = (name: string): void => {
    if (this.registeredNames[name]) {
      const message = `[LazyWithRetryUniqueNameValidator] Unique name violation for: ${name}. Please use unique names for lazy components.`;

      console.error(message);
    }

    this.registeredNames[name] = true;
  };
}

class LazyWithRetryStorage {
  private static readonly BANNED_NAMES = [
    {
      key: 'page-has-been-force-refreshed',
      reason: `[LazyWithRetryStorage] Key "page-has-been-force-refreshed" was used to force refresh the page, so it cannot be used as a component name. Please use a different name or provide migration`,
    },
  ];

  public constructor(
    private readonly persistKey: string,
    private readonly storage: Storage
  ) {
    if (import.meta.env.DEV) {
      LazyWithRetryStorage.BANNED_NAMES.forEach((item) => {
        if (this.persistKey === item.key) {
          console.error(item.reason);
        }
      });
    }
  }

  private readonly ForceRefreshedForComponentMapSchema = z.record(
    z.coerce.boolean()
  );

  public getValue = (): Record<string, boolean> => {
    try {
      /**
       * Should return `{}` if there is no such key
       * bcs it would mean that we haven't refreshed the page yet
       */
      const componentsMap: unknown = JSON.parse(
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        this.storage.getItem(this.persistKey) || '{}'
      );

      return this.ForceRefreshedForComponentMapSchema.parse(componentsMap);
    } catch (e) {
      return {};
    }
  };

  public getValueFor = (key: string): boolean => {
    try {
      /**
       * Should return `{}` if there is no such key
       * bcs it would mean that we haven't refreshed the page yet
       */
      const componentsMap: unknown = JSON.parse(
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        this.storage.getItem(this.persistKey) || '{}'
      );

      const result =
        this.ForceRefreshedForComponentMapSchema.parse(componentsMap);

      return !!result[key];
    } catch (e) {
      /**
       * Need to return `true` if there is an error
       *
       *   bcs it could mean that storage is not available for example in incognito mode,
       *   or value in storage is broken,
       *
       *   so we don't force refresh page and don't get into infinite loop
       */
      return true;
    }
  };

  public setValue = (key: string, value: true | false) => {
    try {
      const current = this.getValue();

      this.storage.setItem(
        this.persistKey,
        JSON.stringify({ ...current, [key]: !!value })
      );
    } catch (e) {
      // pass
    }
  };
}

export { createLazyWithRetryAndRefresh };
