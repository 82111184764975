import type { RouteObject } from 'react-router-dom';

import { UserBlockedPage } from './blocked';
import { UserMigrationPage } from './migration';
import { ResetPasswordPages } from './reset-password';
import { SignInPage } from './sign-in';
import { SignUpPage } from './sign-up';
import { UserSSOPage } from './sso';

const AuthPages: RouteObject[] = [
  {
    path: '/sign-up',
    Component: SignUpPage.Component,
  },

  {
    path: '/sign-in',
    Component: SignInPage.Component,
  },

  ...ResetPasswordPages,

  {
    path: '/blocked',
    Component: UserBlockedPage.Component,
  },

  {
    path: '/migration',
    Component: UserMigrationPage.Component,
  },

  {
    path: '/sso',
    Component: UserSSOPage.Component,
  },
];

export { AuthPages };
