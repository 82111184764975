import clsx from 'clsx';
import type { RefObject } from 'react';
import {
  type ElementRef,
  forwardRef,
  memo,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { PaperProps } from '@kuna-pay/ui/ui/paper';
import { Paper } from '@kuna-pay/ui/ui/paper';
import { Skeleton } from '@kuna-pay/ui/ui/skeleton';
import type { SkeletonProps } from '@kuna-pay/ui/ui/skeleton/skeleton';

import styles from './adaptive-paper.module.scss';

type AdaptivePaperProps = PaperProps & {
  scrollIntoView?: boolean;
};

const AdaptivePaper = createCompoundComponent(
  () =>
    forwardRef<HTMLDivElement, AdaptivePaperProps>(
      ({ className, scrollIntoView, ...props }, forwardedRef) => {
        const containerRef = useRef<ElementRef<'div'>>(null);
        const ref = (forwardedRef ?? containerRef) as RefObject<HTMLDivElement>;

        useEffect(() => {
          if (!ref.current) return;

          if (scrollIntoView) {
            ref.current.scrollIntoView({
              block: 'start',
              inline: 'start',
              behavior: 'smooth',
            });
          }
        }, []);

        return (
          <Paper
            ref={ref}
            className={clsx(className, styles.root)}
            {...props}
          />
        );
      }
    ),
  {
    Loading: memo(({ height = 638 }: Pick<SkeletonProps, 'height'>) => {
      const style = useMemo(
        (): Record<string, number | string> => ({
          '--skeleton--desktop-height':
            typeof height === 'number' ? `${height}px` : height,
        }),
        [height]
      );

      return (
        <Skeleton
          containerClassName={styles.skeletonRoot}
          className={styles.skeleton}
          style={style}
        />
      );
    }),
  }
);

export type { AdaptivePaperProps };
export { AdaptivePaper };
