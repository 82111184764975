import clsx from 'clsx';
import { useUnit } from 'effector-react/effector-react.mjs';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '@kuna-pay/utils/react/use-media-query';
import { BaseButton } from '@kuna-pay/ui/ui/button';
import { Modal } from '@kuna-pay/ui/ui/modal';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { Form, useField } from '@kuna-pay/form';

import { RateYourExperienceModel } from './rate-your-experience.model';
import styles from './rate-form.module.scss';

type RateFormProps = {
  i18n: {
    title: string;
  };
};

const RateForm = memo(({ i18n }: RateFormProps) => {
  const $$model = RateYourExperienceModel.useModel();
  const { t } = useTranslation('core');
  const isMobileOrLess = useMediaQuery('(max-width: 580px)');

  const { value, onChange } = useField($$model.$$rateForm.fields.rate);
  const [submit] = useUnit([$$model.$$rateForm.submit]);

  return (
    <>
      <Modal.Title
        className={styles.title}
        variant='subtitle2'
        center={isMobileOrLess}
      >
        {i18n.title}
      </Modal.Title>

      <Modal.Description
        className={styles.description}
        variant='body3'
        center={isMobileOrLess}
      >
        {t('shared.feedback.rate-your-experience.rate.description')}
      </Modal.Description>

      <Form
        className={styles.form}
        name='rate-your-experience-rate-form'
        use={$$model.$$feedbackForm}
      >
        <BaseButton
          className={clsx(styles.rateButton, {
            [styles.rateButtonSelected]: value === 'good',
          })}
          onClick={() => {
            onChange('good');
            submit();
          }}
          fullWidth
        >
          <Typography variant='subtitle5'>
            {t('shared.feedback.rate-your-experience.rate.like')}
          </Typography>
        </BaseButton>

        <BaseButton
          className={clsx(styles.rateButton, {
            [styles.rateButtonSelected]: value === 'bad',
          })}
          color='primary'
          onClick={() => {
            onChange('bad');
            submit();
          }}
          fullWidth
        >
          <Typography variant='subtitle5'>
            {t('shared.feedback.rate-your-experience.rate.dislike')}
          </Typography>
        </BaseButton>
      </Form>
    </>
  );
});

export { RateForm };
