import type { FC, PropsWithChildren, ReactNode } from 'react';
import React, { cloneElement } from 'react';

type BaseCanAccessProps = PropsWithChildren & {
  placeholder?: ReactNode;

  behavior?: 'hide' | 'disable';
};

type CanAccessProps = BaseCanAccessProps & {
  can: boolean;
};

const BaseCanAccess: FC<CanAccessProps> = ({
  can,
  placeholder,
  behavior,
  children,
}) => {
  if (can) return <>{children}</>;

  if (behavior === 'disable') {
    const isValid = React.isValidElement<{ disabled: boolean }>(children);

    if (!isValid) {
      console.warn(
        'Behavior chosen as `disable`, but children is not valid element',
        { children }
      );
    }

    return (
      <>{isValid ? cloneElement(children, { disabled: true }) : placeholder}</>
    );
  }

  return <>{placeholder}</>;
};

export { BaseCanAccess };
export type { BaseCanAccessProps };
