import clsx from 'clsx';
import type {
  AnchorHTMLAttributes,
  ComponentProps,
  ForwardedRef,
  ForwardRefExoticComponent,
  RefAttributes,
} from 'react';
import { forwardRef } from 'react';
import {
  Link as RRLink,
  Navigate as RRNavigate,
  NavLink as RRNavLink,
} from 'react-router-dom';

import type { IAtomicRRRoute } from './lib';
import styles from './link.module.scss';

type RRLinkProps<To extends Record<string, unknown> = {}> = Omit<
  ComponentProps<typeof RRLink>,
  'to'
> &
  (
    | {
        to: IAtomicRRRoute<To>;
        params?: To;
      }
    | {
        to: string;
        params?: To;
      }
  );

type RRNavLinkProps<To extends Record<string, unknown> = {}> = Omit<
  ComponentProps<typeof RRNavLink>,
  'to'
> &
  (
    | {
        to: IAtomicRRRoute<To>;
        params?: To;
      }
    | {
        to: string;
        params?: To;
      }
  );

type SharedLinkProps = {
  variant?: 'icon' | 'text';
  primary?: boolean;
};

type LinkProps<To extends Record<string, unknown> = {}> = RRLinkProps<To> &
  SharedLinkProps;

type RRNavigateProps = Omit<React.ComponentProps<typeof RRNavigate>, 'to'> & {
  to: IAtomicRRRoute;
};

const NavLink = forwardRef(
  <To extends Record<string, unknown> = {}>(
    { to, params, ...props }: RRNavLinkProps<To>,
    ref: ForwardedRef<HTMLAnchorElement>
  ) => {
    const path = typeof to === 'string' ? to : to.clone(params).toNavigate();

    return <RRNavLink ref={ref} to={path} {...props} />;
  }
);

const Navigate = ({ to, ...props }: RRNavigateProps) => {
  const path = to.toNavigate();

  return <RRNavigate to={path} {...props} />;
};

const Link = Object.assign(
  <To extends Record<string, unknown> = {}>({
    className,
    variant = 'text',
    primary,
    to,
    params,
    ...props
  }: LinkProps<To>) => {
    const path = typeof to === 'string' ? to : to.clone(params).toNavigate();

    const Component = RRLink as ForwardRefExoticComponent<
      RRLinkProps<To> & RefAttributes<HTMLAnchorElement>
    >;

    return (
      <Component
        className={clsx(
          styles.link,
          styles[variant],
          { [styles.primary]: primary },
          className
        )}
        to={path}
        {...props}
      />
    );
  },
  {
    className: (variant: SharedLinkProps['variant'] = 'text') =>
      clsx(styles.link, styles[variant]),
    /**
     * Cannot replace base impl with this one because of generic:
     */
    ForwardRef: forwardRef(
      (
        {
          className,
          variant = 'text',
          to,
          params,
          ...props
        }: RRLinkProps & SharedLinkProps,
        ref: ForwardedRef<HTMLAnchorElement>
      ) => {
        const path =
          typeof to === 'string' ? to : to.clone(params).toNavigate();

        return (
          <RRLink
            ref={ref}
            to={path}
            className={clsx(styles.link, styles[variant], className)}
            {...props}
          />
        );
      }
    ),
  }
);

type ExternalLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> &
  SharedLinkProps;

const ExternalLink = forwardRef<HTMLAnchorElement, ExternalLinkProps>(
  (
    {
      className,
      variant = 'text',
      href = '',
      target = '_blank',
      primary,
      ...props
    },
    ref
  ) => (
    <a
      ref={ref}
      className={clsx(
        styles.link,
        styles[variant],
        { [styles.primary]: primary },
        className
      )}
      href={href}
      target={href.startsWith('mailto:') ? undefined : target}
      rel='noreferrer'
      {...props}
    />
  )
);

export { ExternalLink, Link, Navigate, NavLink };
