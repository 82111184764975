import { modelView } from '@kuna-pay/utils/effector';
import { AdaptivePaper } from '@kuna-pay/core/shared/ui/adaptive-paper';

import { SetupMFA } from '../../../../features/auth/mfa/setup';
import { TurnOn2FARRPageModel } from './page.model';
import styles from './page.module.scss';

type TurnOn2FARRPageProps = {
  onWhatIsMfaClick?: () => void;
};

const TurnOn2FARRPage = modelView(
  TurnOn2FARRPageModel,
  ({ onWhatIsMfaClick }: TurnOn2FARRPageProps) => {
    const $$model = TurnOn2FARRPageModel.useModel();

    return (
      <AdaptivePaper className={styles.root}>
        <SetupMFA.Form
          $$model={$$model.$$confirm}
          onWhatIsMfaClick={onWhatIsMfaClick}
        />
      </AdaptivePaper>
    );
  }
);

export { TurnOn2FARRPage };
