import { createEffect } from 'effector';

import { modelFactory } from '@kuna-pay/utils/effector';

import type { CookiePreference } from '../../cookie-consent';
import { GoogleAnalyticsUserIdentity } from './google-analytics';
import { GTMAnalytics } from './gtm.lib';

const GoogleTagManagerAnalyticsModel = modelFactory(() => {
  const identifyFx = createEffect({
    handler: async (userId: string | null) => {
      GoogleAnalyticsUserIdentity.syncUserIdToCookie(userId);
    },
  });

  const consentFx = createEffect({
    handler: async ({
      analytics,
      marketing,
    }: Record<CookiePreference, boolean>) => {
      /**
       * @see https://github.com/googleanalytics/ga4-tutorials/blob/main/src/public/layouts/layout.eta
       * @see https://github.com/googleanalytics/ga4-tutorials/blob/main/src/public/partials/consent.eta
       */
      GTMAnalytics.gtag('consent', 'update', {
        functionality_storage: 'granted',
        security_storage: 'granted',

        ad_storage: marketing ? 'granted' : 'denied',
        analytics_storage: analytics ? 'granted' : 'denied',

        ad_user_data: marketing ? 'granted' : 'denied',
        ad_personalization: marketing ? 'granted' : 'denied',
      });
    },
  });

  return { identify: identifyFx, consentFx };
});

export { GoogleTagManagerAnalyticsModel };
