import { Feature } from '@kuna-pay/merchant/generated/graphql';
import { subscription, query } from '@kuna-pay/merchant/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const publicFeaturesRequest = (
  select: Select<Feature> | string
) => `query publicFeatures {
  data: publicFeatures {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'Feature')}`;
export const publicFeatures = (select: Select<Feature> | string) => () =>
  query<Feature[]>(publicFeaturesRequest(select));
