import { combine, createEvent } from 'effector';
import { createGate } from 'effector-react';
import i18next from 'i18next';
import type { useTranslation } from 'react-i18next';
import type { SchemaOf } from 'yup';
import { mixed } from 'yup';
import { object, string } from 'yup';

import { listen, modelFactory, setState } from '@kuna-pay/utils/effector';
import { notify } from '@kuna-pay/ui/notification';
import { createSingleModal } from '@kuna-pay/ui/ui/modal';
import { createForm } from '@kuna-pay/form';
import { State } from '@kuna-pay/core/shared/lib/state';

import type {
  RateFormInValues,
  RateFormOutValues,
  ShareFeedbackFormInValues,
  ShareFeedbackFormOutValues,
} from './rate-your-experience.types';
import { RateYourExperienceSteps } from './rate-your-experience.types';

type GenericFeedbackModelProps = {
  sendFeedbackFx: (
    values: ShareFeedbackFormOutValues & RateFormOutValues
  ) => void | Promise<void>;

  onClose: () => void;

  MAX_DESCRIPTION_LENGTH?: number;
};

const RateYourExperienceModel = modelFactory(
  ({
    MAX_DESCRIPTION_LENGTH = 500,
    sendFeedbackFx,
    onClose,
  }: GenericFeedbackModelProps) => {
    const $$modal = createSingleModal();
    const Gate = createGate<{ i18n: ReturnType<typeof useTranslation> }>();

    const onBackFromFeedbackClick = createEvent();

    const $$state = State.factory.createModel<RateYourExperienceSteps>(
      RateYourExperienceSteps.Rate
    );

    const $$rateForm = createForm<RateFormInValues>({
      initialValues: { rate: null },
      schema: combine(
        Gate.state,
        (): SchemaOf<RateFormInValues> =>
          object({ rate: mixed<'good' | 'bad'>().nullable().required() })
      ),
    });

    const $$feedbackForm = createForm<ShareFeedbackFormInValues>({
      initialValues: { feedback: '' },

      schema: combine(
        Gate.state,
        (): SchemaOf<ShareFeedbackFormInValues> =>
          object({
            feedback: string()
              .trim()
              .max(MAX_DESCRIPTION_LENGTH)
              .label(
                i18next.t(
                  'shared.feedback.generic-feedback.fields.feedback.errors.label',
                  { ns: 'core' }
                )
              ),
          })
      ),
    });

    listen({
      clock: $$rateForm.submitted,

      handler: () => {
        $$state.next(RateYourExperienceSteps.Feedback);
      },
    });

    listen({
      clock: onBackFromFeedbackClick,
      handler: () => {
        $$state.prev();
        $$feedbackForm.reinit();
      },
    });

    listen({
      clock: $$feedbackForm.submitted,
      source: combine(
        $$rateForm.$values,
        (values) => values as RateFormOutValues
      ),

      handler: async ({ feedback }, { rate }) => {
        try {
          setState($$feedbackForm.$disabled, true);

          await sendFeedbackFx({ feedback, rate });

          $$state.next(RateYourExperienceSteps.Success);
        } catch (error) {
          notify.warning(
            i18next.t(
              'shared.feedback.generic-feedback.send-feedback-request.failed',
              { ns: 'core' }
            )
          );
        } finally {
          setState($$feedbackForm.$disabled, false);
        }
      },
    });

    listen({
      clock: $$modal.closed,
      handler: () => {
        onClose();

        $$state.reset();
        $$feedbackForm.reinit();
        $$rateForm.reinit();
      },
    });

    return {
      $$modal,

      $$ui: {
        Gate,

        onBackFromFeedbackClick,

        $$modal: $$modal.$$modal,
        ID: $$modal.ID,

        $$state,
        $$rateForm,
        $$feedbackForm,

        MAX_DESCRIPTION_LENGTH,
      },
    };
  }
);

export { RateYourExperienceModel };
