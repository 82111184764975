import { combine, createEvent, createStore } from 'effector';

function createState<T extends string>(
  defaultValue: T,
  config: {
    name?: string;
  } = {
    name: 'unnamed.$$state',
  }
) {
  const name = config.name ?? 'unnamed.$$state';

  const init = createEvent(`${name}.init`);
  const reset = createEvent(`${name}.reset`);

  const next = createEvent<T>(`${name}.next`);
  const prev = createEvent(`${name}.prev`);
  const skip = createEvent<T>(`${name}.skip`);

  const $store = createStore<{ current: T; history: T[] }>(
    { current: defaultValue, history: [] },
    {
      name: `${name}.$store`,
    }
  )
    .on(next, (state, value) => ({
      current: value,
      history: [...state.history, state.current],
    }))
    .on(skip, (state, value) => ({
      current: value,
      history: [...state.history],
    }))
    .on(prev, (state) => {
      if (state.history.length) {
        const value = state.history.pop()!;

        return {
          current: value,
          history: [...state.history],
        };
      }

      return state;
    })
    .reset(reset, init);

  return {
    init,
    reset,

    $value: combine($store, (item) => item.current),

    next,
    skip,
    prev,
  };
}

export type StateType<T extends string> = ReturnType<typeof createState<T>>;
export { createState };
