import { attach, createStore } from 'effector';
import type { InitOptions } from 'i18next';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { setState } from '@kuna-pay/utils/effector';
import { atom } from '@kuna-pay/utils/misc';
import {
  CrowdinInContextConfig,
  CrowdinInContextScript,
} from '@kuna-pay/core/shared/i18n';
import { initFormsTranslations } from '@kuna-pay/core/shared/i18n/@x/forms';

import { AvailableLanguages } from '@kuna-pay/merchant/generated/graphql';

import {
  $$crowdinInContextFeatureFlag,
  $$isESLangEnabledFeatureFlag,
} from '../feature-flags/features/static';
import { MonorepoBackend } from './backend';
import { LanguageDetector } from './detection';
import { I18nConfig } from './i18n.config';

const $$i18n = atom(() => {
  const $instance = createStore(i18next, {
    name: '$$i18n.$instance',
  });

  const $language = createStore<string>(i18next.language ?? 'en');

  const $supportedLanguages = createStore<AvailableLanguages[]>(
    [AvailableLanguages.En],
    {
      name: '$$i18n.$supportedLanguages',
    }
  );

  const initFx = attach({
    name: '$$i18n.initFx',
    source: {
      instance: $instance,
      languages: $supportedLanguages,
      isESEnabled: $$isESLangEnabledFeatureFlag.$enabled,
      isCrowdinInContextEnabled: $$crowdinInContextFeatureFlag.$enabled,
    },
    effect: async ({
      instance,
      languages,
      isESEnabled,
      isCrowdinInContextEnabled,
    }) => {
      const supportedLanguages = languages.slice();

      [
        { langCode: AvailableLanguages.Uk, isEnabled: true },
        { langCode: AvailableLanguages.De, isEnabled: true },
        { langCode: AvailableLanguages.Pl, isEnabled: true },
        { langCode: AvailableLanguages.Pt, isEnabled: true },
        { langCode: AvailableLanguages.Es, isEnabled: isESEnabled },
        { langCode: AvailableLanguages.It, isEnabled: true },
        { langCode: AvailableLanguages.Lt, isEnabled: true },
      ].forEach(({ langCode, isEnabled }) => {
        if (isEnabled) {
          supportedLanguages.push(langCode);
        }
      });

      const initOptions: InitOptions = {
        /**
         * Fallback and preload ensuring that
         * we have at least one language loaded
         * before rendering actual app
         *
         * TODO: Consider inlining 'en' into resources
         *       so we load it with the app
         *       rather than making an extra request
         */
        fallbackLng: I18nConfig.FALLBACK_LANGUAGE,
        preload: [I18nConfig.FALLBACK_LANGUAGE],

        supportedLngs: supportedLanguages,

        ns: ['common', 'core'],

        detection: LanguageDetector.options,

        load: 'languageOnly',

        saveMissing: true, // This option enables 'missingKey' event
      };

      if (isCrowdinInContextEnabled) {
        CrowdinInContextConfig.patchI18nextInitConfig(initOptions);
        CrowdinInContextScript.inject();
      }

      void instance
        .use(initReactI18next)

        .use(LanguageDetector.create())

        .use(MonorepoBackend.create())

        .init(initOptions);

      // This event is emitted also on init
      instance.on('languageChanged', (lng) => {
        setState($language, lng);

        initFormsTranslations(instance.t, lng);
      });

      setState($supportedLanguages, supportedLanguages);
    },
  });

  return {
    initFx,
    $instance,
    $language,
    $supportedLanguages,
  };
});

export { $$i18n, i18next };
