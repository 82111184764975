import { captureException } from '@sentry/react';
import type { Location } from 'react-router-dom';
import { matchPath } from 'react-router-dom';

import type { AnalyticsProperties } from '@kuna-pay/merchant/shared/analytics';
import { $$analytics } from '@kuna-pay/merchant/shared/analytics';
import { routes } from '@kuna-pay/merchant/shared/router';

class ContactSupportModalViewedEvent {
  public async logEvent(location: Location) {
    try {
      let from:
        | AnalyticsProperties.ContactSupportModalViewedEventFrom
        | undefined;

      for (const routeToValueMapping of this.routeToValueMappings) {
        if (
          routeToValueMapping.paths.some((path) =>
            matchPath(path, location.pathname)
          )
        ) {
          from = routeToValueMapping.from;

          break; // break the loop
        }
      }

      void $$analytics.logEvent({
        event: 'ContactSupport Modal Viewed',
        properties: {
          from,
          pathname: location.pathname,
        },
      });
    } catch (e) {
      captureException(e);
    }
  }

  public readonly routeToValueMappings: {
    paths: { path: string }[];
    from: AnalyticsProperties.ContactSupportModalViewedEventFrom;
  }[] = [
    {
      paths: [routes.auth.signUp],
      from: 'Sign up',
    },
    {
      paths: [routes.auth.signIn],
      from: 'Sign in',
    },
    {
      paths: [routes.auth.mfa.turnOn, routes.auth.mfa.activated],
      from: '2FA setup',
    },
    {
      paths: [routes.merchant.create, routes.merchant.created],
      from: 'Create company',
    },
    {
      paths: [
        routes.auth.verification.start,
        routes.auth.verification.done,
        routes.auth.verification.alreadyVerified,
      ],
      from: 'Verification',
    },
    {
      paths: [routes.dashboard],
      from: 'Dashboard',
    },
    {
      paths: [routes.assets.list],
      from: 'Assets',
    },
    {
      paths: [routes.invoices.list, routes.invoices.details],
      from: 'Invoices',
    },
    {
      paths: [
        routes.transactions.list,
        routes.transactions.details,
        routes.transactions.processing,
      ],
      from: 'Transactions',
    },
    {
      paths: [
        routes.payouts.list,
        routes.payouts.details,
        routes.payouts.create,
        routes.payouts.launch,
      ],
      from: 'Payouts',
    },
    {
      paths: [routes.members.list],
      from: 'Members',
    },
    {
      paths: [routes.settings.account],
      from: 'Account settings',
    },
    {
      paths: [routes.settings.apiKey],
      from: 'API key set-up',
    },
    {
      paths: [routes.settings.autoConversion],
      from: 'Auto-conversion set-up',
    },
    {
      paths: [routes.settings.exchangeRate],
      from: 'Exchange rate set-up',
    },
    {
      paths: [routes.reporting.settlement],
      from: 'Settlement report',
    },
    {
      paths: [routes.reporting.statement],
      from: 'Statement report',
    },
    {
      paths: [routes.referrals.list],
      from: 'Referrals',
    },
    {
      paths: [
        routes.profile.main,
        routes.profile.notifications,
        routes.profile.security,
      ],
      from: 'My Profile',
    },
  ];
}

export { ContactSupportModalViewedEvent };
