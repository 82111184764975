import type { CookiePreference } from './cookie-consent.types';

class CookieConsentI18n {
  public static cookiePreferenceToI18nMap: Record<
    CookiePreference,
    {
      title: string;
      description: string;
    }
  > = {
    analytics: {
      title: 'shared.cookie-consent.ui.manage.type.analytics.title',
      description: 'shared.cookie-consent.ui.manage.type.analytics.description',
    },
    functional: {
      title: 'shared.cookie-consent.ui.manage.type.functional.title',
      description:
        'shared.cookie-consent.ui.manage.type.functional.description',
    },
    marketing: {
      title: 'shared.cookie-consent.ui.manage.type.marketing.title',
      description: 'shared.cookie-consent.ui.manage.type.marketing.description',
    },
  };
}

export { CookieConsentI18n };
