import clsx from 'clsx';
import type { HTMLAttributes } from 'react';
import type { ImgHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import accessDeniedImg from '../../assets/access-denied.png';
import styles from './access-denied.module.scss';

type AccessDeniedProps = {
  placement?: 'top' | 'center' | 'bottom';
};

const AccessDenied = createCompoundComponent(
  (Components) =>
    ({ placement = 'center' }: AccessDeniedProps) => {
      const { t } = useTranslation(undefined, {
        keyPrefix: 'entities.session.ui.has-access',
      });

      return (
        <Components.Root
          className={clsx({
            [styles.top]: placement === 'top',
          })}
        >
          <Components.Img />

          <Components.Title>{t('title')}</Components.Title>

          <Components.Description>{t('description')}</Components.Description>
        </Components.Root>
      );
    },

  {
    Root: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.root, className)} {...props} />
    ),

    Img: (props: ImgHTMLAttributes<HTMLImageElement>) => (
      <img
        className={styles.img}
        src={accessDeniedImg}
        width={480}
        height={320}
        alt=''
        {...props}
      />
    ),

    Title: ({ className, ...props }: Partial<TypographyProps>) => (
      <Typography
        as='p'
        className={clsx(styles.title, className)}
        variant='h6'
        {...props}
      />
    ),

    Description: ({ className, ...props }: Partial<TypographyProps>) => (
      <Typography
        as='p'
        className={clsx(styles.description, className)}
        variant='body2'
        {...props}
      />
    ),
  }
);

export { AccessDenied };
