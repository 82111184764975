import type { ReactNode } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import type { NotificationOutput } from '@kuna-pay/merchant/generated/graphql';

import { PushNotificationTemplate } from '../../types';

type NotificationTitleProps = Omit<Partial<TypographyProps>, 'placeholder'> & {
  notification: NotificationOutput;

  placeholder?: ReactNode;
};

const NotificationTitle = memo(
  ({ notification, placeholder, ...props }: NotificationTitleProps) => {
    const template = notification.template as PushNotificationTemplate;
    const { t } = useTranslation();

    switch (template) {
      case PushNotificationTemplate.SuccessfulLogin: {
        return (
          <Typography variant='subtitle5' {...props}>
            {t('entities.push-notification.title.login-successful')}
          </Typography>
        );
      }
      //

      case PushNotificationTemplate.IdentificationNeedHelp: {
        return (
          <Typography variant='subtitle5' {...props}>
            {t('entities.push-notification.title.identification-need-help')}
          </Typography>
        );
      }

      case PushNotificationTemplate.IdentificationNeedToComplete: {
        return placeholder;
      }

      case PushNotificationTemplate.IdentificationDetailsAboutProduct: {
        return (
          <Typography variant='subtitle5' {...props}>
            {t(
              'entities.push-notification.title.identification-details-about-product'
            )}
          </Typography>
        );
      }

      case PushNotificationTemplate.IdentificationRejected: {
        return (
          <Typography variant='subtitle5' {...props}>
            {t('entities.push-notification.title.identification-rejected')}
          </Typography>
        );
      }

      case PushNotificationTemplate.IdentificationAccepted: {
        return (
          <Typography variant='subtitle5' {...props}>
            {t('entities.push-notification.title.identification-success')}
          </Typography>
        );
      }

      //
      case PushNotificationTemplate.InvoiceReminderWithHelp: {
        return (
          <Typography variant='subtitle5' {...props}>
            {t('entities.push-notification.title.invoice-reminder-with-help')}
          </Typography>
        );
      }

      case PushNotificationTemplate.InvoiceDepositProcessed: {
        return (
          <Typography variant='subtitle5' {...props}>
            {t('entities.push-notification.title.invoice-deposit-processed')}
          </Typography>
        );
      }

      case PushNotificationTemplate.InvoiceUnPaid: {
        return (
          <Typography variant='subtitle5' {...props}>
            {t('entities.push-notification.title.invoice-unpaid')}
          </Typography>
        );
      }

      case PushNotificationTemplate.InvoiceDepositSuspended: {
        return (
          <Typography variant='subtitle5' {...props}>
            {t('entities.push-notification.title.invoice-deposit-suspended', {
              replace: { invoiceId: notification.payload.invoiceId },
            })}
          </Typography>
        );
      }

      case PushNotificationTemplate.InvoiceDepositCheckSuccessful: {
        return (
          <Typography variant='subtitle5' {...props}>
            {t(
              'entities.push-notification.title.invoice-deposit-check-successful'
            )}
          </Typography>
        );
      }

      case PushNotificationTemplate.InvoiceDepositArrested: {
        return (
          <Typography variant='subtitle5' {...props}>
            {t('entities.push-notification.title.invoice-deposit-arrested')}
          </Typography>
        );
      }

      //
      case PushNotificationTemplate.DepositProcessed: {
        return (
          <Typography variant='subtitle5' {...props}>
            {t('entities.push-notification.title.deposit-processed', {
              replace: { currency: notification.payload.currency },
            })}
          </Typography>
        );
      }

      case PushNotificationTemplate.CompanyExceededDepositLimits: {
        return (
          <Typography variant='subtitle5' {...props}>
            {t(
              'entities.push-notification.title.deposit-company-exceeded-limits'
            )}
          </Typography>
        );
      }

      //
      case PushNotificationTemplate.AutoConvertProcessed: {
        return (
          <Typography variant='subtitle5' {...props}>
            {t('entities.push-notification.title.auto-convert-processed')}
          </Typography>
        );
      }

      //
      case PushNotificationTemplate.ReferralRewardProcessed: {
        return (
          <Typography variant='subtitle5' {...props}>
            {t('entities.push-notification.title.referral-reward-processed')}
          </Typography>
        );
      }

      case PushNotificationTemplate.ReferralReminderEndDiscount: {
        return (
          <Typography variant='subtitle5' {...props}>
            {t(
              'entities.push-notification.title.referral-reminder-end-discount'
            )}
          </Typography>
        );
      }

      //
      case PushNotificationTemplate.WithdrawalProcessed: {
        return (
          <Typography variant='subtitle5' {...props}>
            {t('entities.push-notification.title.withdrawal-processed', {
              replace: { currency: notification.payload.currency },
            })}
          </Typography>
        );
      }

      //
      case PushNotificationTemplate.PayoutProcessed: {
        return (
          <Typography variant='subtitle5' {...props}>
            {t('entities.push-notification.title.payout-processed')}
          </Typography>
        );
      }

      case PushNotificationTemplate.PayoutFailed: {
        return (
          <Typography variant='subtitle5' {...props}>
            {t('entities.push-notification.title.payout-failed')}
          </Typography>
        );
      }

      default: {
        const _: never = template;

        return (
          <Typography variant='subtitle5' {...props}>
            {template}
          </Typography>
        );
      }
    }
  }
);

export type { NotificationTitleProps };
export { NotificationTitle };
