/* eslint-disable @typescript-eslint/lines-between-class-members */

import { StoreProperty } from '@kuna-pay/utils/effector/property';
import {
  ApiOTPService,
  APIPersistService,
} from '@kuna-pay/core/shared/api/http';
import { ApiTokensService } from '@kuna-pay/core/shared/api/http';
import { KunaPayAuthServiceFactory } from '@kuna-pay/core/shared/api/http';
import {
  APIAmplitudeIntegration,
  APIGAIntegration,
} from '@kuna-pay/core/shared/api/integrations';
import { APIRecaptchaIntegration } from '@kuna-pay/core/shared/recaptcha';

import { $$analytics } from '@kuna-pay/merchant/shared/analytics';
import { environment } from '@kuna-pay/merchant/shared/config';
import { LocalStoragePersistKeys } from '@kuna-pay/merchant/shared/persist';
import { $$recaptcha } from '@kuna-pay/merchant/shared/recaptcha';

import { RefreshTokensUseCase } from './use-cases';

class ApiService {
  public constructor(
    public readonly tokenService: ApiTokensService,
    public readonly otpService: ApiOTPService
  ) {}

  public clearTokens() {
    this.tokenService.clearTokens();

    this.otpService.clearOtp();
  }

  public createAuthServiceForGQLClient = () =>
    KunaPayAuthServiceFactory.createAuthService({
      middlewares: [
        APIAmplitudeIntegration.createAuthServiceForGQLClient($$analytics),
        APIGAIntegration.createAuthServiceForGQLClient(
          environment.google.analytics.GA_ID
        ),
        APIRecaptchaIntegration.createAuthServiceForGQLClient($$recaptcha),
        this.tokenService.createAuthServiceForGQLClient(),
        this.otpService.createAuthServiceForGQLClient(),
      ],
    });
}

const $$api = new ApiService(
  new ApiTokensService(
    new APIPersistService(LocalStoragePersistKeys.AccessToken),
    new APIPersistService(LocalStoragePersistKeys.RefreshToken),
    new RefreshTokensUseCase()
  ),

  new ApiOTPService(StoreProperty.create<string | null>(null))
);

export { $$api, ApiService };
