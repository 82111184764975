import { Suspense } from 'react';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';

import { CreateMerchantSuccessPageLoading } from './page.loading';

const Page = lazyWithRetryAndRefresh(
  'CreateMerchantSuccessPage',
  () => import('./page.component')
);

const CreateMerchantSuccessPage = {
  Component: () => (
    <Suspense fallback={<CreateMerchantSuccessPageLoading />}>
      <Page />
    </Suspense>
  ),
};

export { CreateMerchantSuccessPage };
