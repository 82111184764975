import { Suspense } from 'react';
import type { RouteObject } from 'react-router-dom';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';

import {
  MainLayout,
  MainLayoutLoading,
} from '@kuna-pay/merchant/widgets/layout';

const TutorialsPage = lazyWithRetryAndRefresh(
  'TutorialsPage',
  () => import('./page.component')
);

const TutorialsPages: RouteObject[] = [
  {
    path: '/tutorials',
    Component: () => (
      <Suspense
        fallback={
          <MainLayout.Merchant.Content>
            <MainLayoutLoading />
          </MainLayout.Merchant.Content>
        }
      >
        <TutorialsPage />
      </Suspense>
    ),
  },
];

export { TutorialsPages };
