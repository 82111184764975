import { Skeleton } from '@kuna-pay/ui/ui/skeleton';

import styles from './page.loading.module.scss';

const CreateMerchantPageLoading = () => (
  <div className={styles.container}>
    <Skeleton width={750} height={600} />
  </div>
);

export { CreateMerchantPageLoading };
