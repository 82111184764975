import { useUnit } from 'effector-react';
import { memo, type PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getState, listen } from '@kuna-pay/utils/effector';
import { invariant } from '@kuna-pay/utils/invariant';
import {
  createExternalDepsRef,
  useLocalModel,
  useSyncExternalDeps,
} from '@kuna-pay/utils/react/use-local-model';
import { ArrowRightIcon } from '@kuna-pay/ui/icons';
import { Link } from '@kuna-pay/ui/router';
import { Button } from '@kuna-pay/ui/ui/button';
import { createSingleModal, Modal } from '@kuna-pay/ui/ui/modal';
import { Skeleton } from '@kuna-pay/ui/ui/skeleton';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { createEQuery } from '@kuna-pay/core/shared/lib/effector-query';

import { $$session } from '@kuna-pay/merchant/entities/session';
import {
  ProductFeeStrategy,
  TransferType,
} from '@kuna-pay/merchant/generated/graphql';
import { $$analytics } from '@kuna-pay/merchant/shared/analytics';
import { findManyAvailablePaymentMethods } from '@kuna-pay/merchant/shared/api/generated/PaymentMethod/request/findManyAvailablePaymentMethods';
import { routes } from '@kuna-pay/merchant/shared/router';

import styles from './payment-preferences-modal.module.scss';

type PaymentPreferencesModalProps = PropsWithChildren & {
  onClose: () => void;
};

const PaymentPreferencesModal = memo(
  ({ children, onClose }: PaymentPreferencesModalProps) => {
    const { t } = useTranslation();

    const { $$ref, $$single, $$query } = useLocalModel(() => {
      const $$single = createSingleModal();
      const $$ref = createExternalDepsRef({ onClose });

      const $$query = createEQuery({
        initialData: null,
        query: async (_: void) => {
          const [
            company,
            [
              {
                Product: { feeStrategy },
              },
            ],
          ] = await Promise.all([
            getState($$session.$$merchantQuery.$data),

            findManyAvailablePaymentMethods({
              Product: { feeStrategy: true },
            })({
              where: {
                transferType: TransferType.InvoiceDeposit,
              },
            }),
          ]);

          invariant.error(company, 'Company context is not available');
          invariant.error(feeStrategy, 'Fee strategy is not available');

          return {
            isVolatilityCoverageEnabled: company.isVolatilityCoverageEnabled,
            AutoConvertAsset: company.AutoConvertAsset,
            feeStrategy,
          };
        },
      });

      listen({
        clock: $$single.opened,
        handler: async () => {
          const companyId = (await getState($$session.$$jwt.$$company.$id))!;
          const userId = (await getState($$session.$$jwt.$$user.$id))!;

          void $$analytics.logEvent({
            event: 'Payment Settings One Time Notification Viewed',
            properties: {
              companyId,
              userId,
            },
          });

          void $$query.startFx();
        },
      });

      listen({
        clock: $$single.closed,
        source: $$ref.$current,
        handler: (_, { onClose }) => {
          onClose();
        },
      });

      return {
        $$ref,
        $$single,
        $$query,
      };
    });
    useSyncExternalDeps($$ref, { onClose });

    const [isLoading, data] = useUnit([$$query.$isLoading, $$query.$data]);

    return (
      <Modal
        content={
          <Modal.Content size='md'>
            <Modal.Close />

            <Modal.Title className={styles.title} variant='h9'>
              {t('pages.invoices.payment-settings-popup.modal.title')}
            </Modal.Title>

            <Modal.Description className={styles.description} variant='body3'>
              {t('pages.invoices.payment-settings-popup.modal.description')}
            </Modal.Description>

            {!data || isLoading ? (
              <div className={styles.content}>
                <Skeleton fullWidth height={220} />
              </div>
            ) : (
              <div className={styles.content}>
                <div className={styles.item}>
                  <Typography variant='numbers1'>1</Typography>

                  <Typography variant='body3'>
                    <Trans
                      t={t}
                      i18nKey={
                        !!data.AutoConvertAsset
                          ? 'pages.invoices.payment-settings-popup.modal.auto-convert.enabled'
                          : 'pages.invoices.payment-settings-popup.modal.auto-convert.disabled'
                      }
                      values={{
                        asset: data.AutoConvertAsset?.code,
                      }}
                      components={{
                        subtitle4: <Typography variant='subtitle4' />,
                      }}
                    />
                  </Typography>
                </div>

                <div className={styles.item}>
                  <Typography variant='numbers1'>2</Typography>

                  <Typography variant='body3'>
                    <Trans
                      t={t}
                      i18nKey={
                        data.isVolatilityCoverageEnabled
                          ? 'pages.invoices.payment-settings-popup.modal.volatility.enabled'
                          : 'pages.invoices.payment-settings-popup.modal.volatility.disabled'
                      }
                      components={{
                        subtitle4: <Typography variant='subtitle4' />,
                      }}
                    />
                  </Typography>
                </div>

                {!!data.feeStrategy ? (
                  <div className={styles.item}>
                    <Typography variant='numbers1'>3</Typography>

                    <Typography variant='body3'>
                      <Trans
                        t={t}
                        i18nKey={
                          data.feeStrategy === ProductFeeStrategy.Internal
                            ? 'pages.invoices.payment-settings-popup.modal.fee-strategy.internal'
                            : 'pages.invoices.payment-settings-popup.modal.fee-strategy.external'
                        }
                        components={{
                          subtitle4: <Typography variant='subtitle4' />,
                        }}
                      />
                    </Typography>
                  </div>
                ) : (
                  <Skeleton fullWidth height={80} />
                )}
              </div>
            )}

            <div className={styles.actions}>
              <Modal.ActionClose asChild>
                <Button variant='contained' color='primary' size='lg'>
                  {t('pages.invoices.payment-settings-popup.modal.action')}
                </Button>
              </Modal.ActionClose>

              <Button.Root
                variant='text'
                color='primary'
                size='lg'
                asChild
                hasEnd
                onClick={() => onClose()}
              >
                <Link
                  to={routes.settings.paymentsCustomization.withQuery({
                    from: 'Discovery Modal',
                  })}
                >
                  <Button.Text size='lg' hasEnd>
                    {t('pages.invoices.payment-settings-popup.modal.redirect')}
                  </Button.Text>

                  <ArrowRightIcon />
                </Link>
              </Button.Root>
            </div>
          </Modal.Content>
        }
        model={$$single.$$modal}
        id={$$single.ID}
      >
        {children}
      </Modal>
    );
  }
);

export { PaymentPreferencesModal };
