import { memo } from 'react';

import type { CodeInputProps } from '@kuna-pay/ui/ui/text-field';
import { CodeInput } from '@kuna-pay/ui/ui/text-field';

import type { FieldModel } from '../lib';
import { useField } from '../lib';

type FormCodeFieldProps = Omit<
  CodeInputProps,
  'onChange' | 'onBlur' | 'error' | 'value'
> & {
  field: FieldModel<string>;
};

const FormCodeField = memo(
  ({ field, disabled, ...props }: FormCodeFieldProps) => {
    const {
      onFocus,
      onChange,
      focused,
      disabled: formDisabled,
    } = useField(field);

    return (
      <CodeInput
        onChange={(e) => {
          if (!focused) {
            onFocus();
          }

          onChange(e);
        }}
        disabled={!!disabled || !!formDisabled}
        {...props}
      />
    );
  }
);

export { FormCodeField };
