import clsx from 'clsx';
import { useStoreMap, useUnit } from 'effector-react';
import { Trans, useTranslation } from 'react-i18next';

import type { ModelOf } from '@kuna-pay/utils/effector';
import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { ExternalLink } from '@kuna-pay/ui/router';
import { Button } from '@kuna-pay/ui/ui/button';
import { Divider } from '@kuna-pay/ui/ui/divider';
import { Modal } from '@kuna-pay/ui/ui/modal';
import { Switch } from '@kuna-pay/ui/ui/switch';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { urls } from '@kuna-pay/core/shared/config';

import { CookieConsentI18n } from '../cookie-consent.i18n';
import type { CookiePreference } from '../cookie-consent.types';
import type { CookieConsentModelFactory } from '../model/cookie-consent.factory';
import { ManageCookieListItem } from './manage-cookie-list-item.component';
import styles from './manage-cookie-settings.module.scss';

type ManageCookieModel = ModelOf<
  typeof CookieConsentModelFactory
>['$$ui']['$$cookiePreferences'];

type ManageCookieSettingsProps = {
  $$model: ManageCookieModel;
};

const ManageCookieSettings = createCompoundComponent(
  (C) =>
    ({ $$model }: ManageCookieSettingsProps) => {
      const { t, i18n } = useTranslation('core', {
        keyPrefix: 'shared.cookie-consent.ui',
      });

      const [onSaveClicked, onRejectAllClicked] = useUnit([
        $$model.saveCookiePreferencesClicked,
        $$model.rejectAllClicked,
      ]);

      return (
        <Modal
          id={$$model.$$modal.ID}
          model={$$model.$$modal.$$modal}
          classes={{ overlay: styles.overlay }}
          content={
            <Modal.Content className={clsx(styles.root, styles.rootSize)}>
              <div className={styles.header}>
                <Modal.Close className={clsx(styles.close, styles.closeSize)} />

                <Modal.Title
                  className={styles.title}
                  variant='h7'
                  center={false}
                >
                  {t('manage.title')}
                </Modal.Title>
              </div>

              <div className={styles.content}>
                <Typography className={styles.description} variant='body3'>
                  <Trans
                    t={t}
                    i18nKey='manage.description'
                    components={{
                      'cookie-policy': (
                        <ExternalLink
                          className={Typography.classes.subtitle5}
                          href={urls.privacyPolicy.withLocale(i18n.language)}
                          primary
                        />
                      ),
                    }}
                  />
                </Typography>

                <Typography
                  className={styles.manageCookieTitle}
                  variant='subtitle2'
                >
                  {t('manage.list-title')}
                </Typography>

                <div className={styles.cookiesList}>
                  <ManageCookieListItem.Root>
                    <ManageCookieListItem.Header>
                      <ManageCookieListItem.Title>
                        {t('manage.type.necessary.title')}
                      </ManageCookieListItem.Title>

                      <ManageCookieListItem.AlwaysActive />
                    </ManageCookieListItem.Header>

                    <ManageCookieListItem.Content>
                      {t('manage.type.necessary.description')}
                    </ManageCookieListItem.Content>
                  </ManageCookieListItem.Root>

                  <Divider />

                  <C.CookiePreference $$model={$$model} type='functional' />

                  <Divider />

                  <C.CookiePreference $$model={$$model} type='analytics' />

                  <Divider />

                  <C.CookiePreference $$model={$$model} type='marketing' />
                </div>
              </div>

              <div className={styles.actions}>
                <Button
                  variant='contained'
                  color='secondary'
                  size='lg'
                  fullWidth
                  onClick={onRejectAllClicked}
                >
                  {t('manage.actions.reject')}
                </Button>

                <Button
                  variant='contained'
                  color='primary'
                  size='lg'
                  fullWidth
                  onClick={onSaveClicked}
                >
                  {t('manage.actions.save')}
                </Button>
              </div>
            </Modal.Content>
          }
        />
      );
    },

  {
    CookiePreference: ({
      type,
      $$model,
    }: {
      type: CookiePreference;
      $$model: ManageCookieModel;
    }) => {
      const { t } = useTranslation('core');

      const [onPreferenceChange] = useUnit([$$model.preferenceChecked]);

      const checked = useStoreMap({
        store: $$model.$preferences,
        keys: [type],
        fn: (preferences, [type]) => !!preferences[type],
      });

      return (
        <ManageCookieListItem.Root>
          <ManageCookieListItem.Header>
            <ManageCookieListItem.Title>
              {t(CookieConsentI18n.cookiePreferenceToI18nMap[type].title)}
            </ManageCookieListItem.Title>

            <Switch
              size='lg'
              checked={checked}
              onCheckedChange={() => onPreferenceChange(type)}
            />
          </ManageCookieListItem.Header>

          <ManageCookieListItem.Content>
            {t(CookieConsentI18n.cookiePreferenceToI18nMap[type].description)}
          </ManageCookieListItem.Content>
        </ManageCookieListItem.Root>
      );
    },
  }
);

export { ManageCookieSettings };
export type { ManageCookieSettingsProps };
