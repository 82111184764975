/* eslint-disable import/exports-last,import/group-exports,@typescript-eslint/prefer-for-of */

/**
 * This file is a fork of 'react-auth-code-input' package
 * with fixes related to:
 *  - KUPAY-2816
 */

import type {
  ChangeEvent,
  ClipboardEvent,
  FocusEvent,
  KeyboardEvent,
} from 'react';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

const allowedCharactersValues = ['alpha', 'numeric', 'alphanumeric'] as const;

export type AuthCodeProps = {
  allowedCharacters?: (typeof allowedCharactersValues)[number];
  ariaLabel?: string;
  autoFocus?: boolean;
  containerClassName?: string;
  disabled?: boolean;
  inputClassName?: string;
  isPassword?: boolean;
  length?: number;
  placeholder?: string;
  onChange: (res: string) => void;
};

type InputMode = 'text' | 'numeric';

type InputType = 'text' | 'tel' | 'password';

type InputProps = {
  type: InputType;
  inputMode: InputMode;
  pattern: string;
  min?: string;
  max?: string;
};

export type AuthCodeRef = {
  focus: () => void;
  clear: () => void;
};

const propsMap: { [key: string]: InputProps } = {
  alpha: {
    type: 'text',
    inputMode: 'text',
    pattern: '[a-zA-Z]{1}',
  },

  alphanumeric: {
    type: 'text',
    inputMode: 'text',
    pattern: '[a-zA-Z0-9]{1}',
  },

  numeric: {
    type: 'tel',
    inputMode: 'numeric',
    pattern: '[0-9]{1}',
    min: '0',
    max: '9',
  },
};

const AuthCode = forwardRef<AuthCodeRef, AuthCodeProps>(
  (
    {
      allowedCharacters = 'alphanumeric',
      ariaLabel,
      autoFocus = true,
      containerClassName,
      disabled,
      inputClassName,
      isPassword = false,
      length = 6,
      placeholder,
      onChange,
    },
    ref
  ) => {
    if (isNaN(length) || length < 1) {
      throw new Error('Length should be a number and greater than 0');
    }

    if (!allowedCharactersValues.some((value) => value === allowedCharacters)) {
      throw new Error(
        'Invalid value for allowedCharacters. Use alpha, numeric, or alphanumeric'
      );
    }

    const inputsRef = useRef<HTMLInputElement[]>([]);
    const inputProps = propsMap[allowedCharacters];

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputsRef.current) {
          inputsRef.current[0].focus();
        }
      },
      clear: () => {
        if (inputsRef.current) {
          for (let i = 0; i < inputsRef.current.length; i++) {
            inputsRef.current[i].value = '';
          }

          inputsRef.current[0].focus();
        }
        sendResult();
      },
    }));

    useEffect(() => {
      if (autoFocus) {
        inputsRef.current[0].focus();
      }
    }, []);

    const sendResult = () => {
      const result = inputsRef.current.map((input) => input.value).join('');

      if (onChange) {
        onChange(result);
      }
    };

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value, nextElementSibling },
      } = event;

      if (value.length > 1) {
        event.target.value = value.charAt(0);

        if (nextElementSibling !== null) {
          (nextElementSibling as HTMLInputElement).focus();
        }
      } else if (value.match(inputProps.pattern)) {
        if (nextElementSibling !== null) {
          (nextElementSibling as HTMLInputElement).focus();
        }
      } else {
        event.target.value = '';
      }
      sendResult();
    };

    const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      const { key } = event;
      const target = event.target as HTMLInputElement;

      if (key === 'Backspace') {
        if (target.value === '') {
          if (target.previousElementSibling !== null) {
            const prevSiblingTarget =
              target.previousElementSibling as HTMLInputElement;
            prevSiblingTarget.value = '';
            prevSiblingTarget.focus();
            event.preventDefault();
          }
        } else {
          target.value = '';
        }
        sendResult();
      }
    };

    const handleOnFocus = (e: FocusEvent<HTMLInputElement>) => {
      e.target.select();
    };

    const handleOnPaste = async (event: ClipboardEvent<HTMLInputElement>) => {
      const pastedValue = await getPastedValue(event);

      let currentInput = 0;

      for (let i = 0; i < pastedValue.length; i++) {
        const pastedCharacter = pastedValue.charAt(i);
        const currentInputRef = inputsRef.current[currentInput];
        const currentValue = currentInputRef.value;

        if (pastedCharacter.match(inputProps.pattern)) {
          if (!currentValue) {
            currentInputRef.value = pastedCharacter;

            if (currentInputRef.nextElementSibling !== null) {
              (currentInputRef.nextElementSibling as HTMLInputElement).focus();
              currentInput++;
            }
          }
        }
      }

      sendResult();

      event.preventDefault();
    };

    const inputs = [];

    for (let i = 0; i < length; i++) {
      inputs.push(
        <input
          key={i}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onFocus={handleOnFocus}
          onPaste={handleOnPaste}
          {...inputProps}
          type={isPassword ? 'password' : inputProps.type}
          ref={(el: HTMLInputElement) => {
            inputsRef.current[i] = el;
          }}
          maxLength={1}
          className={inputClassName}
          autoComplete={i === 0 ? 'one-time-code' : 'off'}
          aria-label={
            ariaLabel
              ? `${ariaLabel}. Character ${i + 1}.`
              : `Character ${i + 1}.`
          }
          disabled={disabled}
          placeholder={placeholder}
        />
      );
    }

    return <div className={containerClassName}>{inputs}</div>;
  }
);

/**
 *
 * @see https://github.com/drac94/react-auth-code-input/issues/69
 * @see KUPAY-2816
 */
async function getPastedValue(e: ClipboardEvent<HTMLInputElement>) {
  try {
    if (e.clipboardData) {
      return e.clipboardData.getData('Text');
    }

    if (navigator.clipboard) {
      const pastedValue = await navigator.clipboard.readText();

      return pastedValue;
    }

    return '';
  } catch (e) {
    return '';
  }
}

export default AuthCode;
