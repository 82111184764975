import { Verify2FA as Verify2FAView } from './verify-2fa.component';
import { VerifyOTPModel } from './verify-2fa.model';
import { Verify2FAForm } from './verify-2fa-form.component';
import { VerifyAuthenticator2FA } from './verify-authenticator-2fa.component';
import { VerifyEmail2FA } from './verify-email-2fa.component';

const Verify2FA = Object.assign(Verify2FAView, {
  factory: VerifyOTPModel,
  Authenticator: VerifyAuthenticator2FA,
  Email: VerifyEmail2FA,
  Form: Verify2FAForm,
});

export { Verify2FA };
