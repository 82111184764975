import '@kuna-pay/utils/vite/define-global';
import '@kuna-pay/core/app/global';
import '@kuna-pay/ui/global';
import './app.scss';

import { withErrorBoundary } from '@kuna-pay/ui/error-boundary';

import { Pages } from '../pages';
import { withProvider } from './app.provider';
import { AppBoot } from './boot';

const App = withErrorBoundary(
  withProvider(() => (
    <AppBoot>
      <Pages />
    </AppBoot>
  ))
);

export { App };
