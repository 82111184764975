import resourcesToBackend from 'i18next-resources-to-backend';

class MonorepoBackend {
  public static create = () =>
    resourcesToBackend(
      // This loads namespaces in parallel
      // This loads languages in parallel too

      async (language: string, namespace: string) => {
        switch (namespace) {
          case 'common': {
            return import(`../locales/${language}.json`);
          }

          case 'core': {
            return import(
              `../../../../../../packages/core/src/shared/i18n/locales/${language}.json`
            );
          }

          default: {
            throw new Error(`Unknown namespace: ${namespace}`);
          }
        }
      }
    );
}

export { MonorepoBackend };
