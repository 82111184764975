import {
  UserProfileOutput,
  MutationUpdateProfileArgs,
} from '@kuna-pay/merchant/generated/graphql';
import { subscription, query } from '@kuna-pay/merchant/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const updateProfileRequest = (
  select: Select<UserProfileOutput> | string
) => `mutation updateProfile($data: UpdateProfileInput!) {
  data: updateProfile(data: $data) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'UserProfileOutput')}`;
export const updateProfile =
  (select: Select<UserProfileOutput> | string) =>
  (args: MutationUpdateProfileArgs) =>
    query<UserProfileOutput>(updateProfileRequest(select), args);
