import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
  scopeBind,
} from 'effector';
import { persist } from 'effector-storage/session';
import { condition } from 'patronum';

import { bridge } from '@kuna-pay/utils/misc';

function createTimer(
  seconds = 60,
  config: { persist?: { name: string } } = {}
) {
  const init = createEvent();
  const start = createEvent();
  const stop = createEvent();

  const tickFx = createEffect(() => wait(1000));
  const runNewTick = tickFx.prepend((_: void) => {});

  const $count = createStore(seconds);

  sample({
    clock: start,
    target: tickFx,
  });

  $count.on(tickFx, (count) => count - 1);

  condition({
    source: init,
    if: combine($count, (count) => count === seconds || count <= 0),
    then: stop,
    else: runNewTick,
  });

  condition({
    source: tickFx.done,
    if: combine($count, (count) => count > 0),
    then: runNewTick,
    else: stop,
  });

  $count.reset(stop);

  const $pending = combine($count, (count) => count > 0 && count !== seconds);

  /**
   * Its session storage,
   * so we don't have issues with multiple tabs timers running
   */
  if (config.persist) {
    persist({
      store: $count,
      key: config.persist.name,

      serialize: (value): string => {
        if (!value || value === seconds) return '';

        const timeInMs = Date.now();
        const endsAtMs = timeInMs + value * 1000;

        return `${endsAtMs}`;
      },
      deserialize: (endsAtMs) => {
        try {
          if (!endsAtMs) return 0;

          const endsAtMsNumber = Number(endsAtMs);

          if (Number.isNaN(endsAtMsNumber)) return 0;

          const timeInMs = Date.now();
          const timeLeft = endsAtMsNumber - timeInMs;

          if (timeLeft <= 0) return 0;

          return Math.ceil(timeLeft / 1000);
        } catch (e) {
          return 0;
        }
      },
    });

    /**
     * [Warning] Out of scope call
     *
     * This is a workaround for "appStarted" event architecture
     * which requires to call "init" event in the root of the app
     * and it a lots of time to refactor it
     *
     * TODO: effector-hooks
     * @see https://www.reatom.dev/package/hooks/
     */
    init();
  }

  return { $count, start, stop, $pending };
}

function createDynamicTimer(tickIntervalMS = 1_000) {
  type Timestamp = ConstructorParameters<typeof Date>[0];

  const init = createEvent<{ endsAt: Timestamp }>();
  const tick = createEvent<{ timeLeft: number }>();
  const reset = createEvent();

  const intervalFx = createEffect<Timestamp, void>();

  const $timeLeft = createStore<number | null>(null);

  let intervalId: NodeJS.Timer;

  bridge(() => {
    $timeLeft.on(init, (_, { endsAt }) =>
      Math.max(getTimeDiffInSeconds(new Date(), new Date(endsAt)), 0)
    );

    sample({
      clock: init,
      fn: ({ endsAt }) => endsAt,
      target: intervalFx,
    });

    intervalFx.use((endsAt: Timestamp) => {
      const scopedTick = scopeBind(tick, { safe: true });

      if (getTimeDiffInSeconds(new Date(), new Date(endsAt)) <= 0) {
        return Promise.reject();
      }

      return new Promise<void>((resolve) => {
        clearInterval(intervalId);

        intervalId = setInterval(() => {
          const timeLeft = getTimeDiffInSeconds(new Date(), new Date(endsAt));

          scopedTick({ timeLeft });

          if (timeLeft <= 0) {
            resolve();
          }
        }, tickIntervalMS);
      });
    });

    $timeLeft.on(tick, (_, { timeLeft }) => Math.max(timeLeft, 0));
  });

  function getTimeDiffInSeconds(startDate: Date, endDate: Date) {
    return Math.floor((endDate.getTime() - startDate.getTime()) / 1000);
  }

  bridge(() => {
    sample({
      clock: reset,
      target: createEffect(() => {
        clearInterval(intervalId);
      }),
    });

    $timeLeft.reset(reset);
  });

  return {
    init,
    tick,
    done: intervalFx.done,
    fail: intervalFx.fail,
    finally: intervalFx.finally,
    reset,

    $timeLeft,

    $pending: combine(
      $timeLeft,
      (timeLeft) => typeof timeLeft === 'number' && timeLeft > 0
    ),
  };
}

function wait(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export { createDynamicTimer, createTimer };
