import { useUnit } from 'effector-react';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Profile24Icon } from '@kuna-pay/ui/icons';
import { Divider } from '@kuna-pay/ui/ui/divider';

import { routes } from '@kuna-pay/merchant/shared/router';

import { ReactComponent as SignOutIcon } from '../../../assets/logout.svg';
import { Nav } from '../../navigation/nav';
import { HeaderMenuContext } from '../header-menu.context';
import { $$headerMenu } from '../header-menu.model';
import { UserMenuGate } from './user-menu.context';
import styles from './user-menu.mobile.module.scss';

const MobileUserMenuNavigation = {
  Profile: memo(() => {
    const { t } = useTranslation();

    const isGateMounted = useUnit(UserMenuGate.status);
    const closeMobileDrawer = useContext(HeaderMenuContext);

    const [isLoggedIntoCompany] = useUnit([$$headerMenu.$isCompanyLogged]);

    if (!isGateMounted) {
      return null;
    }

    /**
     * Since Profile Page relies on the company context,
     * we should not show it if the user is not logged into the company
     */
    if (!isLoggedIntoCompany) {
      return null;
    }

    return (
      <>
        <Nav.Item.Root
          className={styles.navigationItem}
          to={routes.profile.root}
          title={t('widgets.layout.header-menu.user-menu.profile')}
          onClick={closeMobileDrawer}
        >
          <Nav.Item.Icon>
            <Profile24Icon />
          </Nav.Item.Icon>

          <Nav.Item.Text>
            {t('widgets.layout.header-menu.user-menu.profile')}
          </Nav.Item.Text>
        </Nav.Item.Root>

        <Divider />
      </>
    );
  }),

  SignOut: memo(() => {
    const { t } = useTranslation();

    const isGateMounted = useUnit(UserMenuGate.status);
    const closeMobileDrawer = useContext(HeaderMenuContext);

    const [isLoggedIntoSystem, onLogoutClick] = useUnit([
      $$headerMenu.$isSystemLogged,
      $$headerMenu.$$userMenu.$$logout.logout,
    ]);

    if (!isGateMounted) {
      return null;
    }

    if (!isLoggedIntoSystem) {
      return null;
    }

    return (
      <>
        <Divider />

        <Nav.Item.Root
          className={styles.navigationItem}
          to={routes.auth.signIn}
          title={t('widgets.layout.header-menu.user-menu.profile')}
          onClick={() => {
            closeMobileDrawer();
            onLogoutClick();
          }}
        >
          <Nav.Item.Icon>
            <SignOutIcon />
          </Nav.Item.Icon>

          <Nav.Item.Text>
            {t('widgets.layout.header-menu.user-menu.logout')}
          </Nav.Item.Text>
        </Nav.Item.Root>
      </>
    );
  }),
};

export { MobileUserMenuNavigation };
