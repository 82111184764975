class ExternalURL {
  public constructor(public readonly href: string) {}

  public withLocale = (locale: string) => {
    if (this.href.includes('?lng')) {
      return this.href.replace(/lng=[a-z]{2}/, `lng=${locale}`);
    }

    return `${this.href}?lng=${locale}`;
  };

  public toString = () => this.href;

  public toHref = () => this.href;
}

class KUNAPayLandingURL extends ExternalURL {
  public constructor(public readonly path: string) {
    // @note: without www it redirects to kunapay.io
    const href = !path.startsWith('/')
      ? `https://www.kunapay.io/${path}`
      : `https://www.kunapay.io${path}`;

    super(href);
  }
}

export { ExternalURL, KUNAPayLandingURL };
