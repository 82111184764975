import {
  CheckboxField,
  FormCodeField,
  FormNumberField,
  FormPasswordInput,
  FormTextAreaField,
  FormTextField,
  RadioListField,
} from './adapters';

const Field = {
  TextField: FormTextField,
  NumberField: FormNumberField,
  TextAreaField: FormTextAreaField,
  PasswordInput: FormPasswordInput,
  CodeField: FormCodeField,
  RadioList: RadioListField,

  Checkbox: CheckboxField,
};

export { Field };
