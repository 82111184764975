import * as RadixCheckbox from '@radix-ui/react-checkbox';
import clsx from 'clsx';
import { forwardRef } from 'react';

import { CheckIcon } from '../../icons';
import { BaseButton } from '../button';
import type { CheckboxProps } from './checkbox.props';
import styles from '././checkbox.module.scss';

const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ size = 'md', className, classes, disableInteractive, ...props }, ref) => {
    const Root = disableInteractive ? 'div' : BaseButton;

    return (
      <RadixCheckbox.Root
        ref={ref}
        className={clsx(
          styles.root,
          className,
          classes?.root,
          BaseButton.className()
        )}
        data-size={size}
        {...props}
        asChild
      >
        <Root>
          <RadixCheckbox.Indicator
            className={clsx(styles.indicator, classes?.indicator)}
            data-size={size}
            asChild
          >
            {props.checked === 'indeterminate' ? (
              <span
                className={clsx(
                  styles.indeterminateIcon,
                  classes?.indeterminateIcon
                )}
                data-size={size}
              />
            ) : (
              <CheckIcon className={clsx(classes?.checkIcon)} />
            )}
          </RadixCheckbox.Indicator>
        </Root>
      </RadixCheckbox.Root>
    );
  }
);

export { Checkbox };
