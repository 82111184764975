import type { Event, Store } from 'effector';
import {
  clearNode,
  createEvent,
  createNode,
  restore,
  withRegion,
} from 'effector';
import { useUnit } from 'effector-react';
import { useEffect, useState } from 'react';

const useLocalModel = <T>(factory: () => T) => {
  const [{ region, $$model }] = useState(() => {
    const region = createNode();
    const $$model = withRegion(region, () => factory());

    return { region, $$model };
  });

  useEffect(
    () => () => {
      clearNode(region);
    },
    []
  );

  return $$model;
};

type IExternalDepsRef<T> = {
  $current: Store<T>;
  update: Event<T>;
};

const createExternalDepsRef = <T>(initialValue: T): IExternalDepsRef<T> => {
  const update = createEvent<T>();

  const $current = restore(update, initialValue);

  return { $current, update };
};

const useSyncExternalDeps = <T>($$ref: IExternalDepsRef<T>, deps: T) => {
  const update = useUnit($$ref.update);

  useEffect(() => {
    update(deps);
  }, [deps]);
};

export { createExternalDepsRef, useLocalModel, useSyncExternalDeps };
