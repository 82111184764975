import { useUnit } from 'effector-react';
import { memo, useMemo } from 'react';

import { Skeleton } from '../../skeleton';
import { DataGridModel } from '../model';
import type { GridColumn } from '../types';
import { DataGridCell, TableRow } from './data';

type DataGridBodyLoadingProps<T extends Record<string, unknown>> = {
  columns: GridColumn<T>[];
  gridTemplateColumns: string;

  classNames?: {
    row?: string;
    loadingSkeletonContainer?: string;
    loadingSkeleton?: string;
  };
};

const DataGridBodyLoading = <T extends Record<string, unknown>>({
  columns,
  gridTemplateColumns,
  classNames,
}: DataGridBodyLoadingProps<T>) => {
  const $$model = DataGridModel.useModel<T>();
  const { limit } = useUnit({ limit: $$model.$limit });

  const rows = useMemo(
    () => Array.from({ length: Math.min(limit, 20) }).fill(null),
    [limit]
  );

  return (
    <>
      {rows.map((_, index) => (
        <TableRow
          className={classNames?.row}
          key={index}
          style={{ gridTemplateColumns }}
        >
          {columns.map((column) => (
            <DataGridBodyLoadingCell
              key={`${column.field}:${column.label}`}
              classNames={classNames}
            />
          ))}
        </TableRow>
      ))}
    </>
  );
};

type DataGridBodyLoadingCellProps = {
  classNames?: DataGridBodyLoadingProps<Record<string, unknown>>['classNames'];
};

const DataGridBodyLoadingCell = memo(
  ({ classNames }: DataGridBodyLoadingCellProps) => (
    <DataGridCell>
      <Skeleton
        containerClassName={classNames?.loadingSkeletonContainer}
        className={classNames?.loadingSkeleton}
        borderRadius={8}
        flex
        fullWidth
      />
    </DataGridCell>
  )
);

export { DataGridBodyLoading, DataGridBodyLoadingCell };
