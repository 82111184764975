import { NavbarGate } from './navbar.context';
import { DesktopNavbar } from './navbar.desktop.component';
import { MobileNavbar } from './navbar.mobile.component';

const Navbar = {
  Gate: NavbarGate,
  Desktop: DesktopNavbar,
  Mobile: MobileNavbar,
};

export { Navbar };
