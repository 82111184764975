import clsx from 'clsx';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useId } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { CheckboxProps } from '@kuna-pay/ui/ui/checkbox';
import { Checkbox } from '@kuna-pay/ui/ui/checkbox';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import type { FieldModel } from '../../lib';
import { useField } from '../../lib';
import styles from './checkbox.module.scss';

const IdContext = createContext<string>(null!);

const CheckboxField = createCompoundComponent(
  () =>
    ({ children }: PropsWithChildren) => {
      const id = useId();

      return <IdContext.Provider value={id}>{children}</IdContext.Provider>;
    },
  {
    Control: ({
      field,
      ...props
    }: CheckboxProps & {
      field: FieldModel<boolean>;
    }) => {
      const id = useContext(IdContext);
      const { path, value, onChange, onFocus, onBlur } = useField(field);

      return (
        <Checkbox
          id={id}
          name={path}
          checked={value}
          onCheckedChange={(checked) => onChange(!!checked)}
          onFocus={onFocus}
          onBlur={onBlur}
          {...props}
        />
      );
    },
    Label: ({ className, ...props }: TypographyProps) => {
      const id = useContext(IdContext);

      return (
        <Typography
          as='label'
          className={clsx(styles.label, className)}
          htmlFor={id}
          {...props}
        />
      );
    },
  }
);

export { CheckboxField };
