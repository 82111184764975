import clsx from 'clsx';
import type { HTMLAttributes } from 'react';

import { InfoIcon } from '@kuna-pay/ui/icons';
import { styled } from '@kuna-pay/ui/theme';

import styles from './ui.module.scss';

type NoticeProps = HTMLAttributes<HTMLDivElement>;

const Notice = ({ className, children, ...props }: NoticeProps) => (
  <div className={clsx(styles.container, className)} {...props}>
    <InfoIcon className={styles.icon} width={20} height={20} />

    <div className={styles.content}>{children}</div>
  </div>
);

Notice.Section = styled('p', styles.section);

Notice.Highlight = styled('span', styles.hightlight);

export { Notice };
