import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { JWTCompanyRole } from '@kuna-pay/core/entities/session';

import {
  AccessDenied,
  CanAccess,
  VerificationGuard,
} from '@kuna-pay/merchant/entities/session';
import { CompanyType } from '@kuna-pay/merchant/generated/graphql';
import { MainLayout } from '@kuna-pay/merchant/widgets/layout';

import restrictedImg from '../assets/restricted.png';

const PayoutLayout = memo(() => {
  const { t } = useTranslation();

  return (
    <CanAccess.Company.Type
      type={[CompanyType.Entrepreneur, CompanyType.LegalEntity]}
      placeholder={
        <MainLayout.Merchant.Content>
          <AccessDenied />
        </MainLayout.Merchant.Content>
      }
    >
      <CanAccess.Company.Role
        role={[JWTCompanyRole.Admin, JWTCompanyRole.Owner]}
        placeholder={
          <MainLayout.Merchant.Content>
            <AccessDenied />
          </MainLayout.Merchant.Content>
        }
      >
        <VerificationGuard
          from='Payouts'
          unverified={
            <>
              <VerificationGuard.Img
                src={restrictedImg}
                width={326}
                height={160}
              />

              <VerificationGuard.Title>
                {t('pages.payouts.unverified.title')}
              </VerificationGuard.Title>

              <VerificationGuard.Description>
                {t('pages.payouts.unverified.description')}
              </VerificationGuard.Description>
            </>
          }
          Layout={MainLayout.Merchant.Content}
        >
          <Outlet />
        </VerificationGuard>
      </CanAccess.Company.Role>
    </CanAccess.Company.Type>
  );
});

export { PayoutLayout };
