type RateFormInValues = { rate: 'good' | 'bad' | null };
type RateFormOutValues = { rate: 'good' | 'bad' };

type ShareFeedbackFormInValues = { feedback?: string };
type ShareFeedbackFormOutValues = { feedback?: string };

enum RateYourExperienceSteps {
  Rate = 'rate',
  Feedback = 'feedback',
  Success = 'success',
}

export type {
  RateFormInValues,
  RateFormOutValues,
  ShareFeedbackFormInValues,
  ShareFeedbackFormOutValues,
};
export { RateYourExperienceSteps };
