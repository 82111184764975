const boxShadowAsBorder = Object.assign(
  (sizeInPixel: number, color: string) => `0 0 0 ${sizeInPixel}px ${color}`,

  {
    bottom: (sizeInPixel: number, color: string) =>
      `0 ${sizeInPixel}px 0 ${color}`,
  }
);

export { boxShadowAsBorder };
