import { TimeoutedResendButton } from './timeouted-resend.component';
import { TimeoutedResendModel } from './timeouted-resend.model';

const TimeoutedResend = Object.assign(TimeoutedResendButton, {
  factory: TimeoutedResendModel,
  Button: TimeoutedResendButton,
});

export { TimeoutedResend };
export type { TimeoutedResendButtonProps } from './timeouted-resend.component';
