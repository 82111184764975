import { string } from 'yup';

import { atom } from '@kuna-pay/utils/misc';

import { LocalStoragePersistKeys } from '@kuna-pay/merchant/shared/persist';

import { createSearchParamsCollector } from '../lib/search-params-collector';

const $$referral = atom(() => ({
  getReferrer: async () => {
    const referralFromLocalStorage = localStorage.getItem(
      LocalStoragePersistKeys.Referrer
    );

    if (string().uuid().isValidSync(referralFromLocalStorage)) {
      return referralFromLocalStorage;
    }

    return null;
  },

  useRefCollector: createSearchParamsCollector({
    searchParamKey: 'ref',
    storageKey: LocalStoragePersistKeys.Referrer,
    storage: localStorage,
  }),
}));

export { $$referral };
