import { createContext, useContext } from 'react';

import type { TabsVariant } from './tabs.props';

const TabsVariantContext = createContext<TabsVariant>(null as never);

const useTabVariant = () => useContext(TabsVariantContext);

const TabsVariantProvider = TabsVariantContext.Provider;

export { TabsVariantProvider, useTabVariant };
