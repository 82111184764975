import { useUnit } from 'effector-react';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Bell24Icon } from '@kuna-pay/ui/icons';
import { Divider } from '@kuna-pay/ui/ui/divider';
import { Typography } from '@kuna-pay/ui/ui/typography';

import { $$pushNotifications } from '@kuna-pay/merchant/entities/push-notification';
import { routes } from '@kuna-pay/merchant/shared/router';

import { Nav } from '../../navigation/nav';
import { HeaderMenuContext } from '../header-menu.context';
import { $$headerMenu } from '../header-menu.model';
import styles from './notification-menu.mobile.module.scss';

const MobilePushNotificationsMenuNavigation = memo(() => {
  const { t } = useTranslation();

  const [isLoggedIntoCompany, unreadNotificationCounter] = useUnit([
    $$headerMenu.$isCompanyLogged,
    $$pushNotifications.$$unreadNotificationCounter.$data,
  ]);
  const closeMobileDrawer = useContext(HeaderMenuContext);

  if (!isLoggedIntoCompany) {
    return null;
  }

  return (
    <>
      <Nav.Item.Root
        title={t('widgets.layout.header-menu.notification-menu.mobile')}
        className={styles.mobileNavigationItem}
        to={routes.notifications.root}
        onClick={closeMobileDrawer}
      >
        <Nav.Item.Icon>
          <Bell24Icon />
        </Nav.Item.Icon>

        <Nav.Item.Text>
          {t('widgets.layout.header-menu.notification-menu.mobile')}
        </Nav.Item.Text>

        {unreadNotificationCounter && (
          <Typography className={styles.counter} variant='subtitle5'>
            {unreadNotificationCounter.count}
          </Typography>
        )}
      </Nav.Item.Root>

      <Divider />
    </>
  );
});

export { MobilePushNotificationsMenuNavigation };
