class Cookie {
  public static getCookie(name: string) {
    const nameEQ = `${name}=`;
    const cookies = document.cookie.split(';');

    for (let cookie of cookies) {
      while (cookie.startsWith(' '))
        cookie = cookie.substring(1, cookie.length);

      if (cookie.startsWith(nameEQ))
        return cookie.substring(nameEQ.length, cookie.length);
    }

    return null;
  }

  // set cookie but do not erase other cookies
  public static setCookie({
    name,
    value,
    minutes,
  }: {
    name: string;
    value: string;
    minutes: number;
  }) {
    let expires = '';

    if (minutes) {
      const date = new Date();

      date.setTime(date.getTime() + minutes * 60 * 1000);

      expires = `; expires=${date.toUTCString()}`;
    }

    document.cookie = `${name}=${value || ''}${expires}; path=/`;
  }

  public static eraseCookie(name: string) {
    document.cookie = `${name}=; Max-Age=-99999999;`;
  }
}

export { Cookie };
