import { createContext, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  PasswordRequirementItem,
  PasswordRequirementsContext as PasswordRequirementsContextType,
} from './password-requirements.types';

const PasswordRequirementsContext =
  createContext<PasswordRequirementsContextType | null>(null);

const RequirementItemContext = createContext<PasswordRequirementItem | null>(
  null
);

const PasswordRequirementsContextProvider =
  PasswordRequirementsContext.Provider;

const RequirementItemContextProvider = RequirementItemContext.Provider;

const usePasswordRequirementsContext = () => {
  const value = useContext(PasswordRequirementsContext);

  if (!value) {
    throw new Error('PasswordRequirementsContext is not found');
  }

  return value;
};

const usePasswordRequirementItemContext = () => {
  const value = useContext(RequirementItemContext);

  if (!value) {
    throw new Error('usePasswordRequirementItemContext is not found');
  }

  return value;
};

const usePasswordRequirements = (requirements: PasswordRequirementItem[]) => {
  const { t } = useTranslation('core', {
    keyPrefix: 'shared.ui.password-requirements',
  });

  return useMemo((): PasswordRequirementsContextType => {
    const totalPassed = requirements.reduce(
      (total, { passed }) => (passed ? total + 1 : total),
      0
    );

    const isPerfect = totalPassed === requirements.length;
    const isMedium = totalPassed > 1;
    const isLow = !isPerfect && !isMedium;

    if (isPerfect) {
      return {
        title: t('strength.perfect'),
        mode: 'success',
        totalPassed,
        requirements,
      };
    }

    if (isLow) {
      return {
        title: t('strength.low'),
        mode: 'danger',
        totalPassed,
        requirements,
      };
    }

    return {
      title: t('strength.medium'),
      mode: 'warning',
      totalPassed,
      requirements,
    };
  }, [t, requirements]);
};

export {
  PasswordRequirementsContextProvider,
  RequirementItemContextProvider,
  usePasswordRequirementItemContext,
  usePasswordRequirements,
  usePasswordRequirementsContext,
};
