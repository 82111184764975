import { useUnit } from 'effector-react';
import { memo } from 'react';

import { $$pushNotifications } from '@kuna-pay/merchant/entities/push-notification';
import { $$session } from '@kuna-pay/merchant/entities/session';
import { $$notificationsFeatureFlag } from '@kuna-pay/merchant/shared/feature-flags/features/dynamic';

import { DesktopPushNotificationsMenu } from './notification-menu.desktop.component';
import { MobilePushNotificationsMenuNavigation } from './notification-menu.mobile.component';

const PushNotificationsMenu = {
  /* KUPAY-2039 */
  Gate: memo(() => {
    const id = useUnit($$session.$$jwt.$$company.$id)!;
    const PushNotificationsGate = $$pushNotifications.Gate;
    const notificationsFeatureFlag = useUnit($$notificationsFeatureFlag);

    if (notificationsFeatureFlag.disabled) {
      return null;
    }

    return <PushNotificationsGate key={id} companyId={id} />;
  }),

  MobileNavigation: MobilePushNotificationsMenuNavigation,

  Desktop: DesktopPushNotificationsMenu,
};

export { PushNotificationsMenu };
