import { memo, useContext } from 'react';

import type { PartialField } from '@kuna-pay/utils/typescript';
import { useTheme } from '@kuna-pay/ui/theme';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import { useFormError } from '../lib';
import { FormContext } from './context';

type FormErrorProps = PartialField<TypographyProps, 'variant'> & {
  opacity?: boolean;
};

const FormError = memo(({ opacity, children, ...props }: FormErrorProps) => {
  const error = useFormError(useContext(FormContext));
  const theme = useTheme();

  return (
    <Typography
      as='p'
      variant='subtitle5'
      center
      style={
        !opacity
          ? {
              minHeight: 1,
              color: theme.colors.red400,
              transition: 'margin 0s',
            }
          : {
              minHeight: 1,
              color: theme.colors.red400,
              transition: 'margin 0s, opacity 0.25s',
              opacity: error ? 1 : 0,
            }
      }
      {...props}
    >
      {error || children}
    </Typography>
  );
});

export { FormError };
