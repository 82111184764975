import { Suspense } from 'react';
import type { RouteObject } from 'react-router-dom';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';
import { AdaptivePaper } from '@kuna-pay/core/shared/ui/adaptive-paper';

const Page = lazyWithRetryAndRefresh(
  'SignUpPage',
  () => import('./page.component')
);

const SignUpPage: RouteObject = {
  path: '/sign-up',

  Component: () => (
    <Suspense fallback={<AdaptivePaper.Loading />}>
      <Page />
    </Suspense>
  ),
};

export { SignUpPage };
