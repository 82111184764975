import clsx from 'clsx';
import type { FC, MutableRefObject, PropsWithChildren } from 'react';
import { memo } from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';
import { createContext } from 'react';
import { cloneElement, forwardRef, useId } from 'react';

import { useFocusState } from '@kuna-pay/utils/hooks';
import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { CloseIcon } from '@kuna-pay/ui/icons';
import { IconButton } from '@kuna-pay/ui/ui/button';

import { useExternalRef } from '@kuna-pay/card-number/lib';

import type { TypographyProps } from '../typography';
import { Typography } from '../typography';
import type { BaseInputProps } from './base-input';
import { BaseInput, BaseTextArea } from './base-input';
import type {
  InputAdornmentProps,
  TextAreaFieldProps,
  TextFieldContainerProps,
  TextFieldProps,
  TextFieldSize,
} from './text-field.props';
import styles from './text-field.module.scss';

const TextFieldInputContext = createContext<BaseInputProps>(
  {} as BaseInputProps
);
const TextFieldContainerContext = createContext<TextFieldContainerProps>(
  {} as TextFieldContainerProps
);

const TextField = createCompoundComponent(
  (C) =>
    forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => (
      <C.Provider {...props}>
        <C.Container>
          <C.Input ref={ref} />
        </C.Container>
      </C.Provider>
    )),
  {
    Provider: memo(
      ({
        children, //style
        size,
        variant,
        palette = 'basic',
        classes,
        className,

        //extra
        start,
        end,
        isError,
        helperText,
        hideHelperText,
        hideHead,
        label,

        //input
        id: externalId,
        value,
        disabled,
        onBlur,
        onFocus,
        ...props
      }: PropsWithChildren & TextFieldProps) => {
        const stableId = useId();
        const $$focus = useFocusState();
        const id = externalId ?? stableId;

        const containerProps = useMemo(
          (): TextFieldContainerProps => ({
            className,
            classes,
            id,
            size,
            variant,
            label,
            isError,
            helperText,
            start,
            end,
            disabled,
            palette,
            hideHelperText,
            hideHead,
            inputHasValue: !!value,
            focused: $$focus.isFocused,
          }),
          [
            className,
            classes,
            id,
            size,
            variant,
            label,
            isError,
            helperText,
            start,
            end,
            disabled,
            palette,
            hideHelperText,
            hideHead,
            value,
            $$focus.isFocused,
          ]
        );

        const inputProps = useMemo(
          (): BaseInputProps => ({
            className: clsx(
              styles.input,
              classes?.input,
              {
                sm: Typography.classes.small2,
                md: Typography.classes.numbers1,
                lg: Typography.classes.subtitle5,
                'md-lg': Typography.classes.subtitle5,
              }[size]
            ),
            id,
            value,
            disabled,
            onFocus: (e) => {
              $$focus.onFocus();
              onFocus?.(e);
            },
            onBlur: (e) => {
              $$focus.onBlur();
              onBlur?.(e);
            },
            'data-size': size,
            'data-has-start-adornment': !!start,
            'data-has-end-adornment': !!end,
            ...props,
          }),
          [
            classes,
            id,
            size,
            value,
            disabled,
            onFocus,
            onBlur,
            start,
            end,
            props,
          ]
        );

        return (
          <TextFieldContainerContext.Provider value={containerProps}>
            <TextFieldInputContext.Provider value={inputProps}>
              {children}
            </TextFieldInputContext.Provider>
          </TextFieldContainerContext.Provider>
        );
      }
    ),

    Container: memo(({ children }: PropsWithChildren) => {
      const props = useContext(TextFieldContainerContext);

      return <TextFieldContainer {...props}>{children}</TextFieldContainer>;
    }),

    Input: forwardRef<HTMLInputElement, BaseInputProps>((inputProps, ref) => {
      const props = useContext(TextFieldInputContext);

      return (
        <BaseInput
          ref={ref}
          {...props}
          {...inputProps}
          /**
           * This should be merged instead of overridden
           * because there is internal logic behind this
           */
          className={clsx(props.className, inputProps.className)}
          onFocus={(...args) => {
            props?.onFocus?.(...args);
            inputProps?.onFocus?.(...args);
          }}
          onBlur={(...args) => {
            props?.onBlur?.(...args);
            inputProps?.onBlur?.(...args);
          }}
        />
      );
    }),
  }
);

const TextAreaField = forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  (
    {
      //style
      size,
      variant,
      palette = 'basic',
      classes,

      //extra
      isError,
      helperText,
      hideHelperText,
      label,
      onClear,

      //input
      id,
      value = '',
      disabled,
      onBlur,
      onFocus,
      className,
      maxLength,
      rows = 5,

      ...props
    },
    forwardedRef
  ) => {
    const stableId = useId();
    const $$focus = useFocusState();

    const ref = useExternalRef(
      forwardedRef as MutableRefObject<HTMLTextAreaElement>
    );

    const maxLengthCounter = maxLength
      ? `${value.toString().trim().length}/${maxLength}`
      : '';

    return (
      <TextFieldContainer
        id={id ?? stableId}
        size={size}
        variant={variant}
        label={label}
        isError={isError}
        helperText={helperText}
        inputHasValue={!!value}
        disabled={disabled}
        focused={$$focus.isFocused}
        palette={palette}
        hideHelperText={hideHelperText}
        classes={classes}
        className={clsx(className, styles.textareaRoot)}
        topEnd={maxLengthCounter}
      >
        <BaseTextArea
          ref={ref}
          className={clsx(
            styles.textarea,
            classes?.textarea,
            {
              sm: Typography.classes.small2,
              md: Typography.classes.numbers1,
              lg: Typography.classes.subtitle5,
              'md-lg': Typography.classes.subtitle5,
            }[size]
          )}
          id={id ?? stableId}
          value={value}
          disabled={disabled}
          // maxLength={maxLength} to not trim pasted text
          rows={rows}
          onFocus={(e) => {
            $$focus.onFocus();
            onFocus?.(e);
          }}
          onBlur={(e) => {
            $$focus.onBlur();
            onBlur?.(e);
          }}
          data-size={size}
          data-has-start-adornment={false}
          data-has-end-adornment={false}
          {...props}
        />

        {!disabled &&
          // TODO: Simulate native change event for textarea
          !!onClear && (
            <IconButton
              className={clsx(styles.clear, {
                [styles.touched]: !!value,
                [styles.focused]: $$focus.isFocused,
              })}
              onClick={() => {
                onClear?.();

                if (ref && 'current' in ref) {
                  ref.current?.focus?.();
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
      </TextFieldContainer>
    );
  }
);

const TextFieldContainer: FC<TextFieldContainerProps> = ({
  className,
  children,
  variant,
  inputHasValue,
  disabled,
  isError,
  hideHelperText,
  helperText,
  size,
  end,
  start,
  label,
  id,
  focused,
  palette,
  classes,
  topEnd,
  hideHead,
}) => (
  <div
    className={clsx(styles.wrapper, classes?.wrapper, className)}
    title={
      hideHelperText && typeof helperText === 'string' ? helperText : undefined
    }
  >
    {!hideHead && (
      <div className={styles.topContainer} data-size={size}>
        {label && (
          <TextFieldText
            className={clsx(styles.label, classes?.label)}
            htmlFor={id}
            size={size}
            data-input-has-focused={!!focused}
            data-input-has-value={!!inputHasValue}
            data-input-has-error={!!isError}
            data-input-disabled={!!disabled}
          >
            {label}
          </TextFieldText>
        )}

        {topEnd && (
          <TextFieldText
            className={clsx(styles.label, classes?.label)}
            htmlFor={id}
            size={size}
            data-input-has-focused={!!focused}
            data-input-has-value={!!inputHasValue}
            data-input-has-error={!!isError}
            data-input-disabled={!!disabled}
          >
            {topEnd}
          </TextFieldText>
        )}
      </div>
    )}

    <div
      className={clsx(styles.container, classes?.container)}
      data-variant={variant}
      data-palette={palette}
      data-input-disabled={!!disabled}
      data-input-has-focus={!!focused}
      data-input-has-error={!!isError}
    >
      {!!start &&
        (start.type === InputAdornment
          ? cloneElement(start, { position: 'start', size })
          : start)}

      {children}

      {!!end &&
        (end.type === InputAdornment
          ? cloneElement(end, { position: 'end', size })
          : end)}
    </div>

    {helperText && !hideHelperText && (
      <TextFieldText
        className={clsx(
          styles.helperText,
          classes?.helperText,
          isError && styles.isError,
          isError && classes?.error
        )}
        size={size}
      >
        {helperText}
      </TextFieldText>
    )}
  </div>
);

const InputAdornment: FC<InputAdornmentProps> = ({
  className,
  position,
  size,
  ...props
}) => (
  <div
    className={clsx(styles.adornment, className)}
    data-position={position}
    data-size={size}
    {...props}
  />
);

const TextFieldText: FC<
  Omit<TypographyProps, 'variant'> & { size: TextFieldSize }
> = ({ className, size, ...props }) => (
  <Typography
    className={clsx(styles.text, className)}
    variant={
      (
        {
          sm: 'small2',
          md: 'small2',
          lg: 'numbers1',
          'md-lg': 'numbers1',
        } as const
      )[size]
    }
    data-size={size}
    {...props}
  />
);

export { InputAdornment, TextAreaField, TextField, TextFieldContainer };
