import { memo } from 'react';

import type { PasswordRequirementItem } from './password-requirements';
import { PasswordRequirements } from './password-requirements';

type DesktopPasswordRequirementsProps = {
  requirements: PasswordRequirementItem[];
};

const DesktopPasswordRequirements = memo(
  ({ requirements }: DesktopPasswordRequirementsProps) => (
    <PasswordRequirements.Provider requirements={requirements}>
      <PasswordRequirements.Root shadow>
        <PasswordRequirements.Title variant='subtitle3' />

        <PasswordRequirements.StrengthIndicator />

        <PasswordRequirements.Description>
          <PasswordRequirements.DescriptionTitle variant='numbers2' />

          {requirements.map((requirement) => (
            <PasswordRequirements.Requirement.Provider
              key={requirement.text}
              value={requirement}
            >
              <PasswordRequirements.Requirement.Root>
                <PasswordRequirements.Requirement.Icon />

                <PasswordRequirements.Requirement.Text variant='numbers1'>
                  {requirement.text}
                </PasswordRequirements.Requirement.Text>
              </PasswordRequirements.Requirement.Root>
            </PasswordRequirements.Requirement.Provider>
          ))}

          <PasswordRequirements.Divider />

          <PasswordRequirements.Hint.Root>
            <PasswordRequirements.Hint.Icon />

            <PasswordRequirements.Hint.Text variant='numbers1' />
          </PasswordRequirements.Hint.Root>
        </PasswordRequirements.Description>
      </PasswordRequirements.Root>
    </PasswordRequirements.Provider>
  )
);

export { DesktopPasswordRequirements };
