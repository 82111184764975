import { Suspense } from 'react';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';

import {
  MainLayout,
  MainLayoutLoading,
} from '@kuna-pay/merchant/widgets/layout';

const Page = lazyWithRetryAndRefresh(
  'TransactionListPage',
  () => import('./page.component')
);

const TransactionListPage = {
  Component: () => (
    <Suspense
      fallback={
        <MainLayout.Merchant.Content>
          <MainLayoutLoading />
        </MainLayout.Merchant.Content>
      }
    >
      <Page />
    </Suspense>
  ),
};

export { TransactionListPage };
