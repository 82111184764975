import type { FC } from 'react';

import {
  MainLayout,
  MainLayoutLoading,
} from '@kuna-pay/merchant/widgets/layout';

const VerificationPageLoading: FC = () => (
  <MainLayout.Merchant.Content>
    <MainLayoutLoading width={455} height={638} />
  </MainLayout.Merchant.Content>
);

export { VerificationPageLoading };
