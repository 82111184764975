/* eslint-disable @typescript-eslint/lines-between-class-members */

import { RecaptchaError } from './recaptcha.error';

class ReCaptcha {
  private widgetId: number | undefined;

  private resolveCallback: undefined | ((token: string | undefined) => void);
  private rejectCallback: undefined | (() => void);

  private readonly $container: HTMLDivElement;

  public constructor(private readonly SITE_KEY: string) {
    const globalScriptOnLoadName = 'recaptchaOnLoad' as const;
    const $script = document.createElement('script');
    $script.src = `https://www.google.com/recaptcha/api.js?render=explicit&onload=${globalScriptOnLoadName}`;
    $script.id = 'recaptcha';
    $script.async = true;

    window[globalScriptOnLoadName] = () => {
      this.widgetId = window.grecaptcha.render(this.$container, {
        sitekey: this.SITE_KEY,
        size: 'invisible',
        callback: (token) => {
          if (this.resolveCallback) {
            this.resolveCallback(token);
          }
        },
        'error-callback': () => {
          if (this.rejectCallback) {
            this.rejectCallback();
          }
        },
        'expired-callback': () => {
          if (this.rejectCallback) {
            this.rejectCallback();
          }
        },
      });
    };

    this.$container = document.createElement('div');
    this.$container.style.display = 'none';

    document.body.append($script);
    document.body.append(this.$container);
  }

  public showReCaptchaBadge(): void {
    this.$container.style.display = 'block';
  }

  public hideReCaptchaBadge(): void {
    this.$container.style.display = 'none';
  }

  public async getToken(): Promise<string | null> {
    if (typeof this.widgetId !== 'number') {
      throw new RecaptchaError(
        'reCaptcha widget exists, but widgetId is missing. Widget cannot be rendered.'
      );
    }

    try {
      const token = await new Promise<string | null>((resolve, reject) => {
        this.resolveCallback = (token) => {
          resolve(token ?? null);
          this.resolveCallback = undefined;
          this.rejectCallback = undefined;
        };

        this.rejectCallback = () => {
          reject(new RecaptchaError('reCaptcha token generation failed'));
          this.resolveCallback = undefined;
          this.rejectCallback = undefined;
        };

        /**
         * Even tho its promise-like, and have token in result
         * It will be `null`, and the actual token will be passed to `callback`
         */
        void window.grecaptcha.execute(this.widgetId);
      });

      /**
       * Without this, following `execute` will hang forever
       */
      window.grecaptcha.reset(this.widgetId);

      return token;
    } catch (e) {
      /**
       * (in theory) Without this, following `execute` will hang forever
       */
      window.grecaptcha.reset(this.widgetId);

      throw e;
    }
  }
}

export { ReCaptcha };
