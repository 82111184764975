import type { Store } from 'effector';
import { useStoreMap } from 'effector-react';
import type { FC, PropsWithChildren, ReactElement } from 'react';
import { isValidElement } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';

const StateContext = createContext<{ $value: Store<string> } | null>(null);

type StateProps<State extends string> = PropsWithChildren & {
  $$model: { $value: Store<State> };
};

type StateCaseProps<TState extends string> = {
  value: TState;
  view: FC | ReactElement;
};

const StateSwitch = Object.assign(
  <TState extends string>({ $$model, children }: StateProps<TState>) => (
    <StateContext.Provider
      value={$$model as unknown as { $value: Store<string> }}
    >
      {children}
    </StateContext.Provider>
  ),
  {
    Case: <TState extends string>({
      value,
      view: View,
    }: StateCaseProps<TState>) => {
      const { $value } = useContext(StateContext)!;

      const isMatch = useStoreMap({
        keys: [value],
        store: $value,
        fn: (state, [value]) => state === value,
      });

      if (!isMatch) return null;

      if (isValidElement(View)) {
        return View;
      }

      const Component = View as FC;

      return <Component />;
    },
  }
);

export { StateSwitch };
