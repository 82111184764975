import clsx from 'clsx';
import { forwardRef } from 'react';

import type { PartialField } from '@kuna-pay/utils/typescript';

import { CloseIcon, SearchIcon } from '../../../icons';
import { IconButton } from '../../button';
import { InputAdornment, TextField } from '../text-field.component';
import type { TextFieldProps } from '../text-field.props';
import styles from '././search-field.module.scss';

type SearchFieldProps = PartialField<
  Omit<TextFieldProps, 'end'>,
  'variant' | 'size'
> & {
  onClear?: () => void;
};

const SearchField = forwardRef<HTMLInputElement, SearchFieldProps>(
  (
    { className, size = 'md', variant = 'filled', start, onClear, ...props },
    ref
  ) => (
    <TextField
      className={clsx(styles.input, className)}
      ref={ref}
      start={
        start ?? (
          <InputAdornment>
            <SearchIcon />
          </InputAdornment>
        )
      }
      end={
        <InputAdornment>
          <IconButton onClick={onClear}>
            <CloseIcon className={styles.close} data-hidden={!props.value} />
          </IconButton>
        </InputAdornment>
      }
      variant={variant}
      size={size}
      {...props}
    />
  )
);

export { SearchField };
export type { SearchFieldProps };
