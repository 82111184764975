import deepmerge from 'deepmerge';
import type { EffectParams, Event, Store } from 'effector';
import { is } from 'effector';
import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector';
import { set } from 'object-path-immutable';
import type { AnySchema, SchemaOf } from 'yup';
import { ValidationError } from 'yup';

import { atom, bridge } from '@kuna-pay/utils/misc';

import { createFields } from './create-fields';
import { withFormError } from './form-error';
import type { CreateFormOptions, DeepPartial, FormModel } from './types';
import { ValidateOnEventType } from './types';
import { ValidationVisibilityCondition } from './types';
import type { MappedErrors } from './yup';
import { yupToFormErrors } from './yup';

type DecoratedFormModel<V> = FormModel<V & { formError: string }> & {
  setFormError: Event<string>;
};

//TODO: move to https://github.com/filledout/filledout
const createForm = withFormError(
  <V>({ reinitialize, ...options }: CreateFormOptions<V>): FormModel<V> => {
    const name = options.name ?? '$$form.unnamed';

    const $initialValues = is.store(options.initialValues)
      ? (options.initialValues as Store<V>)
      : createStore(options.initialValues as unknown as V, {
          name: `${name}.$initialValues`,
        });

    const validateOn = options.validateOn ?? [
      ValidateOnEventType.Change,
      ValidateOnEventType.Blur,
    ];

    const showValidationWhen = options.showValidationWhen ?? [
      ValidationVisibilityCondition.Touched,
      ValidationVisibilityCondition.Submitted,
    ];

    const change = createEvent<[string, any]>(`${name}.change`);

    const submit = createEvent(`${name}.submit`);

    const manualValidate = createEvent(`${name}.manualValidate`);

    const submitted = createEvent<V>(`${name}.submitted`);

    const rejected = createEvent<{
      values: V;
      errors: MappedErrors<V>;
    }>(`${name}.rejected`);

    const $schema = is.store(options.schema)
      ? options.schema
      : createStore(options.schema ?? null, {
          name: `${name}.$schema`,
        });

    const patch = createEvent<DeepPartial<V>>(`${name}.patch`);

    const put = createEvent<V>(`${name}.put`);

    const reset = createEvent<V | void>(`${name}.reset`);
    const reinit = createEvent(`${name}.reinit`);

    const blured = createEvent<[string]>(`${name}.blured`);

    const focused = createEvent<[string]>(`${name}.focused`);

    const baseValidateFx = createEffect<
      { values: V; schema: SchemaOf<V> },
      void,
      MappedErrors<V>
    >({
      name: `${name}.baseValidateFx`,
      handler: async ({ values, schema }) => {
        if (!schema) return;

        await validate({ values, schema });
      },
    });

    const validateFx = attach({
      name: `${name}.validateFx`,
      // TODO: remove after https://github.com/effector/effector/issues/1000
      mapParams: (params: EffectParams<typeof baseValidateFx>) => params,
      effect: baseValidateFx,
    });

    const validateBeforeSubmitFx = attach({
      name: `${name}.validateBeforeSubmitFx`,
      // TODO: remove after https://github.com/effector/effector/issues/1000
      mapParams: (params: EffectParams<typeof baseValidateFx>) => params,
      effect: baseValidateFx,
    });

    const $submitCount = createStore(0, {
      name: `${name}.$submitCount`,
    });

    const $submitted = combine($submitCount, (state) => state > 0);

    const $disabled =
      options.$disabled ??
      createStore(false, {
        name: `${name}.$disabled`,
      });

    // const $validating = options.$validating ?? createStore(false);

    //FIXME: tempo
    // eslint-disable-next-line effector/no-getState
    const $values = createStore<V>($initialValues.getState(), {
      name: `${name}.$values`,
    });

    const $externalErrors =
      options.$errors ??
      createStore<MappedErrors<V>>(
        {},
        {
          name: `${name}.$externalErrors`,
        }
      );

    const $validationErrors = createStore<MappedErrors<V>>(
      {},
      {
        name: `${name}.$validationErrors`,
      }
    );

    const $errors = combine(
      $externalErrors,

      $validationErrors,

      (externalErrors, validationErrors): MappedErrors<V> =>
        deepmerge(externalErrors as any, validationErrors as any, {
          arrayMerge: (_, sourceArray) => sourceArray,
        })
    );

    const $dirty = createStore<Record<string, boolean>>(
      {},
      {
        name: `${name}.$dirty`,
      }
    );

    const $touched = createStore<Record<string, boolean>>(
      {},
      {
        name: `${name}.$touched`,
      }
    );

    const $focusedField = createStore<string>(null!, {
      name: `${name}.$focusedField`,
    });

    const $valid = combine($errors, (state) => Object.keys(state).length === 0);

    // @ts-expect-error deepmerge types are weird
    const patchData = sample({
      clock: patch,

      source: $values,

      fn: (values, payload) =>
        deepmerge(values as any, payload as any, {
          arrayMerge: (_, sourceArray) => sourceArray,
        }),
    });

    sample({
      source: $initialValues,

      filter: () => Boolean(reinitialize),

      target: reset,
    });

    bridge(() => {
      const manualValidateWithMeta = sample({
        clock: manualValidate,

        source: {
          values: $values,

          schema: $schema,
        },
      });

      const clock = [manualValidateWithMeta, $schema.updates];

      if (validateOn.includes(ValidateOnEventType.Blur)) {
        clock.push(blured);
      }

      if (validateOn.includes(ValidateOnEventType.Focus)) {
        clock.push(focused);
      }

      if (validateOn.includes(ValidateOnEventType.Change)) {
        clock.push(change);
      }

      sample({
        clock: clock,

        source: {
          values: $values,

          schema: $schema,
        },

        fn: ({ values, schema }) => ({
          values,

          schema,
        }),

        target: validateFx,
      });
    });

    sample({
      clock: submit,

      source: {
        values: $values,

        schema: $schema,
      },

      target: validateBeforeSubmitFx,
    });

    sample({
      clock: validateBeforeSubmitFx.doneData,

      source: $values,

      target: submitted,
    });

    sample({
      clock: validateBeforeSubmitFx.failData,

      source: {
        values: $values,
      },

      fn: ({ values }, errors) => ({
        values,

        errors,
      }),

      target: rejected,
    });

    $values
      .on(change, (state, [key, value]) => set(state, key, value))

      .on([put, patchData], (_, payload) => payload);

    const resetWithValues = sample({
      clock: reset,

      source: $initialValues,

      fn: (initialValues, values) => values ?? initialValues,
    });
    const reinitWithValues = sample({
      clock: reinit,

      source: $initialValues,
    });

    $values
      .on(
        resetWithValues,

        (_, payload) => payload
      )

      .on(reinitWithValues, (_, values) => values);

    $focusedField
      .on(focused, (_, [path]) => path)

      .reset(blured, reinit);

    $dirty
      .on(change, (state, [key]) => {
        if (state[key]) return state;

        return {
          ...state,
          [key]: true,
        };
      })

      .reset(reset, reinit);

    $submitCount.on(submit, (state) => state + 1).reset(reset, reinit);

    $touched
      .on(blured, (state, [path]) => {
        if (state[path]) return state;

        return {
          ...state,
          [path]: true,
        };
      })

      .reset(reset, reinit);

    $validationErrors
      .on(baseValidateFx.failData, (_, payload) => payload ?? {})

      .reset(reset, baseValidateFx.doneData, reinit);

    const meta = {
      $dirty,

      $errors,

      $values,

      $schema,

      $touched,

      $disabled,

      $submitted,

      $focusedField,

      change,

      focused,

      blured,

      validateOn,

      showValidationWhen,
    };

    return {
      $valid,
      $values,
      $errors,
      $disabled,
      $submitted,
      $submitCount,
      $initialValues,
      $focused: combine($focusedField, Boolean),
      $dirty: combine($dirty, (state) => Object.keys(state).length > 0),
      $touched: combine($touched, (state) => Object.keys(state).length > 0),
      $touchedFields: $touched,
      $dirtyFields: $dirty,

      put,
      patch,
      reset,
      reinit,
      submit,
      blured,
      focused,
      rejected,
      submitted,
      changed: change,
      validate: manualValidate,

      fields: createFields<V>(meta as any),

      __: {
        lowLevelAPI: {
          $$error: atom(() => {
            const setErrors = createEvent<MappedErrors<V>>(
              `${name}.lowLevelAPI.$$error.setErrors`
            );

            $validationErrors.on(
              setErrors,
              (current, errors: MappedErrors<V>): MappedErrors<V> =>
                deepmerge(current as any, errors as any, {
                  arrayMerge: (_, sourceArray) => sourceArray,
                })
            );

            return { setErrors };
          }),

          readValuesFx: attach({
            name: `${name}.lowLevelAPI.readValuesFx`,
            source: $values,
            effect: async (values) => values,
          }),
        },
      },
    };
  }
);

async function validate<V>({
  values,
  schema,
}: {
  values: V;
  schema: AnySchema;
}) {
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (error) {
    if (ValidationError.isError(error)) {
      // eslint-disable-next-line no-throw-literal
      throw yupToFormErrors(error) as MappedErrors<V>;
    }
  }
}

// $initialValues + reset
// $errors -> map
// $touched and others naming
// field change -> set
// useField more generic selectors

// array methods: add, remove, reoder, replace
// object method patch

export { createForm };
export type { DecoratedFormModel };
