import { Suspense } from 'react';
import type { RouteObject } from 'react-router-dom';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';
import { AdaptivePaper } from '@kuna-pay/core/shared/ui/adaptive-paper';

const Page = lazyWithRetryAndRefresh(
  'SignInPage',
  () => import('./page.component')
);

const SignInPage: RouteObject = {
  path: '/sign-in',

  Component: () => (
    <Suspense fallback={<AdaptivePaper.Loading height={638} />}>
      <Page />
    </Suspense>
  ),
};

export { SignInPage };
