enum MonitoringErrorType {
  API = 'api',
}

enum MonitoringApiRequestKind {
  Mutation = 'mutation',
  Query = 'query',
}

export { MonitoringApiRequestKind, MonitoringErrorType };
