import { useUnit } from 'effector-react';
import type { FormHTMLAttributes, PropsWithChildren } from 'react';
import { memo, useContext } from 'react';

import type { FormModel } from '../lib';
import { FormContext } from './context';

type FormProps = Omit<
  FormHTMLAttributes<HTMLFormElement>,
  'onSubmit' | 'name'
> & {
  name: `${string}-form`;
  use: FormModel<any>;
};

const Form = Object.assign(
  memo(({ use, children, ...props }: PropsWithChildren<FormProps>) => {
    const [onSubmit, onReset] = useUnit([use.submit, use.reset]);

    return (
      <FormContext.Provider value={use}>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            onSubmit();
          }}
          onReset={(event) => {
            event.preventDefault();

            onReset(undefined);
          }}
          {...props}
        >
          {children}
        </form>
      </FormContext.Provider>
    );
  }),
  {
    useForm: <T,>() => useContext(FormContext) as FormModel<T>,
  }
);

export { Form };
export type { FormProps };
