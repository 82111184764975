import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Company24Icon } from '@kuna-pay/ui/icons';
import { Link } from '@kuna-pay/ui/router';
import { Tabs } from '@kuna-pay/ui/ui/tabs';

import { routes } from '@kuna-pay/merchant/shared/router';
import { NavButton } from '@kuna-pay/merchant/shared/ui/nav-button';

import { SettingsLayoutTabs } from '../page.config';

const AccountSettingsNavItem = {
  useNavLink: () => {
    // const isActive = useMatch(routes.settings.account);

    const to = routes.settings.account.clone();

    // if (!isActive) {
    //   to.searchParams.set('from', 'Menu');
    // }

    return to;
  },

  Tab: memo(() => {
    const { t } = useTranslation();

    const to = AccountSettingsNavItem.useNavLink();

    return (
      <Tabs.Trigger value={SettingsLayoutTabs.Account} asChild>
        <Link.ForwardRef to={to} variant='text'>
          {t('pages.settings.host.tabs.account')}
        </Link.ForwardRef>
      </Tabs.Trigger>
    );
  }),

  NavButton: memo(() => {
    const { t } = useTranslation();

    const to = AccountSettingsNavItem.useNavLink();

    return (
      <NavButton to={to} icon={<Company24Icon />}>
        {t('pages.settings.host.tabs.account')}
      </NavButton>
    );
  }),
};

export { AccountSettingsNavItem };
