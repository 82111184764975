enum LocalStoragePersistKeys {
  AccessToken = 'accessToken',
  RefreshToken = 'refreshToken',

  RefCurrency = 'ref-currency',

  PaymentPreferencesDiscoveryPopup = 'payment-preferences-discovery-popup',
  MfaNotEnabledAlert = '2fa-not-enabled-alert',

  RateYourExperienceFeedback = 'rate-your-experience-feedback',

  Referrer = 'ref',
}

export { LocalStoragePersistKeys };
