import copy from 'copy-to-clipboard';
import { attach, createEffect, createEvent, sample } from 'effector';

import { atom } from '@kuna-pay/utils/misc';

type CopyValue = string | number;
type CopyPayload = CopyValue | { value: CopyValue; message: string };

//FIXME: factory + provider
const $$copy = atom(() => {
  const copyFx = createEffect(copy);
  const copyToClipboardFx = attach({
    mapParams: (params: { value: string; message?: string }): string =>
      params.value,
    effect: copyFx,
  });
  const copyClicked = createEvent<CopyPayload>();

  sample({
    clock: copyClicked,
    fn: (value) => {
      if (typeof value === 'string' || typeof value === 'number') {
        return { value: `${value}` };
      }

      return { ...value, value: `${value.value}` };
    },
    target: copyToClipboardFx,
  });

  return { copyClicked, copyToClipboardFx };
});

export { $$copy };
