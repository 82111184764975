import { UserMenuGate } from './user-menu.context';
import { DesktopUserMenu, UserInfo } from './user-menu.desktop.component';
import { MobileUserMenuNavigation } from './user-menu.mobile.component';

const UserMenu = {
  /* KUPAY-2039 */
  Gate: UserMenuGate,

  Mobile: MobileUserMenuNavigation,

  Desktop: DesktopUserMenu,

  MobileUserInfo: UserInfo,
};

export { UserMenu };
